import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Progress,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import { useParams, Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { Constants } from "../../app/constants/Constants";
import Loading from "../../app/components/loading/Loading";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import '../../assets/css/familyTreeView.css';
import { checkPermission, deliveryOrderStatusColor, displayTypeWithColor, newSpaceBeforeCapitalLetter, returnPaymentIcon, returnPriceWithCurrency, salesOrderStatusColor } from "../../app/common/function/function";
import moment from "moment";
import { RoutesList } from "../../app/constants/RoutesList";
import AccountIcon from 'mdi-react/AccountIcon';
import NotebookMultipleIcon from 'mdi-react/NotebookMultipleIcon';
import GiftOpenOutlineIcon from 'mdi-react/GiftOpenOutlineIcon'
import FileDocumentBoxCheckOutlineIcon from 'mdi-react/FileDocumentBoxCheckOutlineIcon'
import TextBoxPlusOutlineIcon from 'mdi-react/TextBoxPlusOutlineIcon'
import ClockCheckOutlineIcon from 'mdi-react/ClockCheckOutlineIcon'
import ArrowUpThinIcon from 'mdi-react/ArrowUpThinIcon';
import ArrowDownThinIcon from 'mdi-react/ArrowDownThinIcon';
import SiteMapCard from "./SubView/SiteMapCard";
import { history } from '../../';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { ContractLiabilityTimelineObject } from "../../app/models/contractLiability";
import _ from "lodash";

const ContractLiabilityTimeline = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  //Use Store
  const { contractLiabilityStore ,commonStore } = useStore();
  const { getContractLiabilityTimelineDetail } = contractLiabilityStore;
  const { loading, setLoading, windowSize } = commonStore;
  const [displayCustomerSalesOrder, setDisplayCustomerSalesOrder] = useState(true);
  const [displayRedemptions, setDisplayRedemptions] = useState(true);
  const [displayCreditTransactions, setDisplayCreditTransactions] = useState(true);
  const [displayPostedTransactions, setDisplayPostedTransactions] = useState(false);
  const [contractLiabilityTimelineDetail, setContractLiabilityTimelineDetail] = useState<ContractLiabilityTimelineObject | undefined>(undefined);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: intl.formatMessage({ id: "CustomerProfile" }), urlPath: `${RoutesList.customer}/view/${contractLiabilityTimelineDetail?.customerId}`}, { title: intl.formatMessage({ id: "ContractLiabilitySiteMap" }), urlPath: `${RoutesList.contractLiabilitySiteMap}/${contractLiabilityTimelineDetail?.customerId}`}];
  const circleColumnPaddingRight = "18px"
  const circleBorderWidth = "3px";
  const orangeColor = "#f1b44c";
  const blueColor = "#50a5f1";
  const greenColor = Constants.lightgreen;
  const pinkColor = Constants.candypink;
  const lightPurpleColor = Constants.lightpurple;
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer])
  const blnPermissionViewCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder])
  const blnPermissionViewCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder])
  const blnPermissionViewReceipt = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const minusInnerWidth = 20;
  
  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageContractLiability], true)) {
      return;
    }
    
    async function fetchContractLiabilityTimeline() {
      setLoading(true);
      let resultAPI : ContractLiabilityTimelineObject | undefined = await getContractLiabilityTimelineDetail(id);
      if (resultAPI) {
        let resultAPITemp : ContractLiabilityTimelineObject = _.cloneDeep(resultAPI);
        if (resultAPITemp.salesOrders) {
          resultAPITemp.isShowSubContent = false;
          resultAPITemp.salesOrders = resultAPITemp.salesOrders.map((valueSalesOrderTemp)=> {
            let timeLineDetails: any = [];
            if (valueSalesOrderTemp.redemptions) {
              valueSalesOrderTemp.redemptions.map((valueRedemptionsTemp)=> {
                timeLineDetails.push({
                  ...valueRedemptionsTemp,
                  type: Constants.redemption,
                  date: valueRedemptionsTemp.performDateTime,
                  isShowSubContent: false
                })
              })
            }
            if (valueSalesOrderTemp.creditTransactions) {
              valueSalesOrderTemp.creditTransactions.map((valueCreditTransactionsTemp)=> {
                timeLineDetails.push({
                  ...valueCreditTransactionsTemp,
                  type: Constants.creditTransaction,
                  date: valueCreditTransactionsTemp.actionDate,
                  isShowSubContent: false
                })
              })
            }
            valueSalesOrderTemp.timeLineDetails = timeLineDetails;
            valueSalesOrderTemp.timeLineDetails = valueSalesOrderTemp.timeLineDetails.sort((a, b) => (a.date > b.date) ? 1 : -1)
            return valueSalesOrderTemp;
          })
          setContractLiabilityTimelineDetail(resultAPITemp);
        }
      }
      setLoading(false);
    }

    fetchContractLiabilityTimeline();
  }, [])

  const onClickType = (type: string) => {
    if (type === Constants.customerSalesOrder) {
      setDisplayCustomerSalesOrder((prev)=> !prev);
    }
    else if (type === Constants.redemption) {
      setDisplayRedemptions((prev)=> !prev);
    }
    else if (type === Constants.creditTransaction) {
      setDisplayCreditTransactions((prev)=> !prev);
    }
    else if (type === Constants.postedTransaction) {
      setDisplayPostedTransactions((prev)=> !prev);
    }
  }

  const headerTitle = (title: string, subString: string, blnTitleLinkColor?: boolean, titleSubString?: string, statusString?: string, statusStringColor?: Function) => {
    return (
      <>
        <h5 className="pb-1 mt-2 mb-0" style={{fontSize: '14px', color: blnTitleLinkColor ? Constants.lightpurple : undefined}}>{`${title}`} <span style={{fontSize: '13px'}} className={"text-muted"}>{titleSubString}</span></h5>
        {subString && <p className="text-muted mt-0" style={{fontSize: '13px', marginBottom: '0px'}}>{`${subString}`} { statusString && statusStringColor && <span style={{color: statusStringColor(statusString)}}><strong><i>{`(${newSpaceBeforeCapitalLetter(statusString)})`}</i></strong></span>}</p>}
      </>
    )
  }

  const contentTitle = (title: string, subString: string) => {
    return (
      <div>
        <p style={{fontSize: '13px'}} className="text-muted mb-1">{title}</p>
        <h5 style={{fontSize: '14px'}}>{subString}</h5>
      </div>
    )
  }

  const displayExpandArrow = (isShowSubContent: boolean, onClickFunction: any, paddingRightHandling: boolean = false) => {
    return (
      <div className="flex-right pointer-clickable" style={{flex: 1, marginLeft: paddingRightHandling ? '18px': undefined, marginRight: paddingRightHandling ? '-18px' : undefined}}
        onClick={()=> {
          onClickFunction();
        }}>
        {
          isShowSubContent
          ?
          <ArrowUpThinIcon color={"grey"}/>
          :
          <ArrowDownThinIcon color={"grey"}/>
        }
      </div>
    )
  }

  const expandRowFunction = (blnExpand: boolean) => {
    let contractLiabilityTimelineDetailTemp = _.cloneDeep(contractLiabilityTimelineDetail);
    if (contractLiabilityTimelineDetailTemp) {
      contractLiabilityTimelineDetailTemp.isShowSubContent = blnExpand;
      if (contractLiabilityTimelineDetailTemp.salesOrders) {
        contractLiabilityTimelineDetailTemp.salesOrders = contractLiabilityTimelineDetailTemp.salesOrders.map((valueSalesOrderTemp)=> {
          if (valueSalesOrderTemp.timeLineDetails) {
            valueSalesOrderTemp.timeLineDetails = valueSalesOrderTemp.timeLineDetails.map((valueTimelineDetails)=> {
              valueTimelineDetails.isShowSubContent = blnExpand;
              if (valueTimelineDetails.postedTransactions) {
                valueTimelineDetails.postedTransactions = valueTimelineDetails.postedTransactions.map((valuePostedTransactionsTemp)=> {
                  valuePostedTransactionsTemp.isShowSubContent = blnExpand;
                  return valuePostedTransactionsTemp;
                })
              }
              return valueTimelineDetails;
            })
          }
          return ({
            ...valueSalesOrderTemp,
            isShowSubContent: blnExpand
          })
        })
      }
    }
    setContractLiabilityTimelineDetail(contractLiabilityTimelineDetailTemp);
  }

  return (
    <div>
      <div className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ContractLiabilityTimeline" }) })}</title>
        </MetaTags>
        {
          loading
          ?
          <Loading />
          :
          !contractLiabilityTimelineDetail
          ?
          <div/>
          :
          <Container fluid>
            <BreadCrumb 
              breadCrumbList={breadCrumbList} 
              activeBreadCrumbTitle={intl.formatMessage({ id: "ContractLiabilityTimeline" })}/>
            <div style={{overflowX: 'scroll'}}>
              <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : ""} style={{marginRight: "42px"}}>
                {displayTypeWithColor(blueColor, intl.formatMessage({id: "Customer"}), ()=> {})}
                {displayTypeWithColor(displayCustomerSalesOrder ? orangeColor : Constants.lightgrey, intl.formatMessage({id: "CustomerSalesOrder"}), ()=> {onClickType(Constants.customerSalesOrder)})}
                {displayTypeWithColor(displayRedemptions ? pinkColor : Constants.lightgrey, intl.formatMessage({id: "Redemptions"}), ()=> {onClickType(Constants.redemption)})}
                {displayTypeWithColor(displayCreditTransactions ? lightPurpleColor : Constants.lightgrey, intl.formatMessage({id: "CreditTransaction"}), ()=> {onClickType(Constants.creditTransaction)})}
                {displayTypeWithColor(displayPostedTransactions ? greenColor : Constants.lightgrey, intl.formatMessage({id: "PostedTransaction"}), ()=> {onClickType(Constants.postedTransaction)})}
                {displayTypeWithColor(Constants.lightgreen, <ArrowDownThinIcon color="white"/>, ()=> {expandRowFunction(true)}, true)}
                {displayTypeWithColor(Constants.lightgreen, <ArrowUpThinIcon color="white"/>, ()=> {expandRowFunction(false)}, true)}
              </div>
              <div className="treeview js-treeview" style={{overflowY: 'scroll', marginLeft: `-${windowSize.innerWidth/minusInnerWidth}px`}}>
                <style type="text/css">
                  {
                    ` 
                      .treeview ul li { padding: 50px 0px 0px ${windowSize.innerWidth/10}px; } 
                      .treeview ul li:after {  width: ${windowSize.innerWidth/5}px; }
                    `
                  }
                </style>
                <ul style={{}}>
                  <li className="treeview-lightgrey">
                    <SiteMapCard
                      colorString="light-blue"
                      color={blueColor}
                      circleColumnPaddingRight={"0px"}
                      circleBorderWidth={circleBorderWidth}
                      backgroundColor={"white"}
                      blnMarginLeft={"3px"}
                      IconDisplay={AccountIcon}
                      blnPortrait={true}
                      headerUI={()=> {
                        return (
                          <div className="flex-align-items-center-without-height" style={{flex: 1}}>
                            {
                              blnPermissionViewCustomer
                              ?
                              <Link to={`/${RoutesList.customer}/view/${contractLiabilityTimelineDetail.customerId}`} target={Constants.blank} rel="noopener noreferrer">
                                <div>
                                  {headerTitle(returnPriceWithCurrency(contractLiabilityTimelineDetail.amount), `${contractLiabilityTimelineDetail.customerName || ""} (${contractLiabilityTimelineDetail.customerNo || ""})`, true)}
                                </div>
                              </Link>
                              :
                              <div>
                                {headerTitle(returnPriceWithCurrency(contractLiabilityTimelineDetail.amount), `${contractLiabilityTimelineDetail.customerName || ""} (${contractLiabilityTimelineDetail.customerNo || ""})`)}
                              </div>
                            }
                            {
                              displayExpandArrow(
                                contractLiabilityTimelineDetail.isShowSubContent, 
                                ()=> {
                                  setContractLiabilityTimelineDetail((valueContractLiabilityTimeLineTemp: any)=> ({
                                  ...valueContractLiabilityTimeLineTemp,
                                  isShowSubContent: !valueContractLiabilityTimeLineTemp?.isShowSubContent
                                  }))
                                }
                              )
                            }
                          </div>
                        )
                      }}
                      contentUI={()=> {
                        let processValue = contractLiabilityTimelineDetail.amount === 0 ? 100 : (contractLiabilityTimelineDetail.amount-contractLiabilityTimelineDetail.balance)/contractLiabilityTimelineDetail.amount*100;
                        return (
                          contractLiabilityTimelineDetail.isShowSubContent
                          ?
                          <div className="text-xl-start" style={{padding: '18px', marginTop: '18px', width:"380px", borderTop: 'solid', borderColor: '#e3e5ef'}}>
                            <Row>
                              <Col xs="6">
                                {contentTitle(intl.formatMessage({id: "Balance"}), returnPriceWithCurrency(contractLiabilityTimelineDetail.balance))}
                              </Col>
                              <Col xs="6">
                                {contentTitle(intl.formatMessage({id: "BalanceAfterExpired"}), returnPriceWithCurrency(contractLiabilityTimelineDetail.balanceAfterExpired))}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="6">
                                {contentTitle(intl.formatMessage({id: "ExchangedToCredit"}), returnPriceWithCurrency(contractLiabilityTimelineDetail.exchangedToCredit))}
                              </Col>
                              <Col xl='6'>
                                {contentTitle(intl.formatMessage({id: "UsedCredit"}), returnPriceWithCurrency(contractLiabilityTimelineDetail.usedCredit))}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl='6'>
                                {contentTitle(intl.formatMessage({id: "ExchangedToCash"}), returnPriceWithCurrency(contractLiabilityTimelineDetail.exchangedToCash))}
                              </Col>
                              <Col xs="6">
                                {contentTitle(intl.formatMessage({id: "ExpiredDate"}), moment(contractLiabilityTimelineDetail.expiredDate).format(Constants.displayDateFormat))}
                              </Col>
                            </Row>
                            <Col xl='12'>
                              <p style={{fontSize: '13px'}} className="text-muted mb-2">{processValue === 100 ? intl.formatMessage({id: "Completed"}) : intl.formatMessage({id: "Progressing"})}</p>
                              <Progress striped color={processValue === 100 ? "primary" : "secondary"} animated value={processValue}></Progress>
                            </Col>
                          </div>
                          :
                          <div className="mb-3"/>
                        )
                      }}/>
                    {
                      displayCustomerSalesOrder
                      &&
                      <ul>
                      {
                        contractLiabilityTimelineDetail.salesOrders.map((valueSalesOrderTemp, index)=> (
                          <li key={`sales-order-${index}`} className="treeview-lightgrey" style={{paddingLeft: windowSize.innerWidth/minusInnerWidth}}>
                            <SiteMapCard
                              colorString="orange"
                              color={orangeColor}
                              circleColumnPaddingRight={circleColumnPaddingRight}
                              circleBorderWidth={circleBorderWidth}
                              backgroundColor={"white"}
                              IconDisplay={NotebookMultipleIcon}
                              blnPortrait={true}
                              headerUI={()=> {
                                return (
                                  <div className="flex-align-items-center-without-height" style={{flex: 1}}>
                                    {
                                      blnPermissionViewCustomerSalesOrder
                                      ?
                                      <Link to={`/${RoutesList.customerSalesOrder}/view/${valueSalesOrderTemp.customerSalesOrderId}`} target={Constants.blank} rel="noopener noreferrer">
                                        {headerTitle(`${returnPriceWithCurrency(valueSalesOrderTemp.totalAmount)}`, `${valueSalesOrderTemp.customerSalesOrderNo}`, true, `(${moment(valueSalesOrderTemp.submissionDate).format(Constants.displayDateAndTimeFormat)})`, valueSalesOrderTemp.status, salesOrderStatusColor)}
                                      </Link>
                                      :
                                      <div>
                                        {headerTitle(`${returnPriceWithCurrency(valueSalesOrderTemp.totalAmount)}`, `${valueSalesOrderTemp.customerSalesOrderNo}`, false, `(${moment(valueSalesOrderTemp.submissionDate).format(Constants.displayDateAndTimeFormat)})`, valueSalesOrderTemp.status, salesOrderStatusColor)}
                                      </div>
                                    }
                                    {
                                      displayExpandArrow(
                                        valueSalesOrderTemp.isShowSubContent, 
                                        ()=> {
                                          let contractLiabilityTimelineDetailTemp = _.cloneDeep(contractLiabilityTimelineDetail);
                                          let valueSalesOrderFinalTemp = _.cloneDeep(valueSalesOrderTemp);
                                          valueSalesOrderFinalTemp.isShowSubContent = !valueSalesOrderFinalTemp.isShowSubContent;
                                          contractLiabilityTimelineDetailTemp.salesOrders[index] = valueSalesOrderFinalTemp;
                                          setContractLiabilityTimelineDetail(contractLiabilityTimelineDetailTemp);
                                        },
                                        true
                                      )
                                    }
                                  </div>
                                )
                              }}
                              contentUI={()=> {
                                let processValue = valueSalesOrderTemp.entitleAmount === 0 ? 100 : valueSalesOrderTemp.usedAmount/(valueSalesOrderTemp.entitleAmount + valueSalesOrderTemp.usedAmount)*100;
                                return (
                                  valueSalesOrderTemp.isShowSubContent
                                  ?
                                  <div className="text-xl-start" style={{padding: '18px', width:"380px", marginTop: "18px", marginRight: "-18px",borderTop: 'solid', borderColor: '#e3e5ef'}}>
                                    <Row>
                                      <Col xs="6">
                                        {contentTitle(intl.formatMessage({id: "UsedAmount"}), returnPriceWithCurrency(valueSalesOrderTemp.usedAmount))}
                                      </Col>
                                      <Col xs="6">
                                        {contentTitle(intl.formatMessage({id: "EntitleAmount"}), returnPriceWithCurrency(valueSalesOrderTemp.entitleAmount))}
                                      </Col>
                                    </Row>
                                    <Col xl='12'>
                                      <p style={{fontSize: '13px'}} className="text-muted mb-2">{processValue === 100 ? intl.formatMessage({id: "Completed"}) : intl.formatMessage({id: "Progressing"})}</p>
                                      <Progress striped color={processValue === 100 ? "warning" : "secondary"} animated value={processValue}></Progress>
                                    </Col>
                                  </div>
                                  :
                                  <div className="mb-3"/>
                                )
                              }}/>
                              <ul style={{paddingLeft: '-100px'}}>
                                {
                                  (displayRedemptions || displayCreditTransactions)
                                  &&
                                  valueSalesOrderTemp.timeLineDetails.map((valueTimeLineDetails, indexTimeLineDetails)=> {
                                    let blnRedemptionType = valueTimeLineDetails.type === Constants.redemption
                                    let processValue = blnRedemptionType ? valueTimeLineDetails.usedAmount/valueTimeLineDetails.deliverOrderAmount*100 : 0;

                                    if ((blnRedemptionType && !displayRedemptions) ||  (!blnRedemptionType && !displayCreditTransactions)) {
                                      return (
                                        <div key={`timeline-details-${index}-${indexTimeLineDetails}`}/>
                                      )
                                    }

                                    return (
                                      <li key={`timeline-details-${index}-${indexTimeLineDetails}`} className={`treeview-lightgrey`} style={{paddingLeft: windowSize.innerWidth/minusInnerWidth, width:"800px"}}>
                                        <SiteMapCard
                                          colorString={blnRedemptionType ? "pink" : "purple"}
                                          color={blnRedemptionType ? pinkColor : lightPurpleColor}
                                          circleColumnPaddingRight={circleColumnPaddingRight}
                                          circleBorderWidth={circleBorderWidth}
                                          backgroundColor={"white"}
                                          IconDisplay={blnRedemptionType ? GiftOpenOutlineIcon : FileDocumentBoxCheckOutlineIcon }
                                          blnPortrait={true}
                                          headerUI={()=> {
                                            return (
                                              <div className="flex-align-items-center-without-height" style={{flex: 1}}>
                                                {
                                                  blnPermissionViewCustomerDeliverOrder
                                                  &&
                                                  blnRedemptionType
                                                  ?
                                                  <Link to={`/${RoutesList.customerRedemption}/view/${valueTimeLineDetails.customerDeliverOrderId}`} target={Constants.blank} rel="noopener noreferrer">
                                                    {headerTitle(`${returnPriceWithCurrency(valueTimeLineDetails.usedAmount)}`, `${valueTimeLineDetails.customerDeliverOrderNo}`, true, `(${moment(valueTimeLineDetails.performDateTime).format(Constants.displayDateAndTimeFormat)})`, valueTimeLineDetails.status, deliveryOrderStatusColor)}
                                                  </Link>
                                                  :
                                                  blnRedemptionType
                                                  ?
                                                  <div>
                                                    {headerTitle(`${returnPriceWithCurrency(valueTimeLineDetails.usedAmount)}`, `${valueTimeLineDetails.customerDeliverOrderNo}`, false, `(${moment(valueTimeLineDetails.performDateTime).format(Constants.displayDateAndTimeFormat)})`, valueTimeLineDetails.status, deliveryOrderStatusColor)}
                                                  </div>
                                                  :
                                                  <div>
                                                    {headerTitle(`${returnPriceWithCurrency(valueTimeLineDetails.occupiedCredits)}`, `${newSpaceBeforeCapitalLetter(valueTimeLineDetails.entityType)}`, false, `(${moment(valueTimeLineDetails.actionDate).format(Constants.displayDateAndTimeFormat)})`)}
                                                  </div>
                                                }
                                                {
                                                  displayExpandArrow(
                                                    valueTimeLineDetails.isShowSubContent, 
                                                    ()=> {
                                                      let contractLiabilityTimelineDetailTemp = _.cloneDeep(contractLiabilityTimelineDetail);
                                                      let valueTimeLineDetailsTemp = _.cloneDeep(valueTimeLineDetails);
                                                      valueTimeLineDetailsTemp.isShowSubContent = !valueTimeLineDetailsTemp.isShowSubContent;
                                                      contractLiabilityTimelineDetailTemp.salesOrders[index].timeLineDetails[indexTimeLineDetails] = valueTimeLineDetailsTemp;
                                                      setContractLiabilityTimelineDetail(contractLiabilityTimelineDetailTemp);
                                                    },
                                                    true
                                                  )
                                                }
                                              </div>
                                            )
                                          }}
                                          contentUI={()=> {
                                            return (
                                              valueTimeLineDetails.isShowSubContent
                                              ?
                                              <div className="text-xl-start" style={{padding: '18px', width:"500px", marginTop: "18px", marginRight: "-18px",borderTop: 'solid', borderColor: '#e3e5ef'}}>
                                                {
                                                  blnRedemptionType
                                                  ?
                                                  <Row>
                                                    <Col xs="6">
                                                      {contentTitle(intl.formatMessage({id: "DeliverOrderAmount"}), returnPriceWithCurrency(valueTimeLineDetails.deliverOrderAmount))}
                                                    </Col>
                                                  </Row>
                                                  :
                                                  <Row>
                                                    <Col xs="6">
                                                      {contentTitle(intl.formatMessage({id: "ActualCreditsUsed"}), returnPriceWithCurrency(valueTimeLineDetails.actualCreditsUsed))}
                                                    </Col>
                                                  </Row>
                                                }
                                                <Col xs="12" className="mt-2">
                                                  {contentTitle(blnRedemptionType ? intl.formatMessage({id: "DeliverOrderDetails"}) : intl.formatMessage({id: "Action"}), blnRedemptionType ? valueTimeLineDetails.deliverOrderDetails : valueTimeLineDetails.action)}
                                                </Col>
                                              </div>
                                              :
                                              <div className="mb-3"/>
                                            )
                                          }}/>
                                          {
                                            blnRedemptionType
                                            &&
                                            <ul style={{paddingLeft: '-100px'}}>
                                            {
                                              displayPostedTransactions
                                              &&
                                              valueTimeLineDetails.postedTransactions.map((valuePostedTransactionsTemp, indexPostedTransactions)=> {
                                                return (
                                                  <li key={`timeline-details-${index}-${indexTimeLineDetails}-${indexPostedTransactions}`} className="treeview-lightgrey" style={{paddingLeft: windowSize.innerWidth/minusInnerWidth}}>
                                                    <SiteMapCard
                                                      colorString="green"
                                                      color={greenColor}
                                                      circleColumnPaddingRight={circleColumnPaddingRight}
                                                      circleBorderWidth={circleBorderWidth}
                                                      backgroundColor={"white"}
                                                      IconDisplay={TextBoxPlusOutlineIcon}
                                                      blnPortrait={true}
                                                      headerUI={()=> {
                                                        return (
                                                          <div className="flex-align-items-center-without-height" style={{flex: 1}}>
                                                            {
                                                              <div>
                                                                <>
                                                                  <h5 className="pb-1 mt-2 mb-0" style={{fontSize: '14px'}}>
                                                                    {`${returnPriceWithCurrency(valuePostedTransactionsTemp.postedAmount)}`}
                                                                    <span style={{fontSize: '13px', color: Constants.red}}>{` (${returnPriceWithCurrency(valuePostedTransactionsTemp.postedCost)})`}</span>
                                                                  </h5>
                                                                  {
                                                                    valuePostedTransactionsTemp.isAfterExpired 
                                                                    &&
                                                                    <UncontrolledTooltip placement="top" target="afterexpired">
                                                                      {intl.formatMessage({ id: "AfterExpired" })}
                                                                    </UncontrolledTooltip>
                                                                  }
                                                                  <p className="text-muted mt-0 mb-0" style={{fontSize: '13px'}}>
                                                                    {moment(valuePostedTransactionsTemp.postedDate).format(Constants.displayDateAndTimeFormat)}
                                                                    {valuePostedTransactionsTemp.isAfterExpired && <ClockCheckOutlineIcon id="afterexpired" className="text-danger margin-left-4" size={18}/>}
                                                                  </p>
                                                                </>
                                                              </div>
                                                            }
                                                            {
                                                              displayExpandArrow(
                                                                valuePostedTransactionsTemp.isShowSubContent, 
                                                                ()=> {
                                                                  let contractLiabilityTimelineDetailTemp = _.cloneDeep(contractLiabilityTimelineDetail);
                                                                  let valuePostedTransactionsFinalTemp = _.cloneDeep(valuePostedTransactionsTemp);
                                                                  valuePostedTransactionsFinalTemp.isShowSubContent = !valuePostedTransactionsFinalTemp.isShowSubContent;
                                                                  contractLiabilityTimelineDetailTemp.salesOrders[index].timeLineDetails[indexTimeLineDetails].postedTransactions[indexPostedTransactions] = valuePostedTransactionsFinalTemp;
                                                                  setContractLiabilityTimelineDetail(contractLiabilityTimelineDetailTemp);
                                                                },
                                                                true
                                                              )
                                                            }
                                                          </div>
                                                        )
                                                      }}
                                                      contentUI={()=> {
                                                        return (
                                                          valuePostedTransactionsTemp.isShowSubContent
                                                          ?
                                                          <div className="text-xl-start" style={{padding: '18px', width:"380px", marginTop: "18px", marginRight: "-18px",borderTop: 'solid', borderColor: '#e3e5ef'}}>
                                                            {/* <Row>
                                                              <Col xs="6">
                                                                {contentTitle(intl.formatMessage({id: "PostedCost"}), returnPriceWithCurrency(valuePostedTransactionsTemp.postedCost))}
                                                              </Col>
                                                            </Row> */}
                                                            <Row>
                                                              <Col xs="6">
                                                                {contentTitle(intl.formatMessage({id: "SalesCategory"}), newSpaceBeforeCapitalLetter(valuePostedTransactionsTemp.postedSalesCategory))}
                                                              </Col>
                                                              <Col xs="6">
                                                                {contentTitle(intl.formatMessage({id: "Type"}), newSpaceBeforeCapitalLetter(valuePostedTransactionsTemp.postedType))}
                                                              </Col>
                                                            </Row>
                                                          </div>
                                                          :
                                                          <div className="mb-3"/>
                                                        )
                                                      }}/>
                                                  </li>
                                                )
                                              })
                                            }
                                          </ul>
                                          }
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </li>
                          ))
                        }
                      </ul>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        }
      </div>
    </div>
  )
}

export default observer(ContractLiabilityTimeline);