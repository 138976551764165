import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import _ from 'lodash';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { CreditAdjustmentListObject } from "../../app/models/creditAdjustment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnRefundStatusColor, returnSubBrandList } from "../../app/common/function/function";
import moment from "moment";
import CreditAdjustmentModal from "../Customer/Modal/CreditAdjustmentModal";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import MyButton from "../../app/components/form/MyButton";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import Loading from "../../app/components/loading/Loading";
import { PaginationRequestBody } from "../../app/models/pagination";
import BookOpenIcon from "mdi-react/BookOpenIcon";

const CreditAdjustment = () => {
  const intl = useIntl();

  //Use Params
  const { id } = useParams();

  //Use Store
  const { creditAdjustmentStore, customerStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, totalItem, prevPath, setShowDeletePrompt, setSuccessMessage, setErrorMessage } = commonStore;
  const { creditAdjustmentList, creditAdjustmentDetail, creditAdjustmentPaginationParams, getCreditAdjustment, deleteCreditAdjustment, getCreditAdjustmentWithId, creditAdjustmentReview, setCreditAdjustmentPaginationParams, setCreditAdjustmentList } = creditAdjustmentStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { branchDropDownList, isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CreditAdjustmentListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [creditAdjustmentModal, setCreditAdjustmentModal] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedReturnRefundStatus, setSelectedReturnRefundStatus] = useState<number | string>(AllStatus);
  const [selectedReturnRefundStatusId, setSelectedReturnRefundStatusId] = useState<number | string | undefined>(" ");
  const [returnRefundStatusList, setReturnRefundStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedCreditAdjustmentType, setSelectedCreditAdjustmentType] = useState<number | string>(AllType);
  const [selectedCreditAdjustmentTypeId, setSelectedCreditAdjustmentTypeId] = useState<number | string | undefined>(" ");
  const [creditAdjustmentTypeList, setCreditAdjustmentTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() :  AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const blnPermissionDeleteCreditAdjustment = checkPermission([PermissionConstants.DeleteCreditAdjustment]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionReviewCreditAdjustment = checkPermission([PermissionConstants.ReviewCreditAdjustment]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  let creditAdjustmentRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    returnRefundStatus: selectedReturnRefundStatusId,
    returnRefundStatusName: selectedReturnRefundStatus,
    creditAdjustmentType: selectedCreditAdjustmentTypeId,
    creditAdjustmentTypeName: selectedCreditAdjustmentType,
  }

  useEffect(() => {
    async function fetchCreditAdjustmentListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.creditAdjustment)
      if (blnBackNavigation) {
        if (creditAdjustmentPaginationParams) {
          setCurrentPage(creditAdjustmentPaginationParams.pageNumber);
          setSelectedBranchId(creditAdjustmentPaginationParams.branchId!);
          setSelectedBranchName(creditAdjustmentPaginationParams.branchName!);
          setSelectedReturnRefundStatusId(creditAdjustmentPaginationParams.returnRefundStatus)
          setSelectedReturnRefundStatus(creditAdjustmentPaginationParams.returnRefundStatusName || AllStatus)
          setSelectedCustomerId(creditAdjustmentPaginationParams.customerId || "")
          setSelectedCustomer(creditAdjustmentPaginationParams.customerName || "")
          setSelectedCreditAdjustmentTypeId(creditAdjustmentPaginationParams.creditAdjustmentType)
          setSelectedCreditAdjustmentType(creditAdjustmentPaginationParams.creditAdjustmentTypeName || AllType)
          creditAdjustmentRequestBody = creditAdjustmentPaginationParams;
        }
      }
      else {
        setCreditAdjustmentPaginationParams(undefined);
      }
      let aryAPI: any = [
        getCreditAdjustment(creditAdjustmentRequestBody),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.returnRefundStatus, AllStatus),
        getStaticReferenceWithType(Constants.creditAdjustmentType, AllType)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setReturnRefundStatusList(resultAPI[2]);
      setCreditAdjustmentTypeList(resultAPI[3]);
      setLocalLoading(false);
      setInitialCallAPI(false);

      if (id) {
        await onClickViewCreditAdjustment(id);
        window.history.replaceState(null, "", `/${RoutesList.creditAdjustment}`)
      }
    }

    if (!checkPermission([PermissionConstants.ManageCreditAdjustment], true)) {
      return;
    } 

    fetchCreditAdjustmentListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.creditAdjustment)
        if (!blnSetParams) {
          setCreditAdjustmentPaginationParams(undefined);
        }
      }
      setCreditAdjustmentList([]);
    })
  }, [])

  useEffect(() => {
    if (successMessage) {
      setTimeout(async () => {
        setSuccessMessage("");
        fetchCreditAdjustmentAPI(true)
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(() => {
    if (
      (selectedReturnRefundStatusId || selectedReturnRefundStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchCreditAdjustmentAPI(true);
    }
  }, [selectedReturnRefundStatusId]);

  useEffect(() => {
    if (
      (selectedCreditAdjustmentTypeId || selectedCreditAdjustmentTypeId === 0) &&
      !initialCallAPI
    ) {
      fetchCreditAdjustmentAPI(true);
    }
  }, [selectedCreditAdjustmentTypeId]);

  useEffect(() => {
    if (!initialCallAPI && displayCustomerDropdown) {
      fetchCreditAdjustmentAPI(true);
    }
  }, [selectedCustomerId])

  useEffect(() => {
    async function getCustomerDropdownFunction () {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
      fetchCreditAdjustmentAPI(true, Constants.emptyData);
    }
  }, [selectedBranchId])

  async function fetchCreditAdjustmentAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCreditAdjustment({
      ...creditAdjustmentRequestBody,
      ...customerId === Constants.emptyData && { customerId: "" }
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const onClickDelete = async (row: CreditAdjustmentListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const CreditAdjustmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "creditAdjustmentNo",
      text: intl.formatMessage({ id: "CreditAdjustmentNo" }).toUpperCase(),
      style: {
        width: '20%'
      },
    },
    {
      dataField: "requestDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div>
            {moment(row.requestDate).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.requestDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "adjustmentType",
      text: intl.formatMessage({ id: "CreditAdjustmentType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.adjustmentType)}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={returnRefundStatusColor}/>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.creditAdjustment}/view/${row.id}`}
              type={'view'} />
            {
              blnPermissionReviewCreditAdjustment && row.status == Constants.pending
              ?
              <TableActionButton
                to="#"
                type={'edit'}
                onClick={() => onClickViewCreditAdjustment(row.id)} />
              :
              <TableActionButton 
                type="viewModal"
                onClick={()=> onClickViewCreditAdjustment(row.id)}/>
            }
            {
              blnPermissionDeleteCreditAdjustment
              &&
              row.status == Constants.pending
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  const onClickSelect = (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickViewCreditAdjustment = async (creditAdjustmentId: any) => {
    setLocalLoading(true);
    await getCreditAdjustmentWithId(creditAdjustmentId)
    setCreditAdjustmentModal(true);
    setLocalLoading(false);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CreditAdjustment" }) })}
        title={intl.formatMessage({ id: "CreditAdjustment" })}
        breadCrumbList={breadCrumbList}
      >
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CustomerRefund" }) })}
                name="returnRefundStatus"
                options={returnRefundStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedReturnRefundStatus}
                initialValue={selectedReturnRefundStatusId}
                setFieldLabel={setSelectedReturnRefundStatus}
                setFieldValue={setSelectedReturnRefundStatusId}
                disabled={localLoading || loading}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "CreditAdjustmentType" })}
                name="creditAdjustmentType"
                options={creditAdjustmentTypeList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedCreditAdjustmentType}
                initialValue={selectedCreditAdjustmentTypeId}
                setFieldLabel={setSelectedCreditAdjustmentType}
                setFieldValue={setSelectedCreditAdjustmentTypeId}
                disabled={localLoading || loading}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          !initialCallAPI
          &&
          <Row className={`${loadingCustomer ? "margin-bottom-16" : ""}`}>
            <Col xl={'4'} md={'6'} xs={'12'}>
              {
                displayCustomerDropdown 
                &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              }
            </Col>
            {/* <Col xl={'4'} md={'6'} xs={'6'}>
              <MyButton
                type="button"
                class="btn btn-primary margin-top-28 "
                content={intl.formatMessage({ id: "AdvancedSearch" }).toUpperCase()}
                loading={localLoading || loading || loadingAdjust}
                disable={localLoading || loading || loadingAdjust}
                onClick={() => { setCustomerSearchModal(true) }}
              />
            </Col> */}
          </Row>
        }
        {
          localLoading
            ?
            <Loading/>
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CreditAdjustmentColumns}
              data={creditAdjustmentList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditAdjustment" }) })}
              objSorted={defaultSorted}
              returnRefundStatus={selectedReturnRefundStatusId}
              returnRefundStatusName={selectedReturnRefundStatus}
              creditAdjustmentType={selectedCreditAdjustmentTypeId}
              creditAdjustmentTypeName={selectedCreditAdjustmentType}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              requestAPI={getCreditAdjustment} />
        }
        <DeleteModal
          displayName={selectedRow?.creditAdjustmentNo}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCreditAdjustment(selectedRow?.id || "", selectedRow?.creditAdjustmentNo || "");
            let currentPageTemp = currentPage;
            if (creditAdjustmentList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCreditAdjustment({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect} />
      }
      {
        creditAdjustmentModal &&
        <CreditAdjustmentModal
          blnShow={creditAdjustmentModal}
          setModal={setCreditAdjustmentModal}
          customerId={selectedRow?.customerId}
          creditAdjustmentId={selectedRow?.id}
          creditAdjustmentReview={creditAdjustmentReview}
          selectedCreditAdjustmentDetail={creditAdjustmentDetail}
          blnView={true}
        />
      }
    </div>
  );
}

export default observer(CreditAdjustment);
