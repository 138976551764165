import { PaginationRequestBody } from "../models/pagination";
import { CustomerRefundDetailObject, CustomerRefundListObject } from "../models/customerRefund";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class customerRefundStore {
  customerRefundList: CustomerRefundListObject[] = [];
  customerRefundDetail: CustomerRefundDetailObject | undefined = undefined;
  customerRefundPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerRefundList = [];
    this.customerRefundDetail = undefined;
    this.customerRefundPaginationParams = undefined;
  }

  setCustomerRefundList = (customerRefundList: CustomerRefundListObject[]) => {
    this.customerRefundList = customerRefundList;
  }

  setCustomerRefundDetail = (customerRefundDetail: CustomerRefundDetailObject | undefined) => {
    this.customerRefundDetail = customerRefundDetail;
  }

  setCustomerRefundPaginationParams = (customerRefundPaginationParams: PaginationRequestBody | undefined) => {
    this.customerRefundPaginationParams = customerRefundPaginationParams;
  }

  getCustomerRefund = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      this.setCustomerRefundPaginationParams(PaginationRequestBody);
      const resultCustomerRefund = await agent.CustomerRefund.customerRefundList(PaginationRequestBody);
      runInAction(() => {
        this.customerRefundList = resultCustomerRefund.data;
        store.commonStore.setTotalItem(resultCustomerRefund.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerRefundList = [];
    }
  }

  getCustomerRefundWithId = async (id: string) => {
    try{
      const resultCustomerRefundDetail = await agent.CustomerRefund.customerRefundDetail(id);
      runInAction(() => {
        if (resultCustomerRefundDetail.refundDetails) {
          resultCustomerRefundDetail.refundDetails = resultCustomerRefundDetail.refundDetails.sort((a, b) => (a.purchaseType > b.purchaseType) ? 1 : -1)
        }
        this.customerRefundDetail = resultCustomerRefundDetail;
      });
      return Promise.resolve(resultCustomerRefundDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerRefundDetail = undefined;
    }
  }
}