import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, getPurchaseTypeIcon, IncludesLocationPathName, returnPriceWithCurrency, displayPromotionCode, returnRefundStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Label, FormFeedback } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { RefundDetail } from "../../app/models/customerRefund";
import MyButton from "../../app/components/form/MyButton";

const CustomerRefundDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { customerRefundStore, customerSalesOrderStore, commonStore } = useStore();
  const { successMessage, loading, setLoading } = commonStore;
  const { customerRefundDetail, setCustomerRefundDetail, getCustomerRefundWithId } = customerRefundStore;
  const { reviewCustomerRefundCredit } = customerSalesOrderStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ExchangeToCredit" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "ExchangeToCredit" }), urlPath: RoutesList.exchangeToCredit }];
  const customerRefundFullDetail = customerRefundDetail || {
    id: "",
    customerName: "",
    customerId: "",
    branchName: "",
    salesOrderId: "",
    salesOrderNo: "",
    customerReturnRefundNo: "",
    actionBy: "",
    actionOn: "",
    issueDate: "",
    salesOrderPaymentStatus: "",
    status: "",
    subTotalExchangeAmount: "",
    processingFees: "",
    totalExchangeAmount: "",
    refundDetails: []
  }

  const blnPermissionReviewCustomerSalesOrderCredit = checkPermission([PermissionConstants.ReviewCustomerSalesOrderCredit])
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const blnPartialPayment = customerRefundFullDetail.salesOrderPaymentStatus == Constants.partial;
  const blnPendingStatus = customerRefundFullDetail.status == Constants.pending;

  // validation
  const defaultReviewCustomerRefundDetails = {
    customerRefundId: "",
    remark: "",
    workflowAction: "",
  }
  const [reviewCustomerRefundDetails, setReviewCustomerRefundDetails] = useState(defaultReviewCustomerRefundDetails)
  const [errorState, setErrorState] = useState({
    remark: false,
  })
  const [loadingReview, setLoadingReview] = useState(false);

  async function fetchCustomerRefundDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getCustomerRefundWithId(id),
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCustomerRefund], true)) {
      return;
    }

    fetchCustomerRefundDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setCustomerRefundDetail(undefined);
      }
    })
  }, []);   

  const onClickReviewCreditAdjustment = async (valueAction: string) => {
    let reviewCreditAdjustmentTemp = _.cloneDeep({ ...reviewCustomerRefundDetails, customerRefundId: customerRefundFullDetail.id, workflowAction: valueAction })
    setReviewCustomerRefundDetails(reviewCreditAdjustmentTemp);
    setLoadingReview(true);
    await reviewCustomerRefundCredit(reviewCreditAdjustmentTemp)
    setLoadingReview(false);
  }

  const getPaymentStatus = () => {
    if (customerRefundFullDetail.salesOrderPaymentStatus == Constants.paid) {
      return <b className="font-size-13 text-success margin-left-4">{`(${intl.formatMessage({ id: "FullyPaid" })})`}</b>;
    }
    else if (customerRefundFullDetail.salesOrderPaymentStatus == Constants.partial) {
      return <b className="font-size-13 text-warning margin-left-4">{`(${intl.formatMessage({ id: "PartiallyPaid" })})`}</b>;
    }
  }

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
            {value}
          </h6>
        </div>
      </Row>
    )
  }
  
  const displayTableRowData = (label, value) => {
    return (
      <tr>
        <td colSpan={2} className="text-end m-0" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="customerRefund-title margin-bottom-16 ">
                      <Row>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "ExchangeToCredit" })} {getPaymentStatus()}
                            </span>
                            <br />
                            <h3 className="font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {customerRefundFullDetail.branchName}
                            </h3>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ExchangeToCreditNo" })}:`, customerRefundFullDetail.customerReturnRefundNo)}
                            {
                              displayHeaderRowData(
                                `${intl.formatMessage({ id: "SalesOrderNo" })}:`,
                                 blnPermissionManageCustomerSalesOrder 
                                 ? 
                                 <Link to={customerRefundFullDetail.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${customerRefundFullDetail.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{customerRefundFullDetail.salesOrderNo}</Label>
                                </Link> 
                                :  
                                customerRefundFullDetail.salesOrderNo
                              )
                            }
                            {displayHeaderRowData(`${intl.formatMessage({ id: "DateAndTime" })}:`, `${moment(customerRefundFullDetail.issueDate).format(Constants.displayDateFormat)}\n${moment(customerRefundFullDetail.issueDate).format(Constants.displayTimeFormat)}`)}
                          </div>
                        </Col>
                        {
                          !blnPendingStatus 
                          &&
                          <Col xs="6" className="text-end text-muted margin-top-26">
                            <address>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedBy" })}:`, customerRefundFullDetail.actionBy)}
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedOn" })}:`, `${moment(customerRefundFullDetail.actionOn).format(Constants.displayDateFormat)}\n${moment(customerRefundFullDetail.actionOn).format(Constants.displayTimeFormat)}`)}
                            </address>
                          </Col>
                        }
                      </Row>
                    </div>
                    <hr/>
                    <Col xs="6">
                      <address>
                        <strong>{intl.formatMessage({ id: "Customer" })}:</strong>
                        <div style={{fontSize: "14px"}}>
                          {
                            blnPermissionManageCustomer 
                            ? 
                            <Link to={customerRefundFullDetail.customerId ? `/${RoutesList.customer}/view/${customerRefundFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                             <Label className="margin-bottom-0 pointer-clickable">{customerRefundFullDetail.customerName || Constants.emptyData}</Label>
                            </Link>
                            :  
                            customerRefundFullDetail.customerName
                          }
                        </div>
                        <div className="margin-top-8">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div style={{ marginTop: '8px' }}>
                            <GeneralStatus 
                              status={customerRefundFullDetail.status}
                              statusColorFunction={returnRefundStatusColor}
                              blnDisableCenter/>
                          </div>
                        </div>
                      </address>
                    </Col>
                    {
                      customerRefundFullDetail.refundDetails && customerRefundFullDetail.refundDetails.length > 0 &&
                      <>
                        <div className="py-2 mt-4">
                          <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({ id: "ExchangeToCreditItem" })}
                          </h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table-fixed">
                            <thead className="table-light">
                              <tr>
                                <th style={{ width: '30%' }}>{intl.formatMessage({ id: "Name" })}</th>
                                <th className={!blnPartialPayment ? "text-center" : "text-end"} colSpan={!blnPartialPayment ? 1 : 2}>{intl.formatMessage({ id: "Type" })}</th>
                                {!blnPartialPayment && <th className="text-end">{intl.formatMessage({ id: "ExchangeableAmount" })}</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {
                                customerRefundFullDetail.refundDetails.map((value: RefundDetail, index) => (
                                  <tr key={index}>
                                    <td className="">{getPurchaseTypeIcon(value.purchaseType)}{value.purchaseName} {displayPromotionCode(value.promotionCode)}</td>
                                    <td className={!blnPartialPayment ? "text-center" : "text-end"} colSpan={!blnPartialPayment ? 1 : 2}>{value.purchaseType}</td>
                                    {!blnPartialPayment && <td className="text-end">{returnPriceWithCurrency(value.exchangeAmount.toFixed(2))}</td>}
                                  </tr>
                                ))
                              }
                              {displayTableRowData(`${intl.formatMessage({ id: "SubTotal" })}`, returnPriceWithCurrency(customerRefundFullDetail.subTotalExchangeAmount))}
                              {displayTableRowData(`${intl.formatMessage({ id: "ProcessingFees" })}`, returnPriceWithCurrency(customerRefundFullDetail.processingFees))}
                              {displayTableRowData(`${intl.formatMessage({ id: "TotalExchangeAmount" })}`, returnPriceWithCurrency(customerRefundFullDetail.totalExchangeAmount))}
                            </tbody>
                          </Table>
                        </div>
                      </>
                    }
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Reason" })}
                      name="reason"
                      className="mt-3"
                      row={5}
                      field={customerRefundFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true}
                    />
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Review" })}
                      name="remark"
                      className={`${errorState.remark ? "mb-0" : "mb-3"} mt-4`}
                      row={4}
                      validationRequired={errorState.remark}
                      field={blnPendingStatus ? reviewCustomerRefundDetails : customerRefundFullDetail}
                      setFieldValue={setReviewCustomerRefundDetails}
                      // disabled={loadingReview || !blnPendingStatus || Boolean(successMessage) || !blnPermissionReviewCustomerSalesOrderCredit} 
                      disabled={true}/>
                    {errorState.remark ? (
                      <FormFeedback className="font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReviewWhenReject" })}</FormFeedback>
                    ) : null}
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      {/* {
                        blnPendingStatus
                        &&
                        blnPermissionReviewCustomerSalesOrderCredit
                        &&
                        !Boolean(successMessage)
                        &&
                        <>
                          <MyButton
                            type="submit"
                            class="btn btn-success margin-right-8"
                            content={Constants.approve}
                            onClick={() => {
                              setErrorState({remark: false})
                              onClickReviewCreditAdjustment(Constants.approve);
                            }}
                            disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                            loading={loadingReview}
                          />
                          <MyButton
                            type="button"
                            class="btn btn-danger"
                            content={Constants.reject}
                            onClick={() => {
                              let errorStateTemp = {
                                remark: false,
                              };
                              let blnErrorFound = false;
                              if (!reviewCustomerRefundDetails.remark) {
                                errorStateTemp.remark = true;
                                blnErrorFound = true;
                              }
                              if (blnErrorFound) {
                                setErrorState(errorStateTemp)
                              }
                              else {
                                onClickReviewCreditAdjustment(Constants.reject);
                              }
                            }}
                            disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                            loading={loadingReview}
                          />
                        </>
                      } */}
                      {
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(CustomerRefundDetail);
