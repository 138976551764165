import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import _ from 'lodash';
interface Props {
  colorString: string;
  circleColumnPaddingRight: string;
  color: string;
  circleBorderWidth: string;
  backgroundColor: string;
  IconDisplay: any;
  headerUI: any;
  contentUI?: any;
  blnMarginLeft?: string;
  blnPortrait?: boolean;
}

export default function SiteMapCard(props: Props) {
  
  const displayHeadContentWithIcon = () => {
    return (
      <div style={{minWidth: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className={props.contentUI && `border-end treeview-border-right-${props.colorString}`}>
        <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} className="mt-3">
            <div style={{backgroundColor: props.color, width: '40px', height: '40px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}> 
              <props.IconDisplay 
                size={22} 
                color={"white"}/>
            </div>
          </div>
          {props.headerUI()}
        </div>
      </div>
    )
  }

  const displayHeadContentWithIconForPortrait = () => {
    return (
      <div>
        <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
          <div className="mt-3 flex-align-items-center-without-height">
            <div style={{backgroundColor: props.color, width: '40px', height: '40px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '8px'}}> 
              <props.IconDisplay 
                size={22} 
                color={"white"}/>
            </div>
            {props.headerUI()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`treeview__level tree-level-${props.colorString}`} style={{marginLeft: props.blnMarginLeft, paddingRight: props.circleColumnPaddingRight, borderColor: !props.blnPortrait ? props.color : 'undefined', borderWidth: props.circleBorderWidth, backgroundColor: props.backgroundColor, borderRadius: props.blnPortrait ? '20px' : undefined, overflow: 'hidden'}}>
      <Card className="mb-0">
        <div style={{backgroundColor: props.backgroundColor, display: "flex"}}>
          {
            !props.blnPortrait
            &&
            displayHeadContentWithIcon()
          }
          {
            props.contentUI
            ?
            <div style={{minWidth: '300px'}}>
              {props.blnPortrait && displayHeadContentWithIconForPortrait()}
              {props.contentUI()}
            </div>
            :
            <div/>
          }
        </div>
      </Card>
    </div>
  )
}