export const RoutesList = {
  login: "login",
  notFound: "notFound",
  dashboard: "dashboard",
  branch: "branch",
  brand: "brand",
  product: "product",
  serviceTreatment: "serviceTreatment",
  package: "package",
  packageUpgrade: "packageUpgrade",
  packageUpgradePayment: "packageUpgradePayment",
  packageUpgradeAcknowledgement: "packageUpgradeAcknowledgement",
  employee: "employee",
  businessEntity: "businessEntity",
  country: "country",
  currency: "currency",
  category: "category",
  salesCategory: "salesCategory",
  tax: "tax",
  race: "race",
  epfcontribution: "epfcontribution",
  eis: "eis",
  socso: "socso",
  pcb: "pcb",
  appointment: "appointment",
  appointmentList: "appointmentList",
  mediaSource: "mediaSource",
  measurement: "measurement",
  pos: "pos",
  posPayment: "posPayment",
  posAcknowledgement: "posAcknowledgement",
  invoice: "invoice",
  position: "position",
  supplier: "supplier",
  salesTarget: "salesTarget",
  warehouse: "warehouse",
  customerPurchase: "customerPurchase",
  customerPurchaseAcknowledgement: "customerPurchaseAcknowledgement",
  customer: "customer",
  customerRedemption: "customerRedemption",
  stockMovement: "stockMovement",
  leaveType: "leaveType",
  payrollItem: "payrollItem",
  stockRequest: "stockRequest",
  stockReceive: "stockReceive",
  leaveEntitlement: "leaveEntitlement",
  receipt: "receipt",
  receiptUI: "receiptUI",
  purchaseOrder: "purchaseOrder",
  inboundNotice: "inboundNotice",
  goodsReceiveNote: "goodsReceiveNotes",
  purchaseDeliverOrder: "purchaseDeliverOrder",
  profile: "profile",
  permission: "permission",
  changePassword: "changePassword",
  userRole: "userRole",
  report: "report",
  consultantSalesSummary: "consultantSalesSummary",
  consultantSales: "consultantSales",
  consultantSalesCommission: "consultantSalesCommission",
  notificationTemplate: "notificationTemplate",
  stockAdjustment: 'stockAdjustment',
  stockIn: "stockIn",
  stockOut: "stockOut",
  stockReturnToHQ: "stockReturnToHQ",
  paymentMethod: "paymentMethod",
  payslip: "payslip",
  additionalFormField: "additionalFormField",
  notification: "notification",
  forgotPassword: "forgotPassword",
  forgotPasswordValidator: "forgotPasswordValidator",
  serverError: "server-error",
  stockTake: "stockTake",
  testTimeZone: "testTimeZone",
  auditLog: "auditLog",
  lead: "lead",
  customerSalesOrder: "customerSalesOrder",
  appointmentSettings: "appointmentSettings",
  generalSettings: "generalSettings",
  salesSettings: "salesSettings",
  promotion: "promotion",
  exchangeToCredit: "exchangeToCredit",
  creditAdjustment: "creditAdjustment",
  dailySalesSummary: "dailySalesSummary",
  dailySalesDetails: "dailySalesDetails",
  employeeLeave: "employeeLeave",
  leadsSummaryByMedia: "leadsSummaryByMedia",
  leadsDetail: "leadsDetail",
  appointmentSummaryBySource: "appointmentSummaryBySource",
  appointmentSummaryByStatus: "appointmentSummaryByStatus",
  appointmentDetails: "appointmentDetails",
  collectionSummary: "collectionSummary",
  collectionDetails: "collectionDetails",
  packageUnutilizedBalance: "packageUnutilizedBalance",
  productUnutilizedBalance: "productUnutilizedBalance",
  sales: "sales-",
  dynamicSalesDetails: "dynamicSalesDetails",
  averageSpendingSummary: "averageSpendingSummary",
  services: "services-",
  stockForecastSummary: "stockForecastSummary",
  stockCard: "stockCard",
  stockLevel: "stockLevel",
  stockReceiveVariant: "stockReceiveVariant",
  creditHistory: "creditHistory",
  transferCreditHistory: "transferCreditHistory",
  companyRevenue: "companyRevenue",
  salesOrderCost: "salesOrderCost",
  customerProductReturn: "customerProductReturn",
  expiringPackage: "expiringPackage",
  withoutAppointment: "withoutAppointment",
  paymentAdjustment: "paymentAdjustment",
  promoCodeHistory: "promoCodeHistory",
  customerBirthday: "customerBirthday",
  extendPackage: "extendPackage",
  leadDuplicate: "leadDuplicate",
  leadsSummaryByEmployee: "leadsSummaryByEmployee",
  paymentMethodOption: "paymentMethodOption",
  collectOnBehalf: "collectOnBehalf",
  collectOnBehalfDetails: "collectOnBehalfDetails",
  upsalesSummary: "upsalesSummary",
  salesAdjustment: "salesAdjustment",
  productCommissionScheme: "productCommissionScheme",
  productSold: "productSold",
  customerProfilePurchase: "customerProfilePurchase",
  customerAccessRequest: "customerAccessRequest",
  accountPost: "accountPost",
  salesAdjustmentHistory: "salesAdjustmentHistory",
  cache: "cache",
  productMasterData: "productMasterData",
  packageMasterData: "packageMasterData",
  serviceMasterData: "serviceMasterData",
  bundledProductCommissionScheme: "bundledProductCommissionScheme",
  customerTransferRequest: "customerTransferRequest",
  underMaintenance: "under-maintenance",
  customerTurnedUp: "customerTurnedUp",
  leadAppointments: "leadAppointments",
  withoutPackage: "withoutPackage",
  topSpender: "topSpender",
  additionBonusSalesTarget: "additionBonusSalesTarget",
  branchCommission: "branchCommission",
  dayOff: "dayOff",
  customerVirtualWarehouse: "customerVirtualWarehouse",
  contractLiability: "contractLiability",
  contractLiabilitySiteMap: "contractLiabilitySiteMap",
  contractLiabilityTimeline: "contractLiabilityTimeline",
  productRedemptionNote: "productRedemptionNote",
  productRedemptionNoteAcknowledgement: "productRedemptionNoteAcknowledgement",
  virtualWarehouseCreditExchange: "virtualWarehouseCreditExchange",
  productRedemptionNoteReturn: "productRedemptionNoteReturn",
  monthlySalesSummary: "monthlySalesSummary",
  salesOverview: "salesOverview",
  salesOverviewDetails: "salesOverviewDetails",
  outletSchedule: "outletSchedule",
  customerProfileAccessDenied: "customerProfileAccessDenied",
  dsrAdjusment: "dsrAdjustment",
  batchJob: "batchJob",
  customerSubBranchRequest: "customerSubBranchRequest",
  sharedSalesSummary: "sharedSalesSummary",
  commission: "commission",
  reservedProductSummary: "ReservedProductSummary",
  roomUtilization: "roomUtilization",
  roomOccupancy: "roomOccupancy",
  appolousCustomerPackage: "appolousCustomerPackage",
  appolousCustomerPackageRedemption: "appolousCustomerPackageRedemption",
  appolousCustomerSales: "appolousCustomerSales",
  appolousCustomerProduct: "appolousCustomerProduct",
  appolousPaymentCollectionSummary: "appolousPaymentCollectionSummary",
  appolousDynamicSalesDetails: "appolousDynamicSalesDetails",
  appolousProductCommissionVariant: "appolousProductCommissionVariant",
  consultantDailySales: "consultantDailySales",
  reservedProductMovements: "reservedProductMovements",
  appointmentTarget: "appointmentTarget",
  marcomDailyRecord: "marcomDailyRecord",
  appointmentOverviewSummary: "appointmentOverviewSummary",
  appolousFreePackageAndProduct: "appolousFreePackageAndProduct",
  cacheList: "cacheList",
  discountSalesDetails: "discountSalesDetails",
  combinedCollectionSummary: "combinedCollectionSummary",
  department: "department",
  changePaymentMethod: "changePaymentMethod",
  productSalesDetails: "productSalesDetails",
  closingRate: "closingRate",
  appointmentLead: "appointmentLead",
  machine: "machine",
  machineUsage: "machineUsage",
  salesOverviewYearly: "salesOverviewYearly",
  changePasscode: "changePasscode",
  branchSalesSummary: "branchSalesSummary",
  branchDailySalesSummary: "branchDailySalesSummary",
  stockLevelHistory: "stockLevelHistory",
  contractLiabilityBenchmark: "contractLiabilityBenchmark",
  groupCode: "groupCode",
  salesByGroupCode: "salesByGroupCode",
  commissionCalculation: "commissionCalculation",
  salesOrderCommissionSummary: "salesOrderCommissionSummary",
  earnSalesSummary: "earnSalesSummary",
  unearnedSalesDetails: "unearnedSalesDetails",
  recogniseSalesDetails: "recogniseSalesDetails",
  exchangeToCreditDetails: "exchangeToCreditDetails",
  refundFromCredit: "refundFromCredit",
  paymentCollection: "paymentCollection",
  branchMonthlySalesSummary: "branchMonthlySalesSummary",
  salesVariance: "salesVariance",
  reservedProductReversalSummary: "reservedProductReversalSummary",
  tnc: "tnc",
  unknownPayment: "unknownPayment",
  cashOut: "cashOut",
  paymentTerminal: "paymentTerminal",
  changePaymentMode: "changePaymentMode",
  closeCounter: "closeCounter",
  leadsTurnUpRateSummary: "leadsTurnUpRateSummary",
  customerDetails: "customerDetails",
  marcomReport: "marcomReport",
  newVisitVsTurnUp: "newVisitVsTurnUp"
}