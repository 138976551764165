import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, getBranchId, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, productRedemptionNoteReturnStatusColor, returnPriceWithCurrency } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _, { result } from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Input, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyButton from "../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import GeneralInput from "../../app/components/form/GeneralInput";
import CancelModal from "../../app/components/modal/CancelModal";

const ProductRedemptionNoteReturnViewDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { productRedemptionNoteReturnStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, setShowCancelPrompt} = commonStore;
  const { productRedemptionNoteReturnDetail, setProductRedemptionNoteReturnDetail, getProductRedemptionNoteReturnWithId, reviewProductRedemptionNoteReturn, cancelProductRedemptionNoteReturn } = productRedemptionNoteReturnStore;
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ProductRedemptionNoteReturn" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: RoutesList.productRedemptionNoteReturn }, { title: intl.formatMessage({ id: "ProductRedemptionNoteReturn" }), urlPath: RoutesList.productRedemptionNoteReturn }];
  const productRedemptionNoteReturnFullDetail = productRedemptionNoteReturnDetail || {
    id: "",
    isDelete: true,
    productRedemptionNoteReturnId: "",
    productRedemptionNoteReturnNo: "",
    customerId: "",
    customerName: "",
    returnBranchName: "",
    status: "",
    totalExchangeAmount: 0,
    requestedOn: "",
    reviewedOn: "",
    note: "",
    reviewerUserName: "",
    reviewRemark: "",
    productRedemptionNoteReturnDetails: [],
    workflowAction: "Save",
    productRedemptionNoteNo: "",
    productRedemptionNoteId: ""
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: productRedemptionNoteReturnDetail || {
      approvedCreditAmount: 0,
      comment: "",
      id: "",
      workflowAction: "Save",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (productRedemptionNoteReturnFullDetail.status === Constants.pending) {
          if (valuesTemp.workflowAction === Constants.cancel) {
            await cancelProductRedemptionNoteReturn(id);
          }
          else {
            await reviewProductRedemptionNoteReturn(valuesTemp);
          }
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const blnPending = productRedemptionNoteReturnFullDetail.status === Constants.pending;
  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || !blnPending;
  const blnPermissionRequestProductRedemptionNoteReturn = checkPermission([PermissionConstants.RequestProductRedemptionNoteReturn]);
  const blnPermissionReviewProductRedemptionNoteReturn = checkPermission([PermissionConstants.ReviewProductRedemptionNoteReturn]);
  const blnPermissionManageProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  async function fetchProductRedemptionNoteReturnDetailAPI() {
    setLoading(true);
    if (id) {
      await getProductRedemptionNoteReturnWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageProductRedemptionNoteReturn], true)) {
      return;
    }
         
    fetchProductRedemptionNoteReturnDetailAPI();

    return (() => {
      if (id) {
        setProductRedemptionNoteReturnDetail(undefined);
      }
    })
  }, []);
  
  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Col>
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "ProductRedemptionNoteReturn" })}
                            </span>
                            <br />
                            <br />
                          </div>
                        </Col>
                        <Row>
                          <Col>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ReturnRequestNo" })}:`, productRedemptionNoteReturnFullDetail.productRedemptionNoteReturnNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ReturnBranchName" })}:`, `${productRedemptionNoteReturnFullDetail.returnBranchName}`)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "RequestDate" })}:`, moment(productRedemptionNoteReturnFullDetail.requestedOn).format(Constants.displayDateFormat))}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "RequestTime" })}:`, moment(productRedemptionNoteReturnFullDetail.requestedOn).format(Constants.displayTimeFormat))}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "Customer" })}:`, blnPermissionViewCustomer ?
                              <Link to={productRedemptionNoteReturnFullDetail.customerId ? `/${RoutesList.customer}/view/${productRedemptionNoteReturnFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 pointer-clickable">{productRedemptionNoteReturnFullDetail.customerName || Constants.emptyData}</Label>
                              </Link>
                              :
                              `${productRedemptionNoteReturnFullDetail.customerName}`)}
                          </Col>
                          <Col>
                            {
                              productRedemptionNoteReturnFullDetail.productRedemptionNoteNo
                              &&
                              displayHeaderRowData(`${intl.formatMessage({ id: "ProductRedemptionNo" })}:`, 
                              blnPermissionManageProductRedemptionNote
                              ?
                              <Link
                                to={`/${RoutesList.productRedemptionNote}/view/${productRedemptionNoteReturnFullDetail.productRedemptionNoteId}`}
                                className="text-primary pointer-clickable"
                                target={Constants.blank} rel="noopener noreferrer">
                                {productRedemptionNoteReturnFullDetail.productRedemptionNoteNo}
                              </Link>
                              :
                              productRedemptionNoteReturnFullDetail.productRedemptionNoteNo)
                            }
                            {
                              productRedemptionNoteReturnFullDetail.reviewerUserName
                              &&
                              <>
                                {displayHeaderRowData(`${intl.formatMessage({ id: "ApproverName" })}:`, `${productRedemptionNoteReturnFullDetail.reviewerUserName}`)}
                                {displayHeaderRowData(`${intl.formatMessage({ id: "ApprovedDate" })}:`, productRedemptionNoteReturnFullDetail.reviewedOn !== Constants.invalidDate ? moment(productRedemptionNoteReturnFullDetail.reviewedOn).format(Constants.displayDateFormat) : Constants.emptyData)}
                                {displayHeaderRowData(`${intl.formatMessage({ id: "ApprovedDate" })}:`, productRedemptionNoteReturnFullDetail.reviewedOn !== Constants.invalidDate ? moment(productRedemptionNoteReturnFullDetail.reviewedOn).format(Constants.displayTimeFormat) : Constants.emptyData)}
                              </>
                            }
                          </Col>
                        </Row>
                      </div>
                      <hr/>
                      <address style={{ lineHeight: '20px', marginTop: '20px' }}>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={productRedemptionNoteReturnFullDetail.status}
                            statusColorFunction={productRedemptionNoteReturnStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Product(s)" })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th className="">{intl.formatMessage({ id: "Product" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "Quantity" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "ReturnType" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "IsDefect" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "ExchangeAmount" })}</th>
                              <th className="">{intl.formatMessage({ id: "Remarks" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              productRedemptionNoteReturnFullDetail.productRedemptionNoteReturnDetails.map((item, key) => (
                                <tr key={key} className={`${item.isExceedMinStockLevel ? "table-row-exceed-min-stock-level" : ""}`}>
                                  <td className="">{getPurchaseTypeIcon(Constants.product)}{item.productName}</td>
                                  <td className="text-center">{item.quantity}</td>
                                  <td className="text-center">{newSpaceBeforeCapitalLetter(item.returnType)}</td>
                                  <td className="text-center">{item.isDefect ? Constants.yes : Constants.no}</td>
                                  <td className="text-center">{returnPriceWithCurrency(item.exchangeAmount)}</td>
                                  <td className="">{item.remark || Constants.emptyData}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Note" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={true}
                        validation={validation}
                      />
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Comment" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="reviewRemark"
                        className="margin-top-0"
                        row={5}
                        disabled={disabledFieldInput || !blnPermissionRequestProductRedemptionNoteReturn}
                        validation={validation}
                      />
                      <div className="mt-4" />
                      <div className="flex-direction-row d-print-none">
                        {
                          !Boolean(successMessage) && blnPending
                          &&
                          <>
                          {
                            blnPermissionReviewProductRedemptionNoteReturn
                            &&
                            <>
                              <MyButton
                                type="submit"
                                class="btn btn-success margin-right-8"
                                content={intl.formatMessage({ id: "Approve" })}
                                onClick={() => {
                                  validation.setFieldValue("workflowAction", Constants.approve)
                                }}
                                disable={
                                  validation.isSubmitting
                                }
                                loading={
                                  validation.isSubmitting
                                }
                              />
                              <MyButton
                                type="submit"
                                class={`btn btn-danger margin-right-8`}
                                content={intl.formatMessage({ id: "Reject" })}
                                disable={
                                  validation.isSubmitting
                                }
                                loading={
                                  validation.isSubmitting
                                }
                                onClick={() => {
                                  validation.setFieldValue("workflowAction", Constants.reject)
                                }}
                              />
                            </>
                          }
                          {
                            blnPermissionRequestProductRedemptionNoteReturn
                            &&
                            <MyButton
                              type="button"
                              class={`btn btn-warning margin-right-8`}
                              content={Constants.cancel}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                setShowCancelPrompt(true);
                              }}
                            />
                          }
                          </>
                        }
                        <GeneralSubmitAndCancelBtn
                          successMessage={successMessage}
                          className="width-100-percentage"
                          viewAction={false}
                          validation={validation}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={false}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
            <CancelModal
              onCancelClick={async () => {
                validation.setFieldValue("workflowAction", Constants.cancel);
                validation.submitForm();
              }} />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(ProductRedemptionNoteReturnViewDetail);
