import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, returnRefundStatusColor } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import CustomerRefundDetailModal from "./Modal/CustomerRefundDetailModal";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import Loading from "../../app/components/loading/Loading";
import { history } from "../..";
import { PaginationRequestBody } from "../../app/models/pagination";
import BookOpenIcon from "mdi-react/BookOpenIcon";

const CustomerRefund = () => {
  const intl = useIntl();

  //Use Params
  const { id } = useParams();

  //Use Store
  const { customerRefundStore, customerStore, customerSalesOrderStore, staticReferenceStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { customerRefundList, customerRefundPaginationParams, getCustomerRefund, getCustomerRefundWithId, setCustomerRefundList, setCustomerRefundPaginationParams } = customerRefundStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { reviewCustomerRefundCredit } = customerSalesOrderStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { returnRefundStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedReturnRefundStatus, setSelectedReturnRefundStatus] = useState<number | string>(AllStatus);
  const [selectedReturnRefundStatusId, setSelectedReturnRefundStatusId] = useState<number | string | undefined>(" ");
  const [returnRefundStatusList, setReturnRefundStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [customerRefundDetailModal, setCustomerRefundDetailModal] = useState(false);
  const [selectedCustomerRefundDetail, setSelectedCustomerRefundDetail] = useState<any>([]);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const blnPermissionManageCustomerSalesOrder =  checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionReviewCustomerSalesOrderCredit = checkPermission([PermissionConstants.ReviewCustomerSalesOrderCredit])

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  //Table Content UI
  const CustomerRefundColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerReturnRefundNo",
      text: intl.formatMessage({ id: "ExchangeToCreditNo" }).toUpperCase(),
    },
    {
      dataField: "issueDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.issueDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.issueDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "SalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerSalesOrder 
          ?
            <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.salesOrderNo}</Label>
            </Link>
          :
            row.salesOrderNo
        }
      </>
    },
    {
      dataField: "exchangeType",
      text: intl.formatMessage({ id: "ExchangeToCreditType" }).toUpperCase(),
      formatter: (cellContent, row) => <>{newSpaceBeforeCapitalLetter(row.exchangeType)}</>, //Custom UI to display
    },
    {
      dataField: "totalExchangeAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.totalExchangeAmount)}</>, //Custom UI to display
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={returnRefundStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.exchangeToCredit}/view/${row.id}`}
              type={'view'} />
            {
              blnPermissionReviewCustomerSalesOrderCredit && row.status == Constants.pending
              ?
              <TableActionButton
                to="#"
                type={'edit'}
                onClick={() => onClickViewDetail(row.id)} />
              :
              <TableActionButton 
                type="viewModal"
                onClick={()=> onClickViewDetail(row.id)}/>
            }
          </div>
        </>
      ),
    },
  ];

  let customerRefundRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    returnRefundStatus: selectedReturnRefundStatusId,
    returnRefundStatusName: selectedReturnRefundStatus
  }

  useEffect(() => {
    async function fetchCustomerRefundListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.exchangeToCredit)
      if (blnBackNavigation) {
        if (customerRefundPaginationParams) {
          setCurrentPage(customerRefundPaginationParams.pageNumber);
          setSelectedBranchId(customerRefundPaginationParams.branchId!);
          setSelectedBranchName(customerRefundPaginationParams.branchName!);
          setSelectedReturnRefundStatusId(customerRefundPaginationParams.returnRefundStatus)
          setSelectedReturnRefundStatus(customerRefundPaginationParams.returnRefundStatusName || AllStatus)
          setSelectedCustomerId(customerRefundPaginationParams.customerId || "")
          setSelectedCustomer(customerRefundPaginationParams.customerName || "")
          customerRefundRequestBody = customerRefundPaginationParams;
        }
      }
      else {
        setCustomerRefundPaginationParams(undefined);
      }
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.returnRefundStatus, AllStatus),
        getBranchDropDown(),
        getCustomerRefund(customerRefundRequestBody)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setReturnRefundStatusList(resultAPI[0]);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setLocalLoading(false);
      setInitialCallAPI(false);

      if (id) {
        await onClickViewDetail(id);
        window.history.replaceState(null, "", `/${RoutesList.exchangeToCredit}`)
      }
    }

    if (!checkPermission([PermissionConstants.ManageCustomerRefund], true)) {
      return;
    }

    fetchCustomerRefundListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.exchangeToCredit)
        if (!blnSetParams) {
          setCustomerRefundPaginationParams(undefined);
        }
      }
      setCustomerRefundList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
      fetchCustomerRefundAPI(true, Constants.emptyData);
    }
  }, [selectedBranchId])

  useEffect(() => {
    if (
      (selectedReturnRefundStatusId || selectedReturnRefundStatusId === 0) && !initialCallAPI
    ) {
      fetchCustomerRefundAPI(true);
    }
  }, [selectedReturnRefundStatusId, selectedCustomerId]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setCustomerRefundDetailModal(false);
        fetchCustomerRefundAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchCustomerRefundAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerRefund({
      ...customerRefundRequestBody,
      ...customerId === Constants.emptyData && { customerId: "", customerName: "" },
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const onClickSelect = (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickViewDetail = async (customerRefundId: any) => {
    setLoading(true);
    let resultPreviewRefundCredit = await getCustomerRefundWithId(customerRefundId);
    setSelectedCustomerRefundDetail(resultPreviewRefundCredit)
    setCustomerRefundDetailModal(true);
    setLoading(false);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ExchangeToCredit" }) })}
        title={intl.formatMessage({ id: "ExchangeToCredit" })}
        breadCrumbList={breadCrumbList}
      >
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ExchangeToCredit" }) })}
                name="returnRefundStatus"
                options={returnRefundStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedReturnRefundStatus}
                initialValue={selectedReturnRefundStatusId}
                setFieldLabel={setSelectedReturnRefundStatus}
                setFieldValue={setSelectedReturnRefundStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          !initialCallAPI
          &&
          <Row className={`${loadingCustomer ? "margin-bottom-16" : ""}`}>
            <Col xl={'4'} md={'6'} xs={'12'}>
              {
                displayCustomerDropdown &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              }
            </Col>
            {/* <Col xl={'4'} md={'6'} xs={'6'}>
              <MyButton
                type="button"
                class="btn btn-primary margin-top-28 "
                content={intl.formatMessage({ id: "AdvancedSearch" }).toUpperCase()}
                loading={localLoading || loading || loadingDetail}
                disable={localLoading || loading || loadingDetail}
                onClick={() => { setCustomerSearchModal(true) }}
              />
            </Col> */}
          </Row>
        }
        {
          localLoading
          ? 
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={CustomerRefundColumns}
            data={customerRefundList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerRefund" }) })}
            objSorted={defaultSorted}
            requestAPI={getCustomerRefund}
            customerId={selectedCustomerId}
            customerName={selectedCustomer}
            branchId={selectedBranchId}
            branchName={selectedBranchName}
            returnRefundStatus={selectedReturnRefundStatusId}
            returnRefundStatusName={selectedReturnRefundStatus} />
        }
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          blnHideOtherBranch={true}
          onSelectFunction={onClickSelect} />
      }
      {
        customerRefundDetailModal &&
        <CustomerRefundDetailModal
          blnShow={customerRefundDetailModal}
          setModal={setCustomerRefundDetailModal}
          selectedCustomerRefundDetail={selectedCustomerRefundDetail}
          setSelectedCustomerRefundDetail={setSelectedCustomerRefundDetail}
          reviewCustomerRefundCredit={reviewCustomerRefundCredit}
        />
      }
    </div>
  );
}

export default observer(CustomerRefund);
