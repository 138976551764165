import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { DateRangeMonthDetailInterface, ObjectForDropdown, paginationPageObject, totalCalculationDisplayObject } from '../../models/common';
import _ from 'lodash';
import { Constants } from '../../constants/Constants';
import { RoutesList } from '../../constants/RoutesList';
import { store } from '../../stores/store';
import { validate as isValidUUID } from 'uuid';
import { history } from '../../../';
import { AdditionalFormFieldWithFormType } from '../../models/additionalFormField';
import EmailIcon from 'mdi-react/EmailIcon';
import MessageProcessingIcon from 'mdi-react/MessageProcessingIcon';
import BellIcon from 'mdi-react/BellIcon';
import MessageLockIcon from 'mdi-react/MessageLockIcon';
import EmailVariantIcon from 'mdi-react/EmailVariantIcon';
import ClipboardEditIcon from 'mdi-react/ClipboardEditIcon';
import PackageVariantClosedIcon from 'mdi-react/PackageVariantClosedIcon';
import ArrangeBringForwardIcon from 'mdi-react/ArrangeBringForwardIcon';
import BrightnessPercentIcon from 'mdi-react/BrightnessPercentIcon'; 
import { CustomerPaymentDetailObject } from '../../models/customerOrder';
import { PermissionUserListObject } from '../../models/permission';
import logoWatermark from "../../../assets/images/logo/onedoc_logo.png";
import { isMobile } from 'react-device-detect';
import QrcodeIcon from 'mdi-react/QrcodeIcon';
import BookAccountIcon from 'mdi-react/BookAccountIcon';
import CashIcon from 'mdi-react/CashIcon';
import CreditCardCheckOutlineIcon from 'mdi-react/CreditCardCheckOutlineIcon';
import CreditCardOutlineIcon from 'mdi-react/CreditCardOutlineIcon';
import WeatherCloudyIcon from 'mdi-react/WeatherCloudyIcon';
import DiamondStoneIcon from 'mdi-react/DiamondStoneIcon';
import BookCheckOutlineIcon from 'mdi-react/BookCheckOutlineIcon';
import BankOutlineIcon from 'mdi-react/BankOutlineIcon';
import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
import { StaticReferenceDataObject } from '../../models/staticReference';
import PackageVariantClosedPlusIcon from 'mdi-react/PackageVariantClosedPlusIcon';
import { SubBrandObject } from '../../models/brand';
import { Col, Label, Row } from 'reactstrap';
import { TagDetailsObject } from '../../models/customer';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom"
import { PermissionConstants } from '../../constants/PermissionConstants';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';

export function IncludesLocationPathName(pathName: string, runIncludesFunction?: boolean) {
  const location = useLocation();
  let locationPath = "";
  let aryPath = location.pathname.split('/');
  let blnFound = false;

  aryPath.map((valuePath)=> {
    if (!runIncludesFunction) {
      if (valuePath.toLowerCase() === pathName) {
        blnFound = true;
      }
    }
    else if (!isValidUUID(valuePath)) {
      locationPath = locationPath + `/${valuePath}`
    }
  })
  // return locationPath.includes(pathName);
  return runIncludesFunction ? locationPath.toLowerCase().includes(pathName) : blnFound;
}

export function EqualLocationPathName(pathName: string ) {
  const location = useLocation();
  return location?.pathname?.toLowerCase() === pathName;
}

export function validDisplayBranchDropdown(strPath: string = "") {
  // let aryRouteList = [
  //   {
  //     path: `${RoutesList.dashboard}`,
  //     blnInclude: true,
  //   },
  //   {
  //     path: `/${RoutesList.pos}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `${RoutesList.invoice}/view`,
  //     blnInclude: true,
  //   },
  //   {
  //     path: `/${RoutesList.packageUpgrade}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.customerPurchase}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.stockRequest}/add`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.stockReceive}/add`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.customer}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.customer}/view`,
  //     blnInclude: true,
  //   },
  //   {
  //     path: `/${RoutesList.customerProductReturn}/add`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.customerSalesOrder}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.customerSalesOrder}/view`,
  //     blnInclude: true,
  //   },
  //   {
  //     path: `/${RoutesList.customerAccessRequest}`,
  //     blnInclude: true,
  //   },
  //   {
  //     path: `/${RoutesList.employeeLeave}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.customerTransferRequest}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.productRedemptionNote}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.productRedemptionNote}/add`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.productRedemptionNoteReturn}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.productRedemptionNoteReturn}/add`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.virtualWarehouseCreditExchange}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.virtualWarehouseCreditExchange}/add`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.customerVirtualWarehouse}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.stockRequest}`,
  //     blnInclude: false,
  //   },
  //   {
  //     path: `/${RoutesList.stockRequest}/view`,
  //     blnInclude: true,
  //   },
  //   {
  //     path: `/${RoutesList.customerTransferRequest}/view`,
  //     blnInclude: true,
  //   },
  //   {
  //     path: `/`,
  //     blnInclude: false,
  //   },
  // ]

  // let blnProceed = false;
  // for (let a=0; a<aryRouteList.length; a++) {
  //   let valueValidDisplayBranchDropdownTemp = aryRouteList[a];
  //   if (strPath) {
  //     if (valueValidDisplayBranchDropdownTemp.blnInclude) {
  //       blnProceed = strPath.toLowerCase().includes(valueValidDisplayBranchDropdownTemp.path.toLowerCase())
  //     }
  //     else {
  //       blnProceed = strPath.toLowerCase() === valueValidDisplayBranchDropdownTemp.path.toLowerCase()
  //     }
  //   }
  //   else {
  //     if (valueValidDisplayBranchDropdownTemp.blnInclude) {
  //       blnProceed = IncludesLocationPathName(valueValidDisplayBranchDropdownTemp.path.toLowerCase(), true)
  //     }
  //     else {
  //       blnProceed = EqualLocationPathName(valueValidDisplayBranchDropdownTemp.path.toLowerCase())
  //     }
  //   }
  //   if (blnProceed) {
  //     break;
  //   }
  // }
  // return blnProceed;
  return true;
}

export function AddOptionsValueField(aryTemp: any, label: string, value: string, includeValueFieldName?: string) {
  if (includeValueFieldName) {
    aryTemp = _.filter(aryTemp, {[includeValueFieldName]: true})
  }
  return aryTemp.map(valueTemp => ({...valueTemp, label: valueTemp[label], value: valueTemp[value]}))
}

export async function returnBlobImage (localImageUrl : string, blnReturnBase64 : boolean = false) {
  if (localImageUrl) {
    let imageFile = await fetch(localImageUrl).then(r => r.blob());
    if (blnReturnBase64) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }
    else {
      return Promise.resolve(imageFile);
    }
  }
}

export function returnOperatingHours (intervalMinutes: boolean = false, blnCheckCurrentDay = false, startTime?: string, endTime?: string) {
  let aryValueTime : ObjectForDropdown[] = [];
  let hourStartTime = 0;
  let hourEndTime = 24;
  let after30minStartTime = false;
  let after30minEndTime = false;
  if (blnCheckCurrentDay) {
    hourStartTime = Number(moment().format(Constants.displayHourFormat));
    after30minStartTime = moment().minute()> 30
  }
  else if (startTime && endTime) {
    hourStartTime = Number(moment(startTime).format(Constants.displayHourFormat));
    after30minStartTime = Number(moment(startTime).minute())> 30;
    hourEndTime = Number(moment(endTime).format(Constants.displayHourFormat));
    if (hourEndTime < 24) {
      hourEndTime += 1;
    } 
    after30minEndTime = Number(moment(endTime).minute())> 30;
  }

  for (var hour=hourStartTime; hour<hourEndTime; hour++) {
    if (hour !== hourStartTime || (hour === hourStartTime && !after30minStartTime)) {
      aryValueTime.push({
        label: moment({
          hour: Math.floor(hour),
          minutes: 0
        },).format('hh:mm A'),
        value: moment({
          hour: Math.floor(hour),
          minutes: 0
        }).format('hh:mm A'),
      })
    }
    
    if (intervalMinutes) {
      if (hour === hourEndTime-1 && !after30minEndTime && endTime) {
        break;
      }

      aryValueTime.push({
        label: moment({
          hour: Math.floor(hour),
          minutes: 30
        },).format('hh:mm A'),
        value: moment({
          hour: Math.floor(hour),
          minutes: 30
        }).format('hh:mm A'),
      })
    }
  }

  return aryValueTime;
}

export function returnHours () {
  let aryHours : ObjectForDropdown[] = [];
  for (var a=0; a<25; a++) {
    aryHours.push({
      label: `${a} hrs`,
      value: a
    })
  }

  return aryHours
}

export function returnMinutes () {
  let aryMinutes : ObjectForDropdown[] = [];
  for (var a=0; a<60; a++) {
    aryMinutes.push({
      label: `${a} mins`,
      value: a
    })
  }
  
  return aryMinutes
}

export function convertJSONIntoFormData (json: any, formData: FormData) {
  for ( var key in json ) {
    if (json[key] || json[key] === 0) {
      let dataTemp = typeof(json[key]) === 'object' && key !== "file" && key !== "attachmentFile" && key !== "logoPhoto" ? JSON.stringify(json[key]) : json[key]
      if (key === "attachmentFiles" || key === "supportingDocuments" || key === "financeTransactionFiles") {
        json[key].map((valueAttachmentFiles)=> {
          formData.append(key, valueAttachmentFiles)
        })
      }
      else {
        formData.append(key, dataTemp)
      }
    }
  }
}

export function calculateTotalByArray (aryTotal: totalCalculationDisplayObject[], objTemp: any) {
  let number = 0;
  let method = "";
  
  aryTotal.map((valueTemp)=> {
    if (valueTemp.type == 'field' && method == "") {
      number = objTemp[valueTemp.fieldName]
    }
    else if (valueTemp.type == 'method') {
      method = valueTemp.fieldName
    }
    else {
      if (method === '+') {
        number = number + (objTemp[valueTemp.fieldName] || 0)
      }
      else if (method === '-') {
        number = number - (objTemp[valueTemp.fieldName] || 0)
      }
      else if (method === "*") {
        number = number * (objTemp[valueTemp.fieldName] || 0)
      }
      else if (method === '/') {
        number = number / (objTemp[valueTemp.fieldName] || 0)
      }
    }
  })

  return Number(number.toFixed(2));
}

export function getYears(upToWhichYear?: number) {
  let selectedYearTemp = upToWhichYear || 2100;
  let years : ObjectForDropdown[] = [];
  for (var yearsTemp=2020; yearsTemp<=selectedYearTemp; yearsTemp++) {
    years.push({
      label: yearsTemp,
      value: yearsTemp
    })
  }

  return years;
}

export function getMonths () {
  return Array.apply(0, Array(12)).map(function(_,i){
    return {
      label: moment().month(i).format('MMM'),
      value: i+1
    }
  })
}

export function getDays (year, month) {
  const lastDayOfSelectedYearAndMonth = Number(moment(`${year}-${month}`, "YYYY-M").endOf('month').format("D"));
  return Array.apply(0, Array(lastDayOfSelectedYearAndMonth)).map(function(_,i){
    return {
      label: i+1,
      value: i+1
    }
  })
}

export function getDaysWithParam (day) {
  return Array.apply(0, Array(Number(day))).map(function(_,i){
    return {
      label: i+1,
      value: i+1
    }
  })
}

export function compareArrayWithField(aryTemp1, aryTemp2, fieldName1, fieldName2) {
  let aryFinalTemp = aryTemp1.filter((valueTemp1)=> {
    return aryTemp2.find((valueTemp2)=> {
      return valueTemp1[fieldName1] === valueTemp2[fieldName2];
    })
  })
  return aryFinalTemp;
}

export function calculatePOSTotalPrice (count, price, discountType, discountValue, blnTax?) {
  if (discountType && discountType.toLowerCase() == 'amount') {
    return (count * price) - discountValue
  }
  else if (blnTax){
    return (count * price) * discountValue /100
  }
  else {
    return (count * price) - ((count * price) * discountValue /100)
  }
}

export function reformatSubmitValueWithClass (value, Class) {
  let valueTemp = _.cloneDeep(value);
  let classTemp = new Class();
  return _.assign(classTemp , _.pick(valueTemp, _.keys(classTemp)));
}

export function getBrandName() {
  const jwtTokenFromStorage = window.localStorage.getItem("jwt") || "";
  if (jwtTokenFromStorage.split('.').length > 1) {
    const jwtToken : any = JSON.parse(atob(jwtTokenFromStorage.split('.')[1]));
    if (jwtToken['BrandName']) {
      return jwtToken[`BrandName`] || "";
    }
  }
}

export function getBrandId() {
  // return window.localStorage.getItem("brandId") || "";
  const jwtTokenFromStorage = window.localStorage.getItem("jwt") || "";
  if (jwtTokenFromStorage.split('.').length > 1) {
    const jwtToken : any = JSON.parse(atob(jwtTokenFromStorage.split('.')[1]));
    if (jwtToken[`http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata`]) {
      return jwtToken[`http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata`] || "";
    }
  }
}

export function getBranchName() {
  return (getBranchUser() ? store.userStore.branchName : window.localStorage.getItem("branchName") || "");
}

export function getBranchId()  {
  return (getBranchUser() ? store.userStore.branchId : window.localStorage.getItem("branchId") || "");
}

export function getJWT() {
  return window.localStorage.getItem("jwt") || "";
}

export function getBranchUser() {
  const jwtTokenFromStorage = window.localStorage.getItem("jwt") || "";
  if (jwtTokenFromStorage.split('.').length > 1) {
    const jwtToken : any = JSON.parse(atob(jwtTokenFromStorage.split('.')[1]));
    if (jwtToken[`http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication`]) {
      if (jwtToken[`http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication`] !== Constants.branchStaff) {
        return false;
      }
    }
  }
  
  return true;
}

export function isBranchManager() {
  //this function is not valid anymore because they want to support passcode for all branch user
  // return store.profileStore.profileDetail?.roleId === Constants.branchManagerUserRoleId ? true : false;
  return getBranchUser();
}

export function getDisplayName() {
  return store.profileStore.profileDetail?.preferredName || "";
}

export function getDisplayImage() {
  return store.profileStore.profileDetail?.photoUrl || "";
}

export function filterDropDownValue (aryTemp: any, fieldValue) {
  return aryTemp.filter(valueFilter => valueFilter.value !== fieldValue).map((valueTemp)=> valueTemp.value)
}

// lightredfaded -> void, voidRequestSubmitted, rejectedByFirstApprover
// lightblue -> pending, active(item), open , generated
// grey -> sent
// orange -> acknowledge, runningLate(appointment), pendingAllocation, followUp, partial(payment), amended, pendingFinalApproval
// violet -> inServing(appointment), 
// darkblue -> pending(appointment), pendingSign
// red -> rejected, notShow(appointment), closed, voided, failed, exceptionThrow, rejectedBySecondApprover
// lightgreen -> paid, partialComplete, partialReceived, comfirmed(appointment), turnedUp
// lightgrey -> processing, expired, inProgress, unpaid, pendingAssign
// darkgreen -> completed
// darkorange -> pendingApproval, creditExchanged
// green -> approved, receive, received, final, knockOff
// lightgreyfaded -> cancelled
// lightorange -> receiving
// pink -> appointmentMade
// black -> completed(appointment)
// darkbrown -> signed, creditExchangeSubmitted redemptionPendingSign, submitted
// olivine -> redemptionPendingSign, pendingFirstApproval
// chocalate -> reversed
// peach -> reviseRequired

export function paymentStatusColor(status: string) {
  //Paid, Partial, Void, Unpaid, Pending
  if (status === Constants.paid) {
    return Constants.lightgreen;
  }
  else if (status === Constants.partial || status === Constants.partialPayment) {
    return Constants.orange;
  }
  else if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.unpaid) {
    return Constants.lightgrey;
  }
  else if (status === Constants.void) {
    return Constants.lightredfaded;
  }
  else {
    return Constants.grey;
  }
}

export function deliveryOrderStatusColor(status: string) {
  //Pending Received
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.received) {
    return Constants.green;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else if (status === Constants.voidRequestSubmitted){
    return Constants.lightredfaded;
  }
  else if (status === Constants.voided){
    return Constants.red;
  }
  else{
    return Constants.grey;
  }
}

export function stockRequestStatusColor(status: string) {
  //Pending Received
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.sent) {
    return Constants.grey;
  }
  else if (status === Constants.acknowledge) {
    return Constants.orange;
  }
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.receive) {
    return Constants.green;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.void) {
    return Constants.lightredfaded;
  }
  else if (status === Constants.partialComplete) {
    return Constants.lightgreen;
  }
  else if (status === Constants.pendingApproval) {
    return Constants.darkorange;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else{
    return Constants.grey;
  }
}

export function stockReceiveStatusColor(status: string) {
  //Pending Received
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.partialCompleted) {
    return Constants.lightgreen;
  }
  else{
    return Constants.grey;
  }
}

export function stockAdjustmentStatusColor(status: string) {
  //Pending Received
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else{
    return Constants.grey;
  }
}

export function inboundNoticeStatusColor(status: string) {
  //Pending
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.acknowledged) {
    return Constants.orange;
  }
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.receiving) {
    return Constants.lightorange;
  }
  else if (status === Constants.received) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else{
    return Constants.grey;
  }
}

export function purchaseOrderStatusColor(status: string) {
  //Pending
  if (status === Constants.pending) {
    return Constants.lightblue;
  }  
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.partialComplete) {
    return Constants.lightgreen;
  }
  else if (status === Constants.void) {
    return Constants.lightredfaded;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else if (status === Constants.pendingAllocation) {
    return Constants.orange;
  }
  else{
    return Constants.grey;
  }
}

export function goodsReceiveNoteStatusColor(status: string) {
  //Pending
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.partialReceived) {
    return Constants.lightgreen;
  }
  else if (status === Constants.received) {
    return Constants.green;
  }
  else if (status === Constants.void) {
    return Constants.lightredfaded;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else{
    return Constants.grey;
  }
}

export function purchaseDeliverOrderStatusColor(status: string) {
  //Pending
  if (status === Constants.pending) {
    return Constants.lightblue;
  }  
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.acknowledged) {
    return Constants.orange;
  }
  else if (status === Constants.receiving) {
    return Constants.lightorange;
  }
  else if (status === Constants.partialReceived) {
    return Constants.lightgreen;
  }
  else if (status === Constants.received) {
    return Constants.green;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else if (status === Constants.pendingAllocation) {
    return Constants.grey;
  }
  else{
    return Constants.grey;
  }
}

export function stockInStatusColor(status: string) {
  //Pending Received
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.void) {
    return Constants.lightredfaded;
  }
  else{
    return Constants.grey;
  }
}

export function stockOutStatusColor(status: string) {
  //Pending Received
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.void) {
    return Constants.lightredfaded;
  }
  else{
    return Constants.grey;
  }
}

export function stockReturnToHQStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else {
    return Constants.grey;
  }
}


export function stockTakeStatusColor(status: string) {
  //Pending Received
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else{
    return Constants.grey;
  }
}

export function leadStatusColor(statusTemp: string) {
  let status = removeAllSpace(statusTemp);
  if (status === Constants.open) {
    return Constants.lightblue;
  }
  else if (status === Constants.followUp) {
    return Constants.orange;
  }
  else if (status === Constants.turnedUp) {
    return Constants.lightgreen;
  }
  else if (status === Constants.appointmentMade) {
    return Constants.peach;
  }
  else if (status === Constants.closed) {
    return Constants.grey;
  }
  else if (status === Constants.others) {
    return Constants.darkRed;
  }
  else if (status === Constants.notShow) {
    return Constants.red;
  }
  else{
    return Constants.grey;
  }
}

//appointment status color more specific
export function appointmentStatusColor(status: string) {
  //appointment pending (darkblue) 
  if (status === Constants.pending) {
    return Constants.greyblue;
  }
  else if (status === Constants.confirmed) {
    return Constants.lightgreen;
  }
  else if (status === Constants.inServing) {
    return Constants.heliotrope;
  }
  else if (status === Constants.runningLate) {
    return Constants.golden;
  }
  else if (status === Constants.notShow) {
    return Constants.peach;
  }
  //appointment completed (black) 
  else if (status === Constants.completed) {
    return Constants.madisonblack;
  }
  else if (status === Constants.arrived) {
    return Constants.persianblue;
  }
  else if (status === Constants.special) {
    return Constants.specialyellow;
  }
  else{
    return Constants.grey;
  }
}

export function salesOrderStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.inProgress) {
    return Constants.lightgrey;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.creditExchanged) {
    return Constants.darkorange;
  }
  else if (status === Constants.creditExchangeSubmitted) {
    return Constants.darkbrown;
  }
  else if (status === Constants.voidRequestSubmitted){
    return Constants.lightredfaded;
  }
  else if (status === Constants.voided){
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else {
    return Constants.grey;
  }
}

export function appolousSalesOrderStatusColor(status: string) {
  if (status === Constants.draft) {
    return Constants.lightblue;
  }
  else if (status === Constants.unpaid) {
    return Constants.lightgrey;
  }
  else if (status === Constants.paid) {
    return Constants.lightgreen;
  }
  else if (status === Constants.partial || status === Constants.partialPayment) {
    return Constants.orange;
  }
  else if (status === Constants.void) {
    return Constants.lightredfaded;
  }
  else if (status === Constants.voided){
    return Constants.red;
  }
  else {
    return Constants.grey;
  }
}

export function returnRefundStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.expired) {
    return Constants.lightgrey;
  }
  else {
    return Constants.grey;
  }
}

export function purchasedItemStatusColor(status: string) {
  if (status === Constants.creditExchanged) {
    return Constants.darkorange;
  }
  else if (status === Constants.active) {
    return Constants.lightblue;
  }
  else if (status === Constants.creditExchangeSubmitted) {
    return Constants.darkbrown;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.voidRequestSubmitted){
    return Constants.lightredfaded;
  }
  else if (status === Constants.voided){
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else{
    return Constants.grey;
  }
}

export function leaveStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else{
    return Constants.grey;
  }
}

export function stockQuantityStatusColor(quantityTemp: number) {
  if (Math.sign(quantityTemp) == -1) {
    return Constants.red;
  }
  else{
    return Constants.lightgreen;
  }
}

export function customerProductReturnStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else{
    return Constants.grey;
  }
}

export function totalCountStatusColor(quantityTemp: number) {
  if (Math.sign(quantityTemp) == -1 || quantityTemp == 0) {
    return Constants.red;
  }
  else{
    return Constants.lightgreen;
  }
}

export function paymentAdjustmentStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else {
    return Constants.grey;
  }
}

export function extendPackageStatusColor(status: string) {
  if (status === Constants.processing) {
    return Constants.lightgrey;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else {
    return Constants.grey;
  }
}

export function customerAccessRequestStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else {
    return Constants.grey;
  }
}

export function customerTransferRequestStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.signed) {
    return Constants.darkbrown;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else {
    return Constants.grey;
  }
}

export function stockLevelStatusColor(status: string) {
  if (status === Constants.healthy) {
    return Constants.lightgreen;
  }
  else if (status === Constants.moderate) {
    return Constants.orange;
  }
  else if (status === Constants.low) {
    return Constants.lightredfaded;
  }
  else {
    return Constants.grey;
  }
}

export function branchCommissionStatusColor(status: string) {
  if (status === Constants.generated) {
    return Constants.lightblue;
  }
  else if (status === Constants.amended) {
    return Constants.greyblue;
  }
  else if (status === Constants.pendingApproval){
    return Constants.olivine;
  }
  else if (status === Constants.pendingSecondApproval){
    return Constants.orange;
  }
  else if (status === Constants.pendingFinalApproval){
    return Constants.darkorange;
  }
  else if (status === Constants.rejectedByFirstApprover) {
    return Constants.lightredfaded;
  }
  else if (status === Constants.rejectedBySecondApprover) {
    return Constants.peach;
  }
  else if (status === Constants.rejectedByFinalApprover) {
    return Constants.red;
  }
  else if (status === Constants.final) {
    return Constants.green;
  }
  else {
    return Constants.grey;
  }
}

export function virtualWarehouseStatusColor(status: string) {
  if (status === Constants.active) {
    return Constants.lightblue;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.voided) {
    return Constants.red;
  }
  else if (status === Constants.creditExchangeSubmitted) {
    return Constants.darkbrown;
  }
  else if (status === Constants.creditExchanged) {
    return Constants.darkorange;
  }
  else if (status === Constants.redemptionPendingSign) {
    return Constants.olivine;
  }
  else if (status === Constants.voidRequestSubmitted) {
    return Constants.lightredfaded;
  }
  else if (status === Constants.expired) {
    return Constants.lightgrey;
  }
  else if (status === Constants.reversed) {
    return Constants.chocalate;
  }
  else {
    return Constants.grey;
  }
}

export function productRedemptionStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.received) {
    return Constants.green;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else if (status === Constants.creditExchangeSubmitted) {
    return Constants.darkbrown;
  }
  else if (status === Constants.creditExchanged) {
    return Constants.darkorange;
  }
  else if (status === Constants.voidRequestSubmitted) {
    return Constants.lightredfaded;
  }
  else if (status === Constants.voided) {
    return Constants.red;
  }
  else {
    return Constants.grey;
  }
}

export function virtualWarehouseCreditExchangeStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else {
    return Constants.grey;
  }
}

export function productRedemptionNoteReturnStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else{
    return Constants.grey;
  }
}

export function batchJobStatusColor(status: string) {
  if (status === Constants.active) {
    return Constants.lightblue;
  }
  else if (status === Constants.inProgress) {
    return Constants.lightgrey;
  }
  else if (status === Constants.exceptionThrow) {
    return Constants.red;
  }
  else{
    return Constants.grey;
  }
}

export function batchJobHistoryStatusColor(status: string) {
  if (status === Constants.inProgress) {
    return Constants.lightgrey;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.failed) {
    return Constants.red;
  }
  else{
    return Constants.grey;
  }
}

export function customerSubBranchRequestStatusColor(status: string) {
  if (status === Constants.pending) {
    return Constants.lightblue;
  }
  else if (status === Constants.signed) {
    return Constants.darkbrown;
  }
  else if (status === Constants.approved) {
    return Constants.green;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else {
    return Constants.grey;
  }
}

export function appolousCustomerPackageStatusColor(status: string) {
  if (status === Constants.active) {
    return Constants.lightblue;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.closed) {
    return Constants.red;
  }
  else if (status === Constants.expired) {
    return Constants.lightgrey;
  }
  else{
    return Constants.grey;
  }
}

export function unknownPaymentStatusColor(status: string) {
  if (status === Constants.pendingAssign) {
    return Constants.lightgrey;
  }
  else if (status === Constants.active) {
    return Constants.lightblue;
  }
  else if (status === Constants.knockOff) {
    return Constants.green;
  }
  else {
    return Constants.grey;
  }
}

export function cashOutStatusColor(status: string) {
  if (status === Constants.draft) {
    return Constants.lightblue;
  }
  else if (status === Constants.submitted) {
    return Constants.darkbrown;
  }
  else {
    return Constants.grey;
  }
}

export function changePaymentModeStatusColor(status: string) {
  if (status === Constants.draft) {
    return Constants.lightblue;
  }
  else if (status === Constants.pendingSign) {
    return Constants.darkblue;
  }
  else if (status === Constants.pendingFirstApproval) {
    return Constants.olivine;
  }
  else if (status === Constants.pendingFinalApproval) {
    return Constants.orange;
  }
  else if (status === Constants.completed) {
    return Constants.darkgreen;
  }
  else if (status === Constants.cancelled) {
    return Constants.lightgreyfaded;
  }
  else if (status === Constants.reviseRequired) {
    return Constants.peach;
  }
  else if (status === Constants.rejected) {
    return Constants.red;
  }
  else {
    return Constants.grey;
  }
}

export function closeCounterStatusColor(status: string) {
  if (status === Constants.draft) {
    return Constants.lightblue;
  }
  else if (status === Constants.submitted) {
    return Constants.darkbrown;
  }
  else if (status === Constants.overridden) {
    return Constants.orange;
  }
  else {
    return Constants.grey;
  }
}

export function stockRequestViewAvailable(status: string) {
  return status !== Constants.pending;
}

export function stockRequestEditAvailable(status: string) {
  return status !== Constants.completed && status !== Constants.partialComplete && status !== Constants.cancelled && status !== Constants.rejected;
}

export function stockReceiveViewAvailable(status: string) {
  return status == Constants.completed || status == Constants.partialCompleted;
}

export function stockReceiveEditAvailable(status: string) {
  return status !== Constants.partialCompleted && status !== Constants.completed;
}

export function stockAdjustmentViewAvailable(status: string) {
  return status !== Constants.pending;
}

export function stockAdjustmentEditAvailable(status: string) {
  return status !== Constants.completed && status !== Constants.rejected;
}

export function inboundNoticeViewAvailable(status: string) {
  return status !== Constants.pending
}

export function inboundNoticeEditAvailable(status: string) {
  return status !== Constants.cancelled && status !== Constants.received && status !== Constants.acknowledged  && status !== Constants.receiving  
}

export function inboundNoticeEditViewAvailable(status: string) {
  return status !== Constants.pending && status !== Constants.processing && status !== Constants.acknowledged
}

export function purchaseOrderViewAvailable(status: string) {
  // return status !== Constants.pending;
  return status !== Constants.pending && status !== Constants.pendingAllocation;
}

export function purchaseOrderEditAvailable(status: string) {
  return status !== Constants.rejected && status !== Constants.cancelled && status !== Constants.void && status !== Constants.completed;
}

export function purchaseOrderEditViewAvailable(status: string) {
  // return status !== Constants.pending && status !== Constants.processing && status !== Constants.partialComplete;
  return status !== Constants.pending && status !== Constants.processing && status !== Constants.partialComplete && status !== Constants.pendingAllocation;
}

export function goodsReceiveNoteViewAvailable(status: string) {
  return status !== Constants.pending
}

export function goodsReceiveNoteEditAvailable(status: string) {
  return status !== Constants.received && status !== Constants.partialReceived  && status !== Constants.cancelled  
}

export function goodsReceiveNoteEditViewAvailable(status: string) {
  return status !== Constants.pending && status !== Constants.acknowledged
}

export function purchaseDeliverOrderViewAvailable(status: string) {
  return status !== Constants.pending && status !== Constants.acknowledged && status !== Constants.pendingAllocation;
}

export function purchaseDeliverOrderEditAvailable(status: string) {
  return status !== Constants.received && status !== Constants.cancelled && status !== Constants.rejected;
}

export function purchaseDeliverOrderEditViewAvailable(status: string) {
  return status !== Constants.pending && status !== Constants.processing && status !== Constants.acknowledged && status !== Constants.partialReceived && status !== Constants.pendingAllocation;
}

export function stockInViewAvailable(status: string) {
  return status !== Constants.pending;
}

export function stockInEditAvailable(status: string) {
  return status !== Constants.completed && status !== Constants.rejected && status !== Constants.void;
}

export function stockOutViewAvailable(status: string) {
  return status !== Constants.pending;
}

export function stockOutEditAvailable(status: string) {
  return status !== Constants.completed && status !== Constants.rejected && status !== Constants.void;
}

export function stockReturnToHQViewAvailable(status: string) {
  return status !== Constants.pending;
}

export function stockReturnToHQEditAvailable(status: string) {
  return status !== Constants.cancelled && status !== Constants.approved && status !== Constants.rejected;
}

export function inboundNoticeDeleteAvailable(status: string) {
  return status == Constants.pending;
}

export function goodsReceiveNoteDeleteAvailable(status: string) {
  return status == Constants.pending;
}

export function purchaseDeliverOrderDeleteAvailable(status: string) {
  return status == Constants.pending;
}

export function stockInDeleteAvailable(status: string) {
  return status == Constants.pending;
}

export function stockOutDeleteAvailable(status: string) {
  return status == Constants.pending;
}

export function stockReceiveDeleteAvailable(status: string) {
  return status == Constants.pending;
}

export function customerProductReturnViewAvailable(status: string) {
  return status == Constants.pending;
}

export function redemptionCalculation (blnTab: number, availableCount: number = 0, count: number) {
  //blnTab == 1 (Service), blnTab == 2 (Product), blnTab == 3 (Optional Product)
  if (availableCount < count) {
    store.commonStore.setErrorMessage(blnTab === 1 ? "You has exceed limit of service redemption" :  blnTab === 2 ? "You has exceed limit of product redemption" : "You has exceed limit of optional product redemption" )
    return false;
  }

  return true;
}

export function newSpaceBeforeCapitalLetter (value) {
  if (value === 'ReSign') {
    return value;
  }
  
  if (typeof value === 'string' && value !== ' ') {
    // value = value.replace(/[a-z](?=\d)|\d(?=[a-z])/gi, '$& '); //This regex is to add space between number and alphabet
    if (value === "ByEach15Minutes") {
      value = "By Each 15 Minutes";
    }
    else if (value === "ByEach1Minutes") {
      value = "By Each 1 Minutes";
    }
    else {
      value = value.replace(/([a-z])([A-Z])/g, '$1 $2');
      value = value.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    }
    return value;
  }

  return value;
}

export function removeAllSpace (value) {
  if (value) {
    if (typeof(value) === 'string') {
      return value.replace(/ /g,'');
    }
  }
  
  return value;
}

export function convertValueWithSpaceInOptionsArray (aryTemp: ObjectForDropdown[], applyValue?: boolean){
  return aryTemp = aryTemp.map((valueTemp)=> ({
    ...valueTemp,
    label: newSpaceBeforeCapitalLetter(valueTemp.label),
    value: applyValue ? newSpaceBeforeCapitalLetter(valueTemp.value) : valueTemp.value
  }))
}

export function scrolltoTop() {
  window.scrollTo(0, 0);
}

export function returnCurrentOnlyDate () {
  return moment().format(Constants.displayDateFormat);
}

export function returnCurrentOnlyTimeWith30Minutes () {
  return moment().minute(moment().minute()> 30 ? 30 : 0).format(Constants.displayTimeFormat);
}

export function returnWidthPercentage (value: number) {
  return `${value/12 * 100}%`
}

export function replaceAllStringWithArray(str, aryString: string[], replace) {
  aryString.map((valueFind)=> {
    str = str.replace(new RegExp(valueFind, 'g'), replace);
  })
  return str;
}

export function returnNameFromArray (aryTemp, field?: string) {
  let strTemp = ""
  aryTemp.map((valueTemp, index)=> {
    let fieldTemp = field ? valueTemp[field] : valueTemp;
    strTemp = `${strTemp}${index !== 0 ? "," : ""} ${fieldTemp}`
  })
  return strTemp
}

export function checkPermission (actionList: string[], blnNavigatePage: boolean = false, aryPermissionList?: PermissionUserListObject[]) { 
  let blnPermission = false;
  let finalPermissionUserList = aryPermissionList || store.permissionStore.returnPermissionUserList
  actionList.map((action) => {
    let indexPermission = _.findIndex(finalPermissionUserList, {systemName: action});
    if (indexPermission > -1) {
      blnPermission = true;
    }
  })

  if (blnNavigatePage && !blnPermission) {
    // history.push('/not-found')
    store.commonStore.setDisplayPermissionDenied(true);
  }

  return blnPermission;
}

export async function downloadExcel (urlPath: string, requestBody: any) {
  // const token = store.commonStore.token;
  const token = getJWT();
  if (requestBody.fromDate) {
    requestBody.fromDate = moment(decodeURIComponent(requestBody.fromDate)).format(Constants.displayDateFormat) 
  }
  if (requestBody.toDate) {
    requestBody.toDate = moment(decodeURIComponent(requestBody.toDate)).format(Constants.displayDateFormat) 
  }

  if (requestBody.dateRange) {
    requestBody = convertDataRangeData(requestBody, "dateRange", "fromDate", "toDate")
  }
  if (requestBody.appointmentDateRange) {
    requestBody = convertDataRangeData(requestBody, "appointmentDateRange", "appointmentFromDate", "appointmentToDate")
  }
  if (requestBody.submissionDateRange) {
    requestBody = convertDataRangeData(requestBody, "submissionDateRange", "submissionFromDate", "submissionToDate")
  }
  if (requestBody.firstAppointmentDateRange) {
    requestBody = convertDataRangeData(requestBody, "firstAppointmentDateRange", "firstAppointmentFromDate", "firstAppointmentToDate")
  }
  if (requestBody.lastAppointmentDateRange) {
    requestBody = convertDataRangeData(requestBody, "lastAppointmentDateRange", "lastAppointmentFromDate", "lastAppointmentToDate")
  }
  
  let aryKey = Object.keys(requestBody)
  aryKey.map((valueKeyTemp)=> {
    //Special handling for specific field (boolean)
    if (valueKeyTemp === "isActiveGroupCode" || valueKeyTemp === "isPackageSalesOnly") {
    }
    else if ((!requestBody[valueKeyTemp] && requestBody[valueKeyTemp] !== 0) || requestBody[valueKeyTemp] === " ") {
      delete requestBody[valueKeyTemp];
    }
  })

  try{
    await fetch(`${process.env.REACT_APP_API_URL}${urlPath}`,{
      method: 'POST',
      headers:{
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody),
    })
    .then(response => {
      return response.blob().then((data) => {
        return {
          blob: data,
          fileName: response.headers.get('FileName'),
          status: response.status
        };
     });
    })
    .then(({blob, fileName, status}) => {
      if (status === 400 && urlPath === "/report/marcom/sunday/export") {
        throw {message: "SundayMarcom-001"};
      }
      else if (status !== 200) {
        history.push('/server-error');
        return;
      }
      
      let fileNameTemp = fileName || "Report"
      // if (fileName) {
      //   if (fileName.split("=").length > 1) {
      //     fileNameTemp = fileName.split("=")[1]
      //   }
      // }
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${fileNameTemp}`); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(err => { return Promise.reject(err);});
  }
  catch (error: any) {
    return Promise.reject(error);
  }
}

export function convertDataRangeData(requestBodyTemp, fieldName, fromDateFieldName, toDateFieldName) {
  let requestBody = _.cloneDeep(requestBodyTemp)
  if (requestBody[fieldName].fromDate) {
    requestBody[fieldName][fromDateFieldName] = moment(decodeURIComponent(requestBody[fieldName].fromDate)).format(Constants.displayDateFormat) 
    if (fromDateFieldName !== "fromDate") {
      delete requestBody[fieldName].fromDate;
    }
  }
  if (requestBody[fieldName].toDate) {
    requestBody[fieldName][toDateFieldName] = moment(decodeURIComponent(requestBody[fieldName].toDate)).format(Constants.displayDateFormat) 
    if (toDateFieldName !== "toDate") {
      delete requestBody[fieldName].toDate;
    }
  }
  requestBody = {...requestBody, ...requestBody[fieldName]}
  delete requestBody[fieldName];

  return requestBody;
}

export function returnExpiredDate (format) {
  return moment().add(6, 'months').format(format);
}

export function returnNewExpiredDate (format) {
  return moment().add(3, 'months').format(format);
}

export function returnPriceWithCurrency (value) {
  let valueTemp = returnThousandSeperator(value);
  return `${store.branchStore.branchCurrency} ${valueTemp}`
}

export function returnThousandSeperator (value) {
  return returnNumberWithDecimal(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function returnCurrency() {
  return `${store.branchStore.branchCurrency}`
}

export function convertAdditonalFormDataSubmit (additionalFormFieldFormTypeList, submitValuesTemp) {
  let submitValues = _.cloneDeep(submitValuesTemp);
  let strErrorField = "";
  let additionalFormFieldFormTypeListTemp = additionalFormFieldFormTypeList;
  additionalFormFieldFormTypeListTemp.map((valueAdditionalFormFieldFormTypeList) => {
    if (valueAdditionalFormFieldFormTypeList.isRequired) {
      if (!submitValues[valueAdditionalFormFieldFormTypeList.id]) {
        strErrorField = valueAdditionalFormFieldFormTypeList.name;
        return;
      }
      else if (valueAdditionalFormFieldFormTypeList.controlType === Constants.checkboxes) {
        if (JSON.parse(submitValues[valueAdditionalFormFieldFormTypeList.id]).length < 1) {
          strErrorField = valueAdditionalFormFieldFormTypeList.name;
          return;
        }
      }
    }

    if (submitValues[valueAdditionalFormFieldFormTypeList.id]) {
      submitValues.additionalData = {...submitValues.additionalData , [valueAdditionalFormFieldFormTypeList.id]: String(submitValues[valueAdditionalFormFieldFormTypeList.id])}
      delete submitValues[valueAdditionalFormFieldFormTypeList.id];
    }
  })

  if (submitValues.additionalFormData) {
    delete submitValues["additionalFormData"];
  }

  if (strErrorField) {
    return {status: Constants.failed, data: strErrorField}
  }

  return {status: Constants.success, data: submitValues};
}

export function convertAdditionalFormDataRead (additionalFormData, aryAdditionalFormFieldWithFormType?: AdditionalFormFieldWithFormType[]) {
  if (additionalFormData) {
    let objTemp : any = {};
    if (aryAdditionalFormFieldWithFormType) {
      let aryKey = Object.keys(additionalFormData);
      aryKey.map((valueKeyTemp)=> {
        aryAdditionalFormFieldWithFormType.map((valueAryAdditionalFormFieldWithFormTypeTemp)=> {
          if (valueAryAdditionalFormFieldWithFormTypeTemp.id === valueKeyTemp) {
            if (valueAryAdditionalFormFieldWithFormTypeTemp.controlType === Constants.checkboxes) {
              objTemp = convertCheckboxesData(objTemp, valueAryAdditionalFormFieldWithFormTypeTemp.options, additionalFormData[valueKeyTemp])
            }
            return;
          }
        })
        objTemp = {...objTemp, [valueKeyTemp]: additionalFormData[valueKeyTemp]}
      })
    }
    else {
      additionalFormData.map((valueAdditionalFormDataTemp) => {
        if (valueAdditionalFormDataTemp.controlType === Constants.checkboxes)  {
          objTemp = convertCheckboxesData(objTemp, valueAdditionalFormDataTemp.options, valueAdditionalFormDataTemp.value)
        }
        else {
          objTemp = {...objTemp, [valueAdditionalFormDataTemp.id] : valueAdditionalFormDataTemp.value}
        }
      }) 
    }
    return objTemp;
  }
}

export function convertCheckboxesData (objTemp, optionCheckboxes, valueCheckboxes) {
  let aryValuesCheckbox = JSON.parse(valueCheckboxes);
  optionCheckboxes.map((valueOptionsTemp) => {
    let indexFound = aryValuesCheckbox.indexOf(valueOptionsTemp);
    objTemp = {...objTemp, [valueOptionsTemp] : indexFound > -1 ? true : false}
  })

  return objTemp;
}

export function removeAllEmptyDataFromObject (objTemp: any) {
  if (_.isArray(objTemp)) {
    return objTemp;
  }

  let aryKey = Object.keys(objTemp);
  let objTempFinal : any = {}
  aryKey.map((valueKeyTemp)=> {
    if (objTemp[valueKeyTemp]) {
      objTempFinal = {...objTempFinal, [valueKeyTemp]: objTemp[valueKeyTemp]}
    }
  })
  return objTempFinal;
}

export function getFileExtension(filename) {
  return filename.split('.').pop()
}

export function getNotificationIcon(notificationType) {
  if (notificationType == Constants.email) {
    return <EmailIcon size={Constants.menuIconSize}/>
  } 
  else if (notificationType == Constants.sms) {
    return <MessageProcessingIcon size={Constants.menuIconSize}/>
  }
  else if (notificationType == Constants.pushNotification) {
    return <BellIcon size={Constants.menuIconSize}/>
  }
  else if (notificationType === Constants.secureMessage) {
    return <MessageLockIcon size={Constants.menuIconSize}/>
  }
  else {
    return <EmailVariantIcon size={Constants.menuIconSize}/>
  }
}

export function getPurchaseTypeIcon(purchaseType, blnDisabled: boolean = false) {
  if (purchaseType == Constants.package || purchaseType == Constants.packageProduct || purchaseType == Constants.packageOptionalProduct || purchaseType == Constants.packageService) {
    return <PackageVariantClosedIcon size={18} className="text-success align-middle me-2"/>
  } 
  else if (purchaseType == Constants.service || purchaseType == Constants.serviceTreatmentOnly) {
    return <ClipboardEditIcon size={18} className={`${!blnDisabled ? "text-primary" : ""} align-middle me-2`}/>
  }
  else if (purchaseType === Constants.product || purchaseType === Constants.productOnly) {
    return <ArrangeBringForwardIcon size={18} className="text-warning align-middle me-2"/>
  }
  else if (purchaseType == newSpaceBeforeCapitalLetter(Constants.optionalProducts) ) {
    return <ArrangeBringForwardIcon size={18} className="text-optionalProduct align-middle me-2"/>
  }
  else if (purchaseType == newSpaceBeforeCapitalLetter(Constants.promotion)) {
    return <BrightnessPercentIcon size={18} className="align-middle me-2"/>
  }
  else if (purchaseType == newSpaceBeforeCapitalLetter(Constants.upgrade)) {
    return <PackageVariantClosedPlusIcon size={18} className="text-dark-green-color align-middle me-2"/>
  }
}

export function displayOptionalProducts(valueOptionalProducts, intl, blnLabelField: boolean = true, labelFieldName: string = ""){
  if (!valueOptionalProducts) {
    return;
  }
  else if (valueOptionalProducts.length === 0) {
    return <p className="margin-bottom-0" style={{fontSize: '11px', color: "grey"}}>{intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({id: "ConsumeableProduct"}).toLocaleLowerCase()})}</p>;
  }

  let aryViews: any = [];
  valueOptionalProducts.map((valueOptionalProductsTemp, index)=> {
    aryViews.push(
      <div className='flex-align-items-center-without-height' key={`optionProductCart_${index}`}>
        <p className="margin-bottom-0" style={{fontSize: '11px', color: "grey"}}>- {blnLabelField ? labelFieldName ? valueOptionalProductsTemp[labelFieldName] : valueOptionalProductsTemp.label : valueOptionalProductsTemp}</p>
      </div>
    )
  })
  return aryViews;
}

export function displayPromotionCode(promotionCode:any) {
  return <span style={{color: Constants.pink, fontSize:"12px"}}><b>{promotionCode ? `(${promotionCode})` : ""}</b></span>
}

export function capitalizeFirstLetter(originalText: any) {
  if (typeof originalText !== 'string' && originalText === ' ') {
      return originalText;
  }
  return originalText.substring(0, 1).toUpperCase() + originalText.substring(1);
}

export function convertParamsUrlToArray (fieldName: string, paramsURL) {
  let aryFinalData : any = [];
  if (paramsURL) {
    paramsURL = paramsURL.replace("?", "")
  }
  if (paramsURL) {
    let aryParamsURL = paramsURL.split("&")
    aryParamsURL.map((valueAryParamsURLTemp)=> {
      let aryField = valueAryParamsURLTemp.split("=")
      if (aryField.length > 1) {
        if (aryField[0] === fieldName && aryField[1] !== Constants.emptyId) {
          aryFinalData.push(aryField[1])
        }
      }
    })
  }

  return aryFinalData;
}

export function convertRequestBodyObject (requestBody) {
  let finalRequestBody = "";
  let keyOfRequestBody = Object.keys(requestBody);
  keyOfRequestBody.map((valueKeyOfRequestBody)=> {
    let valueTemp = requestBody[valueKeyOfRequestBody];
    if (Array.isArray(valueTemp)){
      valueTemp.map((valueAryTemp)=> {
        finalRequestBody = finalRequestBody+`${finalRequestBody !== "" ? "&" : ""}${valueKeyOfRequestBody}=${valueAryTemp}`
      })
    }
    else if (typeof valueTemp === 'object') {
      let listOfObjectTemp = Object.keys(valueTemp);
      listOfObjectTemp.map((valueListOfObjectTemp)=> {
        finalRequestBody = finalRequestBody+`${finalRequestBody !== "" ? "&" : ""}${valueListOfObjectTemp}=${valueTemp[valueListOfObjectTemp]}`
      })
    }
    else {
      finalRequestBody = finalRequestBody+`${finalRequestBody !== "" ? "&" : ""}${valueKeyOfRequestBody}=${valueTemp}`
    }
  })
  return finalRequestBody;
}

export function compareParamsArrayWithDefaultArray (arrayParams, arrayToCompare, fieldToCompare, fieldName, fieldId) {
  let aryFinalTemp : any = [];
  let aryListToCompare: any = _.cloneDeep(arrayToCompare);
  arrayParams.map((valueArrayParamsTemp) => {
    let indexBranchTemp = _.findIndex(aryListToCompare, {[fieldToCompare]: valueArrayParamsTemp})
    if (indexBranchTemp > -1) {
      aryFinalTemp.push({
        ...aryListToCompare[indexBranchTemp], 
        label: newSpaceBeforeCapitalLetter(aryListToCompare[indexBranchTemp][fieldName]),
        value: aryListToCompare[indexBranchTemp][fieldId]
      })
    }
  })

  return aryFinalTemp;
}

export function convertArrayToStringDisplay (aryData : string [], withSlash: boolean = false, noSpace: boolean = false) {
  let finalStr = "";
  aryData.map((valueTemp)=> {
    let finalValue = noSpace ? valueTemp : newSpaceBeforeCapitalLetter(valueTemp);
    finalStr = finalStr == "" ? finalValue : `${finalStr}${withSlash ? ` /\n` : `, `}${finalValue}`
  })
  return finalStr;
}


export function arraySpliceInAllValue (aryTemp: any, displayField: string, blnForBranchOnly?: boolean, fieldName?: string, valueName?: string) {
  if (aryTemp) {
    if (blnForBranchOnly) {
      aryTemp = aryTemp.map((value) => ({...value, label: value[fieldName || "name"], value: value[valueName || "id"] }))
      if (!getBranchUser() && displayField) {
        aryTemp.splice(0, 0, { label: displayField, value: " " });
      }
    }
    else {
      aryTemp.splice(0, 0, { [fieldName ||"displayValue"]: displayField, [valueName || "key"]: " " });
    }
  }
  return aryTemp || [];
}

export function returnRouteFromModule (reference: string) {
  if (reference === "InitialBranchProduct" || reference === "UpdateBranchProduct") {
  }
  else if (reference === "StockAdjustment") {
    return RoutesList.stockAdjustment;
  }
  else if (reference === "StockRequest") {
    return RoutesList.stockRequest;
  }
  else if (reference === "OutletStockPurchase" || reference === "OutletTransfer") {
    return RoutesList.purchaseDeliverOrder;
  }
  else if (reference === "CustomerServiceTreatment" || reference === "CustomerProductRedeem" || reference === "CustomerProductPurchase" || reference === "CustomerDeliveryOrder" || reference === "ReverseCustomerProductPurchase" || reference === "ReverseCustomerServiceTreatment" || reference === "ReverseCustomerProductRedeem") {
    return RoutesList.customerRedemption;
  }
  else if (reference === "StockReceive") {
    return RoutesList.stockReceive;
  }
  else if (reference === "CustomerProductReturn" || reference === "CustomerDefectProductReturn" || reference === "CustomerProductExchange" || reference === "ProductReturnCredit" || reference === "CustomerDefectExchange") {
    return RoutesList.customerProductReturn;
  }
  else if (reference === "StockReturnToHQ" || reference === "DefectStockReturnToHQ" || reference === "HQStockReturn") {
    return RoutesList.stockReturnToHQ;
  }
  else if (reference === "RefundSalesOrderToCredit" || reference === "CustomerReturnRefund") {
    return RoutesList.exchangeToCredit;
  }
  else if (reference === "ProductRedemptionNote") {
    return RoutesList.productRedemptionNote;
  }
  else if (reference === "ProductRedemptionNoteReturn"){
    return RoutesList.productRedemptionNoteReturn;
  }
  else if (reference === "CustomerVirtualWarehouseCreditExchange" || reference === "VirtualWarehouseCreditExchange") {
    return RoutesList.virtualWarehouseCreditExchange;
  }
  else if (reference === "AdditionBonusSalesTarget"){
    return RoutesList.additionBonusSalesTarget;
  }
  else if (reference === "Appointment"){
    return RoutesList.appointment;
  }
  else if (reference === "Branch"){
    return RoutesList.branch;
  }
  else if (reference === "BranchCommission"){
    return RoutesList.branchCommission;
  }
  else if (reference === "Brand"){
    return RoutesList.brand;
  }
  else if (reference === "BusinessEntity"){
    return RoutesList.businessEntity;
  }
  // else if (reference === "CommissionScheme"){
  //   return RoutesList.packageCommissionScheme
  // }
  else if (reference === "Country"){
    return RoutesList.country;
  }
  else if (reference === "Currency"){
    return RoutesList.currency;
  }
  else if (reference === "Customer" || reference === "CustomerRelationship"){
    return RoutesList.customer;
  }
  else if (reference === "CustomerSalesOrder"){
    return RoutesList.customerSalesOrder;
  }
  else if (reference === "CustomerTransferRequest"){
    return RoutesList.customerTransferRequest;
  }
  else if (reference === "Employee"){
    return RoutesList.employee;
  }
  else if (reference === "EmployeeLeaves"){
    return RoutesList.employeeLeave;
  }
  else if (reference === "Lead"){
    return RoutesList.lead;
  }
  else if (reference === "Measurement"){
    return RoutesList.measurement;
  }
  else if (reference === "MediaSource"){
    return RoutesList.mediaSource;
  }
  else if (reference === "Package"){
    return RoutesList.package;
  }
  else if (reference === "PaymentMethod"){
    return RoutesList.paymentMethod;
  }
  else if (reference === "PaymentMethodOption"){
    return RoutesList.paymentMethodOption;
  }
  else if (reference === "Position"){
    return RoutesList.position;
  }
  else if (reference === "Product"){
    return RoutesList.product;
  }
  else if (reference === "ProductRedemptionNoteReturn"){
    return RoutesList.productRedemptionNoteReturn;
  }
  else if (reference === "Promotion"){
    return RoutesList.promotion;
  }
  else if (reference === "Race"){
    return RoutesList.race;
  }
  else if (reference === "SalesCategory"){
    return RoutesList.salesCategory;
  }
  else if (reference === "SalesTarget"){
    return RoutesList.salesTarget;
  }
  else if (reference === "StockTake"){
    return RoutesList.stockTake;
  }
  else if (reference === "Taxes"){
    return RoutesList.tax;
  }
  else if (reference === "NotificationTemplate"){
    return RoutesList.notificationTemplate;
  }
  else if (reference === "ServiceTreatment"){
    return RoutesList.serviceTreatment;
  }
  else if (reference === "Category"){
    return RoutesList.category;
  }
  else if (reference === "DailySalesReportHistory"){
    return RoutesList.dsrAdjusment;
  }
  else if (reference === "CustomerVirtualWarehouse" || reference === "CustomerVirtualWarehouseExpired" || reference === "ReverseVirtualWarehouse"){
    return RoutesList.customerVirtualWarehouse;
  }
  else if (reference === "CustomerTransfer"){
    return RoutesList.customerTransferRequest;
  }
  else if (reference === "AppolousPackageRedemption" || reference === "ReverseAppolousPackageRedemption") {
    return `${RoutesList.customer}/${RoutesList.appolousCustomerPackageRedemption}`
  }
  else if (reference === "AppolousSale" || reference === "AppolousCustomerSales"){
    return `${RoutesList.customer}/${RoutesList.appolousCustomerSales}`
  }
  else if (reference === "CloseCounter") {
    return `${RoutesList.closeCounter}`
  }
  else if (reference === "AppointmentTarget") {
    return `${RoutesList.appointmentTarget}`
  }
  else if (reference === "UnknownCustomerPayment") {
    return `${RoutesList.unknownPayment}`
  }
  else if (reference === "ChangePaymentMode") {
    return `${RoutesList.changePaymentMode}`
  }
}

export function urlHandleWithWorflowAction (url, requestBody) {
  let finalUrl = url;
  if (requestBody) {
    if (requestBody.workflowAction) {
      if (requestBody.workflowAction === Constants.approve || requestBody.workflowAction === Constants.reject) {
        if (finalUrl) {
          let partsOfFinalUrl = finalUrl.split('/');
          let lastPartOfUrl = partsOfFinalUrl.pop() || partsOfFinalUrl.pop();
          let blnReviewExist = false;
          if (lastPartOfUrl) {
            if (lastPartOfUrl.toLocaleLowerCase() === Constants.review.toLocaleLowerCase()) {
              blnReviewExist = true;
            }
          }

          if (!blnReviewExist) {
            finalUrl = finalUrl + '/review'
          }
        }
      }
    }
  }
  return finalUrl;
}

export function calculateBalanceAmount (validation, paymentDetailsFieldName, totalPriceFieldName, paymentDetails?: CustomerPaymentDetailObject) {
  let prefillPaidNumber : any = 0;
  let paymentDetailsTemp = paymentDetails || validation.values[paymentDetailsFieldName] || [];
  if (validation.values[totalPriceFieldName]) {
    let totalPaidAmount = 0;
    paymentDetailsTemp.map((valuePaymenttDetailsTemp)=> {
      totalPaidAmount += Number(valuePaymenttDetailsTemp.paidAmount);
    })
    if (!paymentDetails) {
      totalPaidAmount += Number(validation.values.paidNumber);
    }
    prefillPaidNumber = validation.values[totalPriceFieldName] - totalPaidAmount;
    if (prefillPaidNumber > 0) {
      prefillPaidNumber = Number(prefillPaidNumber).toFixed(2);
    }
  }

  if (prefillPaidNumber < 0) {
    prefillPaidNumber = 0;
  }
  return prefillPaidNumber;
}

export function blockNegativeOrZeroPayment (paidNumber:number, intl){
  if (Number(paidNumber) < 0) {
    store.commonStore.setErrorMessage(intl.formatMessage({id: "NegativePaymentAmount"}))
    return true;
  }
  return false;
}

export function firstDayOfMonthToCurrentDay() {
  return ({
    fromDate: moment().startOf('month').format(Constants.displayDateFormat), 
    toDate: moment().format(Constants.displayDateFormat)
  })
}

export function firstDayOfCurrentMonthToLastDayOfCurrentMonth() {
  return ({
    fromDate: moment().startOf('month').format(Constants.displayDateFormat), 
    toDate: moment().endOf('month').format(Constants.displayDateFormat)
  })
}

export function firstDayOfYearToLastDayOfYear() {
  return ({
    fromDate: moment().startOf('year').format(Constants.displayDateFormat), 
    toDate: moment().endOf('year').format(Constants.displayDateFormat)
  })
}

export function firstDayOfFiveYearAgoToLastDayOfCurrentYear() {
  return ({
    fromDate: moment().subtract(5, 'year').startOf('year').format(Constants.displayDateFormat), 
    toDate: moment().endOf('year').format(Constants.displayDateFormat)
  })
}

export function returnBrandSlogan (valueBrand) {
  return valueBrand === 'One Doc X Hair Doc' ? `THE FUTURE OF SKIN WELLNESS` : valueBrand === 'Slim Doc' ? `GET HEALTHY, GET SLIM` : `FACE AND BODY EXPERT`
}

export function comparePathForPagination (prevPath, pathToCompare, blnHandleSinglePath: boolean = false) {
  let blnPathSame = false;
  if (prevPath) {
    let aryPath = prevPath.split('/');
    if (aryPath.length > 2 || (blnHandleSinglePath && aryPath.length > 1)) {
      if (aryPath[1] === pathToCompare) {
        blnPathSame = true;
      }
    }
  }
  return blnPathSame
}

export function comparePathForSetParams (history, pathToCompare, blnHandleSinglePath: boolean = false) {
  let blnPathSame = false;
  if (history) {
    if (history.location) {
      if (history.location.pathname) {
        let aryPath = history.location.pathname.split('/');
        if (aryPath.length > 2 || (blnHandleSinglePath && aryPath.length > 1)) {
          if (aryPath[1] === pathToCompare) {
            blnPathSame = true;
          }
        }
      }
    }
  }
  return blnPathSame;
}

export function displayWatermark (){
  return(
    <div/>
    // <div className='logoWatermark'>
    //   <img src={logoWatermark}/>
    // </div>
  );
}

export function displayWatermarkForENV (text, color){
  return(
    <div className='labelWaterMark'>
      <label style={{backgroundColor: color}}>{text}</label>
    </div>
  );
}

export function returnMobileOrTable (){
  if (window.navigator.userAgent.match(/Android/i)
    || window.navigator.userAgent.match(/webOS/i)
    || window.navigator.userAgent.match(/iPhone/i)
    || window.navigator.userAgent.match(/iPad/i)
    || window.navigator.userAgent.match(/iPod/i)
    || window.navigator.userAgent.match(/BlackBerry/i)
    || window.navigator.userAgent.match(/Windows Phone/i) 
    ||(window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 0)) 
  {
    return true;
  } else {
    return false;
  }
}

export const escapedNewLineToLineBreakTag = (string) => {
  if (string) {
    return string.split('\n').map((item, index) => {
      return (index === 0) ? item : [<br key={index} />, item]
    })
  }
  return string;
}

export const blnValidErrorMessage = (errorCode) => {
  if (errorCode === 'Appointment-012' || 
      errorCode === "Appointment-014" || 
      errorCode === "Appointment-016" || 
      errorCode === "Appointment-017" ||
      errorCode === "StockRequest-007" || 
      errorCode === "StockRequest-012" || 
      errorCode === "CustomerSalesOrder-005" || 
      errorCode === "Customer-008" || 
      errorCode === "CustomerSalesOrder-044" || 
      errorCode === "Product-014" || 
      errorCode === "Lead-013" || 
      errorCode === "Employee-007" || 
      errorCode === "Employee-008" || 
      errorCode === "Employee-009" || 
      errorCode === "Employee-015" || 
      errorCode === "Customer-010" || 
      errorCode === "Lead-015" ||
      errorCode === "Lead-016" ||
      errorCode === "Lead-017" ||
      errorCode === "ServiceTreatment-010" || 
      errorCode === "Customer-014" ||
      errorCode === "Package-020" || 
      errorCode === "Appolous-013" ||
      errorCode === "CustomerSalesOrder-042" ||
      errorCode === "CustomerSalesOrder-047" || 
      errorCode === "CustomerPayment-020" || 
      errorCode === "CustomerCart-013" || 
      errorCode === "CustomerSalesOrder-052" || 
      errorCode === "SalesTarget-004" || 
      errorCode === "CustomerAccessRequest-009" || 
      errorCode === "CustomerSalesOrder-056" ) {
    return true;
  }
  return false;
}

export const appendCountryCode = (value, countryCode: string) => {
  if (removeAllSpace(value)) {
    if (value.length > 0) {
      if (value.charAt(0) != countryCode) {
        value=countryCode+value
      }
    }
  }
  return value;
}

export const blnDecimal = (valueTemp) => {
  let resultTemp = (valueTemp - Math.floor(valueTemp)) !== 0;
  return resultTemp;
}

export const returnAdvancedSearchText = () => {
  let resultAdvancedSearchText = "AdvancedSearch";
  if(window.screen.width <= 998 && isMobile){
    resultAdvancedSearchText = "Adv.Search";
  }
  return resultAdvancedSearchText;
}

export const returnAdvancedSearchIcon = () => {
  return  <AccountSearchIcon size={20}/>
}

export const returnPaymentIcon = (value, blnReturnPureIcon: boolean = false) => {
  let iconTemp : any = null;
  let valueNameTemp = String(value).toLowerCase();

  if (valueNameTemp.includes("qr")) {
    if (!blnReturnPureIcon) {
      iconTemp = <QrcodeIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = QrcodeIcon;
    }
  }
  else if (valueNameTemp.includes("cheque")) {
    if (!blnReturnPureIcon) {
      iconTemp = <BookAccountIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = BookAccountIcon;
    }
  }
  else if (valueNameTemp.includes("cash")){
    if (!blnReturnPureIcon) {
      iconTemp = <CashIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = CashIcon;
    }
  }
  else if (valueNameTemp.includes("credit card")){
    if (!blnReturnPureIcon) {
      iconTemp = <CreditCardCheckOutlineIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = CreditCardCheckOutlineIcon;
    }
  }
  else if (valueNameTemp.includes("card")){
    if (!blnReturnPureIcon) {
      iconTemp = <CreditCardOutlineIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = CreditCardOutlineIcon;
    }
  }
  else if (valueNameTemp.includes("online")){
    if (!blnReturnPureIcon) {
      iconTemp = <WeatherCloudyIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = WeatherCloudyIcon;
    }
  }
  else if (valueNameTemp.includes("customer")) {
    if (!blnReturnPureIcon) {
      iconTemp = <DiamondStoneIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = DiamondStoneIcon;
    }
  }
  else if (valueNameTemp.includes("transfer credit")) {
    if (!blnReturnPureIcon) {
      iconTemp = <BookCheckOutlineIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = BookCheckOutlineIcon;
    }
  }
  else  {
    if (!blnReturnPureIcon) {
      iconTemp = <BankOutlineIcon size={24} className='text-primary align-middle'/>;
    }
    else {
      iconTemp = BankOutlineIcon;
    }
  }

  return iconTemp;
}

export const displayTypeWithColor = (color, typeName, onClickFunction?: Function, blnHideCircle?: boolean) => {
  return (
    <div 
      className={`flex-align-items-center ${onClickFunction ? "pointer-clickable" : ""}`} 
      style={{marginLeft: '16px'}}
      onClick={()=> {
        if (onClickFunction) {
          onClickFunction();
        }
      }}>
      <div style={{backgroundColor: color, width: "30px", height: '30px', borderRadius: '15px', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        {
          blnHideCircle
          &&
          <span>{typeName}</span>
        }
      </div>
      {!blnHideCircle && <span style={{marginLeft: "8px", fontWeight: '500', fontSize: "14px"}}>{typeName}</span>}
    </div>
  )
}

export const returnSubBrandList = (blnReturnWithAllTitle?: boolean) => {
  // if (store.profileStore.profileDetail) {
  //   if (store.profileStore.profileDetail.isHavingSubBrand) {
  //     if (store.profileStore.profileDetail.subBrands.length > 0) {
  //       let arySubBrandsTemp = _.cloneDeep(store.profileStore.profileDetail.subBrands);
  //       arySubBrandsTemp = _.filter(arySubBrandsTemp, function(valueSubBrandTemp) { 
  //         return !valueSubBrandTemp.isDelete; 
  //       });
  //       if (arySubBrandsTemp.length > 0) {
  //         return arySubBrandsTemp;
  //       }
  //     }
  //   }
  // }
  if (store.brandStore.subBrandList) {
    if (store.brandStore.subBrandList.length > 0) {
      let arySubBrandsTemp: SubBrandObject[] | any = _.cloneDeep(store.brandStore.subBrandList);
      arySubBrandsTemp = _.filter(arySubBrandsTemp, function(valueSubBrandTemp) { 
        return !valueSubBrandTemp.isDelete; 
      });
      if (arySubBrandsTemp.length > 0) {
        if (blnReturnWithAllTitle) {
          return _.concat([{id: "", name: "All"}], arySubBrandsTemp);
        }
        return arySubBrandsTemp;
      }
    }
  }
  return [];
}

export const displaySiteMapIcon = (Icon: any, size: number, style?: any) => {
  return (
    <div style={{width: "40px", height: '40px', position: 'absolute', left: '-17.5px', top: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Icon size={size} color="white" style={{...style}}/>
    </div>
  )
}

export function convertArrayForExpandRow (aryTempInsert: any, fieldName: string, applyMoment: boolean = false, disabledCheckFieldName: string = "") {
  let aryFinalTemp : any = [];
  let aryTemp = _.cloneDeep(aryTempInsert);
  if (aryTemp) {
    aryTemp.map((valueAryTemp, indexTemp)=> {
      let valueTemp = {...valueAryTemp, originalIndex: indexTemp}
      if (valueTemp[fieldName]) {
        let dateTemp = applyMoment ? moment(valueTemp[fieldName]).format(Constants.displayDateFormat) : valueTemp[fieldName]
        let indexDate = _.findIndex(aryFinalTemp, {[fieldName]: dateTemp});
        if (indexDate > -1) {
          aryFinalTemp[indexDate].data.push(valueTemp)
        }
        else {
          aryFinalTemp.push({
            [fieldName]: dateTemp,
            data: [valueTemp],
            collapse: true
          })
        }
      }
    })
  }

  if (disabledCheckFieldName && aryFinalTemp.length > 0) {
    aryFinalTemp = aryFinalTemp.map((valueFinalTemp)=> {
      if (valueFinalTemp.data) {
        let blnIsActive = false;
        valueFinalTemp.data.map((valueDataTemp)=> {
          if (!valueDataTemp[disabledCheckFieldName]) {
            blnIsActive = true;
          }
        })
        if (!blnIsActive) {
          valueFinalTemp[fieldName] = `${valueFinalTemp[fieldName]} (Not editable)`
        }
      }
      return valueFinalTemp;
    })
  }
  return aryFinalTemp;
}

export function truncateText (textTemp, length) {
  return textTemp.length > length ? textTemp.substring(0, length) + "..." : textTemp;
}

export function dailySalesRecordTypeListHandling (aryTemp: StaticReferenceDataObject[], blnRemoveResign: boolean = false) {
  let dailySalesRecordTypeListTemp = _.cloneDeep(aryTemp);

  if (blnRemoveResign) {
    _.remove(dailySalesRecordTypeListTemp, { displayValue: "ReSign"});
  }
  else {
    let indexResign = _.findIndex(dailySalesRecordTypeListTemp, {displayValue: Constants.resign})
    if (indexResign > -1) {
      dailySalesRecordTypeListTemp[indexResign].displayValue = Constants.resignUpgrade;
    }
  }

  let indexNewVist = _.findIndex(dailySalesRecordTypeListTemp, {displayValue: Constants.newVisit})
  if (indexNewVist > -1) {
    dailySalesRecordTypeListTemp[indexNewVist].displayValue = "New Visit";
  }
  
  return dailySalesRecordTypeListTemp;
}

export const returnBrandCommissionType = () => {
  let brandProductCommissionTypeTemp = "";
  if (store.profileStore.profileDetail) {
    if (store.profileStore.profileDetail.brandProductCommissionType) {
      brandProductCommissionTypeTemp = store.profileStore.profileDetail.brandProductCommissionType;
    }
  }
  return brandProductCommissionTypeTemp;
}

export const handleCustomizeTableScrolling = () => {
  return isMobile && window.screen.width < 998;
}

export const checkValidDate = (date: string) => {
  if (date === Constants.invalidDateTime || date === "" || !date || date === null){
    return Constants.emptyData;
  }
  else{
    return moment(date).format(Constants.displayDateFormat);
  }
}

export const customFilter = (option, searchText) => {
  return true;
};

export const returnMalaysiaCurrencyRounding = (value) => {
  let valueTemp = _.cloneDeep(Number(value));
  return Number(Math.floor((valueTemp + 0.025) * 20) / 20);
}

export const returnSupportStickyHeaderPath = (path) => {
  return (
    path === `/${RoutesList.appointment}` || 
    path === `/${RoutesList.stockRequest}/view/:id` ||
    path === `/${RoutesList.stockReceive}/view/:id` || 
    path === `/${RoutesList.permission}`
  );
}

export const returnTableCellUI = (key, index, totalLength, value, borderedColor?: boolean, minHeightValue?: string, maxHeightValue?: string, heightValue?: string) => {
	return (
		<div 
			key={key}
			style={{
				marginTop: index === 0 ? "-0.75rem" : "", 
				marginBottom: index === totalLength-1 ? "-0.75rem" : "", 
				marginLeft: "-0.75rem",
				marginRight: "-0.75rem",
				paddingLeft: "16px",
				paddingRight: '16px',
				minHeight: minHeightValue ? minHeightValue : '50px', 
				maxHeight: maxHeightValue ? maxHeightValue : '50px', 
				height: heightValue ? heightValue : '50px', 
				alignItems: 'center', 
				display: 'flex', 
				borderBottom: index === totalLength-1 ? "" : "dashed #eff2f7",
				borderBottomWidth: "1px",
        ...borderedColor && { backgroundColor: index % 2 ? '#f0f3f5' : '#f7fcff' },
			}}>
			{value}
		</div>
	)
}

export const returnThousandSeperatorInString = (value) =>{
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const returnSwitchTabButtonUI = (name, id, defaultChecked, disabled, displayText, onClickFunction, width: string = "", className?: string) => {
  return (
    <>
      <input type="radio" className="btn-check" name={name} id={id} autoComplete="off" checked={defaultChecked} disabled={disabled}
        onChange={()=> {}}
        onClick={async ()=> {
          onClickFunction()
        }}/>
      <label style={{zIndex: 0, minWidth: width || Constants.actionButtonWidth, maxWidth: width || Constants.actionButtonWidth}} className={`btn btn-outline-primary ${className ? className : ""} mb-0`} htmlFor={id}>
        {displayText}
      </label>
    </>
  )
}

export const paginationButtonTabDisplay = (totalItem, pageSize, currentPage, setCurrentPage, intl) => {
  let totalPage = Math.ceil(totalItem/pageSize); 
  let fromPage = 0;
  let upToPage = 3;
  let blnAddLastPage = false;

  while (currentPage > upToPage) {
    upToPage += 3;
  }

  if (totalPage > upToPage) {
    fromPage = (upToPage - 3)+1
  }
  else {
    fromPage = upToPage - 2
    upToPage = totalPage;
  }

  let aryButton : paginationPageObject[] = [];
  if (totalPage <= 1) {
    aryButton = [{page : 1}]
  }
  else {
    for (var p=fromPage; p<=upToPage; p++) {
      if (p !== 1 && p !==totalPage) {
        aryButton.push({
          page: p
        })
      }
    }
    blnAddLastPage = true;
  }

  const displayPaginationButton = (displayText, pageNumber, blnArrow = false, key="") => {
    return (
      <button
        type="button"
        key={`button_key_${pageNumber+(blnArrow ? 8888 : 0)}_${key}`}
        className={`btn ${currentPage==pageNumber && "btn-primary"}`}
        style={{marginLeft: '4px', minWidth: '35px', width: 'auto', paddingLeft: '8px', paddingRight: '8px', height: '35px', borderRadius:'17.5px'}}
        onClick={() => {setCurrentPage(pageNumber)}}
      >
        {`${displayText}`}
      </button>
    )
  }

  return (
    <Row className="align-items-md-center mt-30">
      {
        totalItem > 0
        &&
        <Col>
          {intl.formatMessage({ id: "ShowNumbersOfPaginationTable" }, 
            { 
              fromNumber: (currentPage * pageSize)+1-pageSize, 
              toNumber: currentPage * pageSize < totalItem ? currentPage * pageSize : totalItem, 
              totalNumber: totalItem
            }
          )}
        </Col>
      }
      <Col className="pagination pagination-rounded justify-content-end margin-bottom-2 inner-custom-pagination">
        {
          blnAddLastPage && displayPaginationButton(`1`, 1)
        }
        {
          upToPage > 3 && displayPaginationButton(`<<`, fromPage-1, true)
        }
        {
          currentPage !== 1 && displayPaginationButton(`<`, currentPage-1, true, "single_arrow_<")
        }
        {aryButton.map(p => (
          displayPaginationButton(p.page, p.page)
        ))}
        {
          currentPage !== totalPage && displayPaginationButton(`>`, currentPage+1, true, 'single_arrow_>')
        }
        {
          upToPage !== totalPage && displayPaginationButton(`>>`, upToPage+1, true)
        }
        {
          blnAddLastPage && displayPaginationButton(totalPage, totalPage)
        }
      </Col>
    </Row>
  );
};

export const displayTagUI = (valueTags: TagDetailsObject, blnPermissionUpdateCustomer: boolean, onClickFunction?: Function, ) => {
  return (
    <div 
      key={valueTags.id}
      className={`customer-tag ${blnPermissionUpdateCustomer && onClickFunction ? "pointer-clickable customer-tag-hover" : ""}`}
      onClick={()=> {
        if (onClickFunction) {
          onClickFunction(valueTags);
        }
      }}>
      {valueTags.tagValue}
    </div>
  )
}

export const promptInfoMessage = (message, type) => {
  type === Constants.serviceTreatmentOnly || type === Constants.packageService 
  ? 
  toast.info(message, {style: { background: Constants.lightpurple }}) 
  : 
  type === Constants.packageOptionalProduct
  ? 
  toast.info(message, {style: { background: Constants.brown }}) 
  :
  toast.info(message, {style: { background: Constants.orange }});
}

export const returnSplitBackgroundRow = (className = "") => {
  return (
    <div style={{backgroundColor: Constants.localBackgroundColor, height: '16px'}} className={className}/>
  )
}
	
export const renderBranchQuantities = (aryBranchQuantitiesTemp, intl) => {
  return (
    <>
      <div className="branch-quantities-tool-tip-background">
        <h4 className="mb-2 branch-quantities-tool-tip-header-background padding-0-75rem">
          {intl.formatMessage({ id: "Branches" })}
        </h4>
        <div className="branch-quantities-tool-top-content-background font-size-14">
            <ul>
              {aryBranchQuantitiesTemp.sort().map(values => (
                <li key={values} style={{ textAlign: 'left' }}>{values}</li>
              ))}
            </ul>
        </div>
      </div>
    </>
  )
}

export const returnDateRangeByYearAndMonthChecking = (valueDate) => {
  return {
    fromDate: String(valueDate.startOf('month').format(Constants.displayDateFormat)),
    toDate: moment().isSame(valueDate, 'month') ? moment().format(Constants.displayDateFormat) : String(valueDate.endOf('month').format(Constants.displayDateFormat))
  }
}

// 自定义四舍五入
export const returnNumberWithDecimal = (value) => {
  return (Math.round(Number((Number(value)*100).toFixed(2))) / 100).toFixed(2);
};

export const convertTimeToMinutes = (hours, minutes) => {
  return (Number(hours || 0) * 60) + Number(minutes || 0);
}

export const displaySummaryDetailsTableCellUI = (rowDetails, fieldName, supportPercentage?: boolean, supportCurrency?: boolean, supportHyperLinkTitle?: string, supportHyperLinkPath?: string, hyperlinkPathFieldName?: string) => {
  let aryViews : any = [];
  rowDetails.map((value, index) => {
    let valueTemp = value[fieldName] || value[fieldName] === 0 ? typeof value[fieldName] === 'object' ? convertArrayToStringDisplay(value[fieldName], true) : value[fieldName] : value.isTotalField ? "" : Constants.emptyData;
    let finalValue = supportCurrency ? `${returnPriceWithCurrency(valueTemp)}`: `${valueTemp}${(valueTemp || valueTemp === 0) && supportPercentage ? "%" : ""}`;
    aryViews.push(
      <div 
        key={`view_${fieldName}_${index}`}
        style={{
          backgroundColor: index === rowDetails.length-1 && value.isTotalField ? Constants.tableCellGrey : "", 
          fontWeight: index === rowDetails.length-1 && value.isTotalField ? '600' : "",
          marginLeft: '-12px',
          marginRight: '-12px',
          paddingLeft: '12px',
          paddingRight: '12px'
        }}>
        {
          returnTableCellUI(
            `${fieldName}_${index}`, 
            index, 
            rowDetails.length, 
            supportHyperLinkTitle && supportHyperLinkPath
            ?
            <Link 
              to={`${supportHyperLinkPath}${value[fieldName]}`} 
              target={Constants.supportBlank} 
              rel="noopener noreferrer">
              <span className="margin-bottom-0 pointer-clickable text-truncate">{supportHyperLinkTitle}</span>
            </Link>
            :
            hyperlinkPathFieldName
            ?
            value[hyperlinkPathFieldName]
            ?
            <Link 
              to={`${value[hyperlinkPathFieldName]}`} 
              target={Constants.supportBlank} 
              rel="noopener noreferrer">
              <span className="margin-bottom-0 pointer-clickable text-truncate">{finalValue}</span>
            </Link>
            :
            finalValue
            :
            finalValue,
            false,
            value.divHeight,
            value.divHeight,
            value.divHeight
          )
        }
      </div>
    )
  })
  return aryViews;
}

export const returnPendingSignOrderLinkPath = (pendingSignTypeTemp: number, orderId: string) => {
  switch (pendingSignTypeTemp) {
    case 0:
      return checkPermission([PermissionConstants.ManageCustomerSalesOrder]) && `/${RoutesList.customerSalesOrder}/view/${orderId}`;
    case 1:
      return checkPermission([PermissionConstants.ManageCustomerDeliverOrder]) && checkPermission([PermissionConstants.UpdateCustomerDeliverOrder]) ? `/${RoutesList.customerRedemption}/edit/${orderId}` : checkPermission([PermissionConstants.ManageCustomerDeliverOrder]) && `/${RoutesList.customerRedemption}/view/${orderId}`;
    case 2:
      return checkPermission([PermissionConstants.ManageProductRedemptionNote]) && checkPermission([PermissionConstants.UpdateProductRedemptionNote]) ? `/${RoutesList.productRedemptionNote}/edit/${orderId}` : checkPermission([PermissionConstants.ManageProductRedemptionNote]) && `/${RoutesList.productRedemptionNote}/view/${orderId}`;
  }
}

export const contructValidationErrorMessage = (errors) => {
  let aryErrorKeys = errors ? Object.keys(errors) : [];
  let errorMessage = "";
  aryErrorKeys.map((valueErrorKey, indexErrorKey)=> {
    errorMessage = errorMessage + `${indexErrorKey !== 0 ? "\n" : ""}${errors[valueErrorKey]}${indexErrorKey !== aryErrorKeys.length-1 ? "," : ""}`;
  })
  return errorMessage || "Please help to fill up the required fields";
}

export function getCurrentMonthRange() {
  let currentYear = Number(moment().format("YYYY"));
  let currentMonth = Number(moment().format("M"));

  return ({
    from: {
      year: currentYear,
      month: currentMonth
    },
    to: {
      year: currentYear,
      month: currentMonth
    }
  })
}

export function returnMonthRangeByYear(year: number, blnMinMaxField: boolean) {
  return (
    blnMinMaxField
    ?
    {
      min: {
        year: year,
        month: 1
      },
      max: {
        year: year,
        month: 12
      }
    }
    :
    {
      from: {
        year: year,
        month: 1
      },
      to: {
        year: year,
        month: 12
      }
    }
  )
}

export function getCurrentMonthRangeForSingleYear() {
  let currentYear = Number(moment().format("YYYY"));
  let currentMonth = Number(moment().format("M"));

  return (
    [
      {
          "year": currentYear,
          "month": currentMonth
      }
    ]
  )
}

export function returnArrayTypeOfMonthRangeByYear(year) {
  let aryMonths : DateRangeMonthDetailInterface[] = [];
  for (var indexMonth = 1; 12>= indexMonth; indexMonth++) {
    aryMonths.push({
      year: year,
      month: indexMonth
    })
  }
  return aryMonths;
}

export function returnSuccessMarkedIcon () {
  return (
    <CheckCircleOutlineIcon 
      size={"6em"} 
      color={"#34c38f"}
      className={"margin-bottom-8"}/>
  )
} 

export function calculateDivHeight (text, width, fontSize, lineHeight) {
  const averageCharWidth = fontSize * 0.6; // Approximate average character width
  const charsPerLine = Math.floor(width / averageCharWidth);
  const numLines = Math.ceil(text.length / charsPerLine);
  return numLines * fontSize * lineHeight;
}

export function isObjectEmpty (objTemp) {
  return (
    objTemp &&
    Object.keys(objTemp).length === 0 &&
    objTemp.constructor === Object
  );
};

export function getUniqueFileName (filename, existingFiles) {
  const fileNameWithoutExtension = filename.replace(/\.[^/.]+$/, ''); // Remove file extension
  const extension = filename.match(/\.[^/.]+$/) ? filename.match(/\.[^/.]+$/)[0] : ''; // Get file extension

  // Regex to match the base filename and any number in parentheses
  const baseFileNameRegex = new RegExp(`^${fileNameWithoutExtension.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(?: \\((\\d+)\\))?${extension.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}$`);

  let usedNumbers: Number[] = [];

  // Check for files with the same base name and collect any numbers in parentheses
  existingFiles.forEach((valueExistingFile) => {
    const match = valueExistingFile.path.match(baseFileNameRegex);
    if (match) {
      const number = match[1] ? parseInt(match[1], 10) : 0;
      usedNumbers.push(number);
    }
  });

  // If original filename is not used, return it
  if (!usedNumbers.includes(0)) {
    return filename;
  }

  // Find the smallest available number
  let newNumber = 1;
  while (usedNumbers.includes(newNumber)) {
    newNumber++;
  }

  // Return the new filename with the next available number
  return `${fileNameWithoutExtension} (${newNumber})${extension}`;
};