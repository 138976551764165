import React, { useState, useEffect } from "react";
import { Col, FormFeedback, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, displayPromotionCode, getPurchaseTypeIcon, returnPriceWithCurrency, returnRefundStatusColor } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Constants } from "../../../app/constants/Constants";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import { RefundDetail } from "../../../app/models/customerRefund";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { history } from '../../..';
import moment from "moment";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import GeneralInputWithClickableUI from "../../../app/components/form/GeneralInputWithClickableUI";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerRefundDetail: any;
  setSelectedCustomerRefundDetail: Function;
  reviewCustomerRefundCredit?: Function;
}

export default observer(function CustomerRefundDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingReview, setLoadingReview] = useState(false);

  const defaultReviewCustomerRefundDetails = {
    customerRefundId: "",
    remark: "",
    workflowAction: "",
  }
  const [reviewCustomerRefundDetails, setReviewCustomerRefundDetails] = useState(defaultReviewCustomerRefundDetails)
  const [errorState, setErrorState] = useState({
    remark: false,
  })
  const blnPermissionReviewCustomerSalesOrderCredit = checkPermission([PermissionConstants.ReviewCustomerSalesOrderCredit])
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const hideModal = () => {
    setReviewCustomerRefundDetails(defaultReviewCustomerRefundDetails);
    props.setModal(false);
  }

  useEffect(() => {
    if (props.selectedCustomerRefundDetail) {
      validationLocal.setFieldValue("customerId", props.selectedCustomerRefundDetail.customerId);
      validationLocal.setFieldValue("customerName", props.selectedCustomerRefundDetail.customerName);
      validationLocal.setFieldValue("salesOrderId", props.selectedCustomerRefundDetail.salesOrderId);
      validationLocal.setFieldValue("salesOrderNo", props.selectedCustomerRefundDetail.salesOrderNo);
      validationLocal.setFieldValue("customerReturnRefundNo", props.selectedCustomerRefundDetail.customerReturnRefundNo);
      validationLocal.setFieldValue("branchName", props.selectedCustomerRefundDetail.branchName);
      validationLocal.setFieldValue("actionBy", props.selectedCustomerRefundDetail.actionBy || Constants.emptyData);
      validationLocal.setFieldValue("actionOn", (props.selectedCustomerRefundDetail.actionOn !== Constants.invalidDateTimeWithValue && props.selectedCustomerRefundDetail.actionOn !== Constants.invalidDateTime) ? moment(props.selectedCustomerRefundDetail.actionOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyData)
    }
  }, [props.selectedCustomerRefundDetail])

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerName: "",
      customerId: "",
      branchName: "",
      salesOrderId: "",
      salesOrderNo: "",
      customerReturnRefundNo: "",
      actionBy: "",
      actionOn: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const blnPartialPayment = props.selectedCustomerRefundDetail.salesOrderPaymentStatus == Constants.partial;
  const blnPendingStatus = props.selectedCustomerRefundDetail.status == Constants.pending;

  const displayHeaderRowData = (label, value) => {
    return (
      <tr>
        <td colSpan={2} className="text-end m-0" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  const getPaymentStatus = () => {
    if (props.selectedCustomerRefundDetail.salesOrderPaymentStatus == Constants.paid) {
      return <b className="font-size-13 text-success">{`(${intl.formatMessage({ id: "FullyPaid" })})`}</b>;
    }
    else if (props.selectedCustomerRefundDetail.salesOrderPaymentStatus == Constants.partial) {
        return <b className="font-size-13 text-warning">{`(${intl.formatMessage({ id: "PartiallyPaid" })})`}</b>;
    }
  }

  const onClickReviewCreditAdjustment = async (valueAction: string) => {
    let reviewCreditAdjustmentTemp = _.cloneDeep({ ...reviewCustomerRefundDetails, customerRefundId: props.selectedCustomerRefundDetail.id, workflowAction: valueAction })
    setReviewCustomerRefundDetails(reviewCreditAdjustmentTemp);
    if (props.reviewCustomerRefundCredit) {
      setLoadingReview(true);
      await props.reviewCustomerRefundCredit(reviewCreditAdjustmentTemp)
      setLoadingReview(false);
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingReview && !successMessage) {
            hideModal();
          }
        }}
        size={"lg"}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered>
        {
          !props.selectedCustomerRefundDetail
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div className="flex-direction-row" style={{flex:1 }}>
                  <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "ExchangeToCredit" }) })} {getPaymentStatus()}</h5>
                  <GeneralStatus
                    className="margin-right-20"
                    status={props.selectedCustomerRefundDetail.status}
                    statusColorFunction={returnRefundStatusColor}/>
                </div>
                {
                  !loadingReview && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInputWithClickableUI
                      title={intl.formatMessage({ id: "Customer" })}
                      name="customerName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                      onClickFunction={()=> {
                        if (blnPermissionManageCustomer) {
                          window.open(`/${RoutesList.customer}/view/${validationLocal.values.customerId}`)
                        }
                      }}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Branch" })}
                      name="branchName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ExchangeToCreditNo" })}
                      name="customerReturnRefundNo"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInputWithClickableUI
                      title={intl.formatMessage({ id: "SalesOrderNo" })}
                      name="salesOrderNo"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                      onClickFunction={()=> {
                        if (blnPermissionManageCustomerSalesOrder) {
                          window.open(`/${RoutesList.customerSalesOrder}/view/${validationLocal.values.salesOrderId}`)
                        }
                      }}/>
                  </DoubleColumnRowParent>
                </Row>
                {
                  !blnPendingStatus &&
                  <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ReviewedBy" })}
                      name="actionBy"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <div
                      onClick={()=> {
                        if (blnPermissionManageCustomerSalesOrder) {
                          window.open(`/${RoutesList.customerSalesOrder}/view/${validationLocal.values.salesOrderId}`)
                        }
                      }}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "ReviewedOn" })}
                        name="actionOn"
                        type="text"
                        disabled={true}
                        validationRequired={true}
                        validation={validationLocal}
                      />
                    </div>
                  </DoubleColumnRowParent>
                </Row>
                }

                <Borderline />
                {
                  props.selectedCustomerRefundDetail.refundDetails && props.selectedCustomerRefundDetail.refundDetails.length > 0 &&
                  <>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "ExchangeToCreditItem" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th style={{ width: '30%' }}>{intl.formatMessage({ id: "Name" })}</th>
                            <th className={!blnPartialPayment ? "text-center" : "text-end"} colSpan={!blnPartialPayment ? 1 : 2}>{intl.formatMessage({ id: "Type" })}</th>
                            {!blnPartialPayment && <th className="text-end">{intl.formatMessage({ id: "ExchangeableAmount" })}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.selectedCustomerRefundDetail.refundDetails.map((value: RefundDetail, index) => (
                              <tr key={index}>
                                <td className="">{getPurchaseTypeIcon(value.purchaseType)}{value.purchaseName} {displayPromotionCode(value.promotionCode)}</td>
                                <td className={!blnPartialPayment ? "text-center" : "text-end"} colSpan={!blnPartialPayment ? 1 : 2}>{value.purchaseType}</td>
                                {!blnPartialPayment && <td className="text-end">{returnPriceWithCurrency(value.exchangeAmount.toFixed(2))}</td>}
                              </tr>
                            ))
                          }
                          {displayHeaderRowData(`${intl.formatMessage({ id: "SubTotal" })}`, returnPriceWithCurrency(props.selectedCustomerRefundDetail.subTotalExchangeAmount))}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "ProcessingFees" })}`, returnPriceWithCurrency(props.selectedCustomerRefundDetail.processingFees))}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "TotalExchangeAmount" })}`, returnPriceWithCurrency(props.selectedCustomerRefundDetail.totalExchangeAmount))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                }
                <GeneralTextAreaForModal
                  title={intl.formatMessage({ id: "Reason" })}
                  name="reason"
                  className="mt-3"
                  row={5}
                  field={props.selectedCustomerRefundDetail}
                  setFieldValue={props.selectedCustomerRefundDetail}
                  disabled={true}
                />
                {/* {
                  blnPendingStatus
                  && */}
                  <>
                    <Borderline />
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Review" })}
                      name="remark"
                      className={errorState.remark ? "mb-0" : "mb-3"}
                      row={4}
                      validationRequired={errorState.remark}
                      field={blnPendingStatus ? reviewCustomerRefundDetails : props.selectedCustomerRefundDetail}
                      setFieldValue={setReviewCustomerRefundDetails}
                      disabled={loadingReview || !blnPendingStatus || Boolean(successMessage) || !blnPermissionReviewCustomerSalesOrderCredit} />
                    {errorState.remark ? (
                      <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReviewWhenReject" })}</FormFeedback>
                    ) : null}
                  </>
                {/* } */}
                <div className="mb-2 mt-4 flex-right">
                  {/* <MyButton
                    type="button"
                    class={`btn ${blnPendingStatus ? "btn-back margin-right-8" : "btn-primary"}`}
                    style={{ width: '20%' }}
                    content={intl.formatMessage({ id: blnPendingStatus ? "Back" : "Close" })}
                    onClick={() => {
                      hideModal();
                    }}
                    disable={loadingReview || Boolean(successMessage)}
                    loading={loadingReview}
                  /> */}
                  {
                    blnPendingStatus
                    &&
                    blnPermissionReviewCustomerSalesOrderCredit
                    &&
                    !Boolean(successMessage)
                    &&
                    <>
                      <MyButton
                        type="button"
                        class="btn btn-danger margin-right-8"
                        style={{ width: '20%' }}
                        content={Constants.reject}
                        onClick={() => {
                          let errorStateTemp = {
                            adjustmentType: false,
                            creditAdjustmentAmount: false,
                            reason: false,
                            accountHolderName: false,
                            identityNo: false,
                            bankAccountType: false,
                            bankAccountNo: false,
                            remark: false,
                          };
                          let blnErrorFound = false;
                          if (!reviewCustomerRefundDetails.remark) {
                            errorStateTemp.remark = true;
                            blnErrorFound = true;
                          }
                          if (blnErrorFound) {
                            setErrorState(errorStateTemp)
                          }
                          else {
                            onClickReviewCreditAdjustment(Constants.reject);
                          }
                        }}
                        disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                      <MyButton
                        type="submit"
                        class="btn btn-success"
                        style={{ width: '20%' }}
                        content={Constants.approve}
                        onClick={() => {
                          onClickReviewCreditAdjustment(Constants.approve);
                        }}
                        disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                    </>
                  }
                </div>
              </div>
            </div>
        }
      </Modal>
  )
})