import { useEffect, useState, useRef } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Input,
  Form
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import SortCaret from '../../app/components/table/SortCaret';
import * as Yup from "yup";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { history } from "../..";
import { useFormik } from "formik";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import { CustomerOrderDetailObject } from "../../app/models/customerOrder";
import CustomerOrderDetailModal from "./Modal/CustomerOrderDetailModal";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import Borderline from "../../app/components/form/BorderLine";
import { calculatePOSTotalPrice, checkPermission, contructValidationErrorMessage, convertAdditionalFormDataRead, getBranchId, getBranchName, getPurchaseTypeIcon, returnAdvancedSearchIcon, returnExpiredDate, returnPriceWithCurrency, returnSubBrandList } from "../../app/common/function/function";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import CustomerOrderCart from "./subView/CustomerOrderCart";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { ObjectForDropdown } from "../../app/models/common";
import DeleteModal from "../../app/components/modal/DeleteModal";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import { EmployeeListObject } from "../../app/models/employee";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import { CustomerListObject } from "../../app/models/customer";
import { PromotionListWithCodeDetailObject, PromotionListWithCodeObject } from "../../app/models/promotion";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import { CustomerDetailTemporary } from "../../app/models/customerSalesOrder";
import moment from "moment";
import CustomerOrderPendingSignModal from "./Modal/CustomerOrderPendingSignModal";
import { PackageListObject } from "../../app/models/package";
import { CloseCounterPendingObject } from "../../app/models/closeCounter";
import PendingCloseCounterModal from "./Modal/PendingCloseCounterModal";
import TodayCloseCounterSubmittedModal from "./Modal/TodayCloseCounterSubmittedModal";

interface PromotionValueObject {
  promotionValue: string;
}

const CustomerOrder = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { serviceTreatmentStore, packageStore, productStore, staticReferenceStore, employeeStore, customerStore, customerCartStore, additionalFormFieldStore, promotionStore, settingsStore, dashboardStore, closeCounterStore, commonStore } = useStore();
  const { loading, totalItem, setLoading, errorMessage, successMessage, windowSize, setErrorMessage, setSuccessMessage, setShowDeletePrompt, setSuccessMessageWithoutNavigation } = commonStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { packageDropdownList, getPackageDropdown } = packageStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { purchaseType, discountType, getStaticReferenceWithType } = staticReferenceStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { customerCartList, setCustomerCartList, addCustomerCart, updateCustomerCart, getCustomerCart, getCustomerCartWithId, deleteCustomerCart, deleteCustomerCartByBranch } = customerCartStore;
  const { additionalFormFieldFormTypeList, setAdditionalFormFieldFormTypeList, getAdditionalFormFieldDropdown } = additionalFormFieldStore;
  const { promotionDropdownList, promotionDropdownWithoutSponsorList, getPromotionDropdown, getPromotionListWithCode } = promotionStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const { weeklyPendingOrdersChart, getWeeklyPendingOrdersChart, setWeeklyPendingOrders } = dashboardStore;
  const { getPendingCloseCounter } = closeCounterStore;
  const services = `${intl.formatMessage({ id: "Service" })}`;
  const packages = `${intl.formatMessage({ id: "Package" })}`;
  const products = `${intl.formatMessage({ id: "Product" })}`;
  const promotion = `${intl.formatMessage({ id: "Promotion" })}`;
  const aryTab = [services, packages, products, promotion];
  const [customerOrderDetailModal, setCustomerOrderDetailModal] = useState(false);
  const [selectedCustomerOrderDetail, setSelectedCustomerOrderDetail] = useState<CustomerOrderDetailObject | undefined>(undefined);
  const [productBundledPackageDropdownList, setProductBundledPackageDropdownList] = useState<PackageListObject[]>([]); 
  const location = useLocation();
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
  const [loadingCustomerCartModal, setLoadingCustomerCartModal] = useState(false);
  const [loadingCustomerDropdown, setLoadingCustomerDropdown] = useState(false);
  const [customerCartModal, setCustomerCartModal] = useState(false);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [displayConsultantDropdown, setDisplayConsultantDropdown] = useState(true);
  const [displayPromoCodeDropdown, setDisplayPromoCodeDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [promotionItemList, setPromotionItemList] = useState<PromotionListWithCodeDetailObject[]>([]);
  const [loadingPromotion, setLoadingPromotion] = useState(false);
  const [clearPromotionModal, setClearPromotionModal] = useState(false);
  const [deleteAllCustomerCartModal, setDeleteAllCustomerCartModal] = useState(false);
  const [actionAfterClearPromotion, setActionAfterClearPromotion] = useState<string>("");
  const actionApplyPromotionCode : string = "actionApplyPromotionCode";
  const actionApplyCustomerDropdown : string = "actionApplyCustomerDropdown";
  const actionApplyCustomerAdvanceSearch : string = "actionApplyCustomerAdvanceSearch";
  const [localCustomerIdTemporally, setLocalCustomerIdTemporally] = useState<string>("");
  const [localCustomerDetailTemporally, setLocalCustomerDetailTemporally] = useState<CustomerDetailTemporary>({id: "", preferredName: "", customerNo: "", mobileNo: ""})
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [blnDifferentBranch, setBlnDifferentBranch] = useState(false);
  const [employeeDropdownListForOtherBranch, setEmployeeDropdownListForOtherBranch] = useState<EmployeeListObject[]>([]);
  const [blnAccessGrantedCustomer, setBlnAccessGrantedCustomer] = useState(false);
  const blnPermissionBackDateCustomerCart = checkPermission([PermissionConstants.BackdateCustomerSalesOrder]);
  const [blnDisablePurchaseTabModal, setBlnDisablePurchaseTabModal] = useState(false);
  const [blnShowPendingCloseCounterModal, setBlnShowPendingCloseCounterModal] = useState(false);
  const [blnShowTodayCloseCounterSubmittedModal, setBlnShowTodayCloseCounterSubmittedModal] = useState(false);
  const [pendingCloseCounterDetail, setPendingCloseCounterDetail] = useState<CloseCounterPendingObject | undefined>(undefined);
  const refButtonClearSearch : any = useRef(null);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      tabBar: services,
      branchId: "",
      customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      consultantIds: [],
      consultantIdsForAGWithProduct: [], //AG == Access Granted
      consultants: [],
      note: "",
      totalCount: 0,
      totalPrice: 0,
      totalSellingPrice: 0,
      totalTax: 0,
      paidNumber: undefined,
      salesOrderDetails: [],
      paymentDetails: [],
      customerCartId: "",
      promotionValue: "",
      voidedSalesOrderNo: "",
      checkboxVoidCustomerSalesOrderNo: false,
      submissionDate: "",
      isBackdate: false,
      subBrandName: "",
      subBrandId: "",
      originalBranchName: "",
      hasRounding: false,
      totalRoundingPrice: 0,
      totalPriceBeforeRounding: 0,
      isSponsorProfile: false,
    },
    validationSchema: Yup.object({
      subBrandId: Yup.string().test("subBrandName", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) }), (value: any, context) => {
        if (returnSubBrandList().length > 0 ? true : false) {
          return value;
        }
        else {
          return true;
        }
			}).nullable(),
      customerId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) })),
      salesOrderDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })),
      consultantIds: Yup.array().test("consultantIds", intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }).toLowerCase() }), (value: any, context) => {
        if (!blnAccessGrantedWithProductOnly) {
          return value.length > 0;
        }
        else {
          return true;
        }
      }).nullable(),
      consultantIdsForAGWithProduct: Yup.array().test("consultantIdsForAGWithProduct", intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }).toLowerCase() }), (value: any, context) => {
        if (blnAccessGrantedWithProductOnly) {
          return value.length > 0;
        }
        else {
          return true;
        }
      }).nullable()
    }),
    onSubmit: async (values) => {
      let blnProceed = true;
      const valuesTemp: any = _.cloneDeep(values);
      // let salesOrderDetailsServicesChecking = _.cloneDeep(valuesTemp.salesOrderDetails);
      // salesOrderDetailsServicesChecking = salesOrderDetailsServicesChecking.map((value, index) => {
      //   if (value.purchaseType == Constants.service) {
      //     if (!value.serviceById || !value.serviceByName) {
      //       blnProceed = false;
      //       value = { ...value, validationRequired: true }
      //     }
      //     else {
      //       if (value.validationRequired) {
      //         delete value["validationRequired"];
      //       }
      //     }
      //   }
      //   return value
      // })

      if (!blnProceed) {
        // validation.setFieldValue("salesOrderDetails", salesOrderDetailsServicesChecking);
      }
      else {
        let salesOrderDetailsTemp = _.cloneDeep(valuesTemp.salesOrderDetails);
        salesOrderDetailsTemp = salesOrderDetailsTemp.map((value, index) => {
          if (value.serviceProductUsed) {
            value.serviceProductUsedTemp = _.cloneDeep(value.serviceProductUsed);
            value.serviceProductUsed = value.serviceProductUsed.map((valueServiceProductUsed) => (valueServiceProductUsed.value))
          }
          if (value.availableOptionalProducts) {
            delete value["availableOptionalProducts"];
          }
          return value
        })
        valuesTemp.salesOrderDetails = salesOrderDetailsTemp
        valuesTemp.customerCartDetails = salesOrderDetailsTemp
        let consultantIdsTemp = !blnAccessGrantedWithProductOnly ? valuesTemp.consultantIds : valuesTemp.consultantIdsForAGWithProduct;
        valuesTemp.consultantIds = !blnAccessGrantedWithProductOnly ? valuesTemp.consultantIds.map((valueTemp) => valueTemp.value) : valuesTemp.consultantIdsForAGWithProduct.map((valueTemp) => valueTemp.value);
        valuesTemp.branchId = getBranchId();
        
        if (valuesTemp.salesOrderDetails.length > 0) {
          try {
            let valuesTempToSend = _.cloneDeep(valuesTemp);
            if (valuesTempToSend.submissionDate) {
              valuesTempToSend.submissionDate = moment(valuesTempToSend.submissionDate).format(Constants.defaultDateFormat);
            }
            delete valuesTempToSend["salesOrderDetails"]
            let resultAPI: any = valuesTemp.customerCartId ? await updateCustomerCart(valuesTempToSend) : await addCustomerCart(valuesTempToSend);
            if (resultAPI.status === Constants.success) {
              if (!valuesTemp.customerCartId) {
                valuesTemp.customerCartId = resultAPI.data?.customerCartId;
              }
              if (resultAPI.data?.minimumPaymentAmount) {
                valuesTemp.minimumPaymentAmount = resultAPI.data?.minimumPaymentAmount;
              }
              if (resultAPI.data?.roundedMinimumPaymentAmount) {
                valuesTemp.roundedMinimumPaymentAmount = resultAPI.data?.roundedMinimumPaymentAmount;
              }
              valuesTemp.consultantIds = consultantIdsTemp;
              // Temporrally comment out access granted customer handling
              // if (blnDifferentBranch) {
              //   valuesTemp.employeeDropdownListForOtherBranch = employeeDropdownListForOtherBranch;
              // }
              // valuesTemp.blnDifferentBranch = blnDifferentBranch;
              valuesTemp.blnAccessGrantedCustomer = blnAccessGrantedCustomer;
              delete valuesTemp["customerCartDetails"]
              
              // if (Constants.supportMalaysiaRounding) {
              //   let finalPriceAfterRounding = returnMalaysiaCurrencyRounding(valuesTemp.totalPrice);
              //   let totalRoundingPrice = Math.round((finalPriceAfterRounding - valuesTemp.totalPrice) * 100) / 100;
              //   valuesTemp.totalPrice = finalPriceAfterRounding;
              //   valuesTemp.totalRoundingPrice = totalRoundingPrice;
              // }

              history.push({
                pathname: `/${RoutesList.posPayment}`,
                state: { detail: valuesTemp }
              });
            }
          }
          catch (e: any) {
            setErrorMessage(e);
          }
          finally {
            validation.setSubmitting(false);
          }
        }
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || loadingCustomerCartModal;

  //Convert Search framework into SearchBar for naming.
  const { SearchBar } = Search;

  //Table Content UI
  const CustomerOrderColumns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        width: '30%',
      },
      sortCaret: (order, column) => <SortCaret order={order} />,
      formatter: (cellContent, row) => (
        <>
        {row.promotionCode && getPurchaseTypeIcon(row.purchaseType)}{row.name}
        </>
      ),
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({ id: "Price" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${validation.values.tabBar === promotion ? returnPriceWithCurrency(row.discountedPrice) : returnPriceWithCurrency(row.sellingPrice)}`}</div>
      ),
    },
    {
      dataField: "add",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'right' },
      formatter: (cellContent, row) => (
        <div className="flex-right">
          <MyButton
            type="button"
            content={intl.formatMessage({ id: "Add" }).toUpperCase()}
            class="btn btn-success"
          />
        </div>
      ),
    }
  ];

  //Table Content UI
  const CustomerCartColumns = [
    {
      dataField: "id", //field name from ar1ray to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerName",
      text: `${intl.formatMessage({ id: "Customer" }).toUpperCase()}.`,
      sort: true,
    },
    {
      dataField: "branchName",
      text: `${intl.formatMessage({ id: "Branch" }).toUpperCase()}.`,
      sort: true,
    },
    {
      dataField: "mobileNo",
      text: `${intl.formatMessage({ id: "MobileNo" }).toUpperCase()}.`,
      sort: true,
    },
    {
      dataField: "submissionDate",
      text: `${intl.formatMessage({ id: "SubmissionDate" }).toUpperCase()}.`,
      formatter: (cellContent, row) => <>{
        row.submissionDate ? moment(row.submissionDate).format(Constants.displayDateAndTimeFormat) : Constants.emptyData
      }</>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: '190px' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center gap-3">
          {
            ((row.isBackdate && blnPermissionBackDateCustomerCart) || (!row.isBackdate))
            &&
            <MyButton
              id={Constants.selectWithTableListSelectButton}
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({ id: "Select" })} />
          }
          <MyButton
            id={Constants.selectWithTableListDeleteButton}
            type="button"
            class="btn btn-danger"
            content={intl.formatMessage({ id: "Delete" })} />
        </div>
      ),
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (String(e.target).includes(Constants.htmlButton) && !validation.isSubmitting) {
        let blnPromotionTab = validation.values.tabBar === promotion;
        let indexTemp = _.findIndex(purchaseType, { displayValue: validation.values.tabBar })
        if (!validProductTabForAccessGranted(blnPromotionTab ? row.purchaseType === Constants.product : indexTemp > -1 ? purchaseType[indexTemp].displayValue === Constants.product : false)){
          setBlnDisablePurchaseTabModal(true);
          return;
        }
        
        let salesOrderDetailsTemp: any = _.cloneDeep(validation.values.salesOrderDetails);
        let indexCustomerOrderDetail = _.findIndex(salesOrderDetailsTemp, { itemId: row.id, promotionItemType : blnPromotionTab})
        if (indexCustomerOrderDetail > -1) {
          if (salesOrderDetailsTemp[indexCustomerOrderDetail].availableQuantity || salesOrderDetailsTemp[indexCustomerOrderDetail].availableQuantity === 0) {
            if (salesOrderDetailsTemp[indexCustomerOrderDetail].availableQuantity < (salesOrderDetailsTemp[indexCustomerOrderDetail].count + 1)) {
              setErrorMessage(intl.formatMessage({id: "PromoItemsMaximumCount"}))
              return;
            }
          }
          if (salesOrderDetailsTemp[indexCustomerOrderDetail].isPrepaidPackage) {
            if ((generalSettings?.maxPrepaidPackageInSalesOrder || 0) <= salesOrderDetailsTemp[indexCustomerOrderDetail].count) {
              setErrorMessage(intl.formatMessage({id: "PrepaidPackageMaximumCount"}))
              return;
            }
          }
          // if (salesOrderDetailsTemp[indexCustomerOrderDetail].isCreditPackage) {
          //   setErrorMessage(intl.formatMessage({id: "PrepaidPackageMaximumCount"}))
          //   return;
          // }
          salesOrderDetailsTemp[indexCustomerOrderDetail].count += 1;
        }
        else {
          if (blnPromotionTab) {
            if (row.availableQuantity === 0) {
              setErrorMessage(intl.formatMessage({id: "PromoItemsMaximumCount"}))
              return;
            }
          }

          let objOrderDetails: CustomerOrderDetailObject = {
            itemName: row.name,
            purchaseType: blnPromotionTab ? row.purchaseType : indexTemp > -1 ? purchaseType[indexTemp].displayValue : "",
            count: 1,
            price: blnPromotionTab ? row.discountedPrice : row.sellingPrice,
            floorPrice: row.floorPrice || 0,
            itemId: row.id,
            discountType: discountType.length > 0 ? discountType[0].displayValue : `${intl.formatMessage({ id: "Amount" })}`,
            discountValue: 0,
            tax: row.taxRate || 0,
            promotionItemType: blnPromotionTab,
            remark: "",
            ...blnPromotionTab && { promotionCode: row.promotionCode, availableQuantity: row.availableQuantity, originalPrice: row.sellingPrice},
          }

          if (validation.values.tabBar === packages || (blnPromotionTab && row.purchaseType === packages)) {
            objOrderDetails = {
              ...objOrderDetails,
              isPrepaidPackage: row.isPrepaidPackage,
              isCreditPackage: row.isCreditPackage,
              // ...!row.isCreditPackage && { expiredDate: returnExpiredDate(Constants.textInputDateFormat) }, //Only for packages
            }
          }
          else if (validation.values.tabBar == services || (blnPromotionTab && row.purchaseType === services)) {
            // let serviceProductUsedTemp = row.availableOptionalProducts.map((valueTemp) => ({ label: valueTemp.productName, value: valueTemp.productId }))
            objOrderDetails = {
              ...objOrderDetails,
              // serviceById: undefined,
              // serviceByName: undefined,
              // availableOptionalProducts: serviceProductUsedTemp,
              // serviceProductUsed: serviceProductUsedTemp
            }
          }

          // if (validation.values.tabBar == services || (blnPromotionTab && row.purchaseType === services) || objOrderDetails.isCreditPackage) {
          if (objOrderDetails.isCreditPackage) {
            setSelectedCustomerOrderDetail(objOrderDetails.additionalData ? _.cloneDeep(convertAdditionalFormDataRead(objOrderDetails.additionalData, additionalFormFieldFormTypeList)) : _.cloneDeep(objOrderDetails))
            setCustomerOrderDetailModal(true);
          }
          else {
            salesOrderDetailsTemp.push(objOrderDetails);
          }
        }

        clearSearchInput();
        setTimeout(()=> {
          validation.setFieldValue("salesOrderDetails", salesOrderDetailsTemp);
        }, 10)
      }
    }
  };

  useEffect(() => {
    async function fetchPackageAPI() {
      setLoading(true);
      let aryAPI: any = [
        getServiceTreatmentDropdown({ branchId: getBranchId(), isActive: true }, true),
        getPackageDropdown({ branchId: getBranchId(), isActive: true }, true),
        getProductDropdown({ branchId: getBranchId(), productType: 2 /*Purchasable*/, isActive: true }, false, true),
        getStaticReferenceWithType(Constants.purchaseType),
        getStaticReferenceWithType(Constants.discountType),
        getAdditionalFormFieldDropdown(Constants.serviceRecord),
        getPromotionDropdown(),
        getGeneralSettings(),
        getEmployeeDropdown({ branchId: getBranchId(), isBranchStaff: true, isActive: true })
      ];

      if (location) {
        if (location.state) {
          if (location.state.detail) {
            if (location.state.detail.customerCartId) {
              aryAPI.push(getCustomerCartWithId(location.state.detail.customerCartId))
            }
          }
        }
      }
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[1]) {
        let packageDropdownTemp = _.cloneDeep(resultAPI[1]);
        packageDropdownTemp = _.filter(packageDropdownTemp, {isProductBundledPackage: true});
        setProductBundledPackageDropdownList(packageDropdownTemp);
      }

      if (resultAPI.length > 9) {
        let valuesTemp: any = resultAPI[9];
        if (valuesTemp.cartDetails) {
          updateFromCustomerCart(valuesTemp, resultAPI[8])
        }
      }
      if (location) {
        if (location.state) {
          if (location.state.detail) {
            if (location.state.detail.customerId && !location.state.detail.customerCartId) {
              let consultantIdsTemp: any = [];
              if (location.state.detail.consultantId && location.state.detail.consultantName) {
                consultantIdsTemp.push({
                  preferredName: location.state.detail.consultantName,
                  id: location.state.detail.consultantId,
                  label: location.state.detail.consultantName,
                  value: location.state.detail.consultantId,
                })
                validation.setFieldValue("consultantIds", consultantIdsTemp)
              }

              if (location.state.detail.branchId) {
                prefillCustomerInfo(location.state.detail.customerId, [], true, true, false, location.state.detail.branchId);
              }
              else {
                prefillCustomerInfo(location.state.detail.customerId)
              }
            }
          }
        }
      }

      if (!resultAPI[7]?.isAllowCreateSalesOrderWithPendingSign) {
        await getWeeklyPendingOrdersChart({ branchId: getBranchId(), isFilterOutTodayOrder: true });
      }
      if (resultAPI[7]?.isEnableCloseCounter) {
        let resultPendingCloseCounter = await getPendingCloseCounter(getBranchId());
        if (resultPendingCloseCounter) {
          if (resultPendingCloseCounter.isInvalidCashOut || resultPendingCloseCounter.isInvalidCloseCounter) {
            setPendingCloseCounterDetail(resultPendingCloseCounter);
            setBlnShowPendingCloseCounterModal(true);
          }
          else if (resultPendingCloseCounter.isTodayClosingSubmitted) {
            setBlnShowTodayCloseCounterSubmittedModal(true);
          }
        }
      }

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerSalesOrder], true)) {
      return;
    }

    fetchPackageAPI();

    return (()=> {
      setCustomerCartList([]);
      setWeeklyPendingOrders([]);
    })
  }, [])

  useEffect(() => {
    let countTemp = 0;
    let priceTemp = 0;
    let taxTemp = 0;

    validation.values.salesOrderDetails.map((valueTemp: CustomerOrderDetailObject) => {
      countTemp += Number(valueTemp.count);
      let finalPrice = calculatePOSTotalPrice(valueTemp.count, valueTemp.price, valueTemp.discountType, valueTemp.discountValue)
      priceTemp += finalPrice
      let finalTax = calculatePOSTotalPrice(1, finalPrice, "discount", valueTemp.tax, true)
      taxTemp += finalTax;
    })

    validation.setFieldValue("totalCount", countTemp)
    validation.setFieldValue("totalSellingPrice", priceTemp.toFixed(2))
    validation.setFieldValue("totalPrice", (priceTemp + taxTemp).toFixed(2))
    validation.setFieldValue("totalTax", taxTemp.toFixed(2))
  }, [validation.values.salesOrderDetails])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomerDropdown(false);
    }
    else if (!value) {
      setLoadingCustomerDropdown(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  function handleChangeRadioBox(event) {    
    if (validProductTabForAccessGranted(event.target.id === Constants.product) || event.target.id == Constants.promotion) {
      validation.setFieldValue("tabBar", event.target.id)
    }
    else {
      setBlnDisablePurchaseTabModal(true);
    }
  }

  const deleteCustomerOrder = (index) => {
    let salesOrderDetailsTemp: any = _.cloneDeep(validation.values.salesOrderDetails);
    salesOrderDetailsTemp.splice(index, 1);
    validation.setFieldValue("salesOrderDetails", salesOrderDetailsTemp);
  }

  const selectCustomerOrder = (index) => {
    let objOrderDetails: CustomerOrderDetailObject = validation.values.salesOrderDetails[index];
    setSelectedCustomerOrderDetail({ ...objOrderDetails, ...objOrderDetails.additionalData ? _.cloneDeep(convertAdditionalFormDataRead(objOrderDetails.additionalData, additionalFormFieldFormTypeList)) : _.cloneDeep(objOrderDetails) })
    setCustomerOrderDetailModal(true);
  }

  const onChangeCustomerCartModal = async (row) => {
    setLoading(true);
    try {
      let resultCustomerCart = await getCustomerCartWithId(row.id);
      if (resultCustomerCart) {
        if (resultCustomerCart.cartDetails) {
          updateFromCustomerCart(resultCustomerCart);
          setCustomerCartModal(false);
        }
      }
    }
    finally {
      setLoading(false);
    }
  }

  const onDeleteCustomerCartModal = async (row) => {
    setLoading(true);
    try {
      let resultDeleteCustomerCart = await deleteCustomerCart(row.id || "");
      if (resultDeleteCustomerCart.status === Constants.success) {
        let customerCartListTemp = _.cloneDeep(customerCartList);
        _.remove(customerCartListTemp, {id : row.id});
        setCustomerCartList(customerCartListTemp);
      }
    }
    finally {
      setTimeout(()=> {
        setLoading(false);
      }, Constants.dismissSuccessMessage)
    }
  }

  const onDeleteCustomerCartByBranchModal = async () => {
    if (customerCartList.length < 1) {
      return;
    }
    
    setLoading(true);
    try {
      let resultDeleteCustomerCart = await deleteCustomerCartByBranch(getBranchId());
      if (resultDeleteCustomerCart.status === Constants.success) {
        setCustomerCartList([]);
      }
    }
    finally {
      setTimeout(()=> {
        setLoading(false);
      }, Constants.dismissSuccessMessage)
    }
  }

  const checkSelectedPromotionItemsFromCart = () => {
    let blnPromotionItemExist = false;
    validation.values.salesOrderDetails.map((valueSalesOrderDetails: CustomerOrderDetailObject)=> {
      if(valueSalesOrderDetails.promotionItemType) {
        blnPromotionItemExist = true;
      }
    })

    return blnPromotionItemExist;
  }

  const applyPromotionCode = async () => {
    setLoadingPromotion(true);
    setPromotionItemList([]);
    let resultPromotionListWithCode = await getPromotionListWithCode({promotionCode: validation.values.promotionValue, branchId: getBranchId(), customerId: validation.values.customerId})
    if (resultPromotionListWithCode.status === Constants.success) {
      let resultPromotionList : PromotionListWithCodeObject | any = _.cloneDeep(resultPromotionListWithCode.data);
      resultPromotionList.promotionDetails = resultPromotionList.promotionDetails.sort((a, b) => (a.purchaseType > b.purchaseType) ? 1 : -1)
      if (blnAccessGrantedCustomer) {
        resultPromotionList.promotionDetails = resultPromotionList.promotionDetails.filter(valuePromotionDetail => valuePromotionDetail.isProductBundledPackage || valuePromotionDetail.purchaseType === Constants.product)
      }

      if (resultPromotionList.promotionDetails.length < 1 && blnAccessGrantedCustomer) {
        setErrorMessage(intl.formatMessage({ id: "NoProductOrProductPackageInThisPromotion" }));
      }
      else if (!resultPromotionList.isFullyRedeemed) {
        resultPromotionList.promotionDetails = resultPromotionList.promotionDetails.map((value)=> ({...value, name: value.itemName, promotionCode: resultPromotionList.code, id: value.itemId}))
        setPromotionItemList(resultPromotionList.promotionDetails);
      }
      else {
        setErrorMessage(intl.formatMessage({ id: "PromoFullyRedemption" }));
      }
    }
    setLoadingPromotion(false);
  }

  const clearSelectedPromotionItemFromCart = () => {
    setClearPromotionModal(false);
    let salesOrderDetailsListTemp : CustomerOrderDetailObject[] = []; 
    validation.values.salesOrderDetails.map((valueSalesOrderDetails: CustomerOrderDetailObject)=> {
      if(!valueSalesOrderDetails.promotionItemType) {
        salesOrderDetailsListTemp.push(valueSalesOrderDetails);
      }
    })
    validation.setFieldValue("salesOrderDetails", salesOrderDetailsListTemp)

    if(actionAfterClearPromotion === actionApplyPromotionCode) {
      applyPromotionCode();
    }
    else if (actionAfterClearPromotion === actionApplyCustomerDropdown) {
      prefillCustomerInfo(localCustomerIdTemporally, [], false);
    }
    else if (actionAfterClearPromotion === actionApplyCustomerAdvanceSearch) {
      onClickSelect(localCustomerDetailTemporally, false);
    }
  }

  const updateFromCustomerCart = (valuesTemp: any, employeeDropdownListTemp?: EmployeeListObject[]) => {
    valuesTemp.salesOrderDetails = valuesTemp.cartDetails;
    valuesTemp.paymentDetails = [];
    let consultantIdsTemp: any = [];
    for (let consultantNameIndex = 0; consultantNameIndex < valuesTemp.consultants.length; consultantNameIndex++) {
      for (let consultantIdIndex = 0; consultantIdIndex < valuesTemp.consultantIds.length; consultantIdIndex++) {
        if (consultantNameIndex === consultantIdIndex) {
          consultantIdsTemp.push({
            preferredName: valuesTemp.consultants[consultantNameIndex],
            id: valuesTemp.consultantIds[consultantIdIndex],
            label: valuesTemp.consultants[consultantNameIndex],
            value: valuesTemp.consultantIds[consultantIdIndex],
          })
          break;
        }
      }
    }
    valuesTemp.consultantIds = consultantIdsTemp;
    let indexPurchaseTypeProduct = _.findIndex(valuesTemp.salesOrderDetails, {purchaseType: Constants.product});
    if (indexPurchaseTypeProduct > -1) {
      valuesTemp.consultantIdsForAGWithProduct = consultantIdsTemp;
    }

    valuesTemp.customerCartId = valuesTemp.id;
    valuesTemp.salesOrderDetails = valuesTemp.salesOrderDetails.map((salesOrderDetailsTemp) => {
      let serviceProductUsedTemp: ObjectForDropdown[] = [];
      if (salesOrderDetailsTemp.serviceProductUsed && salesOrderDetailsTemp.serviceProductUsedIds) {
        for (let serviceProductUsedIndex = 0; serviceProductUsedIndex < salesOrderDetailsTemp.serviceProductUsed.length; serviceProductUsedIndex++) {
          for (let serviceProductUsedIdIndex = 0; serviceProductUsedIdIndex < salesOrderDetailsTemp.serviceProductUsedIds.length; serviceProductUsedIdIndex++) {
            if (serviceProductUsedIndex === serviceProductUsedIdIndex) {
              serviceProductUsedTemp.push({
                label: salesOrderDetailsTemp.serviceProductUsed[serviceProductUsedIndex],
                value: salesOrderDetailsTemp.serviceProductUsedIds[serviceProductUsedIdIndex],
              })
              break;
            }
          }
        }
      }

      if (salesOrderDetailsTemp.purchaseType === Constants.product || salesOrderDetailsTemp.isCreditPackage) {
        delete salesOrderDetailsTemp["expiredDate"];
      }

      if (salesOrderDetailsTemp.purchaseType === Constants.product || salesOrderDetailsTemp.purchaseType === Constants.package) {
        delete salesOrderDetailsTemp["serviceById"];
        delete salesOrderDetailsTemp["serviceProductUsedIds"];
        delete salesOrderDetailsTemp["serviceProductUsed"];
      }

      if (salesOrderDetailsTemp.expiredDate) {
        salesOrderDetailsTemp.expiredDate = returnExpiredDate(Constants.textInputDateFormat);
      }

      if (salesOrderDetailsTemp.serviceById) {
        let employeeDropdownListFinal = employeeDropdownListTemp || employeeDropdownList;
        let indexEmployeeIndex = _.findIndex(employeeDropdownListFinal, { id: salesOrderDetailsTemp.serviceById })
        if (indexEmployeeIndex > -1) {
          salesOrderDetailsTemp.serviceByName = employeeDropdownListFinal[indexEmployeeIndex].preferredName;
        }
      }

      salesOrderDetailsTemp.promotionItemType = salesOrderDetailsTemp.promotionCode ? true : false

      let taxTemp = 0;
      let floorPriceTemp = 0;
      if (salesOrderDetailsTemp.purchaseType === Constants.service) {
        let indexService = _.findIndex(serviceTreatmentDropdownList, { id: salesOrderDetailsTemp.itemId })
        if (indexService > -1) {
          taxTemp = serviceTreatmentDropdownList[indexService].taxRate;
          floorPriceTemp = serviceTreatmentDropdownList[indexService].floorPrice;
        }
      }
      else if (salesOrderDetailsTemp.purchaseType === Constants.package) {
        let indexPackage = _.findIndex(packageDropdownList, { id: salesOrderDetailsTemp.itemId })
        if (indexPackage > -1) {
          taxTemp = packageDropdownList[indexPackage].taxRate;
          floorPriceTemp = packageDropdownList[indexPackage].floorPrice;
        }
      }
      else if (salesOrderDetailsTemp.purchaseType === Constants.product) {
        let indexProduct = _.findIndex(productDropdownList, { id: salesOrderDetailsTemp.itemId })
        if (indexProduct > -1) {
          taxTemp = productDropdownList[indexProduct].taxRate;
          floorPriceTemp = productDropdownList[indexProduct].floorPrice;
        }
      }

      let objTemp: any = {
        ...salesOrderDetailsTemp,
        price: salesOrderDetailsTemp.unitPrice,
        tax: taxTemp,
        ...!salesOrderDetailsTemp.promotionCode && {floorPrice: floorPriceTemp},
        ...salesOrderDetailsTemp.promotionCode && {discountValue: 0},
        ...salesOrderDetailsTemp.promotionCode && {availableQuantity: salesOrderDetailsTemp.promotionAvailableCount}
      }

      if (serviceProductUsedTemp.length > 0) {
        objTemp = { ...objTemp, serviceProductUsed: serviceProductUsedTemp, availableOptionalProducts: serviceProductUsedTemp }
      }
      return objTemp;
    });
    delete valuesTemp["cartDetails"];
    valuesTemp.tabBar = Constants.service;
    valuesTemp.checkboxVoidCustomerSalesOrderNo = valuesTemp.voidedSalesOrderNo ? true : false
    valuesTemp.isBackdate = valuesTemp.submissionDate ? true : false
    if (valuesTemp.submissionDate) {
      valuesTemp.submissionDate = moment(valuesTemp.submissionDate).format(Constants.textInputDateAndTimeFormat);
    }
    valuesTemp.originalBranchName = valuesTemp.customerBranchName;
    validation.setValues(valuesTemp);
    requestLocalEmployeeDropdown(valuesTemp.customerBranchId, false);
    prefillCustomerInfo(valuesTemp.customerId, [], false, false, true);
  }

  const prefillCustomerInfo = async (id: string, customerDropdownListTemp: CustomerListObject[] | any = [], blnPromotionItemChecking: boolean = true, prefillSubBrand: boolean = true, updateFromCustomerCart: boolean = false, branchId: string = "") => {
    setDisplayCustomerDropdown(false);
    setDisplayPromoCodeDropdown(false);
    if (customerDropdownListTemp.length === 0) {
      if (customerDropdownList.length > 0) {
        customerDropdownListTemp = customerDropdownList;
      }
      else {
        customerDropdownListTemp = await getCustomerDropdown(branchId, {customerId: id});
      }
    }

    if (blnPromotionItemChecking && checkSelectedPromotionItemsFromCart()) {
      onChangeUserWhenPromotionItemExist(id, true);
      return;
    }
    
    setPromotionItemList([]);
    // Temporrally comment out access granted customer handling
    // if (blnDifferentBranch && !updateFromCustomerCart) {
    //   setBlnDifferentBranch(false);
    //   setDisplayConsultantDropdown(false);
    //   validation.setFieldValue("consultantIds", []);
    //   validation.setFieldValue("consultants", []);
    // }
    
    let indexCustomer = _.findIndex(customerDropdownListTemp, { id: id });
    let blnAccessGrantedCustomerTemp = false;
    if (indexCustomer > -1) {
      validation.setFieldValue("customerId", customerDropdownListTemp[indexCustomer].id);
      validation.setFieldValue("customerName", customerDropdownListTemp[indexCustomer].preferredName);
      validation.setFieldValue("customerNo", customerDropdownListTemp[indexCustomer].customerNo);
      validation.setFieldValue("customerMobileNo", customerDropdownListTemp[indexCustomer].mobileNo);
      validation.setFieldValue("isSponsorProfile", customerDropdownListTemp[indexCustomer].isSponsorProfile);
      // sub brand remove prefill value, user is mandatory to select a sub-brand to continue
      // if (prefillSubBrand) {
      //   validation.setFieldValue("subBrandId", customerDropdownListTemp[indexCustomer].subBrandId);
      //   validation.setFieldValue("subBrandName", customerDropdownListTemp[indexCustomer].subBrandName);
      // }
      validation.setFieldValue("originalBranchName", customerDropdownListTemp[indexCustomer].branchName)
      validation.setFieldValue("promotionValue", "");
      if (customerDropdownListTemp[indexCustomer].branchName !== getBranchName()) {
        // Temporrally comment out access granted customer handling
        // setDisplayConsultantDropdown(false);
        // setBlnDifferentBranch(true);
        // let consultantsTemp : any = [{
        //   preferredName: customerDropdownListTemp[indexCustomer].consultantName,
        //   id: customerDropdownListTemp[indexCustomer].consultantId,
        //   label: customerDropdownListTemp[indexCustomer].consultantName,
        //   value: customerDropdownListTemp[indexCustomer].consultantId,
        // }];
        // setEmployeeDropdownListForOtherBranch(consultantsTemp);
        // validation.setFieldValue("consultantIds", consultantsTemp);
        if (customerDropdownListTemp[indexCustomer].subBranchName !== getBranchName()) {
          blnAccessGrantedCustomerTemp = true;
          validation.setFieldValue("tabBar", Constants.package);
        }
      }
    }

    setBlnAccessGrantedCustomer(blnAccessGrantedCustomerTemp);
    setTimeout(() => {
      clearCustomerDropdownList();
      setDisplayCustomerDropdown(true);
      setDisplayPromoCodeDropdown(true);
      // Temporrally comment out access granted customer handling
      // setDisplayConsultantDropdown(true);
    }, 100)
  }

  const onClickSelect = (row, blnPromotionItemChecking: boolean = true) => {
    if (blnPromotionItemChecking && checkSelectedPromotionItemsFromCart()) {
      onChangeUserWhenPromotionItemExist(row, false);
      return;
    }
    
    requestLocalEmployeeDropdown(row.originalBranchId, true);
    setPromotionItemList([]);
    validation.setFieldValue("promotionValue", "");
    validation.setFieldValue("customerId", row.id);
    validation.setFieldValue("customerName", row.preferredName);
    validation.setFieldValue("customerNo", row.customerNo);
    validation.setFieldValue("customerMobileNo", row.mobileNo);
    validation.setFieldValue("isSponsorProfile", row.isSponsorProfile);
    validation.setFieldValue("subBrandName", "");
    validation.setFieldValue("subBrandId", "");
    validation.setFieldValue("originalBranchName", row.branchName);
    setBlnAccessGrantedCustomer(row.blnAccessGranted);
    if (row.blnAccessGranted) {
      validation.setFieldValue("salesOrderDetails", []);
      validation.setFieldValue("tabBar", Constants.package);
    }
    // sub brand remove prefill value, user is mandatory to select a sub-brand to continue
    // if (row.badges) {
    //   if (row.badges.length > 0) {
    //     validation.setFieldValue("subBrandName", row.badges[0].name);
    //     validation.setFieldValue("subBrandId", row.badges[0].id);
    //   }
    // }

    // Temporrally comment out access granted customer handling
    // let consultantsTemp : any = [{
    //   preferredName: row.customerConsultantName ? row.customerConsultantName : row.consultantName,
    //   id: row.customerConsultantId ? row.customerConsultantId : row.consultantId,
    //   label: row.customerConsultantName ? row.customerConsultantName : row.consultantName,
    //   value: row.customerConsultantId ? row.customerConsultantId : row.consultantId,
    // }];
    // setEmployeeDropdownListForOtherBranch(consultantsTemp);
    // validation.setFieldValue("consultantIds", consultantsTemp);
    // validation.setFieldValue("consultantIdsForAGWithProduct", []);
    setDisplayCustomerDropdown(false);
    setDisplayPromoCodeDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setDisplayPromoCodeDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onChangeUserWhenPromotionItemExist = (detail: any, blnEmployeeDropdown) => {
    setActionAfterClearPromotion(blnEmployeeDropdown ? actionApplyCustomerDropdown : actionApplyCustomerAdvanceSearch);
    setClearPromotionModal(true);
    if (blnEmployeeDropdown) {
      setLocalCustomerIdTemporally(detail);
    }
    else {
      setLocalCustomerDetailTemporally(detail);
    }

    validation.setValues((values) => ({
      ...values
    }))

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setDisplayPromoCodeDropdown(true);
    }, 100)
    return;
  }

  const requestLocalEmployeeDropdown = (branchId: string, blnRemoveConsultant: boolean) => {
    // if ((branchId && branchId !== getBranchId()) || blnDifferentBranch) {
    //   if (blnRemoveConsultant) {
    //     validation.setFieldValue("consultantIds", []);
    //     validation.setFieldValue("consultants", []);
    //   }
    //   setDisplayConsultantDropdown(false);
    //   if (branchId && branchId !== getBranchId()) {
    //     getEmployeeDropdown({ branchId: branchId, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true}, true)
    //     .then((valueEmployeeDropdown: any )=> {
    //       setEmployeeDropdownListForOtherBranch(valueEmployeeDropdown || [])
    //     })
    //     .finally(()=> {
    //       setDisplayConsultantDropdown(true);
    //     })
    //   }
    //   else {
    //     setTimeout(()=> {
    //       setDisplayConsultantDropdown(true);
    //     }, 100)
    //   }
    // }
    
    // Temporrally comment out access granted customer handling
    // setBlnDifferentBranch(branchId && branchId !== getBranchId() ? true : false)
    // setDisplayConsultantDropdown(false);
    // setTimeout(()=> {
    //   setDisplayConsultantDropdown(true);
    // }, 100)
  }

  const displayCustomerOrderTab = (searchProps) => {
    const handleClick = () => {
      if (searchProps) {
        if (searchProps.onSearch) {
          searchProps.onSearch("");
        }
      }
    };

    return (
      <div>
        <button 
          ref={refButtonClearSearch}
          type="button" 
          style={{border: 'none', background: 'none'}}
          onClick={handleClick}>  
        </button>
      </div>
    )
  }

  const clearSearchInput = () => {
    if (refButtonClearSearch) {
      if (refButtonClearSearch.current) {
        if (refButtonClearSearch.current.click) {
          refButtonClearSearch.current.click();
        }
      }
    }
  }

  const validProductTabForAccessGranted = (blnProduct: boolean) => {
    // Temporrally comment out access granted customer handling
    // if (blnAccessGrantedCustomer) {
    //   let salesOrderDetailsTemp : any = _.cloneDeep(validation.values.salesOrderDetails);
    //   if (blnProduct) {
    //     let indexPurchaseTypeService = _.findIndex(salesOrderDetailsTemp, {purchaseType: Constants.service});
    //     let indexPurchaseTypePackage = _.findIndex(salesOrderDetailsTemp, {purchaseType: Constants.package});
    //     if (indexPurchaseTypeService > -1 || indexPurchaseTypePackage > -1) {
    //       return false;
    //     }
    //   }
    //   else {
    //     let indexPurchaseTypeProduct = _.findIndex(salesOrderDetailsTemp, {purchaseType: Constants.product});
    //     if (indexPurchaseTypeProduct > -1) {
    //       return false;
    //     }
    //   }
    // }

    return true;
  }

  const productExistOnCart = () => {
    let salesOrderDetailsTemp : any = _.cloneDeep(validation.values.salesOrderDetails);
    let indexPurchaseTypeProduct = _.findIndex(salesOrderDetailsTemp, {purchaseType: Constants.product});
    if (indexPurchaseTypeProduct > -1) {
      return true;
    }
    return false;
  }

  const parkSales = async () => {
    if (!validation.values.customerId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) }));
      return;
    }
    else if (returnSubBrandList().length > 0 && !validation.values.subBrandId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" })}));
      return;
    }
    else if (validation.values.salesOrderDetails.length === 0) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() }));
      return;
    }
    
    setLoadingCustomerCartModal(true);
    const valuesTemp: any = _.cloneDeep(validation.values);

    let salesOrderDetailsTemp = _.cloneDeep(valuesTemp.salesOrderDetails);
    salesOrderDetailsTemp = salesOrderDetailsTemp.map((value, index) => {
      if (value.serviceProductUsed) {
        value.serviceProductUsedTemp = _.cloneDeep(value.serviceProductUsed);
        value.serviceProductUsed = value.serviceProductUsed.map((valueServiceProductUsed) => (valueServiceProductUsed.value))
      }
      if (value.availableOptionalProducts) {
        delete value["availableOptionalProducts"];
      }
      return value
    })
    valuesTemp.salesOrderDetails = salesOrderDetailsTemp
    valuesTemp.customerCartDetails = salesOrderDetailsTemp
    valuesTemp.consultantIds = !blnAccessGrantedWithProductOnly ? valuesTemp.consultantIds.map((valueTemp) => valueTemp.value) : valuesTemp.consultantIdsForAGWithProduct.map((valueTemp) => valueTemp.value);
    valuesTemp.branchId = getBranchId();
    
    try {
      let valuesTempToSend = _.cloneDeep(valuesTemp);
      if (valuesTempToSend.submissionDate) {
        valuesTempToSend.submissionDate = moment(valuesTempToSend.submissionDate).format(Constants.defaultDateFormat);
      }
      delete valuesTempToSend["salesOrderDetails"]
      let resultAPI: any = valuesTemp.customerCartId ? await updateCustomerCart(valuesTempToSend) : await addCustomerCart(valuesTempToSend);
      if (resultAPI.status === Constants.success) {
        setSuccessMessage(intl.formatMessage({ id: "CustomerOrderParkSuccessfully" }));
        setTimeout(()=> {
          window.location.reload();
        }, Constants.timeoutNavigation) 
      }
    }
    catch (e: any) {
      setErrorMessage(e);
    }
    finally {
      setLoadingCustomerCartModal(false);
    }
  }

  // Temporrally comment out access granted customer handling
  // const blnAccessGrantedWithProductOnly = blnAccessGrantedCustomer && productExistOnCart();
  const blnAccessGrantedWithProductOnly = false;

  const displayTableBySalesCategory = (baseProps: any) => {
    let aryDataTemp = _.cloneDeep(baseProps?.data || []);

    if (validation.values.tabBar === promotion || aryDataTemp.length === 0) {
      return (
        <BootstrapTable
          responsive
          bordered={false}
          striped={false}
          defaultSorted={defaultSorted}
          classes={"table align-middle"}
          keyField="id"
          rowEvents={rowEvents}
          noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: validation.values.tabBar == services ? "ServicesTreatment" : validation.values.tabBar == packages ? "Packages" : validation.values.tabBar === products ? "Products" : "Promotion" }) })}
          {...baseProps}/>
      )
    }

    const uniqueSalesCategory = Array.from(new Set(aryDataTemp.map(item => item.salesCategoryId)))
    .map(uniqueId => {
      const matchingObject = aryDataTemp.find(item => item.salesCategoryId === uniqueId);
      return { id: matchingObject.salesCategoryId, name: matchingObject.salesCategoryName };
    });
    let finalViews: any = [];
    
    uniqueSalesCategory.map((valueSalesCategoryOnlyTemp)=> {
      let finalData = _.filter(aryDataTemp, {salesCategoryId: valueSalesCategoryOnlyTemp.id});
      if (finalData.length > 0) {
        finalViews.push(
          <div key={`table_key_${valueSalesCategoryOnlyTemp.name}`}>
            <div 
              style={{padding: "0.75rem 0.75rem"}}>
              <span style={{color: Constants.lightpurple, fontSize: '16px', fontWeight: 'bold', borderBottom: '1px solid', marginLeft: '-8px'}}>
                {valueSalesCategoryOnlyTemp.name}
              </span>
            </div>
            <BootstrapTable
              responsive
              bordered={false}
              striped={false}
              defaultSorted={defaultSorted}
              classes={"table align-middle"}
              keyField="id"
              rowEvents={rowEvents}
              noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: validation.values.tabBar == services ? "ServicesTreatment" : validation.values.tabBar == packages ? "Packages" : validation.values.tabBar === products ? "Products" : "Promotion" }) })}
              {...baseProps}
              data={finalData}
            />
          </div>
        )
      }
    })
    return finalViews;
  }
  
  const onColumnMatch = ({searchText, row}) => {
    let blnReturnRow = !searchText;

    //Search Selling Price / Discounted Price
    let priceTemp = validation.values.tabBar !== promotion ? row.sellingPrice : row.discountedPrice;
    if (priceTemp === Number(searchText)) {
      blnReturnRow = true;
    }
    
    //Search Name
    if (row.name) {
      if (String(row.name).toLowerCase().includes(searchText)) {
        blnReturnRow = true;
      }
    }
    
    //Search Group Code Name
    if (row.groupCodeName) {
      if (String(row.groupCodeName).toLowerCase().includes(searchText)) {
        blnReturnRow = true;
      }
    }

    return blnReturnRow;
  }
  
  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "P.O.S" }) })}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
              <div className="h4">{intl.formatMessage({ id: "P.O.S" })}</div>
            </Col>
          </Row>
          <BreadCrumb activeBreadCrumbTitle={intl.formatMessage({ id: "P.O.S" })} />
          {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          {loading && !successMessage && !customerCartModal ? (
            <Loading />
          ) : (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="7" md="7" xs="12" sm="12">
                  <ToolkitProvider
                    keyField="id"
                    columns={CustomerOrderColumns}
                    data={validation.values.tabBar == services ? serviceTreatmentDropdownList : validation.values.tabBar == packages ? !blnAccessGrantedCustomer ? packageDropdownList : productBundledPackageDropdownList : validation.values.tabBar === products ? productDropdownList : promotionItemList}
                    bootstrap4
                    search={{onColumnMatch}}
                  >
                    {toolkitProps => (
                      <div
                        className="standard-layout">
                        {displayCustomerOrderTab(toolkitProps.searchProps)}
                        <Row>
                          <Col xl="6" md="12" xs="12">
                            <Row>
                              <Col xl='8' md='8' xs='8'>
                                {
                                  !displayCustomerDropdown
                                  ?
                                  <Loading/>
                                  :
                                  <DropDownWithTitle
                                    name={"customerId"}
                                    title={intl.formatMessage({ id: "Customer" })}
                                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                                    specifyReturnFieldName={[
                                      {
                                        field: "customerId",
                                        value: "id",
                                      }
                                    ]}
                                    labelField={"preferredName"}
                                    valueField={"id"}
                                    options={customerDropdownList}
                                    disabled={disabledFieldInput}
                                    initialLabel={validation.values.customerName}
                                    initialValue={validation.values.customerId}
                                    validationRequired={true}
                                    validation={validation}
                                    onChangeField={"id"}
                                    onChangeFunction={prefillCustomerInfo}
                                    blnSupportCustomSearch={true}
                                    onChangeCustomSearch={(value)=> {
                                      fetchCustomerDropdown(value);
                                    }}
                                    onMenuOpenFunction={()=> {
                                      clearCustomerDropdownList();
                                      setLoadingCustomerDropdown(false);
                                    }}
                                    loadingCustomSearch={loadingCustomerDropdown}
                                    setLoadingCustomSearch={setLoadingCustomerDropdown}
                                  />
                                }
                              </Col>
                              <Col xl='4' md='4' xs='4' style={{overflow: 'hidden'}}>
                                <MyButton
                                  type="button"
                                  class={`btn btn-primary margin-top-28`}
                                  content={""}
                                  subContent={returnAdvancedSearchIcon()}
                                  loading={loading}
                                  disable={loading}
                                  onClick={() => { setCustomerSearchModal(true) }}
                                />
                              </Col>
                            </Row>
                            {
                              validation.values.originalBranchName !== "" && validation.values.originalBranchName !== getBranchName()
                              &&
                              <p className="text-danger margin-top--12">*{intl.formatMessage({ id: "OriginalBranch" })}: {validation.values.originalBranchName} { blnAccessGrantedCustomer && ` (${intl.formatMessage({ id: "AccessGranted" })})`}</p>
                            }
                          </Col>
                          <Col xl="6" md="12" xs="12">
                            <Row>
                              <Col xl='8' md='8' xs='8'>
                                {
                                  !displayPromoCodeDropdown
                                  ?
                                  <Loading/>
                                  :
                                  <DropDownWithTitle
                                    name={"promotionValue"}
                                    title={intl.formatMessage({ id: "PromoCode" })}
                                    specifyReturnFieldName={[
                                      {
                                        field: "promotionValue",
                                        value: "code",
                                      }
                                    ]}
                                    labelField={"code"}
                                    valueField={"code"}
                                    options={!validation.values.isSponsorProfile ? promotionDropdownWithoutSponsorList : promotionDropdownList}
                                    disabled={disabledFieldInput}
                                    initialLabel={validation.values.promotionValue}
                                    initialValue={validation.values.promotionValue}
                                    validation={validation}
                                  />
                                }
                              </Col>
                              <Col xl='4' md='4' xs='4'>
                                <MyButton
                                  type="button"
                                  class={`btn btn-primary margin-top-28`}
                                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                                  loading={loading || loadingPromotion}
                                  disable={loading || !Boolean(validation.values.promotionValue) || !Boolean(validation.values.customerId)}
                                  onClick={() => { 
                                    if (checkSelectedPromotionItemsFromCart()) {
                                      setActionAfterClearPromotion(actionApplyPromotionCode);
                                      setClearPromotionModal(true);
                                    } 
                                    else {
                                      applyPromotionCode();
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Col xl='12'>
                          {
                            displayConsultantDropdown
                            ?
                            // Temporrally comment out access granted customer handling
                            // !blnAccessGrantedWithProductOnly
                            // ?
                            <DropDownWithTitleMultiSelect
                              name={"consultantIds"}
                              keyDiv={"consultantIds"}
                              title={intl.formatMessage({ id: "Consultants" })}
                              specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                              returnFieldWithLabel={true}
                              returnAllField={true}
                              labelField={"preferredName"}
                              valueField={"id"}
                              // Temporrally comment out access granted customer handling
                              // options={!blnDifferentBranch ? employeeDropdownList : employeeDropdownListForOtherBranch}
                              // disabled={disabledFieldInput || blnDifferentBranch}
                              options={employeeDropdownList}
                              disabled={disabledFieldInput}
                              initialValue={validation.values.consultantIds}
                              validationRequired={true}
                              validation={validation} />
                            // :
                            // <DropDownWithTitleMultiSelect
                            //   name={"consultantIdsForAGWithProduct"}
                            //   keyDiv={"consultantIdsForAGWithProduct"}
                            //   title={intl.formatMessage({ id: "Consultants" })}
                            //   specifyReturnFieldName={[{ "field":"consultantIdsForAGWithProduct", "value": "id" }]}
                            //   returnFieldWithLabel={true}
                            //   returnAllField={true}
                            //   labelField={"preferredName"}
                            //   valueField={"id"}
                            //   options={employeeDropdownList}
                            //   disabled={disabledFieldInput}
                            //   initialValue={validation.values.consultantIdsForAGWithProduct}
                            //   validationRequired={true}
                            //   validation={validation} />
                            :
                            <Loading />
                          }
                        </Col>
                        <Borderline />
                        <Row>
                          {
                            aryTab.map((value, index) => {
                              let blnRadioDisabled = (value === promotion && promotionItemList.length === 0) || (value === services && blnAccessGrantedCustomer);
                              return (
                                <Col xl="3" sm="6" key={`${value}_tab_bar`}>
                                  <div className="mb-3">
                                    <label className="card-radio-label mb-0">
                                      <Input
                                        type="radio"
                                        id={value}
                                        name="tabBar"
                                        checked={validation.values.tabBar == value}
                                        className={`display-none ${blnRadioDisabled ? `card-radio-input-Disabled` : validation.values.tabBar === services ? `card-radio-input-${services}` : validation.values.tabBar === packages ? `card-radio-input-${packages}` : validation.values.tabBar === products ? `card-radio-input-${products}` : `card-radio-input-${promotion}`}`}
                                        disabled={blnRadioDisabled ? true : false}
                                        onChange={event => {
                                          if (validation.isSubmitting) {
                                            return;
                                          }
                                          clearSearchInput();
                                          setTimeout(()=> {
                                            handleChangeRadioBox(event)
                                          }, 10)
                                        }}
                                      />
                                      <div className={`card-radio`} style={{ borderRadius: '10px', padding: '12px', borderColor: `${blnRadioDisabled ? Constants.lightgreyfaded : value === services ? Constants.lightpurple : value === packages ? Constants.lightgreen : value === products ? Constants.orange : Constants.lightpink}` }}>
                                        <div className={"flex-direction-row flex-justfity-content-center"}>
                                          <span className="" style={{color: blnRadioDisabled ? Constants.lightgreyfaded : value == promotion ? "pink" : ""}}>{getPurchaseTypeIcon(value, blnRadioDisabled)}</span>
                                          <p className="margin-bottom-0 label-truncate" style={{}}>{value == services ? "Service Treatment" : value}</p>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </Col>
                              )
                            })
                          }
                        </Row>
                        {blnAccessGrantedCustomer && <p className={`text-danger flex-right`}>*{intl.formatMessage({ id: "CustomerOrderProductOnly" })}</p>}
                        {/* {validation.values.tabBar === products && <p className="text-danger flex-right">*{intl.formatMessage({ id: "ProductRedeemWhenFullPaymentMade" })}</p>} */}
                        <Col className="flex-right">
                          <div className="search-box ms-2 margin-bottom-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar
                                {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              {displayTableBySalesCategory(toolkitProps.baseProps)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
                <Col xl="5" md="5" xs="12" sm="12">
                  <div className={`standard-layout ${Constants.innerWidthCompare770>windowSize.innerWidth ? "margin-top-16" : ""}`}>
                    <CustomerOrderCart
                      validation={validation}
                      disabledCustomerOrder={disabledFieldInput}
                      disabledCustomerPayment={true}
                      disabledNote={disabledFieldInput}
                      disabledVoidedSalesOrderNo={disabledFieldInput}
                      selectCustomerOrder={selectCustomerOrder}
                      deleteCustomerOrder={deleteCustomerOrder}
                      loadingSubBrand={!displayCustomerDropdown}
                      blnAllowCrossMonthSales={generalSettings?.isAllowCrossMonthSales || false}
                    />
                    <MyButton
                      type="button"
                      class="primary-color"
                      style={{ backgroundColor: "transparent", borderColor: "transparent" }}
                      content={intl.formatMessage({ id: "ViewCustomerCart" }).toUpperCase()}
                      disable={
                        validation.isSubmitting || loadingCustomerCartModal
                      }
                      loading={
                        validation.isSubmitting || loadingCustomerCartModal
                      }
                      onClick={async () => {
                        setLoadingCustomerCartModal(true);
                        try {
                          await getCustomerCart({ pageNumber: 1, pageSize: 10, branchId: getBranchId() });
                          setCustomerCartModal(true);
                        }
                        finally {
                          setLoadingCustomerCartModal(false);
                        }
                      }}
                    />
                    <Borderline />
                    <Row>
                      <Col>
                        {
                          !Boolean(successMessage) &&
                          (
                            <MyButton
                              type="submit"
                              class="btn btn-primary"
                              style={{ width: '100%' }}
                              content={intl.formatMessage({ id: "Pay" }).toUpperCase()}
                              disable={
                                validation.isSubmitting || loadingCustomerCartModal
                              }
                              loading={
                                validation.isSubmitting || loadingCustomerCartModal
                              }
                            />
                          )
                        }
                        {
                          !Boolean(successMessage) 
                          &&
                          <div className="flex-align-items-center-without-height">
                            <MyButton
                              type="button"
                              class="btn btn-secondary margin-top-8 button-padding-overwrite"
                              style={{ width: '100%' }}
                              content={intl.formatMessage({ id: "Park" }).toUpperCase()}
                              disable={
                                validation.isSubmitting || loadingCustomerCartModal
                              }
                              loading={
                                validation.isSubmitting || loadingCustomerCartModal
                              }
                              onClick={() => {
                                parkSales();
                              }}
                            />
                            {
                              validation.values.customerCartId &&
                              (
                                <MyButton
                                  type="button"
                                  class="btn btn-danger margin-top-8 button-padding-overwrite"
                                  style={{ width: '100%', marginLeft: '8px' }}
                                  content={intl.formatMessage({ id: "Discard" }).toUpperCase()}
                                  disable={
                                    validation.isSubmitting || loadingCustomerCartModal
                                  }
                                  loading={
                                    validation.isSubmitting || loadingCustomerCartModal
                                  }
                                  onClick={() => {
                                    setSelectedRow(validation.values.customerCartId);
                                    setShowDeletePrompt(true);
                                  }}
                                />
                              )
                            }
                          </div>
                        }
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
          {
            customerOrderDetailModal
            &&
            <CustomerOrderDetailModal
              blnShow={customerOrderDetailModal}
              setModal={setCustomerOrderDetailModal}
              selectedCustomerOrderDetail={selectedCustomerOrderDetail}
              setSelectedCustomerOrderDetail={setSelectedCustomerOrderDetail}
              discountType={discountType}
              employeeList={employeeDropdownList}
              calulateTotalPrice={calculatePOSTotalPrice}
              validation={validation}
              additionalFormFieldFormTypeList={additionalFormFieldFormTypeList}
              maxPrepaidPackageInSalesOrder={generalSettings?.maxPrepaidPackageInSalesOrder || 0} />
          }
          <SelectWithTableListModal
            blnShow={customerCartModal}
            setModal={setCustomerCartModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerCart" }) })}
            options={customerCartList}
            keyField={"id"}
            fieldName={"customerCartId"}
            onSelectFunction={onChangeCustomerCartModal}
            onDeleteFunction={onDeleteCustomerCartModal}
            topRightButton={()=> (
              customerCartList.length > 0
              &&
              <MyButton
                type="button"
                content={intl.formatMessage({ id: "DeleteAllCustomerCart" }).toUpperCase()}
                class="btn btn-danger"
                onClick={()=> {
                  setDeleteAllCustomerCartModal(true);
                }}
                loading={loading}
              />
            )}
            columns={CustomerCartColumns}
            blnPagination={true}
            totalItem={totalItem}
            requestAPI={getCustomerCart}
            paginationSubmitField={{ branchId: getBranchId() }}
            validation={validation} />
          <DeleteModal
            displayName={""}
            onDeleteClick={async () => {
              validation.setSubmitting(true);
              await deleteCustomerCart(selectedRow || "");
              validation.setSubmitting(false);
              history.replace({
                pathname: `/${RoutesList.pos}`,
                state: {}
              });
              window.location.reload();
            }} />
          {
            customerSearchModal
            &&
            <CustomerSearchModal
              blnShow={customerSearchModal}
              blnHideOtherBranch={false}
              setModal={setCustomerSearchModal}
              title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
              onSelectFunction={onClickSelect} />
          }
          {
            clearPromotionModal
            &&
            <ActionPromptModal 
              title={`${intl.formatMessage({ id: "ClearPromoItemsAlert" })}`}
              primaryActionTitle={`Yes`}
              showPrompt={clearPromotionModal}
              setShowPrompt={setClearPromotionModal}
              onPrimaryClick={()=> {clearSelectedPromotionItemFromCart()}}
            />
          }
          {
            deleteAllCustomerCartModal
            &&
            <ActionPromptModal 
              title={`${intl.formatMessage({ id: "DeleteAllCustomerCartMessage" })}`}
              primaryActionTitle={`Yes`}
              showPrompt={deleteAllCustomerCartModal}
              setShowPrompt={setDeleteAllCustomerCartModal}
              onPrimaryClick={()=> {
                onDeleteCustomerCartByBranchModal()
              }}
            />
          }
          {
            blnDisablePurchaseTabModal
            &&
            <ActionPromptModal 
              title={`${intl.formatMessage({ id: "DisableProductTabForAccessGrantedCustomer" })}`}
              primaryActionTitle={`Close`}
              showPrompt={blnDisablePurchaseTabModal}
              setShowPrompt={setBlnDisablePurchaseTabModal}
              blnHideCancelButton={true}
              blnShowCloseButton={true}
              onPrimaryClick={()=> {}}
            />
          }
          {
            weeklyPendingOrdersChart.length > 0
            &&
            <CustomerOrderPendingSignModal
              blnShow={true}
              weeklyPendingOrdersChart={weeklyPendingOrdersChart}/>
          }
          {
            blnShowPendingCloseCounterModal
            &&
            <PendingCloseCounterModal 
              blnShow={blnShowPendingCloseCounterModal}
              setModal={setBlnShowPendingCloseCounterModal}
              pendingCloseCounterDetail={pendingCloseCounterDetail!}
              disabledCloseFunction={true}/>
          }
          {
            blnShowTodayCloseCounterSubmittedModal
            &&
            <TodayCloseCounterSubmittedModal 
              blnShow={blnShowTodayCloseCounterSubmittedModal}/>
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(CustomerOrder);
