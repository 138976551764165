import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "../stores/store";
import { history } from '../../';
import { convertRequestBodyObject, convertJSONIntoFormData, getJWT, removeAllEmptyDataFromObject, urlHandleWithWorflowAction, getBranchId, blnValidErrorMessage, truncateText, getBranchName } from "../common/function/function";
import { toast } from 'react-toastify';
import { ForgetPasswordFormValues, ForgetPasswordRequestFormValues, User, UserDropdownListObject, UserFormValues } from "../models/user";
import { BrandListObject, BrandAddObject, BrandUpdateObject, BrandDetailObject, SubBrandObject } from '../models/brand';
import { PaginationRequestBody, PaginatedResult } from "../models/pagination";
import { BranchListObject, BranchAddObject, BranchUpdateObject, BranchDetailObject, BusinessHourForBranchObject, PaymentMethodForBranchObject, ProductForBranchObject, BranchProductBatchGetObject, BranchProductBatchListObject, BranchObject, BranchDropdownGetObject } from "../models/branch";
import { BusinessEntityListObject, BusinessEntityAddObject, BusinessEntityUpdateObject, BusinessEntityDetailObject } from '../models/businessEntity';
import { ProductAddObject, ProductDetailObject, ProductListObject, ProductUpdateObject, BranchForProductObject, ProductDropdownRequestBody, ProductImportObject, ProductExportObject, ProductStockOutObject, UpdateServiceConsumeQuantityObject } from "../models/product";
import { BranchForServiceTreatmentObject, ServiceMachineActiveObject, ServiceMachineActiveRequestObject, ServiceTreatmentAddObject, ServiceTreatmentDetailObject, ServiceTreatmentDropdownRequestBody, ServiceTreatmentImportFailedDetailObject, ServiceTreatmentImportObject, ServiceTreatmentListObject, ServiceTreatmentUpdateObject } from "../models/serviceTreatment";
import { BranchForPackageObject, PackageAddObject, PackageDetailObject, PackageDropdownRequestBody, PackageImportFailedDetailObject, PackageImportObject, PackageListObject, PackageUpdateObject, PackageWithGroupCodeListObject, PackageWithGroupCodeRequestObject } from "../models/package";
import { AccessTreeObject, AddEmployeeBranchTransferHistoryObject, AddEmployeePositionMovementHistoryObject, EmployeeAddObject, EmployeeAdvanceSearchResponseObject, EmployeeDetailObject, EmployeeDropdownForConsultantSalesRequestBody, EmployeeDropdownRequestBody, EmployeeExportObject, EmployeeImportObject, EmployeeImportSuccessObject, EmployeeListObject, EmployeeNameUpdateImportSuccessObject, EmployeeResetPasscodeObject, EmployeeResetPasscodeSuccessObject, EmployeeResetPasswordObject, EmployeeResetPasswordSuccessObject, EmployeeUpdateObject, ReassignCustomerObject, UpdateEmployeeBranchTransferHistoryObject, UpdateEmployeePositionMovementHistoryObject } from "../models/employee";
import { CurrencyAddObject, CurrencyDetailObject, CurrencyListObject, CurrencyUpdateObject } from "../models/currency";
import { CountryListObject, CountryAddObject, CountryUpdateObject, CountryDetailObject, StateDetailObject } from '../models/country';
import { StaticReferenceAuditModuleListObject, StaticReferenceObject } from "../models/staticReference";
import { CategoryAddObject, CategoryDetailObject, CategoryListObject, CategoryUpdateObject } from "../models/category";
import { SalesCategoryAddObject, SalesCategoryDetailObject, SalesCategoryListObject, SalesCategoryUpdateObject } from "../models/salesCategory";
import { TaxAddObject, TaxDetailObject, TaxListObject, TaxUpdateObject } from "../models/tax";
import { MeasurementAddObject, MeasurementDetailObject, MeasurementListObject, MeasurementUpdateObject } from "../models/measurement";
import { RaceAddObject, RaceDetailObject, RaceListObject, RaceUpdateObject } from "../models/race";
import { EPFContributionAddObject, EPFContributionDetailObject, EPFContributionListObject, EPFContributionUpdateObject } from "../models/epfContribution";
import { EISAddObject, EISDetailObject, EISListObject, EISUpdateObject } from "../models/eis";
import { SocsoAddObject, SocsoDetailObject, SocsoListObject, SocsoUpdateObject } from "../models/socso";
import { PCBAddObject, PCBDetailObject, PCBListObject, PCBUpdateObject } from "../models/pcb";
import { AppointmentAddObject, AppointmentBlockerAddObject, AppointmentBlockerDetailObject, AppointmentBlockerUpdateObject, AppointmentDetailObject, AppointmentGetListObject, AppointmentCalendarObject, AppointmentUpdateObject, AppointmentUpdateStatusObject, LinkAppointmentCustomerObject, RoomListObject, AppointmentListObject, AppointmentCustomerTreatmentListObject, AppointmentAddSuccessObject, AppointmentHistoryListObject, AppointmentPinRequestObject, AppointmentCustomerPurchasedListObject } from "../models/appointment";
import { UserRoleDetailObject, UserRoleListObject } from "../models/userRole";
import { AddCustomerRelationshipObject, AddCustomerTagObject, ClosedPurchaseHistoryObject, CustomerAddObject, CustomerAddResponseObject, CustomerAvailablePaymentObject, CustomerCreditHistoryListObject, CustomerCriteriaSearchGetObject, CustomerCriteriaSearchResponseObject, CustomerDetailObject, CustomerDropdownRequestObject, CustomerExpiredPackageObject, CustomerExportObject, CustomerImportFailedDetailObject, CustomerImportObject, CustomerListObject, CustomerNoteAddObject, CustomerNoteDetailObject, CustomerNoteListObject, CustomerNoteUpdateObject, CustomerPackageRedeemHistoryObject, CustomerRedeemHistoryListObject, CustomerRequestAccessListObject, CustomerServiceRedeemHistoryObject, CustomerSubBranchHistoryObject, CustomerUpdateObject, CustomerValidationRequestBody, CustomerValidationResponseObject, PurchasePackageObject, PurchaseProductObject, PurchaseServiceObject, RequestOtherBranchObject, ReservedProductObject, SalesOrderObject, SubBranchCustomerListObject, TransferHistoryObject, UpdateCustomerJoinedDateObject, UpdateCustomerMediaSourceObject } from "../models/customer";
import { MediaSourceAddObject, MediaSourceDetailObject, MediaSourceListObject, MediaSourceUpdateObject } from "../models/mediaSource";
import { CustomerOrderObject, CustomerOrderPayment, CustomerOrderPaymentSuccess, CustomerPurchaseObject, CustomerPurchaseRedeem, CustomerPurchaseSuccess, CustomerUpgrade, UpdateCustomerSalesOrderObject } from "../models/customerOrder";
import { InvoiceDetailObject, InvoiceListObject, InvoiceSignObject } from "../models/invoice";
import { PositionAddObject, PositionDetailObject, PositionListObject, PositionUpdateObject } from "../models/position";
import { SupplierAddObject, SupplierDetailObject, SupplierListObject, SupplierUpdateObject } from "../models/supplier";
import { AddSalesTargetObject, SalesTargetAddObject, SalesTargetDetailObject, SalesTargetDuplicatedRequestObject, SalesTargetListObject, SalesTargetUpdateObject } from "../models/salesTarget";
import { WarehouseAddObject, WarehouseDetailObject, WarehouseProductDetails, WarehouseProductsList, WarehousePurchaseOrderDropdownRequestBody, WarehousePurchaseOrderForProductObject, WarehouseUpdateObject } from "../models/warehouse";
import { AutoRedemptionGeneratedObject, DeliverOrderUpdateObject, DeliverOrderVoidObject, DeliveryOrderDetailObject, DeliveryOrderListObject, DeliveryOrderProductListObject, DeliveryOrderSignObject, EditDeliverOrderDetailObject } from "../models/deliveryOrder";
import { StockMovementAddObject, StockMovementDetailObject, StockMovementListObject, StockMovementUpdateObject } from "../models/stockMovement";
import { LeaveTypeAddObject, LeaveTypeDetailObject, LeaveTypeListObject, LeaveTypeUpdateObject } from "../models/leaveType";
import { PayrollItemAddObject, PayrollItemDetailObject, PayrollItemListObject, PayrollItemUpdateObject } from "../models/payrollItem";
import { StockRequestAddObject, StockRequestDetailObject, StockRequestForProductDetailsObject, StockRequestListObject, StockRequestUpdateObject } from "../models/stockRequest";
import { LeaveEntitlementAddObject, LeaveEntitlementDetailObject, LeaveEntitlementListObject, LeaveEntitlementUpdateObject } from "../models/leaveEntitlement";
import { PurchaseOrderAddObject, PurchaseOrderDetailObject, PurchaseOrderDropdownRequestBody, PurchaseOrderListObject, PurchaseOrderUpdateObject, PurchaseOrderWarehouseDetailsObject, PurchaseOrderWarehouseRequestBody } from "../models/purchaseOrder";
import { InboundNoticeAddObject, InboundNoticeDetailObject, InboundNoticeListObject, InboundNoticeUpdateObject } from "../models/inboundNotice";
import { GoodsReceiveNoteAddObject, GoodsReceiveNoteDetailObject, GoodsReceiveNoteListObject, GoodsReceiveNoteUpdateObject } from "../models/goodsReceiveNote";
import { PurchaseDeliverOrderAddObject, PurchaseDeliverOrderDetailObject, PurchaseDeliverOrderListObject, PurchaseDeliverOrderUpdateObject } from "../models/purchaseDeliverOrder";
import { PermissionListObject, PermissionUpdateObject, PermissionUserListObject } from "../models/permission";
import { ChangePasscodeObject, ChangePasswordObject, ProfileDetailObject, ProfileUpdateObject } from "../models/profile";
import { ConsultantSalesAddObject, ConsultantSalesCommissionObject, ConsultantSalesSummaryExportObject, ConsultantSalesListObject, ConsultantSalesSummaryListObject, SalesAdjustmentHistoryListObject, DynamicSalesDetailsListObject, ProductSoldGetObject, ProductSoldListObject, SalesByCategoryGetObject, SalesByCategoryListObject, MonthlySalesSummaryGetObject, MonthlySalesSummaryListObject, SalesOverviewGetObject, SalesOverviewListObject, SalesOverviewDetailsGetObject, SalesOverviewDetailsListObject, SubBranchSalesSummaryGetObject, SubBranchSalesSummaryObject, ConsultantDailySalesListObject, DiscountSalesDetailsListObject, SalesByEmployeeGetAndExportObject, SalesByProductGetAndExportObject, SalesByProductDetailObject, ProductSalesDetailsGetAndExportObject, ProductSalesDetailsObject, ClosingRateListGetAndExportObject, ClosingRateListObject, SalesOverviewYearlyListGetAndExportObject, SalesOverviewYearlyListObject, BranchSalesSummaryListGetAndExportObject, BranchSalesSummaryListObject, BranchDailySalesSummaryListGetAndExportObject, BranchDailySalesSummaryListObject, SalesByGroupCodeListGetAndExportObject, SalesByGroupCodeListObject, CommissionReportDetailsGetAndExportObject, CommissionReportDetailsObject, ExchangeToCreditDetailsGetAndExportObject, ExchangeToCreditDetailsListObject, RefundFromCreditGetAndExportObject, RefundFromCreditListObject, BranchMonthlySalesSummaryGetAndExportObject, BranchMonthlySalesSummaryListObject, SalesVarianceGetAndExportObject, SalesVarianceListObject } from "../models/reportSales";
import { NotificationTemplateAddObject, NotificationTemplateDetailObject, NotificationTemplateListObject, NotificationTemplateUpdateObject } from "../models/notificationTemplate";
import { StockAdjustmentAddObject, StockAdjustmentDetailObject, StockAdjustmentListObject, StockAdjustmentPreloadObject, StockAdjustmentUpdateObject } from "../models/stockAdjustment";
import { StockInAddObject, StockInDetailObject, StockInListObject, StockInUpdateObject } from "../models/stockIn";
import { StockOutAddObject, StockOutDetailObject, StockOutListObject, StockOutUpdateObject } from "../models/stockOut";
import { PaymentMethodAddObject, PaymentMethodDetailObject, PaymentMethodListObject, PaymentMethodUpdateObject } from "../models/paymentMethod";
import { PayslipDetailObject, PayslipListObject, PayslipAddObject } from "../models/payslip";
import { AdditionalFormFieldAddObject, AdditionalFormFieldDetailObject, AdditionalFormFieldListObject, AdditionalFormFieldUpdateObject, AdditionalFormFieldWithFormType } from "../models/additionalFormField";
import { NotificationDetailObject, NotificationListObject } from "../models/notification";
import { StockTakeAddObject, StockTakeDetailObject, StockTakeListObject, StockTakePreloadObject, StockTakeUpdateObject } from "../models/stockTake";
import { TestTimeZoneAddObject } from "../models/testTimeZone";
import { AuditLogDetailObject, AuditLogListObject } from "../models/auditLog";
import { AddCustomerCartObject, CustomerCartAddObject, CustomerCartDetailObject, CustomerCartListObject, CustomerCartUpdateObject } from "../models/customerCart";
import { ChangeLeadStatusRequestBody, LeadExportObject, FollowUpAddObject, FollowUpDetailObject, FollowUpListObject, FollowUpUpdateObject, LeadAddObject, LeadDetailObject, LeadDropdownObject, LeadListObject, LeadUpdateObject, LeadImportObject, LeadImportSuccessObject, LeadDuplicateListObject, LeadDuplicateDetailObject, ReassignLeadObject, LeadAddSuccessObject } from "../models/lead";
import { CustomerSalesOrderDetailObject, CustomerSalesOrderListObject, CustomerSalesOrderObject, CustomerSalesOrderUpgradeSubmitObject, CustomerSalesOrderPaymentObject, CustomerSalesOrderPaymentSuccess, CustomerSalesOrderRedeemListObject, CustomerSalesOrderRedeemSubmitObject, CustomerSalesOrderRedeemSuccessObject, CustomerSalesOrderUpdateObject, CustomerSalesOrderUpgradeList, RefundCreditListObject, RefundCreditObject, CustomerSalesOrderUpgradeResponseObject, CustomerSalesOrderRefundReviewObject, CustomerSalesOrderAddPaymentSuccess, CustomerSalesOrderUpdateConsultantObject, CustomerSalesOrderReceiptObject, CustomerSalesOrderSignObject, CustomerSalesOrderVoidObject, CustomerSalesOrderSiteMapObject, CustomerSalesOrderCommissionObject, CustomerSalesOrderUpdateSubBrandObject, CustomerSalesOrderProductRedemptionListObject } from "../models/customerSalesOrder";
import { AppointmentSettings, AppointmentSubmitObject, GeneralSettings, GeneralSubmitObject, SalesSettings, SalesSubmitObject } from "../models/setting";
import { PromotionAddObject, PromotionDetailObject, PromotionDropdownListObject, PromotionListObject, PromotionListWithCodeObject, PromotionListWithCodeRequestBody, PromotionUpdateObject } from "../models/promotion";
import { CustomerRefundDetailObject, CustomerRefundListObject } from "../models/customerRefund";
import { CreditAdjustmentDetailObject, CreditAdjustmentListObject, CreditAdjustmentPreviewObject, CreditAdjustmentRequestObject, CreditAdjustmentReviewObject } from "../models/creditAdjustment";
import { TransferCreditHistoryListObject, TransferCreditObject } from "../models/transferCredit";
import { EmployeeLeaveAddObject, EmployeeLeaveDeleteWithDateRangeObject, EmployeeLeaveDetailObject, EmployeeLeaveExportObject, EmployeeLeaveImportObject, EmployeeLeaveListObject, EmployeeLeaveUpdateObject, ImportDayOffLeaveConfirmationObject, ImportEmployeeLeaveConfirmationObject } from "../models/employeeLeave";
import { DailySalesDetailListObject, DailySalesDetailsGetObject, DailySalesGetObject, DailySalesSummaryListObject } from "../models/reportSales";
import { AppointmentOverviewSummaryGetObject, AppointmentOverviewSummaryListObject, CustomerTurnedUpListObject, LeadAppointmentsListObject, LeadDetailsListObject, LeadSummaryByEmployeeListGetObject, LeadSummaryByEmployeeListObject, LeadSummaryByMediaListGetObject, LeadSummaryByMediaListObject, LeadTurnUpGetAndExportObject, LeadTurnUpListObject, PromoCodeHistoryListObject, NewVisitVsTurnUpGetAndExportObject, NewVisitVsTurnUpDetailObject } from "../models/reportMarcom";
import { AppointmentDetailsListObject, AppointmentSummaryListBySourceObject, AppointmentSummaryListByStatusObject, AppointmentSummaryListGetObject, RoomOccupancyListGetObject, RoomOccupancyListObject, RoomUtilizationListGetObject, RoomUtilizationListObject } from "../models/reportAppointment";
import { CombinedCollectionSummaryObject, CombinedPaymentCollectionDetailListObject, PaymentCollectionDetailsListObject, PaymentCollectionSummaryListGetObject, PaymentCollectionSummaryListObject, PaymentCollectOnBehalfDetailsListObject, PaymentCollectOnBehalfListGetObject, PaymentCollectOnBehalfListObject } from "../models/reportPayment";
import { CreditHistoryObject, CustomerBirthdayObject, UpsalesSummaryObject, ExpiringPackageObject, PackageUnutilizedBalanceGetObject, PackageUnutilizedBalanceListObject, ProductUnutilizedBalanceListObject, AverageSpendingSummaryGetObject, AverageSpendingSummaryObject, WithoutAppointmentObject, UpsalesSummaryGetObject, WithoutPackageListObject, TopSpenderListObject, CustomerDetailsListObject } from "../models/reportCustomer";
import { StockReceiveAddObject, StockReceiveCompleteObject, StockReceiveDetailObject, StockReceiveListObject, StockReceiveUpdateObject } from "../models/stockReceive";
import { ServicesByEmployeeListGetObject, ServicesByEmployeeListObject } from "../models/reportServices";
import { ChartObject, ChartRequestBodyObject, DailyCustomerBirthdayObject, DailySalesSummaryObject, MonthlyOfTopObject, MonthlyOfTopSalesObject, ReferralSpendingSummary, SalesByEmployeeSummaryObject, SalesPaymentSummaryObject, UpcomingAppointmentObject, WeeklyPendingOrders } from "../models/dashboard";
import { ReservedProductReversalSummaryListObject, ReservedProductSummaryExportObject, ReservedProductSummaryObject, StockCardDetailsListObject, StockForecastSummaryListGetObject, StockForecastSummaryListObject, StockLevelDetailsListObject, StockLevelHistoryListObject, StockReceiveVariantListObject } from "../models/reportStock";
import { AccountPostListObject, CompanyRevenueSummaryListGetObject, CompanyRevenueSummaryListObject, SalesOrderCostListObject } from "../models/reportManagement";
import { CustomerPaymentAdjustmentDetailObject, CustomerPaymentAdjustmentListObject, CustomerPaymentAdjustmentRequestObject, CustomerPaymentAdjustmentReviewObject, CustomerPaymentAdjustmentWithSalesOrderDetailObject, CustomerPaymentAdjustmentWithSalesOrderObject } from "../models/customerPaymentAdjustment";
import { CustomerProductReturnAddObject, CustomerProductReturnDetailObject, CustomerProductReturnEligibleListObject, CustomerProductReturnListObject, CustomerProductReturnUpdateObject } from "../models/customerProductReturn";
import { BranchProductForHQStockReturnObject, StockReturnToHQAddObject, StockReturnToHQDetailObject, StockReturnToHQListObject, StockReturnToHQUpdateObject } from "../models/stockReturnToHQ";
import { ExtendPackageDetailObject, ExtendPackageListObject, ExtendPackageRequestObject, ExtendPackageReviewObject } from "../models/extendPackage";
import { PaymentMethodOptionAddObject, PaymentMethodOptionDetailObject, PaymentMethodOptionListObject, PaymentMethodOptionUpdateObject } from "../models/paymentMethodOption";
import { SalesAdjustmentAddObject, SalesAdjustmentDetailObject, SalesAdjustmentListObject, SalesAdjustmentUpdateObject } from "../models/salesAdjustment";
import { CommissionSchemeAddObject, CommissionSchemeDetailObject, CommissionSchemeListObject, CommissionSchemePackageListObject, CommissionSchemeProductListObject, CommissionSchemeUpdateObject } from "../models/commissionScheme";
import { CustomerAccessRequestAddObject, CustomerAccessRequestDetailObject, CustomerAccessRequestExtendObject, CustomerAccessRequestListObject, CustomerAccessRequestUpdateObject } from "../models/customerAccessRequest";
import { boolean } from "yup";
import { PackageMasterDataListObject, ProductMasterDataListObject, ServiceTreatmentMasterDataListObject } from "../models/reportMasterData";
import { CustomerTransferRequestAddObject, CustomerTransferRequestDetailObject, CustomerTransferRequestListObject, CustomerTransferRequestUpdateObject } from "../models/customerTransferRequest";
import { RoutesList } from "../constants/RoutesList";
import { AdditionBonusSalesTargetAddObject, AdditionBonusSalesTargetDetailObject, AdditionBonusSalesTargetDuplicatedRequestObject, AdditionBonusSalesTargetListObject, AdditionBonusSalesTargetUpdateObject } from "../models/additionBonusSalesTarget";
import { BranchCommissionAddObject, BranchCommissionDetailObject, BranchCommissionListObject, BranchCommissionUpdateObject } from "../models/branchCommission";
import { DayOffListObject, OutletScheduleListObject } from "../models/reportHumanResource";
import { CustomerVirtualWarehouseDetailObject, CustomerVirtualWarehouseListObject, CustomerVirtualWarehouseMovementListObject } from "../models/customerVirtualWarehouse";
import { ProductRedemptionEligibleListObject, ProductRedemptionNoteAddObject, ProductRedemptionNoteAddResponseObject, ProductRedemptionNoteDetailObject, ProductRedemptionNoteListObject, ProductRedemptionNoteSignObject, ProductRedemptionNoteSignSuccessObject, ProductRedemptionNoteVoidObject } from "../models/productRedemptionNote";
import { ContractLiabilityDetailObject, ContractLiabilitySiteMapObject, ContractLiabilityTimelineObject } from "../models/contractLiability";
import { VirtualWarehouseCreditExchangeDetailObject, VirtualWarehouseCreditExchangeListObject, VirtualWarehouseCreditExchangeRequestObject, VirtualWarehouseCreditExchangeReviewObject } from "../models/virtualWarehouseCreditExchange";
import { ProductRedemptionNoteReturnAddObject, ProductRedemptionNoteReturnDetailObject, ProductRedemptionNoteReturnEligibleListObject, ProductRedemptionNoteReturnListObject, ProductRedemptionNoteReturnReviewObject } from "../models/productRedemptionNoteReturn";
import { Constants } from "../constants/Constants";
import { BatchJobAddObject, BatchJobDetailObject, BatchJobHistoryDetailObject, BatchJobHistoryListObject, BatchJobListObject, BatchJobUpdateObject } from "../models/batchJob";
import { DSRAdjustmentAddObject, DSRAdjustmentListObject } from "../models/dsrAdjustment";
import { CustomerSubBranchRequestAddObject, CustomerSubBranchRequestDetailObject, CustomerSubBranchRequestListObject, CustomerSubBranchRequestReviewObject, CustomerSubBranchRequestUpdateObject } from "../models/customerSubBranchRequest";
import { OnlyIdResponse } from "../models/common";
import { AppolousCustomerPackageAddRequestBody, AppolousCustomerPackageDetailObject, AppolousCustomerPackageDetailRequestBody, AppolousCustomerPackageEditRequestBody, AppolousCustomerPackageExtendExpiryRequestBody, AppolousCustomerPackageListObject, AppolousCustomerPackageRedeemRequestBody, AppolousCustomerPackageRedemptionEditRequestBody, AppolousCustomerPackageRedemptionObject, AppolousCustomerPackageStatusUpdateRequestBody } from "../models/appolousCustomerPackage";
import { AppolousCustomerProductAddRequestBody, AppolousCustomerProductDetailObject, AppolousCustomerProductListObject, AppolousCustomerProductRedeemRequestBody, AppolousCustomerProductStatusUpdateRequestBody } from "../models/appolousCustomerProduct";
import { AppolousCustomerSaleListObject, AppolousCustomerSaleDetailsObject, AppolousSalesAddPaymentObject, AppolousSalesUpdatePaymentObject } from "../models/appolousCustomerSales";
import { AppolousDynamicSalesListObject, AppolousFreePackageAndProductListObject, AppolousPaymentCollectionSummaryListGetObject, AppolousPaymentCollectionSummaryObject, AppolousProductCommissionVariantListObject } from "../models/reportAppolous";
import { VirtualWarehouseStockMovementListObject } from "../models/virtualWarehouseStockMovement";
import { AddAppointmentTargetObject, AppointmentTargetAddObject, AppointmentTargetDetailObject, AppointmentTargetDuplicatedRequestObject, AppointmentTargetListObject, AppointmentTargetUpdateObject } from "../models/appointmentTarget";
import { MarcomDailyRecordAddObject, MarcomDailyRecordListObject } from "../models/marcomDailyRecord";
import { DepartmentAddObject, DepartmentDetailObject, DepartmentDropdownListObject, DepartmentListObject, DepartmentUpdateObject } from "../models/department";
import { ChangePaymentMethodAddOrUpdateResponseObject, CustomerChangePaymentMethodDetailObject, CustomerChangePaymentMethodListObject, CustomerChangePaymentMethodRequestObject, CustomerChangePaymentMethodReviewObject, CustomerChangePaymentMethodWithSalesOrderDetailObject, CustomerChangePaymentMethodWithSalesOrderObject } from "../models/customerChangePaymentMethod";
import { AppointmentLeadDetailObject, AppointmentLeadFollowUpAddObject, AppointmentLeadFollowUpDetailObject, AppointmentLeadFollowUpListObject, AppointmentLeadFollowUpUpdateObject, AppointmentLeadListObject } from "../models/appointmentLead";
import { BranchMachineAddObject, BranchMachineListObject, BranchMachineListRequestObject, BranchMachineUpdateObject, MachineAddObject, MachineDetailObject, MachineDropdownListObject, MachineListObject, MachineUpdateObject, MachineUsageListObject, MachineUsageListRequestObject } from "../models/machine";
import { ContractLiabilityBenchmarkAddObject, ContractLiabilityBenchmarkDeleteObject, ContractLiabilityBenchmarkDetailObject, ContractLiabilityBenchmarkListObject, ContractLiabilityBenchmarkUpdateObject } from "../models/contractLiabilityBenchmark";
import { GroupCodeAddObject, GroupCodeDetailObject, GroupCodeGetObject, GroupCodeListObject, GroupCodeUpdateObject } from "../models/groupCode";
import { CommissionCalculationListGetAndExportObject, CommissionCalculationListObject, EarnSalesSummaryListGetAndExportObject, EarnSalesSummaryListObject, ContractLiabilityReportListGetAndExportObject, ContractLiabilityReportListObject, UnearnedSalesDetailsListGetAndExportObject, UnearnedSalesDetailsListObject, RecogniseSalesDetailsListGetAndExportObject, RecogniseSalesDetailsListObject, PaymentCollectionObject, CommissionCalculationObject, ChangePaymentModeListGetAndExportObject, ChangePaymentModeReportListObject, PaymentCollectionDetailListObject } from "../models/reportAccount";
import { TncAddObject, TncCategoryObject, TncDetailObject, TncListObject, TncUpdateObject } from "../models/tnc";
import { UnknownPaymentAddObject, UnknownPaymentDetailObject, UnknownPaymentListObject, UnknownPaymentUpdateObject } from "../models/unknownPayment";
import { CashOutAddObject, CashOutDetailObject, CashOutListObject, CashOutUpdateObject } from "../models/cashOut";
import { PaymentTerminalAddObject, PaymentTerminalDetailObject, PaymentTerminalListObject, PaymentTerminalUpdateObject } from "../models/paymentTerminal";
import { ChangePaymentModeAddObject, ChangePaymentModeCancelObject, ChangePaymentModeDetailObject, ChangePaymentModeListObject, ChangePaymentModeOldPaymentListObject, ChangePaymentModePaymentMethodListObject, ChangePaymentModeReviewFinalApprovalObject, ChangePaymentModeReviewFirstApprovalObject, ChangePaymentModeSignObject, ChangePaymentModeUpdateAfterCompletedObject, ChangePaymentModeUpdateObject } from "../models/changePaymentMode";
import { CloseCounterAddObject, CloseCounterDetailObject, CloseCounterListObject, CloseCounterPendingObject, CloseCounterUpdateObject, OverrideCloseCounterObject } from "../models/closeCounter";

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    // Comment it because it's replacing empty error message when calling multiple api even it hit error
    // store.commonStore.setErrorMessage(""); 
    // store.commonStore.setSuccessMessage("");
    // const token = store.commonStore.token;
    const token = getJWT();
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    return config;
})

axios.interceptors.response.use(async response => {
    //if(process.env.NODE_ENV === 'development') await sleep(2000);
    const pagination = response.headers['pagination'];

    if(pagination){
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    return response;

}, async (error: AxiosError) => {
    // if(process.env.NODE_ENV === 'development') await sleep(2000);
    try {
      if (error.message) {
        if (error.message === "canceled") {
          throw {message: ""};
        }
      }

      const { data, status, config, headers } = error.response!;
      switch (status) {
          case 400:
              if (typeof data === 'string') {
                store.commonStore.setErrorMessage(data);
                // toast.error(data);
              }

              if (config.method === 'get' && data.errors?.hasOwnProperty('id')) {
                //invalid id
                history.push(`/${RoutesList.notFound}`);
              }

              if (data.errors) {
                const modalStateErrors : any = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key]);
                    }
                }
                throw modalStateErrors.flat();
              }

              if (data.errorCode) {
                if (data.errorCode === 'Appointment-011' || data.errorCode === 'Appointment-019') {
                  throw data;
                }
                else if (blnValidErrorMessage(data.errorCode)) {
                  if (data.errorCode === 'StockRequest-012' || data.errorCode === 'StockRequest-007') {
                    if (data.errorMessage) {
                      let errorMessageTemp = data.errorMessage.replace(Constants.StockRequestProductNotAvailableMessage, "");
                      data.errorMessage = `${Constants.StockRequestProductNotAvailableMessage} ${truncateText(errorMessageTemp, 150)}`;
                    }
                  }
                  else if (data.errorCode === 'Customer-008') {
                    history.push(`/${RoutesList.customerProfileAccessDenied}`);
                    throw {message: ""};
                  }
                  else if (data.errorCode === 'CustomerSalesOrder-044') {
                    store.commonStore.setDisplayPermissionDenied(true);
                    throw {message: ""};
                  }
                  else if (data.errorCode === 'CustomerAccessRequest-009') {
                    data.errorMessage = `${getBranchName()} already has pending access request for the customer`;
                  }
                  throw {message : data.errorMessage};
                }
                throw {message : data.errorCode};
              }
              break;
          case 401:
              // if(status === 401 && headers['www-authenticate']){
              if(status === 401){
                store.userStore.setUrlPathAfter401(window.location.pathname);
                store.userStore.logout(true, false);
                // toast.error('Session expired -  please login again');
              }
              break;
          case 403: //Permission denied from API
            if (window.location.pathname.includes(`/${RoutesList.customerSalesOrder}/view`) || window.location.pathname.includes(`/${RoutesList.customerRedemption}/view`) || window.location.pathname.includes(`/${RoutesList.customerRedemption}/edit`)) {
              throw {message: ""};
            }
            store.commonStore.setDisplayPermissionDenied(true);
            break;
          case 404: //id format incorrect will casued not found
              history.push(`/${RoutesList.notFound}`)
              break;
          case 500:
              store.commonStore.setServerError(data);
              history.push(`/${RoutesList.serverError}`);
              break;
          case 502:
              store.commonStore.setServerError(data);
              history.push(`/${RoutesList.underMaintenance}`);
              break;
      }
    }
    catch (e) {
      if (String(e).includes("error.response")) {
        store.userStore.logout(true, false);
      }
      else {
        return Promise.reject(e)
      }
    }

    return Promise.reject(error);
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string, axiosSignal?: any) => axios.get<T>(url, {...axiosSignal && { signal: axiosSignal}}).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, removeAllEmptyDataFromObject(body)).then(responseBody),
    postWithoutRemoveObject: <T>(url: string, body: {}) => axios.post<T>(url, (body)).then(responseBody),
    postWithoutRemoveObjectWithAxiosSignal: <T>(url: string, body: {}, axiosSignal?: any) => axios.post<T>(url, (body), {...axiosSignal && { signal: axiosSignal}}).then(responseBody),
    postWithFormData: <T>(url: string, body: any) => axios.post<T>(url, body, {headers: {'Content-type': 'multipart/form-data'}}).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(urlHandleWithWorflowAction(url, body), removeAllEmptyDataFromObject(body)).then(responseBody),
    putWithFormData: <T>(url: string, body: any) => axios.put<T>(url, body, {headers: {'Content-type': 'multipart/form-data'}}).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    delWithRequestBody: <T>(url: string, body: {}) => axios.delete<T>(url, {data: body}).then(responseBody)
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<BrandListObject[]>('/account/login', user),
    brandLogin: (user: UserFormValues) => requests.post<User>('/account/brandlogin', user),
    register: (user: UserFormValues) => requests.post<User>('account/register', user),
    refreshToken: () => requests.post<User>('/account/refreshToken',{}),
    logout: () => requests.post<{}>('/account/logout', {}),
    forgetPasswordRequest: (forgetPasswordRequestBody: ForgetPasswordRequestFormValues) => requests.post('/account/forgetpassword/request', forgetPasswordRequestBody),
    forgetPassword: (forgetPasswordRequestBody: ForgetPasswordFormValues) => requests.put('/account/forgetpassword', forgetPasswordRequestBody),
    userDropdownList: (employeeType?: number) => requests.get<UserDropdownListObject[]>(`/user/dropdown${employeeType || employeeType === 0 ? `?employeeType=${employeeType}` : ""}`),
    switchBrand: (brandId: string) => requests.get<User>(`/account/switchbrand/${brandId}`)
}

const Branch = {
    branchList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<BranchListObject[]>>(`/branch?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&businessEntityId=${pagination.businessEntityId || ""}&name=${pagination.branchName || ""}`),
    branchDropdown: (branchDropdownRequestObject: BranchDropdownGetObject, blnFilterBranchAccess: boolean) => requests.get<BranchObject>(`/branch/dropdown?businessEntityId=${branchDropdownRequestObject.businessEntityId || ""}&isFilterBranchAccess=${blnFilterBranchAccess}${branchDropdownRequestObject.isHQ !== undefined ? `&isHQ=${branchDropdownRequestObject.isHQ}` : ""}`),
    branchDetail: (id: string) => requests.get<BranchDetailObject>(`/branch/${id}`),
    addBranch: (branchRequestBody: BranchAddObject) => requests.post(`/branch`, branchRequestBody),
    updateBranch: (branchRequestBody: BranchUpdateObject) => requests.put(`/branch`, branchRequestBody),
    deleteBranch: (id: string) => requests.del<{}>(`/branch/${id}`),
    branchBusinessHour: (branchId: string) => requests.get<BusinessHourForBranchObject[]>(`/branch/businesshour/${branchId}`),
    branchPaymentMethod: (branchId: string) => requests.get<PaymentMethodForBranchObject[]>(`/branch/paymentMethod/${branchId}`),
    branchProductList: (branchId: string) => requests.get<ProductForBranchObject[]>(`/branch/products/${branchId}`),
    branchProductBatch: (branchProductBatchRequestBody: BranchProductBatchGetObject) => requests.get<BranchProductBatchListObject[]>(`/branch/product/batches?branchId=${branchProductBatchRequestBody.branchId}&productId=${branchProductBatchRequestBody.productId}&isRequiredCheckQuantity=${branchProductBatchRequestBody.isRequiredCheckQuantity}`),
    branchProductBatchLegacy: (branchProductBatchLegacyRequestBody: BranchProductBatchGetObject) => requests.get<BranchProductBatchListObject[]>(`/branch/product/batches/legacy?branchId=${branchProductBatchLegacyRequestBody.branchId}&productId=${branchProductBatchLegacyRequestBody.productId}&isRequiredCheckQuantity=${branchProductBatchLegacyRequestBody.isRequiredCheckQuantity}`),
  }

const Brand = {
    brandList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<BrandListObject[]>>(`/brand?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&name=${pagination.brandName || ""}`),
    brandDropdownList: () => requests.get<BrandListObject[]>(`/brand/dropdown`),
    brandDetail: (id: string) => requests.get<BrandDetailObject>(`/brand/${id}`),
    addBrand: (brandRequestBody: BrandAddObject) => {
        let formData = new FormData();
        convertJSONIntoFormData(brandRequestBody, formData);
        return requests.postWithFormData(`/brand`, formData)
    },
    updateBrand: (brandRequestBody: BrandUpdateObject) => {
        let formData = new FormData();
        convertJSONIntoFormData(brandRequestBody, formData);
        return requests.putWithFormData(`/brand`, formData)
    },
    deleteBrand: (id: string) => requests.del<{}>(`/brand/${id}`),
    subBrandDropdownList: () => requests.get<SubBrandObject[]>(`/brand/subbrands/dropdown`)
}

const BusinessEntity = {
    businessEntityList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<BusinessEntityListObject[]>>(`/businessentity?${convertRequestBodyObject(pagination)}`),
    businessEntityDropdown: (brandId: string) => requests.get<BusinessEntityListObject[]>(`/businessentity/dropdown?brandId=${brandId || ""}`),
    businessEntityDetail: (id: string) => requests.get<BusinessEntityDetailObject>(`/businessentity/${id}`),
    addBusinessEntity: (businessEntityRequestBody: BusinessEntityAddObject) => requests.post(`/businessentity`, businessEntityRequestBody),
    updateBusinessEntity: (businessEntityRequestBody: BusinessEntityUpdateObject) => requests.put(`/businessentity`, businessEntityRequestBody),
    deleteBusinessEntity: (id: string) => requests.del<{}>(`/businessentity/${id}`),
}

const Product = {
  productList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ProductListObject[]>>(`/product?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&productType=${pagination.productType || pagination.productType === 0 ? pagination.productType : ""}&name=${pagination.productName || ""}&sKU=${pagination.sKU || ""}${pagination.branchId ? `&branchId=${pagination.branchId}` : ""}&isActive=${typeof(pagination.isActive) === 'boolean' ? pagination.isActive : ""}&categoryId=${pagination.categoryId}&salesCategoryId=${pagination.salesCategoryId}`),
  productDropdownList: (productDropdownRequestBody: ProductDropdownRequestBody) => requests.get<ProductListObject[]>(`/product/dropdown?productType=${productDropdownRequestBody.productType || productDropdownRequestBody.productType === 0 ? productDropdownRequestBody.productType : ""}&branchId=${productDropdownRequestBody.branchId || ""}${productDropdownRequestBody.isActive ? "&isActive=true": ""}`),
  productDetail: (id: string) => requests.get<ProductDetailObject>(`/product/${id}`),
  addProduct: (productRequestBody: ProductAddObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(productRequestBody, formData);
    return requests.postWithFormData(`/product`, formData)
  },
  updateProduct: (productRequestBody: ProductUpdateObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(productRequestBody, formData);
    return requests.putWithFormData(`/product`, formData)
  },
  deleteProduct: (id: string) => requests.del<{}>(`/product/${id}`),
  productBrandAccess: () => requests.get<BranchForProductObject[]>(`/product/branchaccess`),
  exportProduct: (exportProductRequestBody: ProductExportObject) => requests.post(`/product/export`, exportProductRequestBody),
  importProduct: (productImportRequestBody: ProductImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(productImportRequestBody, formData);
    return requests.postWithFormData(`/product/import`, formData)
  },
  stockOutProduct: (productStockOutRequestBody: ProductStockOutObject) => requests.post(`/product/stockout`, productStockOutRequestBody), 
  importProductTemporally: (productImportRequestBody: ProductImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(productImportRequestBody, formData);
    return requests.postWithFormData(`/stockbalance/import`, formData)
  },
  updateServiceConsumeQuantity: (updateServiceConsumeQuantityRequestBody: UpdateServiceConsumeQuantityObject) => requests.post(`/product/consumequantity`, updateServiceConsumeQuantityRequestBody)
}

const ServiceTreatment = {
  serviceTreatmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ServiceTreatmentListObject[]>>(`/servicetreatment?${convertRequestBodyObject(pagination)}`),
  serviceTreatmentDropdownList: (serviceTreatmentDropdownRequestBody: ServiceTreatmentDropdownRequestBody) => requests.get<ServiceTreatmentListObject[]>(`/servicetreatment/dropdown?branchId=${serviceTreatmentDropdownRequestBody.branchId || ""}&isActive=${typeof(serviceTreatmentDropdownRequestBody.isActive) === 'boolean' ? serviceTreatmentDropdownRequestBody.isActive : ""}`),
  serviceTreatmentDetail: (id: string) => requests.get<ServiceTreatmentDetailObject>(`/servicetreatment/${id}`),
  addServiceTreatment: (serviceTreatmentRequestBody: ServiceTreatmentAddObject) => requests.post(`/servicetreatment`, serviceTreatmentRequestBody),
  updateServiceTreatment: (serviceTreatmentRequestBody: ServiceTreatmentUpdateObject) => requests.put(`/servicetreatment`, serviceTreatmentRequestBody),
  deleteServiceTreatment: (id: string) => requests.del<{}>(`/servicetreatment/${id}`),
  serviceTreatmentBrandAccess: () => requests.get<BranchForServiceTreatmentObject[]>(`/servicetreatment/branchaccess`),
  serviceTreatmentBranchMachineActive: (serviceMachineActiveRequestObject: ServiceMachineActiveRequestObject) => requests.get<ServiceMachineActiveObject[]>(`/servicetreatment/branchmachine/active?branchId=${serviceMachineActiveRequestObject.branchId}&serviceId=${serviceMachineActiveRequestObject.serviceTreatmentId}`),
  importServiceTreatment: (serviceTreatmentImportRequestBody: ServiceTreatmentImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(serviceTreatmentImportRequestBody, formData);
    return requests.postWithFormData<ServiceTreatmentImportFailedDetailObject[]>(`/servicetreatment/import`, formData)
  },
}

const Package = {
  packageList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PackageListObject[]>>(`/package?${convertRequestBodyObject(pagination)}`),
  packageDropdownList: (packageDropDownRequestBody: PackageDropdownRequestBody) => requests.get<PackageListObject[]>(`/package/dropdown?branchId=${packageDropDownRequestBody.branchId || ""}&isActive=${typeof(packageDropDownRequestBody.isActive) === 'boolean' ? packageDropDownRequestBody.isActive : ""}`),
  packageDetail: (id: string) => requests.get<PackageDetailObject>(`/package/${id}`),
  addPackage: (packageRequestBody: PackageAddObject) => requests.post(`/package`, packageRequestBody),
  updatePackage: (packageRequestBody: PackageUpdateObject) => requests.put(`/package`, packageRequestBody),
  deletePackage: (id: string) => requests.del<{}>(`/package/${id}`),
  packageBrandAccess: () => requests.get<BranchForPackageObject[]>(`/package/branchaccess`),
  importPackage: (packageImportRequestBody: PackageImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(packageImportRequestBody, formData);
    return requests.postWithFormData<PackageImportFailedDetailObject[]>(`/package/import`, formData)
  },
  packageWithGroupCode: (packageWithGroupCodeRequestObject: PackageWithGroupCodeRequestObject) => requests.get<PackageWithGroupCodeListObject[]>(`/package/groupcode?branchId=${packageWithGroupCodeRequestObject.branchId}&groupCodeId=${packageWithGroupCodeRequestObject.groupCodeId || ""}&serviceTreatmentId=${packageWithGroupCodeRequestObject.serviceTreatmentId || ""}`)
}

const Employee = {
  employeeList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<EmployeeListObject[]>>(`/employee?${convertRequestBodyObject(pagination)}`),
  employeeDropdownList: (employeeDropdownObject: EmployeeDropdownRequestBody) => requests.get<EmployeeListObject[]>(`/employee/dropdown?branchId=${employeeDropdownObject.branchId || ""}&isBranchStaff=${typeof(employeeDropdownObject.isBranchStaff) === 'boolean' ? employeeDropdownObject.isBranchStaff : ""}&userRoleIds=${employeeDropdownObject.userRoleIds || ""}&brandId=${employeeDropdownObject.brandId || ""}&isActive=${typeof(employeeDropdownObject.isActive) === 'boolean' ? employeeDropdownObject.isActive : ""}&departmentId=${employeeDropdownObject.departmentId || ""}`),
  employeeDropdownOnlyHQList: (employeeDropdownObject: EmployeeDropdownRequestBody) => requests.get<EmployeeListObject[]>(`/employee/hq/dropdown?userRoleIds=${employeeDropdownObject.userRoleIds || ""}&isActive=${typeof(employeeDropdownObject.isActive) === 'boolean' ? employeeDropdownObject.isActive : ""}`),
  employeeDropdownForConsultantSalesList: (employeeDropdownForConsultantSalesRequestBody: EmployeeDropdownForConsultantSalesRequestBody) => requests.get<EmployeeListObject[]>(`/employee/consultantsales/dropdown?${convertRequestBodyObject(employeeDropdownForConsultantSalesRequestBody)}`),
  employeeDetail: (id: string) => requests.get<EmployeeDetailObject>(`/employee/${id}`),
  addEmployee: (employeeRequestBody: EmployeeAddObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(employeeRequestBody, formData);
    return requests.postWithFormData(`/employee`, formData)
  },
  updateEmployee: (employeeRequestBody: EmployeeUpdateObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(employeeRequestBody, formData);
    return requests.putWithFormData(`/employee`, formData)
  },
  deleteEmployee: (id: string) => requests.del<{}>(`/employee/${id}`),
  accessTree: () => requests.get<AccessTreeObject>(`/employee/accesstree`),
  employeeForCustomerInfo: (identityNo: string, employeeNo: string) => requests.get<EmployeeAdvanceSearchResponseObject>(`/employee/customer?identityNo=${identityNo}&employeeNo=${employeeNo}`),
  exportEmployee: (exportEmployeeRequestBody: EmployeeExportObject) => requests.post(`/employee/export`, exportEmployeeRequestBody),
  importEmployee: (employeeImportRequestBody: EmployeeImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(employeeImportRequestBody, formData);
    return requests.postWithFormData<EmployeeImportSuccessObject>(`/employee/import`, formData)
  },
  importEmployeeConfirmation: (employeeImportRequestBody: EmployeeImportSuccessObject) => requests.post(`/employee/import/confirm`, employeeImportRequestBody),
  reassignCustomer: (reassignCustomerRequestBody: ReassignCustomerObject) => requests.put(`/employee/reassign/customer`, reassignCustomerRequestBody),
  addEmployeePositionMovementHistory: (addEmployeePositionMovementHistoryRequestBody: AddEmployeePositionMovementHistoryObject) => requests.post(`/employee/positionmovementhistory`, addEmployeePositionMovementHistoryRequestBody),
  updateEmployeePositionMovementHistory: (updateEmployeePositionMovementHistoryRequestBody: UpdateEmployeePositionMovementHistoryObject) => requests.put(`/employee/positionmovementhistory`, updateEmployeePositionMovementHistoryRequestBody),
  deleteEmployeePositionMovementHistory: (id: string, employeeId: string) => requests.del<{}>(`/employee/positionmovementhistory?id=${id}&employeeId=${employeeId}`),
  addEmployeeBranchTransferHistory: (addEmployeeBranchTransferHistoryRequestBody: AddEmployeeBranchTransferHistoryObject) => requests.post(`/employee/branchtransferhistory`, addEmployeeBranchTransferHistoryRequestBody),
  updateEmployeeBranchTransferHistory: (updateEmployeeBranchTransferHistoryRequestBody: UpdateEmployeeBranchTransferHistoryObject) => requests.put(`/employee/branchtransferhistory`, updateEmployeeBranchTransferHistoryRequestBody),
  deleteEmployeeBranchTransferHistory: (id: string, employeeId: string) => requests.del<{}>(`/employee/branchtransferhistory?id=${id}&employeeId=${employeeId}`),
  exportEmployeeNameUpdate: () => requests.post(`/employee/name/export`, {}),
  importEmployeeNameUpdate: (employeeImportRequestBody: EmployeeImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(employeeImportRequestBody, formData);
    return requests.postWithFormData<EmployeeNameUpdateImportSuccessObject>(`/employee/name/import`, formData)
  },
  resetEmployeePassword: (employeeResetPassword: EmployeeResetPasswordObject) => requests.post<EmployeeResetPasswordSuccessObject>(`/employee/reset`, employeeResetPassword),
  importEmployeeDepartment: (employeeImportRequestBody: EmployeeImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(employeeImportRequestBody, formData);
    return requests.postWithFormData<EmployeeNameUpdateImportSuccessObject>(`/employee/department/import`, formData)
  },
  resetEmployeePasscode: (employeeResetPasscode: EmployeeResetPasscodeObject) => requests.post<EmployeeResetPasscodeSuccessObject>(`/employee/reset/passcode`, employeeResetPasscode)
}

const Currency = {
  currencyList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CurrencyListObject[]>>(`/currency?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  currencyDropdownList: () => requests.get<CurrencyListObject[]>(`/currency/dropdown`),
  currencyDetail: (id: string) => requests.get<CurrencyDetailObject>(`/currency/${id}`),
  addCurrency: (currencyRequestBody: CurrencyAddObject) => requests.post(`/currency`, currencyRequestBody),
  updateCurrency: (currencyRequestBody: CurrencyUpdateObject) => requests.put(`/currency`, currencyRequestBody),
  deleteCurrency: (id: string) => requests.del<{}>(`/currency/${id}`)
}

const Country = {
  countryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CountryListObject[]>>(`/country?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  countryDropdownList: () => requests.get<CountryListObject[]>(`/country/dropdown`),
  countryDetail: (id: string) => requests.get<CountryDetailObject>(`/country/${id}`),
  addCountry: (countryRequestBody: CountryAddObject) => requests.post(`/country`, countryRequestBody),
  updateCountry: (countryRequestBody: CountryUpdateObject) => requests.put(`/country`, countryRequestBody),
  deleteCountry: (id: string) => requests.del<{}>(`/country/${id}`),
  stateList: (id: string) => requests.get<StateDetailObject[]>(`/country/state/${id}`),
}

const Customer = {
  customerList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerListObject[]>>(`/customer?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&searchType=${pagination.searchType || pagination.searchType === 0 ? pagination.searchType : ""}&searchValue=${pagination.searchValue || ""}&customerNoOrName=${pagination.customerNoOrName || ""}&mobileNo=${pagination.customerMobileNo || ""}&email=${pagination.customerEmail || ""}&identityNo=${pagination.identityNo || ""}&consultantId=${pagination.consultantId || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&gender=${pagination.gender || pagination.gender === 0 ? pagination.gender : ""}`),
  customerSearchAllList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerListObject[]>>(`/customer/searchall?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&searchType=${pagination.searchType || pagination.searchType === 0 ? pagination.searchType : ""}&searchValue=${pagination.searchValue || ""}&customerNoOrName=${pagination.customerNoOrName || ""}&mobileNo=${pagination.customerMobileNo || ""}&email=${pagination.customerEmail || ""}&identityNo=${pagination.identityNo || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&gender=${pagination.gender || pagination.gender === 0 ? pagination.gender : ""}`),
  customerDropdownList: (branchId: string, customerDropdownRequestObject: CustomerDropdownRequestObject | undefined, axiosSignal: any) => requests.get<CustomerListObject[]>(`/customer/dropdown?branchId=${branchId}&searchValue=${customerDropdownRequestObject?.customerNoOrName || ""}&customerId=${customerDropdownRequestObject?.customerId || ""}`, axiosSignal),
  customerDetail: (id: string) => requests.get<CustomerDetailObject>(`/customer/${id}`),
  customerDetailWithAppointmentId: (appointmentId: string) => requests.get<CustomerDetailObject>(`/customer/appointment/${appointmentId}`),
  addCustomer: (customerRequestBody: CustomerAddObject) => requests.post<CustomerAddResponseObject>(`/customer`, customerRequestBody),
  updateCustomer: (customerRequestBody: CustomerUpdateObject) => requests.put(`/customer`, customerRequestBody),
  deleteCustomer: (id: string) => requests.del<{}>(`/customer/${id}`),
  customerExpiredPackage: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerExpiredPackageObject[]>>(`/customer/expiredpackage?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  customerNoteList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerNoteListObject[]>>(`/customer/note?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  customerNoteDetail: (id: string) => requests.get<CustomerNoteDetailObject>(`/customer/note/${id}`),
  addCustomerNote: (customerNoteRequestBody: CustomerNoteAddObject) => requests.post(`/customer/note`, customerNoteRequestBody),
  updateCustomerNote: (customerNoteRequestBody: CustomerNoteUpdateObject) => requests.put(`/customer/note`, customerNoteRequestBody),
  deleteCustomerNote: (id: string) => requests.del<{}>(`/customer/note/${id}`),
  customerRedeemHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerRedeemHistoryListObject[]>>(`/customer/redeemhistory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  customerCreditHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerCreditHistoryListObject[]>>(`/customer/credithistory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  customerValidation: (customerValidationRequestBody: CustomerValidationRequestBody) => requests.get<CustomerValidationResponseObject[]>(`/customer/validate?validateType=${customerValidationRequestBody.validateType}&validateValue=${customerValidationRequestBody.validateValue}&currentBranchId=${customerValidationRequestBody.currentBranchId}`),
  customerRequestAcessList: (id: string) => requests.get<CustomerRequestAccessListObject[]>(`/customer/requestaccess?requestBranchId=${id}`),
  customerRequestOtherBranch: (id: string) => requests.get<RequestOtherBranchObject>(`/customer/requestotherbranch?requestBranchId=${id}`),
  customerPackageRedeemHistory: (id: string) => requests.get<CustomerPackageRedeemHistoryObject>(`/customer/packageredeemhistory/${id}`),
  customerServiceRedeemHistory: (id: string) => requests.get<CustomerServiceRedeemHistoryObject>(`/customer/serviceredeemhistory/${id}`),
  customerSubBranchHistory: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerSubBranchHistoryObject[]>>(`/customer/subbranchhistory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  customerPurchasedPackage: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PurchasePackageObject[]>>(`/customer/purchasedpackage?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}&showCompleted=${pagination.showCompleted}`),
  customerPurchasedProduct: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PurchaseProductObject[]>>(`/customer/purchasedproduct?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}&showCompleted=${pagination.showCompleted}`),
  customerReservedProduct: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ReservedProductObject[]>>(`/customer/reservedproduct?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  customerPurchasedService: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PurchaseServiceObject[]>>(`/customer/purchasedservice?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}&showCompleted=${pagination.showCompleted}`),
  customerClosePurchasedHistory: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ClosedPurchaseHistoryObject[]>>(`/customer/closepurchasedhistory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  customerSalesOrder: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SalesOrderObject[]>>(`/customer/salesorder?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  customerTransferHistory: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<TransferHistoryObject[]>>(`/customer/transferhistory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  importCustomer: (customerImportRequestBody: CustomerImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(customerImportRequestBody, formData);
    return requests.postWithFormData<CustomerImportFailedDetailObject[]>(`/customer/import`, formData)
  },
  customerCriteriaSearch: (customerCriteriaSearchObject: CustomerCriteriaSearchGetObject) => requests.get<CustomerCriteriaSearchResponseObject>(`/customer/search?${convertRequestBodyObject(customerCriteriaSearchObject)}`),
  updateCustomerJoinedDate: (customerJoinedDateRequestBody: UpdateCustomerJoinedDateObject) => requests.put(`/customer/joineddate`, customerJoinedDateRequestBody),
  updateCustomerMediaSource: (customerMediaSourceRequestBody: UpdateCustomerMediaSourceObject) => requests.put(`/customer/mediasource`, customerMediaSourceRequestBody),
  addCustomerTag: (addCustomerTagRequestBody: AddCustomerTagObject) => requests.post(`/customer/tag`, addCustomerTagRequestBody),
  deleteCustomerTag: (id: string) => requests.del<{}>(`/customer/tag/${id}`),
  addCustomerRelationship: (addCustomerRelationshipRequestBody: AddCustomerRelationshipObject) => requests.post(`/customer/relationship`, addCustomerRelationshipRequestBody),
  deleteCustomerRelationship: (id: string) => requests.del<{}>(`/customer/relationship/${id}`),
  customerAvailablePayment: (customerId: string) => requests.get<CustomerAvailablePaymentObject>(`/customer/availablepayment/${customerId}`),
}

const Category = {
  categoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CategoryListObject[]>>(`/category?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  categoryDropdownList: () => requests.get<CategoryListObject[]>(`/category/dropdown`),
  categoryDetail: (id: string) => requests.get<CategoryDetailObject>(`/category/${id}`),
  addCategory: (categoryRequestBody: CategoryAddObject) => requests.post(`/category`, categoryRequestBody),
  updateCategory: (categoryRequestBody: CategoryUpdateObject) => requests.put(`/category`, categoryRequestBody),
  deleteCategory: (id: string) => requests.del<{}>(`/category/${id}`)
}

const SalesCategory = {
  salesCategoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SalesCategoryListObject[]>>(`/salescategory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  salesCategoryDropdownList: () => requests.get<SalesCategoryListObject[]>(`/salescategory/dropdown`),
  salesCategoryDetail: (id: string) => requests.get<SalesCategoryDetailObject>(`/salescategory/${id}`),
  addSalesCategory: (salesCategoryRequestBody: SalesCategoryAddObject) => requests.post(`/salescategory`, salesCategoryRequestBody),
  updateSalesCategory: (salesCategoryRequestBody: SalesCategoryUpdateObject) => requests.put(`/salescategory`, salesCategoryRequestBody),
  deleteSalesCategory: (id: string) => requests.del<{}>(`/salescategory/${id}`)
}

const Tax = {
  taxList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<TaxListObject[]>>(`/tax?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  taxDropdownList: () => requests.get<TaxListObject[]>(`/tax/dropdown`),
  taxDetail: (id: string) => requests.get<TaxDetailObject>(`/tax/${id}`),
  addTax: (taxRequestBody: TaxAddObject) => requests.post(`/tax`, taxRequestBody),
  updateTax: (taxRequestBody: TaxUpdateObject) => requests.put(`/tax`, taxRequestBody),
  deleteTax: (id: string) => requests.del<{}>(`/tax/${id}`)
}

const Measurement = {
  measurementList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<MeasurementListObject[]>>(`/measurement?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  measurementDropdownList: () => requests.get<MeasurementListObject[]>(`/measurement/dropdown`),
  measurementDetail: (id: string) => requests.get<MeasurementDetailObject>(`/measurement/${id}`),
  addMeasurement: (measurementRequestBody: MeasurementAddObject) => requests.post(`/measurement`, measurementRequestBody),
  updateMeasurement: (measurementRequestBody: MeasurementUpdateObject) => requests.put(`/measurement`, measurementRequestBody),
  deleteMeasurement: (id: string) => requests.del<{}>(`/measurement/${id}`)
}

const Race = {
  raceList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<RaceListObject[]>>(`/race?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  raceDropdownList: () => requests.get<RaceListObject[]>(`/race/dropdown`),
  raceDetail: (id: string) => requests.get<RaceDetailObject>(`/race/${id}`),
  addRace: (measurementRequestBody: RaceAddObject) => requests.post(`/race`, measurementRequestBody),
  updateRace: (measurementRequestBody: RaceUpdateObject) => requests.put(`/race`, measurementRequestBody),
  deleteRace: (id: string) => requests.del<{}>(`/race/${id}`)
}

const EPFContribution = {
  epfContributionList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<EPFContributionListObject[]>>(`/epfcontribution?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  epfContributionDropdownList: () => requests.get<EPFContributionListObject[]>(`/epfcontribution/dropdown`),
  epfContributionDetail: (id: string) => requests.get<EPFContributionDetailObject>(`/epfcontribution/${id}`),
  addEPFContribution: (epfContributionRequestBody: EPFContributionAddObject) => requests.post(`/epfcontribution`, epfContributionRequestBody),
  updateEPFContribution: (epfContributionRequestBody: EPFContributionUpdateObject) => requests.put(`/epfcontribution`, epfContributionRequestBody),
  deleteEPFContribution: (id: string) => requests.del<{}>(`/epfcontribution/${id}`)
}

const EIS = {
  eisList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<EISListObject[]>>(`/eis?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year || ""}`),
  eisDetail: (id: string) => requests.get<EISDetailObject>(`/eis/${id}`),
  addEIS: (eisRequestBody: EISAddObject) => requests.post(`/eis`, eisRequestBody),
  updateEIS: (eisRequestBody: EISUpdateObject) => requests.put(`/eis`, eisRequestBody),
  deleteEIS: (id: string) => requests.del<{}>(`/eis/${id}`)
}

const Socso = {
  socsoList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SocsoListObject[]>>(`/socso?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year || ""}`),
  socsoDetail: (id: string) => requests.get<SocsoDetailObject>(`/socso/${id}`),
  addSocso: (socsoRequestBody: SocsoAddObject) => requests.post(`/socso`, socsoRequestBody),
  updateSocso: (socsoRequestBody: SocsoUpdateObject) => requests.put(`/socso`, socsoRequestBody),
  deleteSocso: (id: string) => requests.del<{}>(`/socso/${id}`)
}

const PCB = {
  pcbList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PCBListObject[]>>(`/pcb?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year || ""}`),
  pcbDetail: (id: string) => requests.get<PCBDetailObject>(`/pcb/${id}`),
  addPCB: (pcbRequestBody: PCBAddObject) => requests.post(`/pcb`, pcbRequestBody),
  updatePCB: (pcbRequestBody: PCBUpdateObject) => requests.put(`/pcb`, pcbRequestBody),
  deletePCB: (id: string) => requests.del<{}>(`/pcb/${id}`)
}

const Appointment = {
  appointmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppointmentListObject[]>>(`/appointment?${convertRequestBodyObject(pagination)}`),
  appointmentCalendar: (appointmentRequestBody: AppointmentGetListObject) => requests.post<AppointmentCalendarObject>(`/appointment/branch`, appointmentRequestBody),
  appointmentDetail: (id: string) => requests.get<AppointmentDetailObject>(`/appointment/${id}`),
  addAppointment: (appointmentRequestBody: AppointmentAddObject) => requests.post<AppointmentAddSuccessObject>(`/appointment`, appointmentRequestBody),
  updateAppointment: (appointmentRequestBody: AppointmentUpdateObject) => requests.put<AppointmentAddSuccessObject>(`/appointment`, appointmentRequestBody),
  appointmentBlockerDetail: (id: string) => requests.get<AppointmentBlockerDetailObject>(`/appointment/blocker/${id}`),
  addAppointmentBlocker: (appointmentBlockerRequestBody: AppointmentBlockerAddObject) => requests.post(`/appointment/blocker`, appointmentBlockerRequestBody),
  updateAppointmentBlocker: (appointmentBlockerRequestBody: AppointmentBlockerUpdateObject) => requests.put(`/appointment/blocker`, appointmentBlockerRequestBody),
  updateAppointmentStatus: (appointmentRequestBody: AppointmentUpdateStatusObject) => requests.post(`/appointment/status`, appointmentRequestBody),
  linkAppointmentCustomer: (appointmentRequestBody: LinkAppointmentCustomerObject) => requests.post(`/appointment/link/${appointmentRequestBody.id}/${appointmentRequestBody.customerId}`, {}),
  unlinkAppointmentCustomer: (id: string) => requests.post(`/appointment/unlink/${id}`, {}),
  deleteAppointment: (id: string) => requests.del<{}>(`/appointment/${id}`),
  roomList: (id: string) => requests.get<RoomListObject[]>(`/appointment/room/${id}`),
  appointmentCustomerTreatmentList: (customerId: string) => requests.get<AppointmentCustomerTreatmentListObject[]>(`/appointment/customer/treatment/${customerId}`),
  appointmentCustomerPurchasedPackageList: (customerId: string) => requests.get<AppointmentCustomerPurchasedListObject[]>(`/appointment/customer/purchasedpackage/${customerId}`),
  appointmentHistory: (id: string) => requests.get<AppointmentHistoryListObject[]>(`/appointment/history/${id}`),
  appointmentPin: (appointmentPinRequestBody: AppointmentPinRequestObject) => requests.put(`/appointment/pin`, appointmentPinRequestBody),
}

const StaticReference = {
  staticReferenceList: () => requests.get<StaticReferenceObject[]>(`/staticreference`),
  staticReferenceWithType: (type: string) => requests.get<StaticReferenceObject>(`/staticreference/${type}`),
  staticReferenceAuditModule: () => requests.get<StaticReferenceAuditModuleListObject[]>(`/staticreference/auditmodule`),
}

const UserRole = {
  userRoleList: () => requests.get<UserRoleListObject[]>(`/userrole`),
  userRoleDetail: (id: string) => requests.get<UserRoleDetailObject>(`/userrole/${id}`),
  userRoleDropdownList: (employeeTypeId: string | undefined | number) => requests.get<UserRoleListObject[]>(`/userrole/dropdown${employeeTypeId || employeeTypeId === 0 ? `?employeeType=${employeeTypeId}` : ""}`),
}

const MediaSource = {
  mediaSourceList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<MediaSourceListObject[]>>(`/mediasource?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  mediaSourceDropdownList: () => requests.get<MediaSourceListObject[]>(`/mediasource/dropdown`),
  mediaSourceDetail: (id: string) => requests.get<MediaSourceDetailObject>(`/mediasource/${id}`),
  addMediaSource: (mediaSourceRequestBody: MediaSourceAddObject) => requests.post(`/mediasource`, mediaSourceRequestBody),
  updateMediaSource: (mediaSourceRequestBody: MediaSourceUpdateObject) => requests.put(`/mediasource`, mediaSourceRequestBody),
  deleteMediaSource: (id: string) => requests.del<{}>(`/mediasource/${id}`)
}

const CustomerOrder = {
  submitPOS: (posRequestBody: CustomerOrderObject) => requests.post<CustomerOrderPaymentSuccess>(`/customerorder`, posRequestBody),
  submitAdditionalPayment: (posRequestBody: CustomerOrderPayment) => requests.post(`/customerorder/payment`, posRequestBody),
  customerPurchaseList: (customerId: string, status?:number) => requests.get<CustomerPurchaseObject>(`/customerorder/${customerId}/${status || status === 0 ? status : ""}`),
  submitCustomerRedeem: (customerRedeemRequestBody: CustomerPurchaseRedeem) => requests.post<CustomerPurchaseSuccess>(`/customerorder/redeem`, customerRedeemRequestBody),
  submitPackageUpgrade: (packageUpgradeRequestBody: CustomerUpgrade) => requests.post<CustomerOrderPaymentSuccess>(`/customerorder/upgrade`, packageUpgradeRequestBody),
}

const Invoice = {
  invoiceList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<InvoiceListObject[]>>(`/customerinvoice?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&paymentStatus=${pagination.paymentStatus || pagination.paymentStatus === 0 ? pagination.paymentStatus : ""}&branchId=${pagination.branchId || ""}`),
  invoiceDetail: (id: string) => requests.get<InvoiceDetailObject>(`/customerinvoice/${id}`),
  signInvoice: (signInvoiceRequestBody: InvoiceSignObject) => requests.post(`/customerinvoice/sign`, signInvoiceRequestBody),
  invoiceFromSalesOrderList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerSalesOrderListObject[]>>(`/customerinvoice/salesorder?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&subBrandId=${pagination.subBrandId || ""}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}&status=${pagination.salesOrderStatus || pagination.salesOrderStatus === 0 ? pagination.salesOrderStatus : ""}&salesOrderNo=${pagination.customerSalesOrderNo || ""}&paymentStatus=${pagination.paymentStatus || pagination.paymentStatus === 0 ? pagination.paymentStatus : ""}&invoiceNo=${pagination.customerInvoiceNo || ""}`),
  invoiceFromSalesOrderDetail: (id: string) => requests.get<CustomerSalesOrderDetailObject>(`/customerinvoice/salesorder/${id}`)
}

const Position = {
  positionList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PositionListObject[]>>(`/position?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  positionDropdownList: (employeeTypeId: string | undefined | number) => requests.get<PositionListObject[]>(`/position/dropdown${employeeTypeId || employeeTypeId === 0 ? `?employeeType=${employeeTypeId}` : ""}`),
  positionDetail: (id: string) => requests.get<PositionDetailObject>(`/position/${id}`),
  addPosition: (positionRequestBody: PositionAddObject) => requests.post(`/position`, positionRequestBody),
  updatePosition: (positionRequestBody: PositionUpdateObject) => requests.put(`/position`, positionRequestBody),
  deletePosition: (id: string) => requests.del<{}>(`/position/${id}`)
}

const Supplier = {
  supplierList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SupplierListObject[]>>(`/supplier?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  supplierDropdownList: () => requests.get<SupplierListObject[]>(`/supplier/dropdown`),
  supplierDetail: (id: string) => requests.get<SupplierDetailObject>(`/supplier/${id}`),
  addSupplier: (supplierRequestBody: SupplierAddObject) => requests.post(`/supplier`, supplierRequestBody),
  updateSupplier: (supplierRequestBody: SupplierUpdateObject) => requests.put(`/supplier`, supplierRequestBody),
  deleteSupplier: (id: string) => requests.del<{}>(`/supplier/${id}`)
}

const SalesTarget = {
  salesTargetList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SalesTargetListObject[]>>(`/salestarget?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year || ""}&month=${pagination.month || ""}&positionId=${pagination.positionId || ""}`),
  salesTargetDetail: (id: string) => requests.get<SalesTargetDetailObject>(`/salestarget/${id}`),
  addSalesTarget: (salesTargetRequestBody: SalesTargetAddObject) => requests.post<AddSalesTargetObject>(`/salestarget`, salesTargetRequestBody),
  updateSalesTarget: (salesTargetRequestBody: SalesTargetUpdateObject) => requests.put(`/salestarget`, salesTargetRequestBody),
  deleteSalesTarget: (id: string) => requests.del<{}>(`/salestarget/${id}`),
  salesTargetDuplicate: (salesTargetDuplicatedRequestObject: SalesTargetDuplicatedRequestObject) => requests.post(`/salestarget/duplicate`, salesTargetDuplicatedRequestObject)
}

const Warehouse = {
  warehouseList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SupplierListObject[]>>(`/warehouse?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  warehouseDropdownList: () => requests.get<SupplierListObject[]>(`/warehouse/dropdown`),
  warehousePurchaseOrderDropdownList: (warehousePurchaseOrderDropdownRequestBody: WarehousePurchaseOrderDropdownRequestBody) => requests.get<WarehousePurchaseOrderForProductObject[]>(`/warehouse/${warehousePurchaseOrderDropdownRequestBody.warehouseId}/branch/${warehousePurchaseOrderDropdownRequestBody.branchId}`),
  warehouseDetail: (id: string) => requests.get<WarehouseDetailObject>(`/warehouse/${id}`),
  warehouseProducts: (id: string) => requests.get<WarehouseProductsList[]>(`/warehouse/products/${id}`),
  warehouseProductDetails: (warehouseId: string, productId: string) => requests.get<WarehouseProductDetails>(`/warehouse/${warehouseId}/product/${productId}`),
  addWarehouse: (warehouseRequestBody: WarehouseAddObject) => requests.post(`/warehouse`, warehouseRequestBody),
  updateWarehouse: (warehouseRequestBody: WarehouseUpdateObject) => requests.put(`/warehouse`, warehouseRequestBody),
  deleteWarehouse: (id: string) => requests.del<{}>(`/warehouse/${id}`)
}

const DeliveryOrder = {
  deliveryOrderList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<DeliveryOrderListObject[]>>(`/customerdeliverorder?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&deliverOrderStatus=${pagination.deliverOrderStatus || pagination.deliverOrderStatus === 0 ? pagination.deliverOrderStatus : ""}&customerId=${pagination.customerId || ""}&salesOrderId=${pagination.salesOrderId || ""}`),
  deliveryOrderDetail: (id: string) => requests.get<DeliveryOrderDetailObject>(`/customerdeliverorder/${id}`),
  signDeliveryOrder: (signDeliveryOrderRequestBody: DeliveryOrderSignObject) => requests.post(`/customerdeliverorder/sign`, signDeliveryOrderRequestBody),
  updateDeliverOrder: (updateDeliverOrderRequestBody: DeliverOrderUpdateObject) => requests.put(`/customerdeliverorder`, updateDeliverOrderRequestBody),
  requestVoidDeliveryOrder: (requestVoidDeliveryOrderRequestBody: DeliverOrderVoidObject) => requests.post(`/customerdeliverorder/void/request`, requestVoidDeliveryOrderRequestBody),
  reviewVoidDeliveryOrder: (reviewVoidDeliveryOrderRequestBody: DeliverOrderVoidObject) => requests.put(`/customerdeliverorder/void/review`, reviewVoidDeliveryOrderRequestBody),
  deliveryOrderProductList: (id: string) => requests.get<DeliveryOrderProductListObject[]>(`/customerdeliverorder/product/${id}`),
  regenerateProductDeliverOrder: (salesOrderId : string) => requests.post<AutoRedemptionGeneratedObject>(`/customerdeliverorder/product/regenerate/${salesOrderId }`, salesOrderId ),
  editDeliverOrderDetail: (editDeliverOrderDetailRequestBody : EditDeliverOrderDetailObject) => requests.put(`/customerdeliverorder/detail/edit`, editDeliverOrderDetailRequestBody),
}

const StockMovement = {
  stockMovementList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockMovementListObject[]>>(`/stockmovement?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&productId=${pagination.productId || ""}&stockMovementType=${pagination.stockMovementType || pagination.stockMovementType === 0 ? pagination.stockMovementType : ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&redemptionId=${pagination.redemptionId || ""}`),
  stockMovementDetail: (id: string) => requests.get<StockMovementDetailObject>(`/stockmovement/${id}`),
  addStockMovement: (stockMovementRequestBody: StockMovementAddObject) => requests.post(`/stockmovement`, stockMovementRequestBody),
  updateStockMovement: (stockMovementRequestBody: StockMovementUpdateObject) => requests.put(`/stockmovement`, stockMovementRequestBody),
  deleteStockMovement: (id: string) => requests.del<{}>(`/stockmovement/${id}`)
}

const LeaveType = {
  leaveTypeList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<LeaveTypeListObject[]>>(`/leaveType?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  leaveTypeDropdownList: () => requests.get<LeaveTypeListObject[]>(`/leaveType/dropdown`),
  leaveTypeDetail: (id: string) => requests.get<LeaveTypeDetailObject>(`/leaveType/${id}`),
  addLeaveType: (leaveTypeRequestBody: LeaveTypeAddObject) => requests.post(`/leaveType`, leaveTypeRequestBody),
  updateLeaveType: (leaveTypeRequestBody: LeaveTypeUpdateObject) => requests.put(`/leaveType`, leaveTypeRequestBody),
  deleteLeaveType: (id: string) => requests.del<{}>(`/leaveType/${id}`)
}

const PayrollItem = {
  payrollItemList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PayrollItemListObject[]>>(`/payrollitem?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  payrollItemDropdownList: () => requests.get<PayrollItemListObject[]>(`/payrollitem/dropdown`),
  payrollItemDetail: (id: string) => requests.get<PayrollItemDetailObject>(`/payrollitem/${id}`),
  addPayrollItem: (payrollitemRequestBody: PayrollItemAddObject) => requests.post(`/payrollitem`, payrollitemRequestBody),
  updatePayrollItem: (payrollitemRequestBody: PayrollItemUpdateObject) => requests.put(`/payrollitem`, payrollitemRequestBody),
  deletePayrollItem: (id: string) => requests.del<{}>(`/payrollitem/${id}`)
}

const StockRequest = {
  stockRequestList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockRequestListObject[]>>(`/stockrequest?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&status=${pagination.stockRequestStatus || pagination.stockRequestStatus === 0 ? pagination.stockRequestStatus : ""}&fromBranchId=${pagination.fromBranchId || ""}&requestBranchId=${pagination.requestBranchId || ""}&stockRequestNo=${pagination.stockRequestNo || ""}&hasStockReceive=${typeof(pagination.hasStockReceive) === 'boolean' ? pagination.hasStockReceive : ""}&purchaseType=${pagination.purchaseType || pagination.purchaseType === 0 ? pagination.purchaseType : ""}&productId=${pagination.productId || ""}`),
  stockRequestDetail: (id: string) => requests.get<StockRequestDetailObject>(`/stockrequest/${id}`),
  addStockRequest: (stockRequestRequestBody: StockRequestAddObject) => requests.post(`/stockrequest`, stockRequestRequestBody),
  updateStockRequest: (stockRequestRequestBody: StockRequestUpdateObject) => requests.put(`/stockrequest`, stockRequestRequestBody),
  deleteStockRequest: (id: string) => requests.del<{}>(`/stockrequest/${id}`),
  stockRequestProductList: (id: string, purchaserType: string, productTypes: string[]) => requests.get<StockRequestForProductDetailsObject[]>(`/stockrequest/products?${convertRequestBodyObject({branchId: id, purchaserType: purchaserType, productTypes: productTypes})}`), 
}

const StockReceive = {
  stockReceiveList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockReceiveListObject[]>>(`/stockreceive?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&requestBranchId=${pagination.requestBranchId || ""}&status=${pagination.stockReceiveStatus || pagination.stockReceiveStatus === 0 ? pagination.stockReceiveStatus : ""}&purchaserType=${pagination.stockRequestPurchaserType || pagination.stockRequestPurchaserType === 0 ? pagination.stockRequestPurchaserType : ""}&stockReceiveNo=${pagination.stockReceiveNo || ""}&productId=${pagination.productId || ""}`),
  stockReceiveDetail: (id: string) => requests.get<StockReceiveDetailObject>(`/stockreceive/${id}`),
  addStockReceive: (stockReceiveRequestBody: StockReceiveAddObject) => requests.post(`/stockreceive`, stockReceiveRequestBody),
  updateStockReceive: (stockReceiveRequestBody: StockReceiveUpdateObject) => requests.put(`/stockreceive`, stockReceiveRequestBody),
  completeStockReceive: (stockReceiveRequestBody: StockReceiveCompleteObject) => requests.post(`/stockreceive/complete`, stockReceiveRequestBody),
  deleteStockReceive: (id: string) => requests.del<{}>(`/stockreceive/${id}`),
  updateStockReceiveStatus: (id: string) => requests.put(`/stockreceive/status/${id}`, {}),
}

const LeaveEntitlement = {
  leaveEntitlementList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<LeaveEntitlementListObject[]>>(`/leaveEntitlement?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  leaveEntitlementDropdownList: () => requests.get<LeaveEntitlementListObject[]>(`/leaveEntitlement/dropdown`),
  leaveEntitlementDetail: (id: string) => requests.get<LeaveEntitlementDetailObject>(`/leaveEntitlement/${id}`),
  addLeaveEntitlement: (leaveEntitlementRequestBody: LeaveEntitlementAddObject) => requests.post(`/leaveEntitlement`, leaveEntitlementRequestBody),
  updateLeaveEntitlement: (leaveEntitlementRequestBody: LeaveEntitlementUpdateObject) => requests.put(`/leaveEntitlement`, leaveEntitlementRequestBody),
  deleteLeaveEntitlement: (id: string) => requests.del<{}>(`/leaveEntitlement/${id}`)
}

const PurchaseOrder = {
  purchaseOrderList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PurchaseOrderListObject[]>>(`/purchaseOrder?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&status=${pagination.purchaseOrderStatus || pagination.purchaseOrderStatus === 0 ? pagination.purchaseOrderStatus : ""}&purchaseOrderType=${pagination.purchaseOrderType || pagination.purchaseOrderType === 0 ? pagination.purchaseOrderType : ""}&purchasePaymentMethod=${pagination.purchasePaymentMethod || pagination.purchasePaymentMethod === 0 ? pagination.purchasePaymentMethod : ""}`),
  purchaseOrderDropdown: (purchaseOrderDropdownRequestBody: PurchaseOrderDropdownRequestBody) => requests.get<PurchaseOrderListObject[]>(`/purchaseOrder/dropdown?orderTypes=${purchaseOrderDropdownRequestBody.orderTypes}&dropDownModule=${purchaseOrderDropdownRequestBody.dropDownModule}&statuses=${purchaseOrderDropdownRequestBody.statuses}`),
  purchaseOrderDetail: (id: string) => requests.get<PurchaseOrderDetailObject>(`/purchaseOrder/${id}`),
  addPurchaseOrder: (purchaseOrderRequestBody: PurchaseOrderAddObject) => requests.post(`/purchaseOrder`, purchaseOrderRequestBody),
  updatePurchaseOrder: (purchaseOrderRequestBody: PurchaseOrderUpdateObject) => requests.put(`/purchaseOrder`, purchaseOrderRequestBody),
  deletePurchaseOrder: (id: string) => requests.del<{}>(`/purchaseOrder/${id}`),
  purchaseOrderWarehouseList: (purchaseOrderWarehouseRequestBody: PurchaseOrderWarehouseRequestBody) => requests.get<PurchaseOrderWarehouseDetailsObject>(`/purchaseOrder/${purchaseOrderWarehouseRequestBody.id}/${purchaseOrderWarehouseRequestBody.warehouseId}`), 
}

const InboundNotice = {
  inboundNoticeList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<InboundNoticeListObject[]>>(`/inboundnotice?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&inboundNoticeStatus=${pagination.inboundNoticeStatus || pagination.inboundNoticeStatus === 0 ? pagination.inboundNoticeStatus : ""}`),
  inboundNoticeDetail: (id: string) => requests.get<InboundNoticeDetailObject>(`/inboundnotice/${id}`),
  addInboundNotice: (inboundNoticeRequestBody: InboundNoticeAddObject) => requests.post(`/inboundnotice`, inboundNoticeRequestBody),
  updateInboundNotice: (inboundNoticeRequestBody: InboundNoticeUpdateObject) => requests.put(`/inboundnotice`, inboundNoticeRequestBody),
  deleteInboundNotice: (id: string) => requests.del<{}>(`/inboundnotice/${id}`),
}

const GoodsReceiveNote = {
  goodsReceiveNoteList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<GoodsReceiveNoteListObject[]>>(`/grn?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&gRNStatus=${pagination.gRNStatus || pagination.gRNStatus === 0 ? pagination.gRNStatus : ""}`),
  goodsReceiveNoteDetail: (id: string) => requests.get<GoodsReceiveNoteDetailObject>(`/grn/${id}`),
  addGoodsReceiveNote: (packageRequestBody: GoodsReceiveNoteAddObject) => requests.post(`/grn`, packageRequestBody),
  updateGoodsReceiveNote: (packageRequestBody: GoodsReceiveNoteUpdateObject) => requests.put(`/grn`, packageRequestBody),
  deleteGoodsReceiveNote: (id: string) => requests.del<{}>(`/grn/${id}`),
}

const PurchaseDeliverOrder = {
  purchaseDeliverOrderList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PurchaseDeliverOrderListObject[]>>(`/purchasedeliverorder?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&status=${pagination.purchaseDeliverOrderStatus || pagination.purchaseDeliverOrderStatus === 0 ? pagination.purchaseDeliverOrderStatus : ""}`),
  purchaseDeliverOrderDetail: (id: string) => requests.get<PurchaseDeliverOrderDetailObject>(`/purchasedeliverorder/${id}`),
  addPurchaseDeliverOrder: (purchaseDeliverOrderRequestBody: PurchaseDeliverOrderAddObject) => requests.post(`/purchasedeliverorder`, purchaseDeliverOrderRequestBody),
  updatePurchaseDeliverOrder: (purchaseDeliverOrderRequestBody: PurchaseDeliverOrderUpdateObject) => requests.put(`/purchasedeliverorder`, purchaseDeliverOrderRequestBody),
  deletePurchaseDeliverOrder: (id: string) => requests.del<{}>(`/purchasedeliverorder/${id}`),
}

const Permission = {
  permissionList: () => requests.get<PermissionListObject[]>(`/permission`),
  permissionUserList: () => requests.get<PermissionUserListObject[]>(`/permission/user`),
  updatePermission: (permissionRequestBody: PermissionUpdateObject[]) => requests.put(`/permission`, permissionRequestBody)
}

const Profile = {
  profileDetail: () => requests.get<ProfileDetailObject>('/account/profile'),
  updateProfile: (profileRequestBody: ProfileUpdateObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(profileRequestBody, formData);
    return requests.postWithFormData(`/account/profile`, formData)
  },
  changePassword: (profileRequestBody: ChangePasswordObject) => requests.put(`/account/changepassword`, profileRequestBody),
  changePasscode: (profileRequestBody: ChangePasscodeObject) => requests.put(`/account/changepasscode`, profileRequestBody),
}

const ReportSales = {
  consultantSalesList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ConsultantSalesListObject[]>>(`/report/consultantsales?${convertRequestBodyObject(pagination)}`),
  consultantSalesSummaryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ConsultantSalesSummaryListObject[]>>(`/report/consultantsales/summary?${convertRequestBodyObject(pagination)}`),
  consultantSalesCommissionList: (pagination: PaginationRequestBody) => requests.get<ConsultantSalesCommissionObject>(`/report/commission?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year}&month=${pagination.month}&branchId=${pagination.branchId}&passcode=${pagination.passcode}`),
  consultantDailySalesList: (pagination: PaginationRequestBody) => requests.get<ConsultantDailySalesListObject[]>(`/report/consultantsales/daily?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year}&month=${pagination.month}&branchId=${pagination.branchId}&subBrandId=${pagination.subBrandId || ""}`),
  consultantSalesSummaryExport: (consultantSalesSummaryExportRequestBody: ConsultantSalesSummaryExportObject) => requests.post(`/report/consultantsales/summary/export`, consultantSalesSummaryExportRequestBody),
  addConsultantSales: (consultantSalesAddObject: ConsultantSalesAddObject) => requests.post(`/consultantsales`, consultantSalesAddObject),
  dailySalesSummaryList: (dailySalesGetObject: DailySalesGetObject) => requests.get<DailySalesSummaryListObject[]>(`/report/sales/dailysalessummary?fromDate=${dailySalesGetObject.fromDate || ""}&toDate=${dailySalesGetObject.toDate || ""}&branchId=${dailySalesGetObject.branchId || ""}&paymentStatus=${dailySalesGetObject.paymentStatus || dailySalesGetObject.paymentStatus === 0 ? dailySalesGetObject.paymentStatus : ""}&subBrandId=${dailySalesGetObject.subBrandId || ""}`),
  dailySalesDetailsList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<DailySalesDetailListObject[]>>(`/report/sales/dailysalesdetails?${convertRequestBodyObject(pagination)}`),
  salesByCategoryList: (salesByCategoryListGetObject: SalesByCategoryGetObject) => requests.get<SalesByCategoryListObject[]>(`/report/sales/by${salesByCategoryListGetObject.category}?branchId=${salesByCategoryListGetObject.branchId || ""}&fromDate=${salesByCategoryListGetObject.dateRange.fromDate || ""}&toDate=${salesByCategoryListGetObject.dateRange.toDate || ""}`),
  dynamicSalesDetailList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<DynamicSalesDetailsListObject[]>>(`/report/sales/dynamicsalesdetails?${convertRequestBodyObject(pagination)}`),
  productSoldList: (productSoldGetObject: ProductSoldGetObject) => requests.get<ProductSoldListObject[]>(`/report/sales/productsold?branchId=${productSoldGetObject.branchId || ""}&fromDate=${productSoldGetObject.dateRange.fromDate || ""}&toDate=${productSoldGetObject.dateRange.toDate || ""}`),
  salesAdjustmentHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SalesAdjustmentHistoryListObject[]>>(`/report/sales/salesadjustmenthistory?${convertRequestBodyObject(pagination)}`),
  monthlySalesSummaryList: (monthlySalesGetObject: MonthlySalesSummaryGetObject) => requests.get<MonthlySalesSummaryListObject[]>(`/report/sales/monthlysalessummary?year=${monthlySalesGetObject.year || ""}&branchId=${monthlySalesGetObject.branchId || ""}&paymentStatus=${monthlySalesGetObject.paymentStatus || monthlySalesGetObject.paymentStatus === 0 ? monthlySalesGetObject.paymentStatus : ""}`),
  salesOverviewList: (salesOverviewGetObject: SalesOverviewGetObject) => requests.get<SalesOverviewListObject[]>(`/report/sales/overview?fromDate=${salesOverviewGetObject.fromDate || ""}&toDate=${salesOverviewGetObject.toDate || ""}&branchId=${salesOverviewGetObject.branchId || ""}&subBrandId=${salesOverviewGetObject.subBrandId || ""}`),
  salesOverviewDetailsList: (salesOverviewDetailsGetObject: SalesOverviewDetailsGetObject) => requests.get<SalesOverviewDetailsListObject[]>(`/report/sales/overview/details?fromDate=${salesOverviewDetailsGetObject.fromDate || ""}&toDate=${salesOverviewDetailsGetObject.toDate || ""}&branchId=${salesOverviewDetailsGetObject.branchId || ""}&type=${salesOverviewDetailsGetObject.type || salesOverviewDetailsGetObject.type === 0 ? salesOverviewDetailsGetObject.type : ""}&subBrandId=${salesOverviewDetailsGetObject.subBrandId || ""}${salesOverviewDetailsGetObject.customerId ? `&customerId=${salesOverviewDetailsGetObject.customerId}` : ""}`),
  subBranchSalesSummary: (subBranchSalesSummaryGetObject: SubBranchSalesSummaryGetObject) => requests.get<SubBranchSalesSummaryObject>(`/report/sales/subbranch?year=${subBranchSalesSummaryGetObject.year || ""}&month=${subBranchSalesSummaryGetObject.month || ""}&branchId=${subBranchSalesSummaryGetObject.branchId || ""}&salesOrderNo=${subBranchSalesSummaryGetObject.salesOrderNo || ""}`),
  discountSalesDetailsList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<DiscountSalesDetailsListObject[]>>(`/report/sales/discount?${convertRequestBodyObject(pagination)}`),
  salesByEmployeeList: (salesByEmployeeListGetObject: SalesByEmployeeGetAndExportObject) => requests.get<SalesByEmployeeSummaryObject[]>(`/report/sales/byemployee?branchId=${salesByEmployeeListGetObject.branchId || ""}&fromDate=${salesByEmployeeListGetObject.dateRange.fromDate || ""}&toDate=${salesByEmployeeListGetObject.dateRange.toDate || ""}`),
  salesByProductList: (salesByProductListGetObject: SalesByProductGetAndExportObject) => requests.get<SalesByProductDetailObject[]>(`/report/sales/byproduct?branchId=${salesByProductListGetObject.branchId || ""}&fromDate=${salesByProductListGetObject.dateRange.fromDate || ""}&toDate=${salesByProductListGetObject.dateRange.toDate || ""}&categoryId=${salesByProductListGetObject.categoryId || ""}&salesCategoryId=${salesByProductListGetObject.salesCategoryId || ""}`),
  productSalesDetails: (productSalesDetailsGetAndExportObject: ProductSalesDetailsGetAndExportObject) => requests.get<ProductSalesDetailsObject>(`/report/sales/productsalesdetail?branchId=${productSalesDetailsGetAndExportObject.branchId || ""}&fromDate=${productSalesDetailsGetAndExportObject.dateRange.fromDate || ""}&toDate=${productSalesDetailsGetAndExportObject.dateRange.toDate || ""}`),
  closingRateList: (closingRateListGetAndExportObject: ClosingRateListGetAndExportObject) => requests.get<ClosingRateListObject[]>(`/report/sales/closingrate?subBrandId=${closingRateListGetAndExportObject.subBrandId}&branchId=${closingRateListGetAndExportObject.branchId || ""}&fromDate=${closingRateListGetAndExportObject.fromDate || ""}&toDate=${closingRateListGetAndExportObject.toDate || ""}`),
  salesOverviewYearlyList: (salesOverviewYearlyListGetAndExportObject: SalesOverviewYearlyListGetAndExportObject) => requests.get<SalesOverviewYearlyListObject[]>(`/report/sales/overview/yearly?subBrandId=${salesOverviewYearlyListGetAndExportObject.subBrandId}&branchId=${salesOverviewYearlyListGetAndExportObject.branchId || ""}&year=${salesOverviewYearlyListGetAndExportObject.year || ""}`),
  branchSalesSummaryList: (branchSalesSummaryListGetAndExportObject: BranchSalesSummaryListGetAndExportObject) => requests.get<BranchSalesSummaryListObject[]>(`/report/sales/branchsalessummary?${convertRequestBodyObject(branchSalesSummaryListGetAndExportObject)}`),
  branchDailySalesSummaryList: (branchDailySalesSummaryListGetAndExportObject: BranchDailySalesSummaryListGetAndExportObject) => requests.get<BranchDailySalesSummaryListObject[]>(`/report/sales/branchdailysalessummary?${convertRequestBodyObject(branchDailySalesSummaryListGetAndExportObject)}`),
  salesByGroupCodeList: (salesByGroupCodeListGetAndExportObject: SalesByGroupCodeListGetAndExportObject) => requests.get<SalesByGroupCodeListObject[]>(`/report/sales/bygroupcode?${convertRequestBodyObject(salesByGroupCodeListGetAndExportObject)}`),
  commissionReportDetails: (commissionReportDetailsGetAndExportObject: CommissionReportDetailsGetAndExportObject) => requests.get<CommissionReportDetailsObject>(`/report/commission/commissiondetails?${convertRequestBodyObject(commissionReportDetailsGetAndExportObject)}`),
  exchangeToCreditDetails: (exchangeToCreditDetailsGetAndExportObject: ExchangeToCreditDetailsGetAndExportObject) => requests.get<PaginatedResult<ExchangeToCreditDetailsListObject[]>>(`/report/sales/exchangetocreditdetails?${convertRequestBodyObject(exchangeToCreditDetailsGetAndExportObject)}`),
  refundFromCredit: (refundFromCreditGetAndExportObject: RefundFromCreditGetAndExportObject) => requests.get<PaginatedResult<RefundFromCreditListObject[]>>(`/report/sales/refundfromcredit?${convertRequestBodyObject(refundFromCreditGetAndExportObject)}`),
  branchMonthlySalesSummaryList: (branchMonthlySalesSummaryGetAndExportObject: BranchMonthlySalesSummaryGetAndExportObject) => requests.get<BranchMonthlySalesSummaryListObject[]>(`/report/sales/branchmonthlysalessummary?${convertRequestBodyObject(branchMonthlySalesSummaryGetAndExportObject)}`),
  salesVarianceList: (salesVarianceGetAndExportObject: SalesVarianceGetAndExportObject) => requests.get<SalesVarianceListObject[]>(`/report/sales/salesvariance?${convertRequestBodyObject(salesVarianceGetAndExportObject)}`)
}

const ReportMarcom = {
  leadsSummaryByMediaList: (leadSummaryByMediaListGetObject: LeadSummaryByMediaListGetObject) => requests.get<LeadSummaryByMediaListObject[]>(`/report/marcom/leadssummarybymedia?sourceId=${leadSummaryByMediaListGetObject.sourceId || ""}&fromDate=${leadSummaryByMediaListGetObject.dateRange.fromDate || ""}&toDate=${leadSummaryByMediaListGetObject.dateRange.toDate || ""}`),
  leadsSummaryByEmployeeList: (leadSummaryByEmployeeListGetObject: LeadSummaryByEmployeeListGetObject) => requests.get<LeadSummaryByEmployeeListObject[]>(`/report/marcom/leadssummarybyemployee?employeeId=${leadSummaryByEmployeeListGetObject.employeeId || ""}&fromDate=${leadSummaryByEmployeeListGetObject.dateRange.fromDate || ""}&toDate=${leadSummaryByEmployeeListGetObject.dateRange.toDate || ""}`),
  leadsDetailList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<LeadDetailsListObject[]>>(`/report/marcom/leadsdetails?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&subBrandId=${pagination.subBrandId}&branchId=${pagination.branchId}&sourceId=${pagination.sourceId}&submissionFromDate=${pagination.submissionDateRange?.fromDate || ""}&submissionToDate=${pagination.submissionDateRange?.toDate || ""}&firstAppointmentFromDate=${pagination.firstAppointmentDateRange?.fromDate || ""}&firstAppointmentToDate=${pagination.firstAppointmentDateRange?.toDate || ""}&lastAppointmentFromDate=${pagination.lastAppointmentDateRange?.fromDate || ""}&lastAppointmentToDate=${pagination.lastAppointmentDateRange?.toDate || ""}&status=${pagination.leadStatus || pagination.leadStatus === 0 ? pagination.leadStatus : ""}&employeeId=${pagination.marcomConsultantId}&isFirstAppointmentOnly=${pagination.isFirstAppointmentOnly}`),
  promoCodeHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PromoCodeHistoryListObject[]>>(`/report/marcom/promocodehistory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&customerId=${pagination.customerId || ""}&promoCode=${pagination.promoCode || ""}&type=${pagination.type || pagination.type === 0 ? pagination.type : ""}`),
  customerTurnedUpList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerTurnedUpListObject[]>>(`/report/marcom/customerturnedup?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  leadAppointments: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<LeadAppointmentsListObject[]>>(`/report/marcom/leadAppointments?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&subBrandId=${pagination.subBrandId}&branchId=${pagination.branchId}&sourceId=${pagination.sourceId}&status=${pagination.appointmentStatus || pagination.appointmentStatus === 0 ? pagination.appointmentStatus : ""}&madeById=${pagination.madeById || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  appointmentOverviewSummary: (appointmentOverviewSummaryGetObject: AppointmentOverviewSummaryGetObject) => requests.get<AppointmentOverviewSummaryListObject[]>(`/report/marcom/overview?branchId=${appointmentOverviewSummaryGetObject.branchId}&year=${appointmentOverviewSummaryGetObject.year || ""}&month=${appointmentOverviewSummaryGetObject.month || ""}${appointmentOverviewSummaryGetObject.day ? `&day=${appointmentOverviewSummaryGetObject.day}` : ""}&subBrandId=${appointmentOverviewSummaryGetObject.subBrandId}`),
  leadTurnUpList: (leadTurnUpGetAndExportObject: LeadTurnUpGetAndExportObject) => requests.get<LeadTurnUpListObject[]>(`/report/marcom/leadturnup?${convertRequestBodyObject(leadTurnUpGetAndExportObject)}`),
  newVisitVsTurnUpDetail: (newVisitVsTurnUpExportObject : NewVisitVsTurnUpGetAndExportObject) => requests.get<NewVisitVsTurnUpDetailObject>(`/report/marcom/newvisitvsturnup?${convertRequestBodyObject(newVisitVsTurnUpExportObject)}`)
}

const ReportAppointment = {
  appointmentSummaryBySourceList: (appointmentSummaryListGetObject: AppointmentSummaryListGetObject) => requests.get<AppointmentSummaryListBySourceObject[]>(`/report/appointment/summarybysource?${convertRequestBodyObject(appointmentSummaryListGetObject)}`),
  appointmentSummaryByStatusList: (appointmentSummaryListGetObject: AppointmentSummaryListGetObject) => requests.get<AppointmentSummaryListByStatusObject[]>(`/report/appointment/summarybystatus?branchId=${appointmentSummaryListGetObject.branchId || ""}&fromDate=${appointmentSummaryListGetObject.dateRange.fromDate || ""}&toDate=${appointmentSummaryListGetObject.dateRange.toDate || ""}`),
  appointmentDetailList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppointmentDetailsListObject[]>>(`/report/appointment/details?${convertRequestBodyObject(pagination)}`),
  roomUtilizationList: (roomUtilizationListGetObject: RoomUtilizationListGetObject) => requests.get<RoomUtilizationListObject[]>(`/report/appointment/roomutilization?branchId=${roomUtilizationListGetObject.branchId || ""}&year=${roomUtilizationListGetObject.year || ""}&month=${roomUtilizationListGetObject.month || ""}&subBrandId=${roomUtilizationListGetObject.subBrandId || ""}`),
  roomOccupancyList: (roomOccupancyListGetObject: RoomOccupancyListGetObject) => requests.get<RoomOccupancyListObject[]>(`/report/appointment/roomoccupancy?branchId=${roomOccupancyListGetObject.branchId || ""}&year=${roomOccupancyListGetObject.year || ""}&month=${roomOccupancyListGetObject.month || ""}&subBrandId=${roomOccupancyListGetObject.subBrandId || ""}`),
}

const ReportPayment = {
  collectionSummaryList: (paymentSummaryListGetObject: PaymentCollectionSummaryListGetObject) => requests.get<PaymentCollectionSummaryListObject>(`/report/payment/collectionsummary?branchId=${paymentSummaryListGetObject.branchId || ""}&fromDate=${paymentSummaryListGetObject.dateRange.fromDate || ""}&toDate=${paymentSummaryListGetObject.dateRange.toDate || ""}&subBrandId=${paymentSummaryListGetObject.subBrandId || ""}`),
  collectionDetailList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PaymentCollectionDetailsListObject[]>>(`/report/payment/collectiondetails?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&subBrandId=${pagination.subBrandId || ""}&branchId=${pagination.branchId || ""}&paymentMethodId=${pagination.paymentMethodId || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&customerId=${pagination.customerId || ""}&employeeId=${pagination.employeeId || ""}`),
  collectOnBehalfList: (paymentCollectOnBehalfGetObject: PaymentCollectOnBehalfListGetObject) => requests.get<PaymentCollectOnBehalfListObject>(`/report/payment/collectonbehalf?branchId=${paymentCollectOnBehalfGetObject.branchId || ""}&fromDate=${paymentCollectOnBehalfGetObject.dateRange.fromDate || ""}&toDate=${paymentCollectOnBehalfGetObject.dateRange.toDate || ""}`),
  collectOnBehalfDetailsList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PaymentCollectOnBehalfDetailsListObject[]>>(`/report/payment/collectonbehalf/details?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&subBrandId=${pagination.subBrandId || ""}&branchId=${pagination.branchId || ""}&paymentMethodId=${pagination.paymentMethodId || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&purchaseBranchId=${pagination.purchaseBranchId || ""}&isIncludeCreditPayment=${pagination.isIncludeCreditPayment}`),
  combinedCollectionSummary: (pagination: PaginationRequestBody) => requests.get<CombinedCollectionSummaryObject>(`/report/payment/combinedcollectionsummary?${convertRequestBodyObject(pagination)}`),
  combinedCollectionSummaryDetailList: (pagination: PaginationRequestBody, axiosSignal: any) => requests.postWithoutRemoveObjectWithAxiosSignal<PaginatedResult<CombinedPaymentCollectionDetailListObject[]>>(`/report/payment/combinedcollectionsummary/detail`, pagination, axiosSignal),
}

const ReportCustomer = {
  customerDetailReportList: (pagination: PaginationRequestBody, axiosSignal: any) => requests.get<PaginatedResult<CustomerDetailsListObject[]>>(`/report/customer/customerdetail?${convertRequestBodyObject(pagination)}`, axiosSignal),
  packageUnutilizedBalanceList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PackageUnutilizedBalanceListObject[]>>(`/report/customer/packageUnutilizedbalance?${convertRequestBodyObject(pagination)}`),
  productUnutilizedBalanceList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ProductUnutilizedBalanceListObject[]>>(`/report/customer/productUnutilizedbalance?${convertRequestBodyObject(pagination)}`),
  averageSpendingSummary: (averageSpendingSummaryGetObject: AverageSpendingSummaryGetObject) => requests.get<AverageSpendingSummaryObject>(`/report/customer/spendingsummary?branchId=${averageSpendingSummaryGetObject.branchId}&year=${averageSpendingSummaryGetObject.year || ""}&month=${averageSpendingSummaryGetObject.month || ""}`),
  creditHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CreditHistoryObject[]>>(`/report/customer/credithistory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&customerId=${pagination.customerId || ""}&movementType=${pagination.movementType || pagination.movementType === 0 ? pagination.movementType : ""}`),
  transferCreditHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CreditHistoryObject[]>>(`/report/customer/transfercredithistory?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&customerId=${pagination.customerId || ""}&movementType=${pagination.movementType || pagination.movementType === 0 ? pagination.movementType : ""}`),
  expiringPackageList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ExpiringPackageObject[]>>(`/report/customer/expiringpackage?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&expired=${pagination.expired}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  withoutAppointmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<WithoutAppointmentObject[]>>(`/report/customer/withoutappointment?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&consultantId=${pagination.consultantId || ""}`),
  customerBirthdayList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerBirthdayObject[]>>(`/report/customer/birthday?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&month=${pagination.month || ""}`),
  upsalesSummary: (upsalesSummaryGetObject: UpsalesSummaryGetObject) => requests.get<UpsalesSummaryObject>(`/report/customer/upsalessummary?branchId=${upsalesSummaryGetObject.branchId}&fromDate=${upsalesSummaryGetObject.dateRange?.fromDate || ""}&toDate=${upsalesSummaryGetObject.dateRange?.toDate || ""}`),
  withoutPackageList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<WithoutPackageListObject[]>>(`/report/customer/withoutpackage?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  topSpenderList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<TopSpenderListObject[]>>(`/report/customer/topspender?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
}

const ReportServices = {
  servicesByEmployeeList: (servicesByEmployeeListGetObject: ServicesByEmployeeListGetObject) => requests.get<ServicesByEmployeeListObject>(`/report/services/byemployee?branchId=${servicesByEmployeeListGetObject.branchId || ""}&fromDate=${servicesByEmployeeListGetObject.dateRange.fromDate || ""}&toDate=${servicesByEmployeeListGetObject.dateRange.toDate || ""}`),
}

const ReportStock = {
  stockForecastSummaryList: (stockForecastSummaryListGetObject: StockForecastSummaryListGetObject) => requests.get<StockForecastSummaryListObject>(`/report/stock/forecastsummary?${convertRequestBodyObject(stockForecastSummaryListGetObject)}`),
  stockCardDetailsList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockCardDetailsListObject[]>>(`/report/stock/stockcard?${convertRequestBodyObject(pagination)}`),
  stockLevelDetailsList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockLevelDetailsListObject[]>>(`/report/stock/stocklevel?${convertRequestBodyObject(pagination)}`),
  stockReceiveVariant: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockReceiveVariantListObject[]>>(`/report/stock/stockreceivevariant?${convertRequestBodyObject(pagination)}`),
  reservedProductSummaryList: (reservedProductSummaryExportObject: ReservedProductSummaryExportObject) => requests.get<ReservedProductSummaryObject[]>(`/report/inventory/reservedproductsummary?${convertRequestBodyObject(reservedProductSummaryExportObject)}`),
  stockLevelHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockLevelHistoryListObject[]>>(`/report/stock/stocklevelhistory?${convertRequestBodyObject(pagination)}`),
  reversedProductReversalSummary: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ReservedProductReversalSummaryListObject[]>>(`/report/stock/reservedproductreversalsummary?${convertRequestBodyObject(pagination)}`)
}

const ReportManagement = {
  companyRevenueSummaryList: (companyRevenueSummaryListGetObject: CompanyRevenueSummaryListGetObject) => requests.get<CompanyRevenueSummaryListObject>(`/report/management/companyrevenue?businessEntityId=${companyRevenueSummaryListGetObject.businessEntityId || ""}&year=${companyRevenueSummaryListGetObject.year || ""}&month=${companyRevenueSummaryListGetObject.month || ""}`),
  salesOrderCostList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SalesOrderCostListObject[]>>(`/report/management/salesordercost?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  accountPostList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AccountPostListObject[]>>(`/report/management/accountpost?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&salesOrderNo=${pagination.customerSalesOrderNo || ""}`),
}

const ReportMasterData = {
  productMasterDataList: (pagination: PaginationRequestBody)=> requests.get<ProductMasterDataListObject[]>(`/report/masterdata/product?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&name=${pagination.productName || ""}&sKU=${pagination.sKU || ""}`),
  packageMasterDataList: (pagination: PaginationRequestBody)=> requests.get<PackageMasterDataListObject[]>(`/report/masterdata/package?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&name=${pagination.packageName || ""}`),
  serviceTreatmentMasterDataList: (pagination: PaginationRequestBody)=> requests.get<ServiceTreatmentMasterDataListObject[]>(`/report/masterdata/serviceTreatment?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&name=${pagination.serviceName || ""}`),
}

const ReportHumanResource = {
  dayOffList: (pagination: PaginationRequestBody)=> requests.get<DayOffListObject[]>(`/report/hr/dayoff?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year}&month=${pagination.month}&branchId=${pagination.branchId}`),
  outletScheduleList: (pagination: PaginationRequestBody)=> requests.get<OutletScheduleListObject[]>(`/report/hr/outletschedule?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year}&month=${pagination.month}&branchId=${pagination.branchId}`),
}

const ReportAppolous = {
  appolousPaymentCollectionSummaryList: (appolousPaymentCollectionSummaryListGetObject: AppolousPaymentCollectionSummaryListGetObject) => requests.get<AppolousPaymentCollectionSummaryObject>(`/report/appolouspayment/collectionsummary?branchId=${appolousPaymentCollectionSummaryListGetObject.branchId || ""}&fromDate=${appolousPaymentCollectionSummaryListGetObject.dateRange.fromDate || ""}&toDate=${appolousPaymentCollectionSummaryListGetObject.dateRange.toDate || ""}`),
  appolousDynamicSalesList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppolousDynamicSalesListObject[]>>(`/report/appolous/dynamicsales?${convertRequestBodyObject(pagination)}`),
  appolousProductCommissionVariantList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppolousProductCommissionVariantListObject[]>>(`/report/appolous/productcommissionvariant?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  appolousFreePackageAndProductList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppolousFreePackageAndProductListObject[]>>(`/report/appolous/freepackageandproduct?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&customerId=${pagination.customerId || ""}&productId=${pagination.productId || ""}&packageId=${pagination.packageId || ""}`),
}

const NotificationTemplate = {
  notificationTemplateList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<NotificationTemplateListObject[]>>(`/notificationtemplate?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  notificationTemplateDropdownList: () => requests.get<NotificationTemplateListObject[]>(`/notificationtemplate/dropdown`),
  notificationTemplateDetail: (id: string) => requests.get<NotificationTemplateDetailObject>(`/notificationtemplate/${id}`),
  addNotificationTemplate: (notificationTemplateRequestBody: NotificationTemplateAddObject) => requests.post(`/notificationtemplate`, notificationTemplateRequestBody),
  updateNotificationTemplate: (notificationTemplateRequestBody: NotificationTemplateUpdateObject) => requests.put(`/notificationtemplate`, notificationTemplateRequestBody),
  deleteNotificationTemplate: (id: string) => requests.del<{}>(`/notificationtemplate/${id}`)
}

const StockAdjustment = {
  stockAdjustmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockAdjustmentListObject[]>>(`/stockadjustment?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&status=${pagination.stockAdjustmentStatus || pagination.stockAdjustmentStatus === 0 ? pagination.stockAdjustmentStatus : ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  stockAdjustmentDetail: (id: string) => requests.get<StockAdjustmentDetailObject>(`/stockadjustment/${id}`),
  addStockAdjustment: (stockAdjustmentRequestBody: StockAdjustmentAddObject) => requests.post(`/stockadjustment`, stockAdjustmentRequestBody),
  updateStockAdjustment: (stockAdjustmentRequestBody: StockAdjustmentUpdateObject) => requests.put(`/stockadjustment`, stockAdjustmentRequestBody),
  deleteStockAdjustment: (id: string) => requests.del<{}>(`/stockadjustment/${id}`),
  stockAdjustmentPreload: (stockTakeId: string) => requests.get<StockAdjustmentPreloadObject>(`/stockadjustment/preload/stocktake/${stockTakeId}`),
  stockAdjustmentPrintAllList: (pagination: PaginationRequestBody) => requests.get<StockAdjustmentDetailObject[]>(`/stockadjustment/printall?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&status=${pagination.stockAdjustmentStatus || pagination.stockAdjustmentStatus === 0 ? pagination.stockAdjustmentStatus : ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`)
}

const StockIn = {
  stockInList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockInListObject[]>>(`/stockin?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&warehouseId=${pagination.warehouseId || ""}&status=${pagination.stockInOutStatus || pagination.stockInOutStatus === 0 ? pagination.stockInOutStatus : ""}`),
  stockInDetail: (id: string) => requests.get<StockInDetailObject>(`/stockin/${id}`),
  addStockIn: (stockInRequestBody: StockInAddObject) => requests.post(`/stockin`, stockInRequestBody),
  updateStockIn: (stockInRequestBody: StockInUpdateObject) => requests.put(`/stockin`, stockInRequestBody),
  deleteStockIn: (id: string) => requests.del<{}>(`/stockin/${id}`)
}

const StockOut = {
  stockOutList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockOutListObject[]>>(`/stockout?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&warehouseId=${pagination.warehouseId || ""}&status=${pagination.stockInOutStatus || pagination.stockInOutStatus === 0 ? pagination.stockInOutStatus : ""}`),
  stockOutDetail: (id: string) => requests.get<StockOutDetailObject>(`/stockout/${id}`),
  addStockOut: (stockOutRequestBody: StockOutAddObject) => requests.post(`/stockout`, stockOutRequestBody),
  updateStockOut: (stockOutRequestBody: StockOutUpdateObject) => requests.put(`/stockout`, stockOutRequestBody),
  deleteStockOut: (id: string) => requests.del<{}>(`/stockin/${id}`)
}

const StockReturnToHQ = {
  stockReturnToHQList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockReturnToHQListObject[]>>(`/hqstockreturn?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&&branchId=${pagination.branchId || ""}&status=${pagination.stockReturnToHQStatus || pagination.stockReturnToHQStatus === 0 ? pagination.stockReturnToHQStatus : ""}`),
  branchProductForHQStockReturnList: (branchId: string) => requests.get<BranchProductForHQStockReturnObject[]>(`/hqstockreturn/branchproduct/${branchId}`),
  stockReturnToHQDetail: (id: string) => requests.get<StockReturnToHQDetailObject>(`/hqstockreturn/${id}`),
  addStockReturnToHQ: (stockReturnToHQRequestBody: StockReturnToHQAddObject) => requests.post(`/hqstockreturn`, stockReturnToHQRequestBody),
  updateStockReturnToHQ: (stockReturnToHQRequestBody: StockReturnToHQUpdateObject) => requests.put(`/hqstockreturn`, stockReturnToHQRequestBody),
  deleteStockReturnToHQ: (id: string) => requests.del<{}>(`/hqstockreturn/${id}`)
}

const PaymentMethod = {
  paymentMethodList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PaymentMethodListObject[]>>(`/paymentmethod?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}`),
  paymentMethodDropdownList: () => requests.get<PaymentMethodListObject[]>(`/paymentmethod/dropdown`),
  paymentMethodDetail: (id: string) => requests.get<PaymentMethodDetailObject>(`/paymentmethod/${id}`),
  addPaymentMethod: (paymentMethodRequestBody: PaymentMethodAddObject) => requests.post(`/paymentmethod`, paymentMethodRequestBody),
  updatePaymentMethod: (paymentMethodRequestBody: PaymentMethodUpdateObject) => requests.put(`/paymentmethod`, paymentMethodRequestBody),
  deletePaymentMethod: (id: string) => requests.del<{}>(`/paymentmethod/${id}`)
}

const Payslip = {
  payslipList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PayslipListObject[]>>(`/payslip?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year || ""}&month=${pagination.month || ""}`),
  payslipDetail: (id: string) => requests.get<PayslipDetailObject>(`/payslip/${id}`),
  addPayslip: (payslipRequestBody: PayslipAddObject) => requests.post(`/payslip`, payslipRequestBody),
}

const AdditionalFormField = {
  additionalFormFieldList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AdditionalFormFieldListObject[]>>(`/additionalformfield?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  additionalFormFieldFormTypeList: (formType: string) => requests.get<AdditionalFormFieldWithFormType[]>(`/additionalformfield/form/${formType}`),
  additionalFormFieldDetail: (id: string) => requests.get<AdditionalFormFieldDetailObject>(`/additionalformfield/${id}`),
  addAdditionalFormField: (additionalFormFieldRequestBody: AdditionalFormFieldAddObject) => requests.post(`/additionalformfield`, additionalFormFieldRequestBody),
  updateAdditionalFormField: (additionalFormFieldRequestBody: AdditionalFormFieldUpdateObject) => requests.put(`/additionalformfield`, additionalFormFieldRequestBody),
  deleteAdditionalFormField: (id: string) => requests.del<{}>(`/additionalformfield/${id}`)
}

const Notification = {
  notificationList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<NotificationListObject[]>>(`/notification?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&notificationType=${pagination.notificationType || pagination.notificationType === 0 ? pagination.notificationType : ""}&to=${pagination.to || ""}&isViewAll=${pagination.isViewAll || false}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  notificationDetail: (id: string) => requests.get<NotificationDetailObject>(`/notification/${id}`),
}

const StockTake = {
  stockTakeList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<StockTakeListObject[]>>(`/stocktake?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&status=${pagination.stockTakeStatus || pagination.stockTakeStatus === 0 ? pagination.stockTakeStatus : ""}&branchId=${pagination.branchId || ""}`),
  stockTakeDetail: (id: string) => requests.get<StockTakeDetailObject>(`/stocktake/${id}`),
  addStockTake: (stockTakeRequestBody: StockTakeAddObject) => requests.post<StockTakePreloadObject>(`/stocktake`, stockTakeRequestBody),
  updateStockTake: (stockTakeRequestBody: StockTakeUpdateObject) => requests.put<StockTakePreloadObject>(`/stocktake`, stockTakeRequestBody),
  deleteStockTake: (id: string) => requests.del<{}>(`/stocktake/${id}`)
}

const AuditLog = {
  auditLogList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AuditLogListObject[]>>(`/auditlog?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&entityName=${pagination.entityName}&entityId=${pagination.entityId || ""}&auditType=${pagination.auditType || pagination.auditType === 0 ? pagination.auditType : ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  auditLogDetail: (id: string) => requests.get<AuditLogDetailObject>(`/auditlog/${id}`),
}

const CustomerCart = {
  customerCartList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerCartListObject[]>>(`/customercart?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}`),
  customerCartDetail: (id: string) => requests.get<CustomerCartDetailObject>(`/customercart/${id}`),
  addCustomerCart: (customerCartRequestBody: CustomerCartAddObject) => requests.post<AddCustomerCartObject>(`/customercart`, customerCartRequestBody),
  updateCustomerCart: (customerCartRequestBody: CustomerCartUpdateObject) => requests.put(`/customercart`, customerCartRequestBody),
  deleteCustomerCart: (id: string) => requests.del<{}>(`/customercart/${id}`),
  deleteCustomerCartByBranch: (id: string) => requests.del<{}>(`/customercart/branch/${id}`)
}

const Lead = {
  leadList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<LeadListObject[]>>(`/lead?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&subBrandId=${pagination.subBrandId || ""}&branchId=${pagination.branchId}&sourceId=${pagination.sourceId}&submissionFromDate=${pagination.submissionDateRange?.fromDate || ""}&submissionToDate=${pagination.submissionDateRange?.toDate || ""}&appointmentFromDate=${pagination.appointmentDateRange?.fromDate || ""}&appointmentToDate=${pagination.appointmentDateRange?.toDate || ""}&status=${pagination.leadStatus || pagination.leadStatus === 0 ? pagination.leadStatus : ""}&marcomConsultantId=${pagination.marcomConsultantId || ""}&name=${pagination.leadName || ""}&mobileNo=${pagination.leadMobileNo || ""}&email=${pagination.leadEmail || ""}&leadNo=${pagination.leadNo || ""}&isFilterExistingCustomer=${pagination.isFilterExistingCustomer || false}&isFilterClosedLeads=${pagination.isFilterClosedLeads || false}`),
  leadDropdown: (leadDropdownRequestBody: LeadDropdownObject) => requests.get<LeadListObject[]>(`/lead/dropdown?branchId=${leadDropdownRequestBody.branchId || ""}&sourceId=${leadDropdownRequestBody.sourceId || ""}&marcomConsultantId=${leadDropdownRequestBody.marcomConsultantId || ""}&isFilterExistingCustomer=${leadDropdownRequestBody.isFilterExistingCustomer}&isFilterClosedLeads=${leadDropdownRequestBody.isFilterClosedLeads}`),
  leadDetail: (id: string) => requests.get<LeadDetailObject>(`/lead/${id}`),
  addLead: (leadRequestBody: LeadAddObject) => requests.post<LeadAddSuccessObject>(`/lead`, leadRequestBody),
  updateLead: (leadRequestBody: LeadUpdateObject) => requests.put<LeadAddSuccessObject>(`/lead`, leadRequestBody),
  changeLeadStatus: (changeLeadStatusRequestBody: ChangeLeadStatusRequestBody) => requests.post(`/lead/status/${changeLeadStatusRequestBody.leadId}/${changeLeadStatusRequestBody.status}`, changeLeadStatusRequestBody),
  changeWalkInLeadStatus: (changeLeadStatusRequestBody: ChangeLeadStatusRequestBody) => requests.post(`/lead/walkinstatus/${changeLeadStatusRequestBody.leadId}/${changeLeadStatusRequestBody.status}`, changeLeadStatusRequestBody),
  deleteLead: (id: string) => requests.del<{}>(`/lead/${id}`),
  exportLead: (exportLeadRequestBody: LeadExportObject) => requests.post(`/lead/export`, exportLeadRequestBody),
  importLead: (platformType: string | number, leadImportRequestBody: LeadImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(leadImportRequestBody, formData);
    return requests.postWithFormData<LeadImportSuccessObject>(`/lead/import${platformType === 0 || platformType !== " " ? `/${platformType}` : ""}`, formData)
  },
  importLeadConfirmation: (leadRequestBody: LeadImportSuccessObject) => requests.post(`/lead/import/confirm`, leadRequestBody),
  followUpList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<FollowUpListObject[]>>(`/lead/followup?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&leadId=${pagination.leadId}`),
  followUpDetail: (id: string) => requests.get<FollowUpDetailObject>(`/lead/followup/${id}`),
  addFollowUp: (leadRequestBody: FollowUpAddObject) => requests.post(`/lead/followup`, leadRequestBody),
  updateFollowUp: (leadRequestBody: FollowUpUpdateObject) => requests.put(`/lead/followup`, leadRequestBody),
  deleteFollowUp: (id: string) => requests.del<{}>(`/lead/followup/${id}`),
  leadDuplicateList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<LeadDuplicateListObject[]>>(`/lead/duplicate?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&sourceId=${pagination.sourceId}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&marcomConsultantId=${pagination.marcomConsultantId || ""}`),
  leadDuplicateDetail: (id: string) => requests.get<LeadDuplicateDetailObject>(`/lead/duplicate/${id}`),
  deleteLeadDuplicate: (id: string) => requests.del<{}>(`/lead/duplicate/${id}`),
  reassignLead: (reassignLeadRequestBody: ReassignLeadObject) => requests.put(`/lead/reassign`, reassignLeadRequestBody),
}

const CustomerSalesOrder = {
  customerSalesOrderList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerSalesOrderListObject[]>>(`/customersalesorder?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&subBrandId=${pagination.subBrandId || ""}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}&status=${pagination.salesOrderStatus || pagination.salesOrderStatus === 0 ? pagination.salesOrderStatus : ""}&salesOrderNo=${pagination.customerSalesOrderNo || ""}&paymentStatus=${pagination.paymentStatus || pagination.paymentStatus === 0 ? pagination.paymentStatus : ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  submitCustomerSalesOrder: (customerSalesOrderRequestBody: CustomerSalesOrderObject) => requests.post<CustomerSalesOrderPaymentSuccess>(`/customersalesorder`, customerSalesOrderRequestBody),
  updateCustomerSalesOrder: (customerSalesOrderRequestBody: CustomerSalesOrderUpdateObject) => requests.put<UpdateCustomerSalesOrderObject>(`/customersalesorder`, customerSalesOrderRequestBody),
  customerSalesOrderDetail: (id: string) => requests.get<CustomerSalesOrderDetailObject>(`/customersalesorder/${id}?branchId=${getBranchId()}`),
  customerSalesOrderSiteMapDetail: (id: string) => requests.get<CustomerSalesOrderSiteMapObject>(`/customersalesorder/sitemap/${id}`),
  submitAdditionalPayment: (customerSalesOrderRequestBody: CustomerSalesOrderPaymentObject) => requests.post<CustomerSalesOrderAddPaymentSuccess>(`/customersalesorder/payment`, customerSalesOrderRequestBody),
  customerSalesOrderRedeem: (pagination: PaginationRequestBody) => requests.get<CustomerSalesOrderRedeemListObject[]>(`/customersalesorder/redeem?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}&salesOrderId=${pagination.salesOrderId || ""}&salesOrderDetailId=${pagination.salesOrderDetailId || ""}&branchId=${pagination.branchId}`),
  addCustomerSalesOrderRedeem: (customerSalesOrderReedemRequestBody: CustomerSalesOrderRedeemSubmitObject) => requests.post<CustomerSalesOrderRedeemSuccessObject>(`/customersalesorder/redeem`, customerSalesOrderReedemRequestBody),
  convertCustomerRefundCredit: (refundCreditRequestBody: RefundCreditObject) => requests.post(`/customersalesorder/refundcredit/convert`, refundCreditRequestBody),
  customerRefundCreditList: (id: string) => requests.get<RefundCreditListObject>(`/customersalesorder/refundcredit/${id}?branchId=${getBranchId()}`),
  customerSalesOrderUpgradeList: (customerId: string) => requests.get<CustomerSalesOrderUpgradeList[]>(`/customersalesorder/upgrade/${customerId}`),
  submitCustomerSalesOrderUpgrade: (customerSalesOrderUpgradeRequestbody: CustomerSalesOrderUpgradeSubmitObject) => requests.post<CustomerSalesOrderUpgradeResponseObject>(`/customersalesorder/upgrade`, customerSalesOrderUpgradeRequestbody),
  reviewCustomerRefundCredit: (reviewRefundCreditRequestBody: CustomerSalesOrderRefundReviewObject) => requests.put(`/customersalesorder/refundcredit/review`, reviewRefundCreditRequestBody),
  updateCustomerSalesOrderConsultant: (customerSalesOrderUpdateConsultantRequestBody: CustomerSalesOrderUpdateConsultantObject) => requests.put(`/customersalesorder/consultants`, customerSalesOrderUpdateConsultantRequestBody),
  customerSalesOrderReceiptDetail: (id: string) => requests.get<CustomerSalesOrderReceiptObject>(`/customersalesorder/receipt/${id}`),
  customerSalesOrderPaymentHistoryDetail: (id: string) => requests.get<CustomerSalesOrderReceiptObject>(`/customersalesorder/paymenthistory/${id}`),
  signCustomerSalesOrder: (signCustomerSalesOrderRequestBody: CustomerSalesOrderSignObject) => requests.post<AutoRedemptionGeneratedObject>(`/customersalesorder/sign`, signCustomerSalesOrderRequestBody),
  requestVoidCustomerSalesOrder: (requestVoidCustomerSalesOrderRequestBody: CustomerSalesOrderVoidObject) => requests.post(`/customersalesorder/void/request`, requestVoidCustomerSalesOrderRequestBody),
  reviewVoidCustomerSalesOrder: (reviewVoidCustomerSalesOrderRequestBody: CustomerSalesOrderVoidObject) => requests.put(`/customersalesorder/void/review`, reviewVoidCustomerSalesOrderRequestBody),
  customerSalesOrderCommissionDetail: (id: string) => requests.get<CustomerSalesOrderCommissionObject>(`/customersalesorder/commission/${id}`),
  updateCustomerSalesOrderStatus: (id: string) => requests.put(`/customersalesorder/status/${id}`, {}),
  updateCustomerSalesOrderSubBrand: (updateCustomerSalesOrderSubBrandRequestBody: CustomerSalesOrderUpdateSubBrandObject) => requests.put(`/customersalesorder/subbrand`, updateCustomerSalesOrderSubBrandRequestBody),
  customerSalesOrderProductRedemptionList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerSalesOrderProductRedemptionListObject[]>>(`/customersalesorder/productredemption?${convertRequestBodyObject(pagination)}`),
}

const Settings = {
  appointmentSettings: () => requests.get<AppointmentSettings>(`/setting/appointment`),
  updateAppointmentSettings: (appointmentSettingsRequestBody: AppointmentSubmitObject) => requests.put(`/setting/appointment`, appointmentSettingsRequestBody),
  generalSettings: () => requests.get<GeneralSettings>(`/setting/general`),
  updateGeneralSettings: (generalSettingsRequestBody: GeneralSubmitObject) => requests.put(`/setting/general`, generalSettingsRequestBody),
  salesSettings: () => requests.get<SalesSettings>(`/setting/sales`),
  updateSalesSettings: (salesSettingsRequestBody: SalesSubmitObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(salesSettingsRequestBody, formData);
    return requests.putWithFormData(`/setting/sales`, formData)
  },
  clearCache: () => requests.del<{}>(`/cache/clearall`)
}

const Promotion = {
  promotionList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PromotionListObject[]>>(`/promotion?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&promotionCode=${pagination.promotionCode || ""}&isActive=${typeof(pagination.isActive) === 'boolean' ? pagination.isActive : ""}`),
  promotionDropdownList: () => requests.get<PromotionDropdownListObject[]>(`/promotion/dropdown`),
  addPromotion: (promotionRequestBody: PromotionAddObject) => requests.post(`/promotion`, promotionRequestBody),
  updatePromotion: (promotionRequestBody: PromotionUpdateObject) => requests.put(`/promotion`, promotionRequestBody),
  promotionDetail: (id: string) => requests.get<PromotionDetailObject>(`/promotion/${id}`),
  promotionListWithCode: (promotionRequestBody: PromotionListWithCodeRequestBody) => requests.get<PromotionListWithCodeObject>(`/promotion/code/${promotionRequestBody.promotionCode}?customerId=${promotionRequestBody.customerId || ""}&branchId=${promotionRequestBody.branchId || ""}`),
  deletePromotion: (id: string) => requests.del<{}>(`/promotion/${id}`)
}

const CustomerRefund = {
  customerRefundList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerRefundListObject[]>>(`/customerrefund?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}&status=${pagination.returnRefundStatus || pagination.returnRefundStatus === 0 ? pagination.returnRefundStatus : ""}`),
  customerRefundDetail: (id: string) => requests.get<CustomerRefundDetailObject>(`/customerrefund/${id}`),
}

const CreditAdjustment = {
  creditAdjustmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CreditAdjustmentListObject[]>>(`/creditadjustment?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}&status=${pagination.returnRefundStatus || pagination.returnRefundStatus === 0 ? pagination.returnRefundStatus : ""}&adjustmentType=${pagination.creditAdjustmentType || pagination.creditAdjustmentType === 0 ? pagination.creditAdjustmentType : ""}`),
  creditAdjustmentDetail: (id: string) => requests.get<CreditAdjustmentDetailObject>(`/creditadjustment/${id}`),
  previewCreditAdjustment: (creditAdjustmentRequestBody: CreditAdjustmentPreviewObject) => requests.post(`/creditadjustment/preview`, creditAdjustmentRequestBody),
  requestCreditAdjustment: (creditAdjustmentRequestBody: CreditAdjustmentRequestObject) => requests.post(`/creditadjustment/request`, creditAdjustmentRequestBody),
  creditAdjustmentReview: (creditAdjustmentRequestBody: CreditAdjustmentReviewObject) => requests.put(`/creditadjustment/review`, creditAdjustmentRequestBody),
  deleteCreditAdjustment: (id: string) => requests.del<{}>(`/creditadjustment/${id}`)
}

const TransferCredit = {
  transferCreditHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<TransferCreditHistoryListObject[]>>(`/customer/transfercredit?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  transferCustomerCredit: (transferCreditRequestBody: TransferCreditObject) => requests.post(`/transfercredit`, transferCreditRequestBody),
}

const EmployeeLeave = {
  employeeLeaveList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<EmployeeLeaveListObject[]>>(`/employeeleave?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&employeeId=${pagination.employeeId || ""}&departmentId=${pagination.departmentId || ""}&status=${pagination.leaveStatus || pagination.leaveStatus === 0 ? pagination.leaveStatus : ""}&leaveType=${pagination.leaveType || pagination.leaveType === 0 ? pagination.leaveType : ""}&isFilterHQStaff=${pagination.isFilterHQStaff}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&referenceNo=${pagination.referenceNo || ""}&isFilterActiveStaff=${typeof(pagination.isFilterActiveStaff) === 'boolean' ? pagination.isFilterActiveStaff : ""}`),
  employeeLeaveDropdownList: () => requests.get<EmployeeLeaveListObject[]>(`/employeeleave/dropdown`),
  employeeLeaveDetail: (id: string) => requests.get<EmployeeLeaveDetailObject>(`/employeeleave/${id}`),
  addEmployeeLeave: (employeeLeaveRequestBody: EmployeeLeaveAddObject) => requests.post(`/employeeleave`, employeeLeaveRequestBody),
  updateEmployeeLeave: (employeeLeaveRequestBody: EmployeeLeaveUpdateObject) => requests.put(`/employeeleave`, employeeLeaveRequestBody),
  deleteEmployeeLeave: (id: string) => requests.del<{}>(`/employeeleave/${id}`),
  exportEmployeeLeave: (exportEmployeeLeaveRequestBody: EmployeeLeaveExportObject) => requests.post(`/employeeleave/export/hr2000`, exportEmployeeLeaveRequestBody),
  importEmployeeLeave: (employeeLeaveImportRequestBody: EmployeeLeaveImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(employeeLeaveImportRequestBody, formData);
    return requests.postWithFormData<ImportEmployeeLeaveConfirmationObject>(`/employeeleave/import/hr2000`, formData)
  },
  importEmployeeLeaveConfirmation: (importEmployeeLeaveConfirmationRequestBody: ImportEmployeeLeaveConfirmationObject) => requests.post(`/employeeleave/import/hr2000/confirm`, importEmployeeLeaveConfirmationRequestBody),
  exportDayOffLeave: (exportDayOffRequestBody: EmployeeLeaveExportObject) => requests.post(`/employeeleave/export/hr2000/dayoff`, exportDayOffRequestBody),
  importDayOffLeave: (dayOffImportRequestBody: EmployeeLeaveImportObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(dayOffImportRequestBody, formData);
    return requests.postWithFormData<ImportDayOffLeaveConfirmationObject>(`/employeeleave/import/hr2000/dayoff`, formData)
  },
  importDayOffLeaveConfirmation: (importDayOffLeaveConfirmationRequestBody: ImportDayOffLeaveConfirmationObject) => requests.post(`/employeeleave/import/hr2000/dayoff/confirm`, importDayOffLeaveConfirmationRequestBody),
  deleteEmployeeLeaveWithDateRange: (employeeLeaveDeleteWithDateRangeObject: EmployeeLeaveDeleteWithDateRangeObject) => requests.del<{}>(`/employeeleave/delete/daterange?year=${employeeLeaveDeleteWithDateRangeObject.year}&month=${employeeLeaveDeleteWithDateRangeObject.month}`),
}

const Dashboard = {
  servicePerformedCount: (chartObject: ChartRequestBodyObject) => requests.get<ChartObject>(`/dashboard/serviceperformedcount?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  salesSummary: (chartObject: ChartRequestBodyObject) => requests.get<ChartObject>(`/dashboard/salessummary?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  salesCategorySummary: (chartObject: ChartRequestBodyObject) => requests.get<ChartObject>(`/dashboard/salescategorysummary?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  hourlySalesSummary: (chartObject: ChartRequestBodyObject) => requests.get<ChartObject>(`/dashboard/hourlysalesSummary?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  salesPaymentSummary: (chartObject: ChartRequestBodyObject) => requests.get<SalesPaymentSummaryObject[]>(`/dashboard/salespaymentsummary?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  newCustomer: (chartObject: ChartRequestBodyObject) => requests.get<ChartObject>(`/dashboard/newcustomercount?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  dailyCustomerBirthday: (chartObject: ChartRequestBodyObject) => requests.get<DailyCustomerBirthdayObject[]>(`/dashboard/dailycustomerbirthday?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  appointmentCount: (chartObject: ChartRequestBodyObject) => requests.get<ChartObject>(`/dashboard/appointmentcount?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  todayUpcomingAppointment: (chartObject: ChartRequestBodyObject) => requests.get<UpcomingAppointmentObject[]>(`/dashboard/todayupcomingappointment?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  weeklyUpcomingAppointment: (chartObject: ChartRequestBodyObject) => requests.get<UpcomingAppointmentObject[]>(`/dashboard/weeklyupcomingappointment?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  topSelling: (chartObject: ChartRequestBodyObject) => requests.get<MonthlyOfTopObject[]>(`/dashboard/topselling?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}&purchaseType=${chartObject.purchaseType}`),
  topCustomerSales: (chartObject: ChartRequestBodyObject) => requests.get<MonthlyOfTopSalesObject[]>(`/dashboard/topcustomersales?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
  weeklyPendingOrders: (chartObject: ChartRequestBodyObject) => requests.get<WeeklyPendingOrders[]>(`/dashboard/weeklypendingorders?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}&isFilterOutTodayOrder=${chartObject.isFilterOutTodayOrder}`),
  dailySalesSummary: (chartObject: ChartRequestBodyObject) => requests.get<DailySalesSummaryObject>(`/dashboard/dailysalessummary?dashboardPeriod=${chartObject.dashboardPeriod || ""}&subBrandId=${chartObject.subBrandId || ""}`),
  referralSpendingSummary: (chartObject: ChartRequestBodyObject) => requests.get<ReferralSpendingSummary[]>(`/dashboard/referralSpendingSummary?dashboardPeriod=${chartObject.dashboardPeriod || ""}&subBrandId=${chartObject.subBrandId || ""}`),
  salesByEmployeeSummary: (chartObject: ChartRequestBodyObject) => requests.get<SalesByEmployeeSummaryObject[]>(`/dashboard/salesbyemployeesummary?branchId=${chartObject.branchId}&dashboardPeriod=${chartObject.dashboardPeriod || ""}`),
}

const CustomerPaymentAdjustment = {
  customerPaymentAdjustmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerPaymentAdjustmentListObject[]>>(`/customerpaymentadjustment?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&status=${pagination.paymentAdjustmentStatus || pagination.paymentAdjustmentStatus === 0 ? pagination.paymentAdjustmentStatus : ""}&branchId=${pagination.branchId || ""}&customerPaymentAdjustmentNo=${pagination.customerPaymentAdjustmentNo || ""}&customerSalesOrderNo=${pagination.customerSalesOrderNo || ""}`),
  customerPaymentAdjustmentDetail: (id: string) => requests.get<CustomerPaymentAdjustmentDetailObject>(`/customerpaymentadjustment/${id}`),
  requestCustomerPaymentAdjustment: (customerPaymentAdjustmentRequestBody: CustomerPaymentAdjustmentRequestObject) => requests.post<AutoRedemptionGeneratedObject>(`/customerpaymentadjustment/request`, customerPaymentAdjustmentRequestBody),
  customerPaymentAdjustmentReview: (customerPaymentAdjustmentRequestBody: CustomerPaymentAdjustmentReviewObject) => requests.put<AutoRedemptionGeneratedObject>(`/customerpaymentadjustment/review`, customerPaymentAdjustmentRequestBody),
  deleteCustomerPaymentAdjustment: (id: string) => requests.del<{}>(`/customerpaymentadjustment/${id}`),
  customerPaymentAdjustmentWithSalesOrder: (customerPaymentAdjustmentWithSalesOrderRequestBody: CustomerPaymentAdjustmentWithSalesOrderObject) => requests.get<CustomerPaymentAdjustmentWithSalesOrderDetailObject[]>(`/customerpaymentadjustment/salesorder?salesOrderId=${customerPaymentAdjustmentWithSalesOrderRequestBody.salesOrderId}&branchId=${customerPaymentAdjustmentWithSalesOrderRequestBody.branchId}`),
}

const CustomerProductReturn = {
  customerProductReturnList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerProductReturnListObject[]>>(`/customerproductreturn?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerDeliverOrderId=${pagination.customerDeliverOrderId || ""}&branchId=${pagination.branchId || ""}&status=${pagination.customerProductReturnStatus || pagination.customerProductReturnStatus === 0 ? pagination.customerProductReturnStatus : ""}`),
  customerProductReturnDetail: (id: string) => requests.get<CustomerProductReturnDetailObject>(`/customerproductreturn/${id}`),
  addCustomerProductReturn: (customerProductReturnRequestBody: CustomerProductReturnAddObject) => requests.post(`/customerproductreturn`, customerProductReturnRequestBody),
  updateCustomerProductReturn: (customerProductReturnRequestBody: CustomerProductReturnUpdateObject) => requests.put(`/customerproductreturn`, customerProductReturnRequestBody),
  customerProductReturnEligibleList: (customerId: string) => requests.get<CustomerProductReturnEligibleListObject[]>(`/customerproductreturn/deliverorder/eligible/${customerId || ""}`),
}

const ExtendPackage = {
  extendPackageList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ExtendPackageListObject[]>>(`/extendpackage?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&status=${pagination.extendPackageStatus || pagination.extendPackageStatus === 0 ? pagination.extendPackageStatus : ""}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}`),
  extendPackageDetail: (id: string) => requests.get<ExtendPackageDetailObject>(`/extendpackage/${id}`),
  requestExtendPackage: (extendPackageRequestBody: ExtendPackageRequestObject) => requests.post(`/extendpackage/request`, extendPackageRequestBody),
  extendPackageReview: (extendPackageRequestBody: ExtendPackageReviewObject) => requests.put(`/extendpackage/review`, extendPackageRequestBody),
  deleteExtendPackage: (id: string) => requests.del<{}>(`/extendpackage/${id}`)
}

const PaymentMethodOption = {
  paymentMethodOptionList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PaymentMethodOptionListObject[]>>(`/paymentmethodoption?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&optionType=${pagination.optionType || pagination.optionType === 0 ? pagination.optionType : ""}&name=${pagination.name || ""}&isActive=${typeof(pagination.isActive) === 'boolean' ? pagination.isActive : ""}`),
  paymentMethodOptionDropdownList: (paymentMethodId: string) => requests.get<PaymentMethodOptionListObject[]>(`/paymentmethodoption/dropdown/${paymentMethodId}`),
  paymentMethodOptionDetail: (id: string) => requests.get<PaymentMethodOptionDetailObject>(`/paymentmethodoption/${id}`),
  addPaymentMethodOption: (paymentMethodOptionRequestBody: PaymentMethodOptionAddObject) => requests.post(`/paymentmethodoption`, paymentMethodOptionRequestBody),
  updatePaymentMethodOption: (paymentMethodOptionRequestBody: PaymentMethodOptionUpdateObject) => requests.put(`/paymentmethodoption`, paymentMethodOptionRequestBody),
  deletePaymentMethodOption: (id: string) => requests.del<{}>(`/paymentmethodoption/${id}`)
}

const SalesAdjustment = {
  salesAdjustmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<SalesAdjustmentListObject[]>>(`/salesadjustment?${convertRequestBodyObject(pagination)}`),
  salesAdjustmentDetail: (id: string) => requests.get<SalesAdjustmentDetailObject>(`/salesadjustment/${id}`),
  addSalesAdjustment: (salesAdjustmentRequestBody: SalesAdjustmentAddObject) => requests.post(`/salesadjustment`, salesAdjustmentRequestBody),
  updateSalesAdjustment: (salesAdjustmentRequestBody: SalesAdjustmentUpdateObject) => requests.put(`/salesadjustment`, salesAdjustmentRequestBody),
  deleteSalesAdjustment: (id: string) => requests.del<{}>(`/salesadjustment/${id}`)
}

const CommissionScheme = {
  commissionSchemeList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CommissionSchemeListObject[]>>(`/commissionscheme?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&commissionSchemeType=${pagination.commissionSchemeType || pagination.commissionSchemeType === 0 ? pagination.commissionSchemeType : ""}`),
  commissionSchemeProductList: () => requests.get<CommissionSchemeProductListObject[]>(`/commissionscheme/products`),
  commissionSchemePackageList: () => requests.get<CommissionSchemePackageListObject[]>(`/commissionscheme/packages`),
  commissionSchemeDetail: (id: string) => requests.get<CommissionSchemeDetailObject>(`/commissionscheme/${id}`),
  addCommissionScheme: (commissionSchemeRequestBody: CommissionSchemeAddObject) => requests.post(`/commissionscheme`, commissionSchemeRequestBody),
  updateCommissionScheme: (commissionSchemeRequestBody: CommissionSchemeUpdateObject) => requests.put(`/commissionscheme`, commissionSchemeRequestBody),
  deleteCommissionScheme: (id: string) => requests.del<{}>(`/commissionscheme/${id}`)
}

const CustomerAccessRequest = {
  customerAccessRequestList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerAccessRequestListObject[]>>(`/customeraccessrequest?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&accessRequestStatus=${pagination.customerAccessRequestStatus || pagination.customerAccessRequestStatus === 0 ? pagination.customerAccessRequestStatus : ""}&originalBranchId=${pagination.originalBranchId || ""}&requestBranchId=${pagination.requestBranchId || ""}&customerId=${pagination.customerId || ""}&customerName=${pagination.customerName || ""}&customerNo=${pagination.customerNo || ""}${pagination.isFilterActiveRequestsOnly ? "&isFilterActiveRequestsOnly=true": ""}`),
  customerAccessRequestDetail: (id: string) => requests.get<CustomerAccessRequestDetailObject>(`/customeraccessrequest/${id}`),
  addCustomerAccessRequest: (customerAccessRequestRequestBody: CustomerAccessRequestAddObject) => requests.post(`/customeraccessrequest`, customerAccessRequestRequestBody),
  updateCustomerAccessRequest: (customerAccessRequestRequestBody: CustomerAccessRequestUpdateObject) => requests.put(`/customeraccessrequest`, customerAccessRequestRequestBody),
  deleteCustomerAccessRequest: (id: string) => requests.del<{}>(`/customeraccessrequest/${id}`),
  extendCustomerAccessRequest: (customerAccessExtendRequestBody: CustomerAccessRequestExtendObject) => requests.put(`/customeraccessrequest/extend`, customerAccessExtendRequestBody),
}

const CustomerTransferRequest = {
  customerTransferRequestList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerTransferRequestListObject[]>>(`/customertransferrequest?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&transferStatus=${pagination.customerTransferRequestStatus || pagination.customerTransferRequestStatus === 0 ? pagination.customerTransferRequestStatus : ""}&fromBranchId=${pagination.fromBranchId || ""}&toBranchId=${pagination.toBranchId || ""}&customerId=${pagination.customerId || ""}&customerName=${pagination.customerName || ""}&customerNo=${pagination.customerNo || ""}&identityType=${pagination.identityType || pagination.identityType === 0 ? pagination.identityType : ""}&identityNo=${pagination.identityNo || ""}`),
  customerTransferRequestDetail: (id: string) => requests.get<CustomerTransferRequestDetailObject>(`/customertransferrequest/${id}`),
  addCustomerTransferRequest: (customerTransferRequestRequestBody: CustomerTransferRequestAddObject) => requests.post(`/customertransferrequest`, customerTransferRequestRequestBody),
  updateCustomerTransferRequest: (customerTransferRequestRequestBody: CustomerTransferRequestUpdateObject) => requests.put(`/customertransferrequest`, customerTransferRequestRequestBody),
  deleteCustomerTransferRequest: (id: string) => requests.del<{}>(`/customertransferrequest/${id}`)
}

const AdditionBonusSalesTarget = {
  additionBonusSalesTargetList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AdditionBonusSalesTargetListObject[]>>(`/additionbonussalestarget?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year || ""}&month=${pagination.month || ""}&additionBonusType=${pagination.additionBonusType || pagination.additionBonusType === 0 ? pagination.additionBonusType : ""}`),
  additionBonusSalesTargetDetail: (id: string) => requests.get<AdditionBonusSalesTargetDetailObject>(`/additionbonussalestarget/${id}`),
  addAdditionBonusSalesTarget: (additionBonusSalesTargetRequestBody: AdditionBonusSalesTargetAddObject) => requests.post(`/additionbonussalestarget`, additionBonusSalesTargetRequestBody),
  updateAdditionBonusSalesTarget: (additionBonusSalesTargetRequestBody: AdditionBonusSalesTargetUpdateObject) => requests.put(`/additionbonussalestarget`, additionBonusSalesTargetRequestBody),
  deleteAdditionBonusSalesTarget: (id: string) => requests.del<{}>(`/additionbonussalestarget/${id}`),
  additionBonusSalesTargetDuplicate: (additionBonusSalesTargetDuplicatedRequestObject: AdditionBonusSalesTargetDuplicatedRequestObject) => requests.post(`/additionbonussalestarget/duplicate`, additionBonusSalesTargetDuplicatedRequestObject)
}

const BranchCommission = {
  branchCommissionList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<BranchCommissionListObject[]>>(`/branchcommission?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year || ""}&month=${pagination.month || ""}&branchId=${pagination.branchId || ""}`),
  branchCommissionDetail: (id: string, passcode: number | string) => requests.get<BranchCommissionDetailObject>(`/branchcommission/${id}?passcode=${passcode}`),
  addBranchCommission: (branchCommissionRequestBody: BranchCommissionAddObject) => requests.post(`/branchcommission`, branchCommissionRequestBody),
  updateBranchCommission: (branchCommissionRequestBody: BranchCommissionUpdateObject) => requests.put(`/branchcommission`, branchCommissionRequestBody),
  branchCommissionReviewFirstApproval: (branchCommissionRequestBody: BranchCommissionUpdateObject) => requests.put(`/branchcommission/review/firstapproval`, branchCommissionRequestBody),
  branchCommissionReviewSecondApproval: (branchCommissionRequestBody: BranchCommissionUpdateObject) => requests.put(`/branchcommission/review/secondapproval`, branchCommissionRequestBody),
  branchCommissionReviewFinalApproval: (branchCommissionRequestBody: BranchCommissionUpdateObject) => requests.put(`/branchcommission/review/finalapproval`, branchCommissionRequestBody),
  deleteBranchCommission: (id: string) => requests.del<{}>(`/branchcommission/${id}`)
}

const CustomerVirtualWarehouse = {
  customerVirtualWarehouseList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerVirtualWarehouseListObject[]>>(`/customerVirtualWarehouse?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}&deliverOrderNo=${pagination.deliverOrderNo || ""}&virtualWarehouseNo=${pagination.virtualWarehouseNo || ""}&productId=${pagination.productId || ""}&status=${pagination.virtualWarehouseStatus || pagination.virtualWarehouseStatus === 0 ? pagination.virtualWarehouseStatus : ""}`),
  customerVirtualWarehouseDetail: (id: string) => requests.get<CustomerVirtualWarehouseDetailObject>(`/customerVirtualWarehouse/${id}`),
  customerVirtualWarehouseMovementList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerVirtualWarehouseMovementListObject[]>>(`/customerVirtualWarehouse/movements?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&virtualWarehouseId=${pagination.virtualWarehouseId || ""}`),
}

const ProductRedemptionNote = {
  productRedemptionNoteList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ProductRedemptionNoteListObject[]>>(`/customerVirtualWarehouse/redemption?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}&productRedemptionNo=${pagination.productRedemptionNo || ""}&status=${pagination.productRedemptionStatus || pagination.productRedemptionStatus === 0 ? pagination.productRedemptionStatus : ""}`),
  productRedemptionNoteDetail: (id: string) => requests.get<ProductRedemptionNoteDetailObject>(`/customerVirtualWarehouse/redemption/${id}`),
  productRedemptionEligibleList: (customerId: string, deliverOrderId?: string) => requests.get<ProductRedemptionEligibleListObject[]>(`/customerVirtualWarehouse/eligible?customerId=${customerId || ""}&deliverOrderId=${deliverOrderId || ""}`),
  addProductRedemptionNote: (productRedemptionRequestBody: ProductRedemptionNoteAddObject) => requests.post<ProductRedemptionNoteAddResponseObject>(`/customerVirtualWarehouse/redemption`, productRedemptionRequestBody),
  signProductRedemptionNote: (signProductRedemptionNoteRequestBody: ProductRedemptionNoteSignObject) => requests.post<ProductRedemptionNoteSignSuccessObject>(`/customervirtualwarehouse/redemption/sign`, signProductRedemptionNoteRequestBody),
  requestVoidProductRedemptionNote: (requestVoidProductRedemptionNoteRequestBody: ProductRedemptionNoteVoidObject) => requests.post(`/customervirtualwarehouse/redemption/void/request`, requestVoidProductRedemptionNoteRequestBody),
  reviewVoidProductRedemptionNote: (reviewVoidProductRedemptionNoteRequestBody: ProductRedemptionNoteVoidObject) => requests.put(`/customervirtualwarehouse/redemption/void/review`, reviewVoidProductRedemptionNoteRequestBody),
  cancelProductRedemptionNote: (id: string) => requests.put(`/customerVirtualWarehouse/redemption/cancel/${id}`, {}),
}

const ContractLiability = {
  contractLiabilitySiteMap: (customerId: string) => requests.get<ContractLiabilitySiteMapObject>(`/customer/contractLiability/sitemap/${customerId}`),
  contractLiabilityDetail: (id: string) => requests.get<ContractLiabilityDetailObject>(`/customer/contractLiability/${id}`),
  contractLiabilityTimelineDetail: (id: string) => requests.get<ContractLiabilityTimelineObject>(`/customer/contractLiability/timeline/${id}`)
}

const VirtualWarehouseCreditExchange = {
  virtualWarehouseCreditExchangeList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<VirtualWarehouseCreditExchangeListObject[]>>(`/customerVirtualWarehouse/creditexchange?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId}&customerId=${pagination.customerId || ""}&virtualWarehouseCreditExchangeNo=${pagination.virtualWarehouseCreditExchangeNo || ""}&status=${pagination.virtualWarehouseCreditExchangeStatus || pagination.virtualWarehouseCreditExchangeStatus === 0 ? pagination.virtualWarehouseCreditExchangeStatus : ""}`),
  virtualWarehouseCreditExchangeDetail: (id: string) => requests.get<VirtualWarehouseCreditExchangeDetailObject>(`/customerVirtualWarehouse/creditexchange/${id}`),
  requestVirtualWarehouseCreditExchange: (virtualWarehouseCreditExchangeRequestBody: VirtualWarehouseCreditExchangeRequestObject) => requests.post(`/customerVirtualWarehouse/creditexchange/request`, virtualWarehouseCreditExchangeRequestBody),
  cancelVirtualWarehouseCreditExchange: (id: string) => requests.put(`/customervirtualwarehouse/creditexchange/cancel/${id}`, id),
  reviewVirtualWarehouseCreditExchange: (reviewVirtualWarehouseCreditExchangeRequestBody: VirtualWarehouseCreditExchangeReviewObject) => requests.put(`/customervirtualwarehouse/creditexchange/review`, reviewVirtualWarehouseCreditExchangeRequestBody),
}

const ProductRedemptionNoteReturn = {
  productRedemptionNoteReturnList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ProductRedemptionNoteReturnListObject[]>>(`/customerproductreturn/redemptionnote?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}&productRedemptionNoteReturnNo=${pagination.productRedemptionNoteReturnNo || ""}&status=${pagination.productRedemptionNoteReturnStatus || pagination.productRedemptionNoteReturnStatus === 0 ? pagination.productRedemptionNoteReturnStatus : ""}`),
  productRedemptionNoteReturnDetail: (id: string) => requests.get<ProductRedemptionNoteReturnDetailObject>(`/customerproductreturn/redemptionnote/${id}`),
  productRedemptionNoteReturnEligibleList: (customerId: string) => requests.get<ProductRedemptionNoteReturnEligibleListObject[]>(`/customerproductreturn/redemptionnote/eligible/${customerId || ""}`),
  addProductRedemptionNoteReturn: (productRedemptionRequestBody: ProductRedemptionNoteReturnAddObject) => requests.post<ProductRedemptionNoteAddResponseObject>(`/customerproductreturn/redemptionnote/request`, productRedemptionRequestBody),
  reviewProductRedemptionNoteReturn: (reviewVoidProductRedemptionNoteRequestBody: ProductRedemptionNoteReturnReviewObject) => requests.put(`/customerproductreturn/redemptionnote/review`, reviewVoidProductRedemptionNoteRequestBody),
  cancelProductRedemptionNoteReturn: (id: string) => requests.put(`/customerproductreturn/redemptionnote/cancel/${id}`, {}),
}

const DSRAdjustment = { 
  dsrAdjustmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<DSRAdjustmentListObject[]>>(`/dsr?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&customerId=${pagination.customerId || ""}&type=${pagination.type}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}`),
  addDSRAdjustment: (dsrRequestBody: DSRAdjustmentAddObject) => requests.post(`/dsr`, dsrRequestBody),
  deleteDSR: (id: string) => requests.del<{}>(`/dsr/${id}`)
}

const BatchJob = {
  batchJobList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<BatchJobListObject[]>>(`/batchjob?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  batchClassDropdownList: () => requests.get<string[]>(`/batchjob/batchclass`),
  batchJobDetail: (id: string) => requests.get<BatchJobDetailObject>(`/batchjob/${id}`),
  addBatchJob: (batchJobRequestBody: BatchJobAddObject) => requests.post(`/batchjob`, batchJobRequestBody),
  updateBatchJob: (batchJobRequestBody: BatchJobUpdateObject) => requests.put(`/batchjob`, batchJobRequestBody),
  deleteBatchJob: (id: string) => requests.del<{}>(`/batchjob/${id}`),
  batchJobHistoryList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<BatchJobHistoryListObject[]>>(`/batchjob/history?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&batchJobId=${pagination.batchJobId || ""}`),
  batchJobHistoryDetail: (id: string) => requests.get<BatchJobHistoryDetailObject>(`/batchjob/history/${id}`),
  refreshBatchJob:(id: string) => requests.put(`/batchjob/refresh/${id}`, {}),
}

const CustomerSubBranchRequest = {
  customerSubBranchRequestList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerSubBranchRequestListObject[]>>(`/customersubbranchrequest?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&requestBranchId=${pagination.requestBranchId || ""}&requestType=${pagination.requestType}&status=${pagination.status}&subBranchId=${pagination.subBranchId || ""}&customerName=${pagination.customerName || ""}&customerNo=${pagination.customerNo || ""}`),
  customerSubBranchRequestDetail: (id: string) => requests.get<CustomerSubBranchRequestDetailObject>(`/customersubbranchrequest/${id}`),
  addCustomerSubBranchRequest: (customerSubBranchRequestBody: CustomerSubBranchRequestAddObject) => requests.post<OnlyIdResponse>(`/customersubbranchrequest`, customerSubBranchRequestBody),
  updateCustomerSubBranchRequest: (customerSubBranchRequestBody: CustomerSubBranchRequestUpdateObject) => requests.put<OnlyIdResponse>(`/customersubbranchrequest`, customerSubBranchRequestBody),
  deleteCustomerSubBranchRequest: (id: string) => requests.del<OnlyIdResponse>(`/customersubbranchrequest/${id}`),
  reviewCustomerSubBranchRequest: (reviewCustomerSubBranchRequestRequestBody: CustomerSubBranchRequestReviewObject) => requests.put(`/customersubbranchrequest/review`, reviewCustomerSubBranchRequestRequestBody),
}

const AppolousCustomerPackage = {
  appolousCustomerPackage: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppolousCustomerPackageListObject[]>>(`/appolous/customerpackage?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  appolousCustomerPackageDetail: (appolousCustomerPackageDetailRequestBody: AppolousCustomerPackageDetailRequestBody) => requests.get<AppolousCustomerPackageDetailObject>(`/appolous/customerpackage/details?appolousCustomerPackageId=${appolousCustomerPackageDetailRequestBody.appolousCustomerPackageId}&isIncludeRedemption=${appolousCustomerPackageDetailRequestBody.isIncludeRedemption}&branchId=${appolousCustomerPackageDetailRequestBody.branchId}`),
  appolousCustomerPackageRedemptionDetail: (appolousCustomerPackageRedemptionId: string) => requests.get<AppolousCustomerPackageRedemptionObject>(`/appolous/customerpackage/redemption/${appolousCustomerPackageRedemptionId}`),
  extendExpiryAppolousCustomerPackage: (appolousCustomerPackageExtendExpiryRequestBody: AppolousCustomerPackageExtendExpiryRequestBody) => requests.put(`/appolous/customerpackage/extendexpiry`, appolousCustomerPackageExtendExpiryRequestBody),
  statusUpdateAppolousCustomerPackage: (appolousCustomerPackageStatusUpdateRequestBody: AppolousCustomerPackageStatusUpdateRequestBody) => requests.put(`/appolous/customerpackage/statusupdate`, appolousCustomerPackageStatusUpdateRequestBody),
  editAppolousCustomerPackage: (appolousCustomerPackageEditRequestBody: AppolousCustomerPackageEditRequestBody) => requests.put(`/appolous/customerpackage/edit`, appolousCustomerPackageEditRequestBody),
  redeemAppolousCustomerPackage: (appolousCustomerPackageRedeemRequestBody: AppolousCustomerPackageRedeemRequestBody) => requests.post(`/appolous/customerpackage/redeem`, appolousCustomerPackageRedeemRequestBody),
  voidAppolousCustomerPackageRedemption: (appolousCustomerPackageRedemptionId: string) => requests.del(`/appolous/customerpackage/redemption/void/${appolousCustomerPackageRedemptionId}`),
  addAppolousCustomerPackage: (appolousCustomerPackageAddRequestBody: AppolousCustomerPackageAddRequestBody) => requests.post(`/appolous/customerpackage`, appolousCustomerPackageAddRequestBody),
  editAppolousCustomerPackageDetail: (appolousCustomerPackageRedemptionEditRequestBody: AppolousCustomerPackageRedemptionEditRequestBody) => requests.put(`/appolous/customerpackage/redemption/edit`, appolousCustomerPackageRedemptionEditRequestBody),
}

const AppolousCustomerProduct = {
  appolousCustomerProduct: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppolousCustomerProductListObject[]>>(`/appolous/customerproduct?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  appolousCustomerProductDetail: (id: string) => requests.get<AppolousCustomerProductDetailObject>(`/appolous/customerproduct/details?id=${id}`),
  redeemAppolousCustomerProduct: (appolousCustomerProductRedeemRequestBody: AppolousCustomerProductRedeemRequestBody) => requests.post(`/appolous/customerproduct/redeem`, appolousCustomerProductRedeemRequestBody),
  statusUpdateAppolousCustomerProduct: (appolousCustomerProductStatusUpdateRequestBody: AppolousCustomerProductStatusUpdateRequestBody) => requests.put(`/appolous/customerproduct/statusupdate`, appolousCustomerProductStatusUpdateRequestBody),
  voidAppolousCustomerProductRedemption: (appolousCustomerProductRedemptionId: string) => requests.del(`/appolous/customerproduct/redemption/void/${appolousCustomerProductRedemptionId}`),
  addAppolousCustomerProduct: (appolousCustomerProductAddRequestBody: AppolousCustomerProductAddRequestBody) => requests.post(`/appolous/customerproduct`, appolousCustomerProductAddRequestBody),
}

const AppolousCustomerSales = {
  appolousCustomerSale: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppolousCustomerSaleListObject[]>>(`/appolous/customersale?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&customerId=${pagination.customerId || ""}`),
  appolousCustomerSaleDetail: (id: string) => requests.get<AppolousCustomerSaleDetailsObject>(`/appolous/customersale/${id}`),
  appolousSubmitAdditionalPayment: (appolousSalesAddPaymentRequestBody: AppolousSalesAddPaymentObject) => requests.post(`/appolous/customersale/payment`, appolousSalesAddPaymentRequestBody),
  appolousUpdateAdditionalPayment: (appolousSalesUpdatePaymentRequestBody: AppolousSalesUpdatePaymentObject) => requests.put(`/appolous/customersale/payment`, appolousSalesUpdatePaymentRequestBody),
  voidAppolousCustomerSalesPayment: (id: string) => requests.del<{}>(`/appolous/customersale/payment/void/${id}`),
  voidAppolousCustomerSales: (id: string) => requests.del<{}>(`/appolous/customersale/void/${id}`),
}

const VirtualWarehouseStockMovement = {
  virtualWarehouseStockMovementList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<VirtualWarehouseStockMovementListObject[]>>(`/virtualwarehousestockmovement?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&productId=${pagination.productId || ""}&movementType=${pagination.movementType || pagination.movementType === 0 ? pagination.movementType : ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&virtualWarehouseNo=${pagination.virtualWarehouseNo || ""}`),
}

const AppointmentTarget = {
  appointmentTargetList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppointmentTargetListObject[]>>(`/appointmenttarget?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&year=${pagination.year || ""}&month=${pagination.month || ""}&subBrandId=${pagination.subBrandId || ""}`),
  appointmentTargetDetail: (id: string) => requests.get<AppointmentTargetDetailObject>(`/appointmenttarget/${id}`),
  addAppointmentTarget: (appointmentTargetRequestBody: AppointmentTargetAddObject) => requests.post<AddAppointmentTargetObject>(`/appointmenttarget`, appointmentTargetRequestBody),
  updateAppointmentTarget: (appointmentTargetRequestBody: AppointmentTargetUpdateObject) => requests.put(`/appointmenttarget`, appointmentTargetRequestBody),
  deleteAppointmentTarget: (id: string) => requests.del<{}>(`/appointmenttarget/${id}`),
  appointmentTargetDuplicate: (appointmentTargetDuplicatedRequestObject: AppointmentTargetDuplicatedRequestObject) => requests.post(`/appointmenttarget/duplicate`, appointmentTargetDuplicatedRequestObject)
}

const MarcomDailyRecord = { 
  marcomDailyRecordList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<MarcomDailyRecordListObject[]>>(`/marcomdailyrecord?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&type=${pagination.type}&year=${pagination.year || ""}&month=${pagination.month || ""}`),
  addMarcomDailyRecord: (marcomDailyRecordRequestBody: MarcomDailyRecordAddObject) => requests.post(`/marcomdailyrecord`, marcomDailyRecordRequestBody),
  deleteMarcomDailyRecord: (id: string) => requests.del<{}>(`/marcomdailyrecord/${id}`)
}  
  
const Machine = {
  machineList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<MachineListObject[]>>(`/machine?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&modelNumber=${pagination.modelNumber || ""}&name=${pagination.name || ""}&consumptionType=${pagination.consumptionType || ""}${pagination.branchId ? `&branchId=${pagination.branchId}` : ""}`),
  machineDropdownList: () => requests.get<MachineDropdownListObject[]>(`/machine/dropdown`),
  machineDetail: (id: string) => requests.get<MachineDetailObject>(`/machine/${id}`),
  addMachine: (machineRequestBody: MachineAddObject) => requests.post(`/machine`, machineRequestBody),
  updateMachine: (machineRequestBody: MachineUpdateObject) => requests.put(`/machine`, machineRequestBody),
  deleteMachine: (id: string) => requests.del<{}>(`/machine/${id}`),
  branchMachineList: (branchMachineListRequestBody: BranchMachineListRequestObject) => requests.get<BranchMachineListObject[]>(`/machine/branchaccess/details?branchId=${branchMachineListRequestBody.branchId}&machineId=${branchMachineListRequestBody.machineId}`),
  machineUsage: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<MachineUsageListObject[]>>(`/machineusage?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&branchId=${pagination.branchId || ""}&machineId=${pagination.machineId || ""}&machineUsageType=${pagination.machineUsageType || pagination.machineUsageType === 0 ? pagination.machineUsageType : ""}&fromDate=${pagination.dateRange?.fromDate || ""}&toDate=${pagination.dateRange?.toDate || ""}&serialNumber=${pagination.serialNumber || ""}&redemptionId=${pagination.redemptionId || ""}`),
  addBranchMachine: (branchMachineRequestBody: BranchMachineAddObject) => requests.put(`/machine/branchaccess/create`, branchMachineRequestBody),
  updateBranchMachine: (branchMachineRequestBody: BranchMachineUpdateObject) => requests.put(`/machine/branchaccess/update`, branchMachineRequestBody),
  resetBranchMachine: (branchMachineId: string) => requests.put(`/machine/branchaccess/reset/${branchMachineId}`, {}),
}

const Cache = { 
  cacheList: (blnShowAll: boolean) => requests.get<string[]>(`/cache/${blnShowAll ? true : false}`),
  cacheDetail: (cacheKey: string) => requests.get<any>(`/cache/view/${cacheKey}`),
  deleteCache: (id: string) => requests.del<{}>(`/cache/clear/${id}`),
  cacheSize: () => requests.get(`/cache/size`)
}

const Department = {
  departmentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<DepartmentListObject[]>>(`/department?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&name=${pagination.name || ""}`),
  departmentDropdownList: () => requests.get<DepartmentDropdownListObject[]>(`/department/dropdown`),
  addDepartment: (departmentRequestBody: DepartmentAddObject) => requests.post(`/department`, departmentRequestBody),
  updateDepartment: (departmentRequestBody: DepartmentUpdateObject) => requests.put(`/department`, departmentRequestBody),
  departmentDetail: (id: string) => requests.get<DepartmentDetailObject>(`/department/${id}`),
  deleteDepartment: (id: string) => requests.del<{}>(`/department/${id}`)
}

const CustomerChangePaymentMethod = {
  customerChangePaymentMethodList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CustomerChangePaymentMethodListObject[]>>(`/customerpaymentadjustment/changepaymentmethod?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&status=${pagination.paymentAdjustmentStatus || pagination.paymentAdjustmentStatus === 0 ? pagination.paymentAdjustmentStatus : ""}&branchId=${pagination.branchId || ""}&customerPaymentAdjustmentNo=${pagination.customerPaymentAdjustmentNo || ""}&customerSalesOrderNo=${pagination.customerSalesOrderNo || ""}`),
  customerChangePaymentMethodDetail: (id: string) => requests.get<CustomerChangePaymentMethodDetailObject>(`/customerpaymentadjustment/changepaymentmethod/${id}`),
  requestCustomerChangePaymentMethod: (customerChangePaymentMethodRequestBody: CustomerChangePaymentMethodRequestObject) => requests.post<AutoRedemptionGeneratedObject>(`/customerpaymentadjustment/changepaymentmethod/request`, customerChangePaymentMethodRequestBody),
  customerChangePaymentMethodReview: (customerChangePaymentMethodRequestBody: CustomerChangePaymentMethodReviewObject) => requests.put<AutoRedemptionGeneratedObject>(`/customerpaymentadjustment/changepaymentmethod/review`, customerChangePaymentMethodRequestBody),
  deleteCustomerChangePaymentMethod: (id: string) => requests.del<{}>(`/customerpaymentadjustment/changepaymentmethod/${id}`),
  customerChangePaymentMethodWithSalesOrder: (customerChangePaymentMethodWithSalesOrderRequestBody: CustomerChangePaymentMethodWithSalesOrderObject) => requests.get<CustomerChangePaymentMethodWithSalesOrderDetailObject[]>(`/customerpaymentadjustment/changepaymentmethod/salesorder?salesOrderId=${customerChangePaymentMethodWithSalesOrderRequestBody.salesOrderId}&branchId=${customerChangePaymentMethodWithSalesOrderRequestBody.branchId}`),
}

const AppointmentLead = {
  appointmentLeadList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppointmentLeadListObject[]>>(`/appointment/lead?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&subBrandId=${pagination.subBrandId || ""}&branchId=${pagination.branchId}&sourceId=${pagination.sourceId}&raceId=${pagination.raceId}&appointmentFromDate=${pagination.appointmentDateRange?.fromDate || ""}&appointmentToDate=${pagination.appointmentDateRange?.toDate || ""}&name=${pagination.leadName || ""}&mobileNo=${pagination.leadMobileNo || ""}&email=${pagination.leadEmail || ""}&leadNo=${pagination.leadNo || ""}&status=${pagination.leadStatus || ""}`),
  appointmentLeadDetail: (id: string) => requests.get<AppointmentLeadDetailObject>(`/appointment/lead/${id}`),
  appointmentLeadFollowUpList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<AppointmentLeadFollowUpListObject[]>>(`/appointment/lead/followup?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&leadId=${pagination.leadId}`),
  appointmentLeadFollowUpDetail: (id: string) => requests.get<AppointmentLeadFollowUpDetailObject>(`/appointment/lead/followup/${id}`),
  addAppointmentLeadFollowUp: (appointmentLeadFollowUpRequestBody: AppointmentLeadFollowUpAddObject) => requests.post(`/appointment/lead/followup`, appointmentLeadFollowUpRequestBody),
  updateAppointmentLeadFollowUp: (appointmentLeadFollowUpRequestBody: AppointmentLeadFollowUpUpdateObject) => requests.put(`/appointment/lead/followup`, appointmentLeadFollowUpRequestBody),
  deleteAppointmentLeadFollowUp: (id: string) => requests.del<{}>(`/appointment/lead/followup/${id}`),
}

const ContractLiabilityBenchmark = {
  contractLiabilityBenchmarkList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ContractLiabilityBenchmarkListObject[]>>(`/contractliabilitybenchmark?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`),
  contractLiabilityBenchmarkDetail: (id: string) => requests.get<ContractLiabilityBenchmarkDetailObject>(`/contractliabilitybenchmark/${id}`),
  addContractLiabilityBenchmark: (contractLiabilityBenchmarkRequestBody: ContractLiabilityBenchmarkAddObject) => requests.post(`/contractliabilitybenchmark`, contractLiabilityBenchmarkRequestBody),
  updateContractLiabilityBenchmark: (contractLiabilityBenchmarkRequestBody: ContractLiabilityBenchmarkUpdateObject) => requests.put(`/contractliabilitybenchmark`, contractLiabilityBenchmarkRequestBody),
  deleteContractLiabilityBenchmark: (contractLiabilityBenchmarkRequestBody: ContractLiabilityBenchmarkDeleteObject) => requests.delWithRequestBody<{}>(`/contractliabilitybenchmark`, contractLiabilityBenchmarkRequestBody)
}

const GroupCode = {
  groupCodeList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<GroupCodeListObject[]>>(`/groupcode?${convertRequestBodyObject(pagination)}`),
  groupCodeDropdown: (groupCodeRequestBody: GroupCodeGetObject) => requests.get<GroupCodeListObject[]>(`/groupcode/dropdown?${convertRequestBodyObject(groupCodeRequestBody)}`),
  groupCodeDetail: (id: string) => requests.get<GroupCodeDetailObject>(`/groupcode/${id}`),
  addGroupCode: (groupCodeRequestBody: GroupCodeAddObject) => requests.post(`/groupcode`, groupCodeRequestBody),
  updateGroupCode: (groupCodeRequestBody: GroupCodeUpdateObject) => requests.put(`/groupcode`, groupCodeRequestBody),
  deleteGroupCode: (id: string) => requests.del<{}>(`/groupcode/${id}`),
}

const ReportAccount = {
  commissionCalculationList: (commissionCalculationListGetObject: CommissionCalculationListGetAndExportObject) => requests.get<CommissionCalculationObject>(`/report/account/commissionCalculation?${convertRequestBodyObject(commissionCalculationListGetObject)}`),
  contractLiabilityReportList: (contractLiabilityReportListGetObject: ContractLiabilityReportListGetAndExportObject) => requests.get<PaginatedResult<ContractLiabilityReportListObject[]>>(`/report/account/contractliability?${convertRequestBodyObject(contractLiabilityReportListGetObject)}`),
  earnSalesSummaryList: (earnSalesSummaryListGetAndExportObject: EarnSalesSummaryListGetAndExportObject) => requests.get<EarnSalesSummaryListObject[]>(`/report/account/earnsalessummary?${convertRequestBodyObject(earnSalesSummaryListGetAndExportObject)}`),
  unearnedSalesDetailsList: (unearnedSalesDetailsListGetAndExportObject: UnearnedSalesDetailsListGetAndExportObject) => requests.get<PaginatedResult<UnearnedSalesDetailsListObject[]>>(`/report/account/unearnedsalesdetails?${convertRequestBodyObject(unearnedSalesDetailsListGetAndExportObject)}`),
  recogniseSalesDetailsList: (recogniseSalesDetailsListGetAndExportObject: RecogniseSalesDetailsListGetAndExportObject) => requests.get<PaginatedResult<RecogniseSalesDetailsListObject[]>>(`/report/account/recognisesalesdetails?${convertRequestBodyObject(recogniseSalesDetailsListGetAndExportObject)}`),
  paymentCollection: (pagination: PaginationRequestBody) => requests.get<PaymentCollectionObject>(`/report/account/paymentcollection?${convertRequestBodyObject(pagination)}`),
  paymentCollectionDetailList: (pagination: PaginationRequestBody, axiosSignal: any) => requests.postWithoutRemoveObjectWithAxiosSignal<PaginatedResult<PaymentCollectionDetailListObject[]>>(`/report/account/paymentcollection/detail`, pagination, axiosSignal),
  changePaymentModeList: (changePaymentModeListGetAndExportObject: ChangePaymentModeListGetAndExportObject) => requests.get<PaginatedResult<ChangePaymentModeReportListObject[]>>(`/report/account/changepaymentmode?${convertRequestBodyObject(changePaymentModeListGetAndExportObject)}`),
}

const UnknownPayment = {
  unknownPaymentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<UnknownPaymentListObject[]>>(`/unknownPayment?${convertRequestBodyObject(pagination)}`),
  unknownPaymentDetail: (id: string) => requests.get<UnknownPaymentDetailObject>(`/unknownPayment/${id}`),
  addUnknownPayment: (unknownPaymentRequestBody: UnknownPaymentAddObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(unknownPaymentRequestBody, formData);
    return requests.postWithFormData(`/unknownPayment`, formData)
  },
  updateUnknownPayment: (unknownPaymentRequestBody: UnknownPaymentUpdateObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(unknownPaymentRequestBody, formData);
    return requests.putWithFormData(`/unknownPayment`, formData)
  },
  deleteUnknownPayment: (id: string) => requests.del<{}>(`/unknownPayment/${id}`)
}

const CashOut = {
  cashOutList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CashOutListObject[]>>(`/cashOut?${convertRequestBodyObject(pagination)}`),
  cashOutDetail: (id: string) => requests.get<CashOutDetailObject>(`/cashOut/${id}`),
  addCashOut: (cashOutRequestBody: CashOutAddObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(cashOutRequestBody, formData);
    return requests.postWithFormData(`/cashOut`, formData)
  },
  updateCashOut: (cashOutRequestBody: CashOutUpdateObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(cashOutRequestBody, formData);
    return requests.putWithFormData(`/cashOut`, formData)
  },
  deleteCashOut: (id: string) => requests.del<{}>(`/cashOut/${id}`)
}

const PaymentTerminal = {
  paymentTerminalList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<PaymentTerminalListObject[]>>(`/paymentTerminal?${convertRequestBodyObject(pagination)}`),
  paymentTerminalDropdownList: () => requests.get<PaymentTerminalListObject[]>(`/paymentTerminal/dropdown`),
  paymentTerminalDetail: (id: string) => requests.get<PaymentTerminalDetailObject>(`/paymentTerminal/${id}`),
  addPaymentTerminal: (paymentTerminalRequestBody: PaymentTerminalAddObject) => requests.post(`/paymentTerminal`, paymentTerminalRequestBody),
  updatePaymentTerminal: (paymentTerminalRequestBody: PaymentTerminalUpdateObject) => requests.put(`/paymentTerminal`, paymentTerminalRequestBody),
  deletePaymentTerminal: (id: string) => requests.del<{}>(`/paymentTerminal/${id}`)
}

const ChangePaymentMode = {
  changePaymentModeList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ChangePaymentModeListObject[]>>(`/changePaymentMode?${convertRequestBodyObject(pagination)}`),
  changePaymentModeDetail: (id: string) => requests.get<ChangePaymentModeDetailObject>(`/changePaymentMode/${id}`),
  addChangePaymentMode: (changePaymentModeRequestBody: ChangePaymentModeAddObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(changePaymentModeRequestBody, formData);
    return requests.postWithFormData<ChangePaymentMethodAddOrUpdateResponseObject>(`/changePaymentMode`, formData)
  },
  updateChangePaymentMode: (changePaymentModeRequestBody: ChangePaymentModeUpdateObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(changePaymentModeRequestBody, formData);
    return requests.putWithFormData<ChangePaymentMethodAddOrUpdateResponseObject>(`/changePaymentMode`, formData)
  },
  deleteChangePaymentMode: (id: string) => requests.del<{}>(`/changePaymentMode/${id}`),
  changePaymentModeOldPaymentList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<ChangePaymentModeOldPaymentListObject[]>>(`/changePaymentMode/oldPayment?${convertRequestBodyObject(pagination)}`),
  changePaymentModePaymentMethodList: (branchId: string) => requests.get<ChangePaymentModePaymentMethodListObject[]>(`/changePaymentMode/paymentmethod/${branchId}`),
  signChangePaymentMode: (signChangePaymentModeRequestBody: ChangePaymentModeSignObject) => requests.put(`/changepaymentmode/sign`, signChangePaymentModeRequestBody),
  cancelChangePaymentMode: (cancelChangePaymentModeRequestBody: ChangePaymentModeCancelObject) => requests.put(`/changepaymentmode/cancel`, cancelChangePaymentModeRequestBody),
  reviewFirstApprovalChangePaymentMode: (reviewFirstApprovalChangePaymentModeRequestBody: ChangePaymentModeReviewFirstApprovalObject) => requests.put(`/changepaymentmode/firstapproval/review`, reviewFirstApprovalChangePaymentModeRequestBody),
  reviewFinalApprovalChangePaymentMode: (reviewFinalApprovalChangePaymentModeRequestBody: ChangePaymentModeReviewFinalApprovalObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(reviewFinalApprovalChangePaymentModeRequestBody, formData);
    return requests.putWithFormData(`/changepaymentmode/finalapproval/review`, formData)
  },
  updateChangePaymentModeAfterCompleted: (updateChangePaymentModeAfterCompletedRequestBody: ChangePaymentModeUpdateAfterCompletedObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(updateChangePaymentModeAfterCompletedRequestBody, formData);
    return requests.putWithFormData(`/changepaymentmode/update/completed`, formData)
  },
}

const Tnc = {
  tncList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<TncListObject[]>>(`/tnc?${convertRequestBodyObject(pagination)}`),
  tncDetail: (id: string) => requests.get<TncDetailObject>(`/tnc/${id}`),
  addTnc: (tncRequestBody: TncAddObject) => requests.post(`/tnc`, tncRequestBody),
  updateTnc: (tncRequestBody: TncUpdateObject) => requests.put(`tnc`, tncRequestBody),
  deleteTnc: (id: string) => requests.del<{}>(`tnc/${id}`),
  getTncWithCategory: (category: string, date: string) => requests.get<TncCategoryObject>(`tnc/category/${category}?date=${date}`)
}

const CloseCounter = {
  closeCounterList: (pagination: PaginationRequestBody) => requests.get<PaginatedResult<CloseCounterListObject[]>>(`/closeCounter?${convertRequestBodyObject(pagination)}`),
  closeCounterDropdownList: () => requests.get<CloseCounterListObject[]>(`/closeCounter/dropdown`),
  closeCounterDetail: (id: string) => requests.get<CloseCounterDetailObject>(`/closeCounter/${id}`),
  addCloseCounter: (closeCounterRequestBody: CloseCounterAddObject) => requests.post(`/closeCounter`, closeCounterRequestBody),
  updateCloseCounter: (closeCounterRequestBody: CloseCounterUpdateObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(closeCounterRequestBody, formData);
    return requests.putWithFormData(`/closeCounter`, formData)
  },
  previewCloseCounter: (closeCounterRequestBody: CloseCounterUpdateObject) => {
    let formData = new FormData();
    convertJSONIntoFormData(closeCounterRequestBody, formData);
    return requests.putWithFormData(`/closeCounter/preview`, formData)
  },
  submitCloseCounter: (id: string) => requests.put(`/closecounter/submit`, {id: id}),
  overrideCloseCounter: (closeCounterRequestBody: OverrideCloseCounterObject) => requests.put(`/closecounter/override`, closeCounterRequestBody),
  deleteCloseCounter: (id: string) => requests.del<{}>(`/closeCounter/${id}`),
  pendingCloseCounter: (branchId: string) => requests.get<CloseCounterPendingObject>(`/closeCounter/pending?branchid=${branchId}`)
}

const TestTimeZone = {
  addTime: (testTimeZoneRequestBody: TestTimeZoneAddObject) => requests.post(`/auditlog`, testTimeZoneRequestBody),
}

const agent = {
    Account,
    Branch,
    Brand,
    BusinessEntity,
    Product,
    ServiceTreatment,
    Package,
    Employee,
    Currency,
    Country,
    Category,
    SalesCategory,
    Tax,
    Measurement,
    StaticReference,
    Race,
    EPFContribution,
    EIS,
    Socso,
    PCB,
    Appointment,
    UserRole,
    Customer,
    MediaSource,
    CustomerOrder,
    Invoice,
    Position,
    Supplier,
    SalesTarget,
    Warehouse,
    DeliveryOrder,
    StockMovement,
    LeaveType,
    PayrollItem,
    StockRequest,
    StockReceive,
    LeaveEntitlement,
    PurchaseOrder,
    InboundNotice,
    GoodsReceiveNote,
    PurchaseDeliverOrder,
    Permission,
    Profile,
    NotificationTemplate,
    StockAdjustment,
    StockIn,
    StockOut,
    StockReturnToHQ,
    PaymentMethod,
    Payslip,
    AdditionalFormField,
    Notification,
    StockTake,
    AuditLog,
    CustomerCart,
    Lead,
    CustomerSalesOrder,
    Settings,
    Promotion,
    CustomerRefund,
    CreditAdjustment,
    TransferCredit,
    EmployeeLeave,
    ReportSales,
    ReportMarcom,
    ReportAppointment,
    ReportPayment,
    ReportCustomer,
    Dashboard,
    ReportServices,
    ReportStock,
    ReportManagement,
    ReportHumanResource,
    CustomerPaymentAdjustment,
    CustomerProductReturn,
    ExtendPackage,
    PaymentMethodOption,
    SalesAdjustment,
    CommissionScheme,
    CustomerAccessRequest,
    ReportMasterData,
    CustomerTransferRequest,
    AdditionBonusSalesTarget,
    BranchCommission,
    CustomerVirtualWarehouse,
    ProductRedemptionNote,
    ContractLiability,
    VirtualWarehouseCreditExchange,
    ProductRedemptionNoteReturn,
    DSRAdjustment,
    BatchJob,
    CustomerSubBranchRequest,
    AppolousCustomerPackage,
    AppolousCustomerProduct,
    AppolousCustomerSales,
    ReportAppolous,
    VirtualWarehouseStockMovement,
    AppointmentTarget,
    MarcomDailyRecord,
    Cache,
    Department,
    CustomerChangePaymentMethod,
    AppointmentLead,
    Machine,
    ContractLiabilityBenchmark,
    GroupCode,
    ReportAccount,
    Tnc,
    UnknownPayment,
    CashOut,
    PaymentTerminal,
    ChangePaymentMode,
    CloseCounter,
    TestTimeZone,
}

export default agent;
