import React, { useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Row } from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, salesOrderStatusColor, getBrandId, returnPriceWithCurrency, purchasedItemStatusColor, getBranchId, convertParamsUrlToArray, compareParamsArrayWithDefaultArray, convertValueWithSpaceInOptionsArray, firstDayOfMonthToCurrentDay, returnSubBrandList, firstDayOfYearToLastDayOfYear } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForReportRow } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link, useLocation } from 'react-router-dom';
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import ReportExpandRow from "../subView/ReportExpandRow";
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import DropDownWithTitle from "../../../app/components/dropdown/DropDownWithTitle";

const DynamicSalesDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, customerStore, staticReferenceStore, employeeStore, productStore, serviceTreatmentStore, packageStore, salesCategoryStore, raceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setErrorMessage } = commonStore;
  const { dynamicSalesDetailsList, getDynamicSalesDetailsList, setDynamicSalesDetailsList, exportDynamicSalesDetails } = reportSalesStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { salesOrderStatus, genderType, getStaticReferenceWithType } = staticReferenceStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { salesCategoryDropdownList, getSalesCategoryDropdown } = salesCategoryStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { packageDropdownList, getPackageDropdown } = packageStore;
  const { raceDropdownList, getRaceDropdown } = raceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingBranchDropdown, setLoadingBranchDropdown] = useState(false);
  const [loadingSalesOrderStatus, setLoadingSalesOrderStatus] = useState(false);
  const [loadingEmployeeDropdown, setLoadingEmployeeDropdown] = useState(false);
  const [loadingCustomerDropdown, setLoadingCustomerDropdown] = useState(false);
  const [loadingSalesCategoryDropdown, setLoadingSalesCategoryDropdown] = useState(false);
  const [loadingProductDropdown, setLoadingProductDropdown] = useState(false);
  const [loadingServiceTreatmentDropdown, setLoadingServiceTreatmentDropdown] = useState(false);
  const [loadingPackageDropdown, setLoadingPackageDropdown] = useState(false);
  const [loadingGenderDropdown, setLoadingGenderDropdown] = useState(false);
  const [loadingRaceDropdown, setLoadingRaceDropdown] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfYearToLastDayOfYear());
  const [selectedBranchOptions, setSelectedBranchOptions] = useState<any>([]);
  const [selectedConsultantOptions, setSelectedConsultantOptions] = useState<any>([]);
  const [selectedCustomerOptions, setSelectedCustomerOptions] = useState<any>([]);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<any>([]);
  const [selectedSalesCategoryOptions, setSelectedSalesCategoryOptions] = useState<any>([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState<any>([]);
  const [selectedPackageOptions, setSelectedPackageOptions] = useState<any>([]);
  const [selectedServiceOptions, setSelectedServiceOptions] = useState<any>([]);
  const [selectedSubBrandOptions, setSelectedSubBrandOptions] = useState<any>([]);
  const [selectedJoinedDateRange, setSelectedJoinedDateRange] = useState<DateRangeInterface | undefined>(undefined);

  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [blnAdvanceFilter, setBlnAdvanceFilter] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [exportMenu, setExportMenu] = useState(false);

  const arySalesOrderDetails: ObjectForReportRow[] = [
    { label: "Name", value: "itemName", type: "string" },
    { label: "Type", value: "purchaseType", type: "string" },
    { label: "SalesCategoryName", value: "salesCategoryName", type: "string" },
    { label: "Count", value: "count", type: "string" },
    { label: "UnitPrice", value: "unitPrice", type: "currency" },
    { label: "TotalPrice", value: "totalPrice", type: "currency" },
    { label: "Status", value: "status", type: "status" },
    { label: "PromotionCode", value: "promotionCode", type: "string" }];

  const paramsSearch = useLocation().search;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchIds: [],
      consultantIds: [],
      customerIds: [],
      salesCategoryIds: [],
      productIds: [],
      serviceIds: [],
      packageIds: [],
      statuses: [],
      subBrandIds: [],
      includeOtherSalesDetails: false,
      isProductOnly: false,
      gender: "",
      raceId: "",
      salesOrderNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  async function fetchBranchDropdown () {
    setLoadingBranchDropdown(true);
    let resultAPI = await getBranchDropDown();
    setLoadingBranchDropdown(false);
    return resultAPI;
  }

  async function fetchSalesOrderStatus () {
    setLoadingSalesOrderStatus(true);
    let resultAPI = await getStaticReferenceWithType(Constants.salesOrderStatus);
    setLoadingSalesOrderStatus(false);
    return resultAPI;
  }

  async function fetchEmployeeDropdown () {
    setLoadingEmployeeDropdown(true);
    let resultAPI = await getEmployeeDropdown({ brandId: getBrandId() });
    setLoadingEmployeeDropdown(false);
    return resultAPI;
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchUser() ? getBranchId() : "", {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomerDropdown(false);
    }
    else if (!value) {
      setLoadingCustomerDropdown(false);
    }
  }

  async function fetchSalesCategoryDropdown () {
    setLoadingSalesCategoryDropdown(true);
    let resultAPI = await getSalesCategoryDropdown();
    setLoadingSalesCategoryDropdown(false);
    return resultAPI;
  }

  async function fetchProductDropdown () {
    setLoadingProductDropdown(true);
    let resultAPI = await getProductDropdown();
    setLoadingProductDropdown(false);
    return resultAPI;
  }

  async function fetchServiceTreatmentDropdown () {
    setLoadingServiceTreatmentDropdown(true);
    let resultAPI = await getServiceTreatmentDropdown();
    setLoadingServiceTreatmentDropdown(false);
    return resultAPI;
  }

  async function fetchPackageDropdown () {
    setLoadingPackageDropdown(true);
    let resultAPI = await getPackageDropdown({ branchId: "" });
    setLoadingPackageDropdown(false);
    return resultAPI;
  }

  async function fetchGenderDropdown () {
    setLoadingGenderDropdown(true);
    await getStaticReferenceWithType(Constants.genderType);
    setLoadingGenderDropdown(false);
  }


  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  useEffect(() => {
    async function fetchDynamicSalesListAPI() {      
      const branchParams = convertParamsUrlToArray("branchId", paramsSearch);
      const statusesParams = convertParamsUrlToArray("statuses", paramsSearch);
      const salesCategoryIdsParams = convertParamsUrlToArray("salesCategoryIds", paramsSearch);
      const customerIdParams = convertParamsUrlToArray("customerId", paramsSearch);
      const packageIdsParams = convertParamsUrlToArray("packageIds", paramsSearch);
      const productIdsParams = convertParamsUrlToArray("productIds", paramsSearch);
      const serviceIdsParams = convertParamsUrlToArray("serviceIds", paramsSearch);
      const consultantIdsParams = convertParamsUrlToArray("consultantIds", paramsSearch);
      const employeeIdParams = convertParamsUrlToArray("employeeId", paramsSearch);
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const includeOtherSalesDetailsParams = Boolean (new URLSearchParams(paramsSearch).get('includeOtherSalesDetailsParams'));
      const subBrandIdsParams = convertParamsUrlToArray("subBrandIds", paramsSearch);

      let blnParams = (branchParams.length > 0 || statusesParams.length > 0 || salesCategoryIdsParams.length > 0 || packageIdsParams.length > 0 || productIdsParams.length > 0 || serviceIdsParams.length > 0 || employeeIdParams.length > 0 || consultantIdsParams.length > 0 || customerIdParams.length > 0 || includeOtherSalesDetailsParams || subBrandIdsParams) && (fromDateParams && toDateParams);
      
      let aryAPI: any = [
        fetchBranchDropdown(),
        fetchSalesOrderStatus(),
        fetchEmployeeDropdown(),
        fetchSalesCategoryDropdown(),
        fetchProductDropdown(),
        fetchServiceTreatmentDropdown(),
        fetchPackageDropdown(),
        fetchGenderDropdown(),
        getRaceDropdown()
      ];
      if (!blnParams) {
        await Promise.all(aryAPI);  
      }
      else {
        setLoading(true);
        setLoadingDropdownRefresh(true);
        
        let resultAPI = await Promise.all(aryAPI);

        if(fromDateParams || toDateParams){
          setSelectedDateRange({ fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" })
        }
        let branchParamsTemp = [];
        if (branchParams) {
          if(branchParams.length > 0 && branchParams[0] !== ""){
            setSelectedBranchOptions(compareParamsArrayWithDefaultArray(branchParams, resultAPI[0], "id", "name", "id"));
            validation.setFieldValue("branchIds", branchParams);
            branchParamsTemp = branchParams;
          } 
        }
        if (salesCategoryIdsParams) {
          setSelectedSalesCategoryOptions(compareParamsArrayWithDefaultArray(salesCategoryIdsParams, resultAPI[3], "id", "name", "id"));
          validation.setFieldValue("salesCategoryIds", salesCategoryIdsParams);
        }
        let employeeParamsTemp = [];
        if (consultantIdsParams || employeeIdParams) {
          if (employeeIdParams && employeeIdParams.length > 0) {
            employeeParamsTemp = employeeIdParams;
          } else{
            employeeParamsTemp = consultantIdsParams;
          }
          setSelectedConsultantOptions(compareParamsArrayWithDefaultArray(employeeParamsTemp, resultAPI[2], "id", "preferredName", "id"));
          validation.setFieldValue("consultantIds", employeeParamsTemp);
        }
        if (customerIdParams) {
          let resultCustomerWithParamsId = await getCustomerDropdown(branchParams, {customerId: customerIdParams});    
          setSelectedCustomerOptions(compareParamsArrayWithDefaultArray(customerIdParams, resultCustomerWithParamsId, "id", "preferredName", "id"));
          validation.setFieldValue("customerIds", customerIdParams);
        }
        if (packageIdsParams) {
          setSelectedPackageOptions(compareParamsArrayWithDefaultArray(packageIdsParams, resultAPI[6], "id", "name", "id"));
          validation.setFieldValue("packageIds", packageIdsParams);
        }
        if (productIdsParams) {
          setSelectedProductOptions(compareParamsArrayWithDefaultArray(productIdsParams, resultAPI[4], "id", "name", "id"));
          validation.setFieldValue("productIds", productIdsParams);
        }
        if (serviceIdsParams) {
          setSelectedServiceOptions(compareParamsArrayWithDefaultArray(serviceIdsParams, resultAPI[5], "id", "name", "id"));
          validation.setFieldValue("serviceIds", serviceIdsParams);
        }
        let statusesParamsIdTemp: string[] = [];
        if (statusesParams) {
          let statusTemp = compareParamsArrayWithDefaultArray(statusesParams, resultAPI[1], "displayValue", "displayValue", "key");
          setSelectedStatusOptions(statusTemp);
          statusesParamsIdTemp = statusTemp.map((valueStatusTemp => valueStatusTemp.key))
          validation.setFieldValue("statuses", statusesParamsIdTemp)
        }
        if (includeOtherSalesDetailsParams) {
          validation.setFieldValue("includeOtherSalesDetails", includeOtherSalesDetailsParams);
        }
        if(subBrandIdsParams){
          setSelectedSubBrandOptions(compareParamsArrayWithDefaultArray(subBrandIdsParams, returnSubBrandList(), "id", "name", "id"));
          validation.setFieldValue("subBrandIds", subBrandIdsParams);
        }

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.dynamicSalesDetails}` });
        await fetchDynamicSalesDetailApi(false, branchParamsTemp, { fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" }, statusesParamsIdTemp, salesCategoryIdsParams, includeOtherSalesDetailsParams, employeeParamsTemp, customerIdParams, packageIdsParams, productIdsParams, serviceIdsParams, subBrandIdsParams);

        setLoading(false);
        setLoadingDropdownRefresh(false);
      }
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchDynamicSalesListAPI();

    return (() => {
      setDynamicSalesDetailsList([]);
    })
  }, []);

  async function fetchDynamicSalesDetailApi(blnDropDown: boolean = false, branchIdTemp?: string[], dateRange?: DateRangeInterface, salesOrderStatusIdTemp?: string[], salesCategoryIdTemp?: string[], includeOtherSalesDetailsTemp?: boolean, employeeIdTemp?: string[], customerIdParams?: string[], packageIdParams?: string[], productIdParams?: string[], serviceIdParams?: string[], subBrandIdsParams?: string[]) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate && !dateRange) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getDynamicSalesDetailsList({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: dateRange || selectedDateRange,
      branchIds: branchIdTemp || validation.values.branchIds,
      consultantIds: employeeIdTemp || validation.values.consultantIds,
      customerIds: customerIdParams || validation.values.customerIds,
      salesCategoryIds: salesCategoryIdTemp || validation.values.salesCategoryIds,
      productIds: productIdParams || validation.values.productIds,
      packageIds: packageIdParams || validation.values.packageIds,
      serviceIds: serviceIdParams || validation.values.serviceIds,
      statuses: salesOrderStatusIdTemp || validation.values.statuses,
      includeOtherSalesDetails: includeOtherSalesDetailsTemp || validation.values.includeOtherSalesDetails,
      subBrandIds: subBrandIdsParams || validation.values.subBrandIds,
      gender: validation.values.gender,
      raceId: validation.values.raceId,
      isProductOnly: validation.values.isProductOnly,
      salesOrderNo: validation.values.salesOrderNo,
      ...selectedJoinedDateRange?.fromDate && { joinedFromDate: selectedJoinedDateRange?.fromDate },
      ...selectedJoinedDateRange?.toDate && { joinedToDate: selectedJoinedDateRange?.toDate },
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportDynamicSalesDetailsApi(templateType: number) {
    // Template Type
    // Cascade = 0
    // WithoutDetails = 1
    // RawData = 2

    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    await exportDynamicSalesDetails({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      branchIds: validation.values.branchIds,
      consultantIds: validation.values.consultantIds,
      customerIds: validation.values.customerIds,
      salesCategoryIds: validation.values.salesCategoryIds,
      productIds: validation.values.productIds,
      packageIds: validation.values.packageIds,
      serviceIds: validation.values.serviceIds,
      statuses: validation.values.statuses,
      includeOtherSalesDetails: validation.values.includeOtherSalesDetails,
      isProductOnly: validation.values.isProductOnly,
      subBrandIds: validation.values.subBrandIds,
      salesOrderNo: validation.values.salesOrderNo,
      ...selectedJoinedDateRange?.fromDate && { joinedFromDate: selectedJoinedDateRange?.fromDate },
      ...selectedJoinedDateRange?.toDate && { joinedToDate: selectedJoinedDateRange?.toDate },
      templateType: templateType
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const onSelectJoinedDateRange = (objDateRange: any) => {
    setSelectedJoinedDateRange(objDateRange);
  }

  //Table Content UI
  const DynamicSalesColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.id ? `/${RoutesList.customerSalesOrder}/view/${row.id}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "salesOrderDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.salesOrderDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.salesOrderDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "gender",
      text: intl.formatMessage({ id: "Gender" }).toUpperCase(),
    },
    {
      dataField: "race",
      text: intl.formatMessage({ id: "Race" }).toUpperCase(),
    },
    {
      dataField: "joinedDate",
      text: intl.formatMessage({ id: "JoinedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.joinedDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.joinedDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalAmount)}</div>
      ),
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPayment)}</div>
      ),
    },
    {
      dataField: "balance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.balance)}</div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={salesOrderStatusColor} />
      ),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "SalesOrderDetails" })}
        rowFieldName={"salesOrderDetails"}
        rowColumns={arySalesOrderDetails}
        keyFieldName={"id"}
        statusColorFunction={purchasedItemStatusColor}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <div>
        {
          !expanded
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    )
  };

  const displayOptionalRow = (mainComponent: any) => {
    return (
      <div className="flex-align-items-center">
        {mainComponent()}
        <div style={{marginTop: '10px', backgroundColor: 'lightgrey', padding: '4px', borderRadius: '20px', marginLeft: "18px"}}>
          <p className="text-muted margin-top-0 margin-bottom-0">OR</p>
        </div>
      </div>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "DynamicSalesDetails" }) })}
        title={intl.formatMessage({ id: "DynamicSalesDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <ButtonDropdown
            isOpen={exportMenu}
            toggle={() => setExportMenu(!exportMenu)}
            className={"d-inline-block"}
            disable={loading ? true : undefined}
            style={{ alignSelf: 'center' }}>
            <DropdownToggle
              className={`btn btn-success margin-left-8`}
              id={`page-header-export-dropdown`}
              tag="button"
              style={{ minWidth: '120px', height: "36px" , borderRadius:'0.25rem' }}>
              <span className="ms-2 me-1">{intl.formatMessage({ id: "Export" })}</span>
              <ChevronDownIcon size={Constants.menuIconSize}/>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem 
                tag="a" 
                onClick={() => {
                  exportDynamicSalesDetailsApi(0);
                }}>
                {intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Cascade" }) })}
              </DropdownItem>
              <DropdownItem 
                tag="a" 
                onClick={() => {
                  exportDynamicSalesDetailsApi(1);
                }}>
                {intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "WithoutDetails" }) })}
              </DropdownItem>
              <DropdownItem 
                tag="a" 
                onClick={() => {
                  exportDynamicSalesDetailsApi(2);
                }}>
                {intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "RawData" }) })}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Dynamic-Sales-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "SubmissionDateRange" })}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              {
                returnSubBrandList().length > 0
                &&
                <DoubleColumnRowParent>
                  <DropDownWithTitleMultiSelect
                    name={"subBrandIds"}
                    title={intl.formatMessage({ id: "SubBrand" })}
                    specifyReturnFieldName={[{ "field": "subBrandIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"name"}
                    valueField={"id"}
                    options={returnSubBrandList()}
                    disabled={localLoading || loading}
                    initialValue={selectedSubBrandOptions}
                    validationRequired={false}
                    validation={validation} />
                </DoubleColumnRowParent>
              }
              {
                !getBranchUser() &&
                <DoubleColumnRowParent>
                  {
                    loadingBranchDropdown
                    ?
                    <Loading/>
                    :
                    <DropDownWithTitleMultiSelect
                      name={"branchIds"}
                      title={intl.formatMessage({ id: "Branch" })}
                      specifyReturnFieldName={[{ "field": "branchIds", "value": "id" }]}
                      returnFieldWithLabel={false}
                      labelField={"name"}
                      valueField={"id"}
                      options={branchDropDownList}
                      disabled={localLoading || loading || getBranchUser()}
                      initialValue={selectedBranchOptions}
                      validationRequired={false}
                      validation={validation} />
                  }
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                {
                  loadingSalesOrderStatus
                  ?
                  <Loading/>
                  :
                  <DropDownWithTitleMultiSelect
                    name={"statuses"}
                    title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "SalesOrder" }) })}
                    specifyReturnFieldName={[{ "field": "statuses", "value": "key" }]}
                    returnFieldWithLabel={false}
                    labelField={"displayValue"}
                    valueField={"key"}
                    options={salesOrderStatus}
                    disabled={localLoading || loading}
                    blnValueWithNewSpace={true}
                    initialValue={selectedStatusOptions}
                    validationRequired={false}
                    validation={validation} />
                }
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                {
                  loadingEmployeeDropdown
                  ?
                  <Loading/>
                  :
                  <DropDownWithTitleMultiSelect
                    name={"consultantIds"}
                    title={intl.formatMessage({ id: "Consultant" })}
                    specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={employeeDropdownList}
                    disabled={localLoading || loading}
                    initialValue={selectedConsultantOptions}
                    validationRequired={false}
                    validation={validation} />
                }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                {
                  <DropDownWithTitleMultiSelect
                    name={"customerIds"}
                    title={intl.formatMessage({ id: "Customer" })}
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    specifyReturnFieldName={[{ "field": "customerIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={customerDropdownList}
                    disabled={localLoading || loading}
                    initialValue={selectedCustomerOptions}
                    blnSupportCustomSearch={true}
                    onChangeFunction={()=> {
                      clearCustomerDropdownList();
                    }}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomerDropdown(false);
                    }}
                    loadingCustomSearch={loadingCustomerDropdown}
                    setLoadingCustomSearch={setLoadingCustomerDropdown}
                    validationRequired={false}
                    validation={validation} />
                }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "IncludeOtherSalesDetails" })}
                  name="includeOtherSalesDetails"
                  type="checkbox"
                  disabled={localLoading || loading}
                  validation={validation}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      Yes
                    </Label>
                  }
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "ProductOnly" })}
                  name="isProductOnly"
                  type="checkbox"
                  disabled={localLoading || loading}
                  validation={validation}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      Yes
                    </Label>
                  }
                />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                {
                  loadingGenderDropdown
                  ?
                  <Loading/>
                  :
                  <DropDownWithTitle
                    name={"gender"}
                    title={intl.formatMessage({ id: "Gender" })}
                    specifyReturnFieldName={[
                      {
                        field: "gender",
                        value: "key",
                      },
                    ]}
                    labelField={"displayValue"}
                    valueField={"displayValue"}
                    options={genderType}
                    blnValueWithNewSpace={true}
                    disabled={localLoading || loading}
                    validation={validation}
                  />
                }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                {
                  loadingRaceDropdown
                  ?
                  <Loading/>
                  :
                  <DropDownWithTitle
                    name={"race"}
                    title={intl.formatMessage({ id: "Race" })}
                    specifyReturnFieldName={[
                      {
                        field: "raceId",
                        value: "id",
                      },
                    ]}
                    labelField={"name"}
                    valueField={"id"}
                    options={raceDropdownList}
                    blnValueWithNewSpace={true}
                    disabled={localLoading || loading}
                    validation={validation}
                  />
                }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "JoinedDate" })})}
                  name={"JoinedDate"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectJoinedDateRange}
                  initialValue={selectedJoinedDateRange} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
                  name="salesOrderNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
                />
              </DoubleColumnRowParent>
            </Row>
            <hr/>
            <div className={`flex-align-items-center margin-bottom-16`}>
              <div style={{backgroundColor: Constants.lightgreyfaded, padding: '4px', paddingLeft: '16px', paddingRight: '16px', borderRadius: '8px'}} 
                onClick={()=> {
                  setBlnAdvanceFilter((valueAdvanceFilter)=> !valueAdvanceFilter);
                }}>
                <Label className="margin-bottom-0">{intl.formatMessage({ id: "AdvanceFilter" })}</Label>
                {blnAdvanceFilter ? <ChevronUpIcon/> : <ChevronDownIcon/>}
              </div>
            </div>
            {
              <Row style={{display: !blnAdvanceFilter ? "none": ""}}>
                <DoubleColumnRowParent>
                  {
                    loadingSalesCategoryDropdown
                    ?
                    <Loading/>
                    :
                    displayOptionalRow(()=> (
                      <DropDownWithTitleMultiSelect
                        name={"salesCategoryIds"}
                        className={"flex-1"}
                        title={intl.formatMessage({ id: "SalesCategory" })}
                        specifyReturnFieldName={[{ "field": "salesCategoryIds", "value": "id" }]}
                        returnFieldWithLabel={false}
                        labelField={"name"}
                        valueField={"id"}
                        options={salesCategoryDropdownList}
                        disabled={localLoading || loading}
                        initialValue={selectedSalesCategoryOptions}
                        validationRequired={false}
                        validation={validation} />
                    ))
                  }
                </DoubleColumnRowParent>
                <DoubleColumnRowParent>
                  {
                    loadingProductDropdown
                    ?
                    <Loading/>
                    :
                    displayOptionalRow(()=> (
                      <DropDownWithTitleMultiSelect
                        name={"productIds"}
                        className={"flex-1"}
                        title={intl.formatMessage({ id: "Product" })}
                        specifyReturnFieldName={[{ "field": "productIds", "value": "id" }]}
                        returnFieldWithLabel={false}
                        labelField={"name"}
                        valueField={"id"}
                        options={productDropdownList}
                        disabled={localLoading || loading}
                        initialValue={selectedProductOptions}
                        validationRequired={false}
                        validation={validation} />
                    ))
                  }
                </DoubleColumnRowParent>
                <DoubleColumnRowParent>
                  {
                    loadingServiceTreatmentDropdown
                    ?
                    <Loading/>
                    :
                    displayOptionalRow(()=> (
                      <DropDownWithTitleMultiSelect
                        name={"serviceIds"}
                        className={"flex-1"}
                        title={intl.formatMessage({ id: "ServiceTreatment" })}
                        specifyReturnFieldName={[{ "field": "serviceIds", "value": "id" }]}
                        returnFieldWithLabel={false}
                        labelField={"name"}
                        valueField={"id"}
                        options={serviceTreatmentDropdownList}
                        disabled={localLoading || loading}
                        initialValue={selectedServiceOptions}
                        validationRequired={false}
                        validation={validation} />
                    ))
                  }
                </DoubleColumnRowParent>
                <DoubleColumnRowParent>
                  {
                    loadingPackageDropdown
                    ?
                    <Loading/>
                    :
                    <DropDownWithTitleMultiSelect
                      name={"packageIds"}
                      className={"margin-right-20"}
                      title={intl.formatMessage({ id: "Package" })}
                      specifyReturnFieldName={[{ "field": "packageIds", "value": "id" }]}
                      returnFieldWithLabel={false}
                      labelField={"name"}
                      valueField={"id"}
                      options={packageDropdownList}
                      disabled={localLoading || loading}
                      initialValue={selectedPackageOptions}
                      validationRequired={false}
                      validation={validation} />
                  }
                </DoubleColumnRowParent>
              </Row>
            }
            <DoubleColumnRowParent>
              <MyButton
                type="button"
                class={`btn btn-primary margin-bottom-18`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchDynamicSalesDetailApi(true)
                }}
              />
            </DoubleColumnRowParent>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={DynamicSalesColumns}
              keyField={"id"}
              data={dynamicSalesDetailsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "DynamicSalesDetails" }) })}
              objSorted={defaultSorted}
              requestAPI={getDynamicSalesDetailsList}
              expandRow={expandRow}
              branchIds={validation.values.branchIds}
              customerIds={validation.values.customerIds}
              consultantIds={validation.values.consultantIds}
              salesCategoryIds={validation.values.salesCategoryIds}
              productIds={validation.values.productIds}
              packageIds={validation.values.packageIds}
              serviceIds={validation.values.serviceIds}
              statuses={validation.values.statuses}
              subBrandIds={validation.values.subBrandIds}
              includeOtherSalesDetails={validation.values.includeOtherSalesDetails}
              gender={validation.values.gender}
              raceId={validation.values.raceId}
              isProductOnly={validation.values.isProductOnly}
              salesOrderNo={validation.values.salesOrderNo}
              joinedFromDate={selectedJoinedDateRange?.fromDate}
              joinedToDate={selectedJoinedDateRange?.toDate}
              dateRange={selectedDateRange} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(DynamicSalesDetails);
