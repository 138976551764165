import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Progress,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import { useParams, Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { Constants } from "../../app/constants/Constants";
import Loading from "../../app/components/loading/Loading";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import '../../assets/css/familyTreeView.css';
import { checkPermission, displayTypeWithColor, returnPaymentIcon, returnPriceWithCurrency } from "../../app/common/function/function";
import moment from "moment";
import { RoutesList } from "../../app/constants/RoutesList";
import AccountIcon from 'mdi-react/AccountIcon';
import NotebookMultipleIcon from 'mdi-react/NotebookMultipleIcon';
import FileDocumentMultipleOutlineIcon from 'mdi-react/FileDocumentMultipleOutlineIcon';
import TimelineCheckOutlineIcon from 'mdi-react/TimelineCheckOutlineIcon';
import SiteMapCard from "./SubView/SiteMapCard";
import { history } from '../../';
import { PermissionConstants } from "../../app/constants/PermissionConstants";

const ContractLiabilitySiteMap = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  //Use Store
  const { contractLiabilityStore ,commonStore } = useStore();
  const { contractLiabilitySiteMap, getContractLiabilitySiteMap, setContractLiabilitySiteMap } = contractLiabilityStore;
  const { loading, setLoading, windowSize } = commonStore;
  const [displayContractLiability, setDisplayContractLiability] = useState(true);
  const [displayPaymentHistory, setDisplayPaymentHistory] = useState(true);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: intl.formatMessage({ id: "CustomerProfile" }), urlPath: `${RoutesList.customer}/view/${id}`}];
  const circleColumnPaddingRight = "18px"
  const circleBorderWidth = "3px";
  const orangeColor = "#f1b44c";
  const blueColor = "#50a5f1";
  const greenColor = Constants.lightgreen;
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer])
  const blnPermissionViewReceipt = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageContractLiability], true)) {
      return;
    }
    
    async function fetchContractLiabilitySiteMap() {
      setLoading(true);
      await getContractLiabilitySiteMap(id);
      setLoading(false);
    }

    fetchContractLiabilitySiteMap();

    return(()=> {
      setContractLiabilitySiteMap(undefined);
    })
  }, [])

  const onClickType = (type: string) => {
    if (type === Constants.contractLiability) {
      setDisplayContractLiability((prev)=> !prev);
    }
    else if (type === Constants.payment) {
      setDisplayPaymentHistory((prev)=> !prev);
    }
  }

  const headerTitle = (title: string, subString: string) => {
    return (
      <>
        <h5 className="pb-1 mt-2 mb-0 text-center" style={{fontSize: '14px'}}>{`${title}`}</h5>
        {subString && <p className="text-muted mt-0 text-center" style={{fontSize: '13px', marginBottom: '0px'}}>{`${subString}`}</p>}
      </>
    )
  }

  const contentTitle = (title: string, subString: string) => {
    return (
      <div>
        <p style={{fontSize: '13px'}} className="text-muted mb-2">{title}</p>
        <h5 style={{fontSize: '14px'}}>{subString}</h5>
      </div>
    )
  }

  return (
    <div>
      <div className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ContractLiabilitySiteMap" }) })}</title>
        </MetaTags>
        {
          loading
          ?
          <Loading />
          :
          <Container fluid>
            <BreadCrumb 
              breadCrumbList={breadCrumbList} 
              activeBreadCrumbTitle={intl.formatMessage({ id: "ContractLiabilitySiteMap" })}/>
            <div style={{overflowX: 'scroll'}}>
              <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : ""} style={{marginRight: "42px"}}>
                {displayTypeWithColor(blueColor, intl.formatMessage({id: "Customer"}), ()=> {})}
                {displayTypeWithColor(displayContractLiability ? orangeColor : Constants.lightgrey, intl.formatMessage({id: "ContractLiability"}), ()=> {onClickType(Constants.contractLiability)})}
                {displayTypeWithColor(displayPaymentHistory ? greenColor : Constants.lightgrey, intl.formatMessage({id: "PaymentHistory"}), ()=> {onClickType(Constants.payment)})}
              </div>
              <div className="treeview js-treeview" style={{overflowY: 'scroll', marginLeft: `-${windowSize.innerWidth/6}px`}}>
                <style type="text/css">
                  {
                    ` 
                      .treeview ul li { padding: 50px 0px 0px ${windowSize.innerWidth/5}px; } 
                      .treeview ul li:after {  width: ${windowSize.innerWidth/5}px; }
                    `
                  }
                </style>
                <ul style={{}}>
                  <li className="treeview-blue">
                    <SiteMapCard
                      colorString="light-blue"
                      color={blueColor}
                      circleColumnPaddingRight={"0px"}
                      circleBorderWidth={circleBorderWidth}
                      backgroundColor={"white"}
                      blnMarginLeft={"3px"}
                      IconDisplay={AccountIcon}
                      headerUI={()=> {
                        return (
                          blnPermissionViewCustomer
                          ?
                          <Link to={`/${RoutesList.customer}/view/${id}`} target={Constants.blank} rel="noopener noreferrer">
                            <div style={{width: '280px'}}>
                              {headerTitle(contractLiabilitySiteMap?.customerName || "" , contractLiabilitySiteMap?.customerNo || "")}
                              {<p className="text-muted mt-0 text-center" style={{fontSize: '13px'}}>{`${contractLiabilitySiteMap?.branchName || ""}`}</p>}
                            </div>
                          </Link>
                          :
                          <div style={{width: '250px'}}>
                            {headerTitle(contractLiabilitySiteMap?.customerName || "" , contractLiabilitySiteMap?.customerNo || "")}
                            {<p className="text-muted mt-0 text-center" style={{fontSize: '13px'}}>{`${contractLiabilitySiteMap?.branchName || ""}`}</p>}
                          </div>
                        )
                      }}/>
                    {
                      displayContractLiability
                      &&
                      contractLiabilitySiteMap
                      &&
                      <ul>
                      {
                        contractLiabilitySiteMap.contractLiabilities.map((valueContractLiability, index)=> (
                          <li key={`sales-order-${index}`} className="treeview-orange" style={{paddingLeft: windowSize.innerWidth/6}}>
                            <SiteMapCard
                              colorString="orange"
                              color={orangeColor}
                              circleColumnPaddingRight={circleColumnPaddingRight}
                              circleBorderWidth={circleBorderWidth}
                              backgroundColor={"white"}
                              IconDisplay={NotebookMultipleIcon}
                              headerUI={()=> {
                                return (
                                  headerTitle(intl.formatMessage({id: "ContractLiability"}), `${moment(valueContractLiability.paymentDate).format(Constants.displayDateFormat)}`)
                                )
                              }}
                              contentUI={()=> {
                                let processValue = valueContractLiability.amount === 0 ? 100 : (valueContractLiability.amount-valueContractLiability.balance)/valueContractLiability.amount*100;
                                return (
                                  <div className="text-center text-xl-start" style={{padding: '18px', width:"380px"}}>
                                    <Row>
                                      <Col xs="6">
                                        {contentTitle(intl.formatMessage({id: "Amount"}), returnPriceWithCurrency(valueContractLiability.amount))}
                                      </Col>
                                      <Col xs="6">
                                        {contentTitle(intl.formatMessage({id: "Balance"}), returnPriceWithCurrency(valueContractLiability.balance))}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="6">
                                        {contentTitle(intl.formatMessage({id: "ExpiredDate"}), moment(valueContractLiability.expiredDate).format(Constants.displayDateFormat))}
                                      </Col>
                                      <Col xs="6">
                                        {contentTitle(intl.formatMessage({id: "BalanceAfterExpired"}), returnPriceWithCurrency(valueContractLiability.balanceAfterExpired))}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xl='12'>
                                        <p style={{fontSize: '13px'}} className="text-muted mb-2">{processValue === 100 ? intl.formatMessage({id: "Completed"}) : intl.formatMessage({id: "Progressing"})}</p>
                                        <Progress striped color={processValue === 100 ? "warning" : "secondary"} animated value={processValue}></Progress>
                                      </Col>
                                    </Row>
                                    <div className="flex-align-items-center-without-height">
                                      <Link to={`/${RoutesList.contractLiability}/view/${valueContractLiability.id}`} target={Constants.blank} rel="noopener noreferrer">
                                        <div className="text-decoration-underline text-reset mt-2">
                                          <FileDocumentMultipleOutlineIcon className="mt-2" id="viewcontractliability" size={18}/>
                                          <UncontrolledTooltip placement="top" target="viewcontractliability">
                                            {intl.formatMessage({ id: "ViewDetail" })}
                                          </UncontrolledTooltip>
                                        </div>
                                      </Link>
                                      <Link to={`/${RoutesList.contractLiabilityTimeline}/view/${valueContractLiability.id}`} target={Constants.blank} rel="noopener noreferrer">
                                        <div className="text-decoration-underline text-reset mt-2">
                                          <TimelineCheckOutlineIcon className="mt-2 margin-left-8" id="viewtimeline" size={20}/>
                                          <UncontrolledTooltip placement="top" target="viewtimeline">
                                            {intl.formatMessage({ id: "ViewTimeline" })}
                                          </UncontrolledTooltip>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                )
                              }}/>
                            <ul style={{paddingLeft: '-100px'}}>
                              {
                                displayPaymentHistory
                                &&
                                valueContractLiability.paymentHistories.map((valuePaymentHistories, indexPaymentHistories)=> (
                                  <li key={`payment-histories-${index}-${indexPaymentHistories}`} style={{paddingLeft: windowSize.innerWidth/6}}>
                                    <SiteMapCard
                                      colorString="green"
                                      color={greenColor}
                                      circleColumnPaddingRight={circleColumnPaddingRight}
                                      circleBorderWidth={circleBorderWidth}
                                      backgroundColor={"white"}
                                      IconDisplay={returnPaymentIcon(valuePaymentHistories.paymentMethodName, true)}
                                      headerUI={()=> {
                                        return (
                                          <>
                                            {headerTitle(returnPriceWithCurrency(valuePaymentHistories.paidAmount), `${valuePaymentHistories.paymentMethodName}`)}
                                            <p className="text-muted mt-0 text-center">{valuePaymentHistories.cardType ? `(${valuePaymentHistories.cardType} | ${valuePaymentHistories.optionName} | ${valuePaymentHistories.last4CardDigitNo})` : valuePaymentHistories.optionName ? `(${valuePaymentHistories.optionName})` : ""}</p>
                                          </>
                                        )
                                      }}
                                      contentUI={()=> {
                                        return (
                                          <div className="text-center text-xl-start" style={{padding: '18px'}}>
                                            <Row>
                                              <Col xs="6">
                                                {contentTitle(intl.formatMessage({id: "PaymentDate"}), moment(valuePaymentHistories.paymentDate).format(Constants.displayDateFormat))}
                                              </Col>
                                              <Col xs="6">
                                                {contentTitle(intl.formatMessage({id: "PaymentBranch"}), valuePaymentHistories.branchName)}
                                              </Col>
                                            </Row>
                                            {
                                              blnPermissionViewReceipt
                                              ?
                                              <Link to={`/${RoutesList.receipt}/view/${valuePaymentHistories.id}`} target={Constants.blank} rel="noopener noreferrer">
                                                <div className="text-decoration-underline text-reset mt-2">
                                                  {intl.formatMessage({id: "ViewReceipt"})} <i className="mdi mdi-arrow-right"></i>
                                                </div>
                                              </Link>
                                              :
                                              <div className="text-decoration-underline text-reset mt-2">
                                                {intl.formatMessage({id: "ViewReceipt"})} <i className="mdi mdi-arrow-right"></i>
                                              </div>
                                            }
                                          </div>
                                        )
                                      }}/>
                                  </li>
                                ))
                              }
                            </ul>
                          </li>
                          ))
                        }
                      </ul>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        }
      </div>
    </div>
  )
}

export default observer(ContractLiabilitySiteMap);