import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { checkPermission, contructValidationErrorMessage, deliveryOrderStatusColor, displayPromotionCode, displayWatermark, escapedNewLineToLineBreakTag, getBranchId, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, productRedemptionStatusColor } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useFormik } from "formik";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SignaturePad from "../../app/components/form/SignaturePad";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { Link } from 'react-router-dom';
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import MyButton from "../../app/components/form/MyButton";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import CancelModal from "../../app/components/modal/CancelModal";
import TableActionButton from "../../app/components/table/TableActionButton";
import { DeliverOrderDetailArray } from "../../app/models/deliveryOrder";
import DeliveryOrderRedemptionDetailEditModal from "./Modal/DeliveryOrderRedemptionDetailEditModal";

const DeliveryOrderDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { deliveryOrderStore, stockMovementStore, machineStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
    successMessageWithoutNavigation,
    setErrorMessage,
    setSuccessMessage,
    setShowCancelPrompt
  } = commonStore;
  const { deliveryOrderDetail, setDeliveryOrderDetail, setCustomerIdFromCustomerPurchase, getDeliveryOrderWithId, signDeliveryOrder, updateDeliverOrder, requestVoidDeliveryOrder, reviewVoidDeliveryOrder, editDeliveryOrderDetail } = deliveryOrderStore;
  const { getStockMovement, stockMovementList, setStockMovementList } = stockMovementStore;
  const { getMachineUsage, machineUsageList, setMachineUsageList } = machineStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CustomerRedemption" })}`;
  const breadCrumbList = [{ title: `${intl.formatMessage({ id: "CustomerRedemption" })}`, urlPath: RoutesList.customerRedemption }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateCustomerDeliverOrder = checkPermission([PermissionConstants.UpdateCustomerDeliverOrder]);
  const blnPermissionRequestVoidCustomerDeliverOrder = checkPermission([PermissionConstants.RequestVoidCustomerDeliverOrder]);
  const blnPermissionReviewVoidCustomerDeliverOrder = checkPermission([PermissionConstants.ReviewVoidCustomerDeliverOrder]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);
  const blnPermissionManageCustomerVirtualWarehouse = checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse]);
  const blnPermissionUpdateCustomerDeliverOrderDetail = checkPermission([PermissionConstants.UpdateCustomerDeliverOrderDetail]);

  const [voidSalesModal, setVoidSalesModal] = useState(false);
  const [editDeliveryOrderRedemptionDetailModal, setEditDeliveryOrderRedemptionDetailModal] = useState(false);
  const [selectedDeliveryOrderRedemptionDetail, setSelectedDeliveryOrderRedemptionDetail] = useState<DeliverOrderDetailArray | undefined>(undefined);
  const [localLoading, setLocalLoading] = useState(false);
  const location = useLocation();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: deliveryOrderDetail || {
      id: "",
      customerDeliverOrderNo: "",
      customerSalesOrderId: "",
      customerSalesOrderNo: "",
      companyNumber: "",
      createdBy: "",
      brandId: "",
      brandName: "",
      businessEntityId: "",
      businessEntityName: "",
      branchId: "",
      branchName: "",
      customerId: "",
      customerPreferredName: "",
      customerFullName: "",
      customerContactNo: "",
      customerAddressLine1: "",
      customerAddressLine2: "",
      customerCity: "",
      customerPostcode: "",
      customerCountryName: "",
      customerStateName: "",
      performDateTime: "",
      signatureImage: "",
      status: "",
      reviewRemark: "",
      isVoidAllowed: false,
      voidDate: "",
      reviewedOn: "",
      reviewerUserName: "",
      isSystemAutoGenerated: false,
      deliverOrderDetails: [
        {
          redeemType: "",
          count: 0,
          redeemDetailId: "",
          redeemDetailName: "",
          serviceById: "",
          serviceByName: "",
          selectedProductIds: [],
          packageId: "",
          packageName: "",
          additionalData: {
            additionalProp1: "",
            additionalProp2: "",
            additionalProp3: "",
          },
          description: "",
          productBatchId: "",
          productBatchReferenceNo: "",
          productBatchWarehouseSupplierReferenceNo: ""
        }
      ],
      productRedemptionNoteDetails: [
        {
          productRedemptionNoteId: "",
          productRedemptionNoteNo: "",
          performedDateTime: "",
          status: "",
          virtualWarehouseId: "",
          virtualWarehouseNo: "",
          productName: "",
          quantity: 0
        }
      ]
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!valuesTemp.signatureImage) {
        validation.setFieldValue("signatureError", true)
      }
      else {
        let valuesFinal = {
          signatureImage: valuesTemp.signatureImage,
          customerDeliveryOrderIds: [valuesTemp.id]
        }

        try {
          let result = await signDeliveryOrder(valuesFinal);
          if (result?.status == Constants.success) {
            validation.setFieldValue("dataSubmitted", true);
          }
        } finally {
          validation.setSubmitting(false);
        }
      }
    },
  });

  async function fetchDeliveryOrderDetailAPI() {
    setLoading(true);
    let aryAPI: any = [
      getDeliveryOrderWithId(id),
    ];
    if (id && !addAction) {
      let resultAPI = await Promise.all(aryAPI);
      if(resultAPI[0]){
        if(resultAPI[0].status){
          let aryMovementAPI: any = [
            getStockMovement({ pageNumber: 1, pageSize: Constants.maxPageSize, stockMovementType: resultAPI[0].status === Constants.voided ? 16 : 5, redemptionId: id }),
            getMachineUsage({ pageNumber: 1, pageSize: Constants.maxPageSize, machineUsageType: resultAPI[0].status === Constants.voided ? 1 : 0, redemptionId: id })
          ];
          await Promise.all(aryMovementAPI)
        }
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCustomerDeliverOrder], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateCustomerDeliverOrder], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCustomerDeliverOrder], true)) {
        return;
      }
    }

    fetchDeliveryOrderDetailAPI();
    if (location) {
      if (location.state) {
        if (location.state.detail) {
          if (location.state.detail.customerId) {
            setCustomerIdFromCustomerPurchase(location.state.detail.customerId)
          }
        }
      }
    }

    return (() => {
      if (id && !addAction) {
        setDeliveryOrderDetail(undefined);
        setCustomerIdFromCustomerPurchase("");
        setStockMovementList([]);
        setMachineUsageList([]);
      }
    })
  }, []);

  useEffect(() => {
    if (localLoading) {
      validation.setSubmitting(true);
    }
    else {
      validation.setSubmitting(false);
    }
  }, [localLoading])

  useEffect(() => {
    if (deliveryOrderDetail) {
      let indexEditAction = _.findIndex(burgerMenuListContent, { label: `${intl.formatMessage({ id: "EditAction" })}` })
      if (indexEditAction < 0 && viewAction && blnPermissionUpdateCustomerDeliverOrder && !blnReceivedStatus && !blnVoidedStatus && !blnVoidRequestSubmittedStatus && !blnCancelledStatus) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.customerRedemption}/edit/${id}`) } })
      }
    }
  }, [deliveryOrderDetail])

  useEffect(()=> {
    if (successMessageWithoutNavigation) {
      if (editDeliveryOrderRedemptionDetailModal) {
        fetchDeliveryOrderDetailAPI(); 
        setTimeout(() => {
            setEditDeliveryOrderRedemptionDetailModal(false);
        }, Constants.dismissSuccessMessage);
      }
    }
  }, [successMessageWithoutNavigation])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const blnReceivedStatus = deliveryOrderDetail?.status == Constants.received;
  const blnVoidRequestSubmittedStatus = deliveryOrderDetail?.status == Constants.voidRequestSubmitted;
  const blnVoidedStatus = deliveryOrderDetail?.status == Constants.voided;
  const blnVoidAllowed = deliveryOrderDetail?.isVoidAllowed;
  const blnCancelledStatus = deliveryOrderDetail?.status == Constants.cancelled;
  const blnPendingStatus = deliveryOrderDetail?.status == Constants.pending;
  const blnIsSameBranch = deliveryOrderDetail?.branchId === getBranchId();
  const blnHqStaff = !getBranchUser();
  const blnIsSystemAutoGenerated = validation.values.isSystemAutoGenerated;

  const ProductRedemptionNoteColumns = [
    {
      dataField: "performedDateTime",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.performedDateTime ? moment(row.performedDateTime).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.performedDateTime ? moment(row.performedDateTime).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "productRedemptionNoteNo",
      text: intl.formatMessage({ id: "ProductRedemptionNo" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <div>
          {
            blnPermissionManageProductRedemptionNote
              ?
              <Link to={row.productRedemptionNoteId ? `/${RoutesList.productRedemptionNote}/view/${row.productRedemptionNoteId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="mb-1 pointer-clickable">{row.productRedemptionNoteNo || Constants.emptyData}</Label>
              </Link>
              :
              row.productRedemptionNoteNo
          }
        </div>,
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{getPurchaseTypeIcon(Constants.product)}{newSpaceBeforeCapitalLetter(row.productName)}</div>
      ),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
    },
    {
      dataField: "virtualWarehouseNo",
      text: intl.formatMessage({ id: "VirtualWarehouseNo" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <div>
          {
            blnPermissionManageCustomerVirtualWarehouse
              ?
              <Link to={row.virtualWarehouseId ? `/${RoutesList.customerVirtualWarehouse}/view/${row.virtualWarehouseId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="mb-1 pointer-clickable">{row.virtualWarehouseNo || Constants.emptyData}</Label>
              </Link>
              :
              row.virtualWarehouseNo
          }
        </div>,
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={productRedemptionStatusColor} />
      )
    },
  ];

  const StockMovementColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.actionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.actionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{getPurchaseTypeIcon(Constants.product)}{newSpaceBeforeCapitalLetter(row.productName)}</div>
      ),
    },
    {
      dataField: "moveFrom",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
    },
    {
      dataField: "moveTo",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.moveTo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Qty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        // <div className="text-center">{`${row.quantity}`}</div>
        <>
          <GeneralStatus
            status={(row.quantity)}
            statusBackgroundColor={Math.sign(row.quantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "balanceQuantity",
      text: intl.formatMessage({ id: "BalQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.balanceQuantity)}
            statusBackgroundColor={Math.sign(row.balanceQuantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "stockMovementType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.stockMovementType)}</div>
      ),
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
      style: {width: "420px"},
      formatter: (cellContent, row) => (
        <div>{`${row.action}`}</div>
      ),
    }
  ];

  //Table Content UI
  const MachineUsageColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.actionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.actionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "machineName",
      text: intl.formatMessage({ id: "Machine" }).toUpperCase(),
    },
    {
      dataField: "machineSerialNumber",
      text: intl.formatMessage({ id: "SerialNumber" }).toUpperCase(),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        // <div className="text-center">{`${row.quantity}`}</div>
        <>
          <GeneralStatus
            status={(row.quantity)}
            statusBackgroundColor={Math.sign(row.quantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "balanceQuantity",
      text: intl.formatMessage({ id: "BalanceQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.balanceQuantity)}
            statusBackgroundColor={Math.sign(row.balanceQuantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "machineUsageType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.machineUsageType)}</div>
      ),
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
      style: {width: "420px"},
      formatter: (cellContent, row) => (
        <div>{`${row.action}`}</div>
      ),
    }
  ];

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <Col xl="8">
          <h6 className="m-0 text-end text-muted">
            {label}
          </h6>
        </Col>
        <Col xl="4">
          <h6 className="text-end text-muted">
            {value}
          </h6>
        </Col>
      </Row>
    )
  }

  const onClickVoidSalesOrder = async () => {
    setLocalLoading(true);
    await requestVoidDeliveryOrder({ id: id })
    setLocalLoading(false);
  }

  const onClickReviewSalesOrder = async (valueAction: string) => {
    setLocalLoading(true);
    await reviewVoidDeliveryOrder({ id: id, reviewRemark: validation.values.reviewRemark, workflowAction: valueAction })
    setLocalLoading(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnShowPrint={true}>
        {loading && !successMessage ? (
          <Loading />
        ) :
          (
            <Form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}
            >
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody className="">
                      <div className="deliveryOrder-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "CustomerRedemption" })} {validation.values.isSystemAutoGenerated && <i className="font-size-17 text-muted fw-normal">{`(${intl.formatMessage({ id: "Auto" }).toUpperCase()})`}</i>}
                              </span>
                              <br />
                              <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                                {validation.values.brandName}
                              </h3>
                              <h3 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {validation.values.businessEntityName} {validation.values.companyNumber ? `(${validation.values.companyNumber})` : ""}
                              </h3>
                            </div>
                          </Col>
                          <Col>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "CustomerRedemptionNo" })}:`, validation.values.customerDeliverOrderNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "CustomerSalesOrderNo" })}:`,
                              blnPermissionManageCustomerSalesOrder ?
                                <Link to={validation.values.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${validation.values.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{validation.values.customerSalesOrderNo || Constants.emptyData}</Label>
                                </Link>
                                :
                                `${validation.values.customerSalesOrderNo}`)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "CreatedBy" })}:`, validation.values.createdBy)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "Date" })}:`, moment(validation.values.performDateTime).format(Constants.displayDateFormat))}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "Time" })}:`, moment(validation.values.performDateTime).format(Constants.displayTimeFormat))}
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6">
                          <address>
                            {/* <strong>{intl.formatMessage({id: "Branch"})}</strong> */}
                            <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "Branch" })}:</strong>
                            <br />
                            <span className="font-size-15 text-muted">{validation.values.branchName}</span>
                          </address>
                        </Col>
                        <Col xs="6" className="text-end">
                          <address>
                            <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "ShipTo" })}:</strong>
                            <br />
                            <span className="font-size-15 text-muted">
                              {
                                blnPermissionViewCustomer ?
                                  <Link to={validation.values.customerId ? `/${RoutesList.customer}/view/${validation.values.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                    <Label className="margin-bottom-0 pointer-clickable">{validation.values.customerPreferredName || Constants.emptyData}</Label>
                                  </Link>
                                  :
                                  `${validation.values.customerPreferredName}`
                              }
                            </span>
                            {validation.values.customerPreferredName && <br />}
                            <span className="font-size-15 text-muted">{validation.values.customerAddressLine1 || ""}</span>
                            {validation.values.customerAddressLine1 && <br />}
                            <span className="font-size-15 text-muted">{validation.values.customerAddressLine2 || ""}</span>
                            {validation.values.customerAddressLine2 && <br />}
                            <span className="font-size-15 text-muted">{validation.values.customerPostcode || ""} {validation.values.customerPostcode ? "," : ""} {validation.values.customerCity || ""}</span>
                            {(validation.values.customerPostcode || validation.values.customerCity) && <br />}
                            <span className="font-size-15 text-muted">{validation.values.customerStateName || ""}, {validation.values.customerCountryName || ""}</span>
                            {(validation.values.customerStateName || validation.values.customerCountryName) && <br />}
                            {validation.values.customerContactNo && <span>TEL: {validation.values.customerContactNo || Constants.emptyData}</span>}
                          </address>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <address>
                            <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                            <br />
                            <div style={{ marginTop: '8px' }}>
                              <GeneralStatus
                                status={validation.values.status}
                                statusColorFunction={deliveryOrderStatusColor}
                                blnDisableCenter />
                            </div>
                          </address>
                        </Col>
                        {
                          blnVoidedStatus
                          &&
                          <Col xs="6" className="text-end text-muted">
                            <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted">{`${intl.formatMessage({ id: "VoidRequestApprovedBy" })}: ${validation.values.reviewerUserName}`}</h4>
                            <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>{`${intl.formatMessage({ id: "VoidRequestApprovedDate" })}: ${moment(validation.values.reviewedOn).format(Constants.displayDateFormat)}`}</h4>
                            <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>{`${intl.formatMessage({ id: "VoidedDate" })}: ${moment(validation.values.voidDate).format(Constants.displayDateFormat)}`}</h4>
                          </Col>
                        }
                      </Row>
                      {/* watermark */}
                      {displayWatermark()}
                      <div className="py-2 margin-top-36">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "CustomerRedemption" }) })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "Name" })}</th>
                              <th>{intl.formatMessage({ id: "Package" })}</th>
                              <th>{intl.formatMessage({ id: "RedeemType" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "Quantity" })}</th>
                              {
                                !blnIsSystemAutoGenerated &&
                                <>
                                  <th>{intl.formatMessage({ id: "ServiceBy" })}</th>
                                  <th>{intl.formatMessage({ id: "Description" })}</th>
                                </>
                              }
                              <th>{intl.formatMessage({ id: "Action" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              validation.values.deliverOrderDetails.map((item, key) => (
                                <tr key={key}>
                                  <td>{getPurchaseTypeIcon(item.redeemType)}{`${item.redeemDetailName} ${item.productBatchReferenceNo ? `(${item.productBatchReferenceNo})` : ""}`} {displayPromotionCode(item.promotionCode)} </td>
                                  <td>{item.redeemType === Constants.packageService || item.redeemType === Constants.packageProduct || item.redeemType === Constants.packageOptionalProduct ? item.packageName : Constants.emptyData}</td>
                                  <td>{newSpaceBeforeCapitalLetter(item.redeemType)}</td>
                                  <td className="text-center">{item.count}</td>
                                  {
                                    !blnIsSystemAutoGenerated &&
                                    <>
                                      <td>{item.serviceByName || Constants.emptyData}</td>
                                      <td>{item.description || Constants.emptyData}</td>
                                    </>
                                  }
                                  <td>
                                    {
                                      item.isUpdateAllowed
                                      &&
                                      blnPermissionUpdateCustomerDeliverOrderDetail
                                      ?
                                        <TableActionButton 
                                          onClick={()=> {
                                            setSelectedDeliveryOrderRedemptionDetail(item);
                                            setEditDeliveryOrderRedemptionDetailModal(true);
                                          }}
                                          type="edit"
                                          disabled={loading || localLoading || blnVoidedStatus || Boolean(successMessage)}
                                        />
                                      :
                                      <div>-</div>
                                    }
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      {
                        // !blnIsSystemAutoGenerated &&
                        <SignaturePad
                          className="mb-4"
                          successMessage={successMessage}
                          validation={validation}
                          disabled={blnCancelledStatus || blnReceivedStatus || blnVoidedStatus || blnVoidRequestSubmittedStatus || Boolean(successMessage) || viewAction}
                          isSubmitting={validation.isSubmitting || localLoading} />
                      }
                      {
                        //footer
                        blnIsSystemAutoGenerated
                        &&
                        <p className="mt-4" style={{ fontSize: '12px', pageBreakInside: "avoid", fontWeight: '200' }}>{escapedNewLineToLineBreakTag(intl.formatMessage({ id: "AutoCustomerRedemptionFooterNote" }))}</p>
                      }
                      {
                        (blnVoidRequestSubmittedStatus || blnVoidedStatus) &&
                        <>
                          <div className={`py-2 ${blnIsSystemAutoGenerated ? "mt-4" : "mt-5"}`}>
                            <h3 className="font-size-15 font-weight-bold">
                              {intl.formatMessage({ id: "ReviewComment" })} :
                            </h3>
                          </div>
                          <GeneralTextArea
                            title=""
                            name="reviewRemark"
                            row={5}
                            disabled={loading || localLoading || blnVoidedStatus || !blnPermissionReviewVoidCustomerDeliverOrder || Boolean(successMessage)}
                            validation={validation} />
                        </>
                      }

                      <div className="d-print-none flex-direction-row">
                        {
                          blnVoidAllowed
                          &&
                          blnPermissionRequestVoidCustomerDeliverOrder
                          &&
                          blnReceivedStatus
                          &&
                          !Boolean(successMessage)
                          &&
                          ((!blnHqStaff && blnIsSameBranch) || blnHqStaff)
                          &&
                          <MyButton
                            type="button"
                            class="btn btn-danger"
                            content={intl.formatMessage({ id: validation.values.isSystemAutoGenerated ? "CancelReserved" :"VoidRedemption" })}
                            style={{ width: '150px', marginTop: blnIsSystemAutoGenerated ? "1rem" : "" }}
                            disable={loading || localLoading || Boolean(successMessage)}
                            loading={loading || localLoading}
                            onClick={async () => {
                              setVoidSalesModal(true);
                            }}
                          />
                        }
                        {
                          blnPermissionReviewVoidCustomerDeliverOrder
                          &&
                          blnVoidRequestSubmittedStatus
                          &&
                          !Boolean(successMessage)
                          &&
                          <>
                            <MyButton
                              type="button"
                              class="btn btn-success margin-right-8"
                              content={intl.formatMessage({ id: "Approve" })}
                              onClick={() => {
                                onClickReviewSalesOrder(Constants.approve);
                              }}
                              disable={loading || localLoading || !blnPermissionReviewVoidCustomerDeliverOrder || Boolean(successMessage)}
                              loading={loading || localLoading}
                            />
                            <MyButton
                              type="button"
                              class="btn btn-danger"
                              content={intl.formatMessage({ id: "Reject" })}
                              onClick={() => {
                                onClickReviewSalesOrder(Constants.reject);
                              }}
                              disable={loading || localLoading || !blnPermissionReviewVoidCustomerDeliverOrder || Boolean(successMessage)}
                              loading={loading || localLoading}
                            />
                          </>
                        }
                        <GeneralSubmitAndCancelBtn
                          successMessage={successMessage}
                          className="width-100-percentage"
                          viewAction={viewAction || blnReceivedStatus || blnVoidedStatus || blnVoidRequestSubmittedStatus || blnCancelledStatus}
                          validation={validation}
                          showPrint={true}
                          showExtraRightButton={blnPermissionUpdateCustomerDeliverOrder && blnPendingStatus}
                          extraRightButtonTitle={intl.formatMessage({ id: "Cancel" })}
                          extraRightButtonFunction={async () => {
                            setShowCancelPrompt(true);
                          }}
                          extraRightButtonColor={"btn-warning"}
                          extraRightButtonWithoutForm={true}
                          extraRightDirtyDisabled={true}
                          submitButtonTitle={intl.formatMessage({ id: "Receive" })}
                          submitButtonColor={"btn-info"}
                        />
                      </div>
                      {
                        !validation.values.isVoidAllowed
                        &&
                        !blnVoidRequestSubmittedStatus
                        &&
                        !blnVoidedStatus
                        &&
                        !blnPendingStatus
                        &&
                        <p className="d-print-none text-danger mt-2"><strong>{intl.formatMessage({ id: "CustomerRedemptionsVoidGeneralMessage" })}</strong></p>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {
                validation.values.productRedemptionNoteDetails.length > 0 &&
                <Row className="d-print-none">
                  <div>
                    <Col className="standard-layout">
                      <h5 className="mb-4">{intl.formatMessage({ id: "ProductRedemptionNote" })}</h5>
                      <PaginationTableWithoutApi
                        title={intl.formatMessage({ id: "ProductRedemptionNote" })}
                        options={validation.values.productRedemptionNoteDetails}
                        columns={ProductRedemptionNoteColumns}
                        pageSize={5}
                        keyField={"virtualWarehouseId"} />
                    </Col>
                  </div>
                </Row>
              }
              {
                stockMovementList.length > 0 &&
                <Row className="d-print-none mt-4">
                  <div>
                    <Col className="standard-layout">
                      <h5 className="mb-4">{intl.formatMessage({ id: "StockMovement" })} <i className ="text-muted">{`(${intl.formatMessage({ id: "ServiceOnly" })})`}</i></h5>
                      <PaginationTableWithoutApi
                        title={intl.formatMessage({ id: "StockMovement" })}
                        options={stockMovementList}
                        columns={StockMovementColumns}
                        pageSize={5}
                        keyField={"id"} />
                    </Col>
                  </div>
                </Row>
              }
              {
                machineUsageList.length > 0 &&
                <Row className="d-print-none mt-4">
                  <div>
                    <Col className="standard-layout">
                      <h5 className="mb-4">{intl.formatMessage({ id: "MachineUsage" })}</h5>
                      <PaginationTableWithoutApi
                        title={intl.formatMessage({ id: "MachineUsage" })}
                        options={machineUsageList}
                        columns={MachineUsageColumns}
                        pageSize={5}
                        keyField={"id"} />
                    </Col>
                  </div>
                </Row>
              }
            </Form>
          )}
        {
          voidSalesModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "VoidDeliveryOrderAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={voidSalesModal}
            setShowPrompt={setVoidSalesModal}
            onPrimaryClick={() => { onClickVoidSalesOrder() }}
          />
        }
        {
          editDeliveryOrderRedemptionDetailModal
          &&
          <DeliveryOrderRedemptionDetailEditModal
            blnShow={editDeliveryOrderRedemptionDetailModal}
            setModal={setEditDeliveryOrderRedemptionDetailModal}
            selectedDeliveryOrderRedemptionDetail={selectedDeliveryOrderRedemptionDetail}
            setSelectedDeliveryOrderRedemptionDetail={setSelectedDeliveryOrderRedemptionDetail}
            editFunction={editDeliveryOrderDetail}
            branchId={deliveryOrderDetail?.branchId || ""}/>
        }
        <CancelModal
          onCancelClick={async () => {
            setLocalLoading(true);
            await updateDeliverOrder({ id: id, workflowAction: Constants.cancel })
            setLocalLoading(false);
          }}
        />
      </DetailViewLayout>
    </div>
  );
};

export default observer(DeliveryOrderDetail);
