import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, newSpaceBeforeCapitalLetter, returnPriceWithCurrency } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import MyButton from "../../../app/components/form/MyButton";
import GeneralInput from "../../../app/components/form/GeneralInput";

const PackageMasterData = () => {
  const intl = useIntl();
  //Use Store
  const { reportMasterDataStore, commonStore } = useStore();
  const { loading, totalItem, windowSize  } = commonStore;
  const { packageMasterDataList, getPackageMasterDataList, setPackageMasterDataList, exportPackageMasterData } = reportMasterDataStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportMasterDataReport = checkPermission([PermissionConstants.ExportMasterDataReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      sKU: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageMasterDataReport], true)) {
      return;
    }

    return (() => {
      setPackageMasterDataList([]);
    })
  }, []);


  const fetchPackageMasterDataListWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if(blnExport){
      await exportPackageMasterData({ pageNumber: 1, pageSize: pageSize, name: validation.values.name });
    }
    else{
      setCurrentPage(1);
      await getPackageMasterDataList({ pageNumber: 1, pageSize: pageSize, packageName: encodeURIComponent(validation.values.name) });
    }
    setLocalLoading(false);
  };


  //Table Content UI
  const PackageMasterDataColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.randomId}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.name || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "originalPrice",
      text: intl.formatMessage({ id: "OriginalPrice" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {returnPriceWithCurrency(row.originalPrice)}
        </div>
      ),
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({ id: "SellingPrice" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {returnPriceWithCurrency(row.sellingPrice)}
        </div>
      ),
    },
    {
      dataField: "floorPrice",
      text: intl.formatMessage({ id: "FloorPrice" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {returnPriceWithCurrency(row.floorPrice)}
        </div>
      ),
    },
    {
      dataField: "groupCode",
      text: intl.formatMessage({ id: "GroupCode" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.groupCode || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "sessions",
      text: intl.formatMessage({ id: "TotalSessions" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.sessions}`}</div>
      ),
    },
    {
      dataField: "maxRedeemProductQuantity",
      text: intl.formatMessage({ id: "MaxRedeemProductQuantity" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.maxRedeemProductQuantity}`}</div>
      ),
    },
    {
      dataField: "optionalProductCost",
      text: intl.formatMessage({ id: "OptionalProductCost" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.optionalProductCost)}
        </div>
      ),
    },
    {
      dataField: "salesCategoryName",
      text: intl.formatMessage({ id: "SalesCategory" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.salesCategoryName)}</div>
      ),
    },
    {
      dataField: "taxName",
      text: intl.formatMessage({ id: "Tax" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.taxName}`}</div>
      ),
    },
    {
      dataField: "isUpgradeable",
      text: intl.formatMessage({ id: "Upgradeable" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isUpgradeable ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isPrepaidPackage",
      text: intl.formatMessage({ id: "PrepaidPackage" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isPrepaidPackage ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isCreditPackage",
      text: intl.formatMessage({ id: "CreditPackage" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isCreditPackage ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isProductBundledPackage",
      text: intl.formatMessage({ id: "ProductBundledPackage" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isProductBundledPackage ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "serviceRatio",
      text: intl.formatMessage({ id: "ServiceRatio" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.serviceRatio}`}</div>
      ),
    },
    {
      dataField: "serviceTreatments",
      text: intl.formatMessage({ id: "AvailableServiceTreatments" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"200px"}}>{row.serviceTreatments || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "productRatio",
      text: intl.formatMessage({ id: "ProductRatio" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.productRatio}`}</div>
      ),
    },
    {
      dataField: "mandatoryProducts",
      text: intl.formatMessage({ id: "MandatoryProducts" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"200px"}}>{row.mandatoryProducts || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "optionalProductRatio",
      text: intl.formatMessage({ id: "OptionalProductRatio" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.optionalProductRatio}`}</div>
      ),
    },
    {
      dataField: "optionalProducts",
      text: intl.formatMessage({ id: "OptionalProducts" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"200px"}}>{row.optionalProducts || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "branchPackages",
      text: intl.formatMessage({ id: "AvailableBranches" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"600px"}}>{`${row.branchPackages}`}</div>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PackageMasterData" }) })}
        title={intl.formatMessage({ id: "PackageMasterData" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMasterDataReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchPackageMasterDataListWithLoading(true)
            }}
          />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterPackageName" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchPackageMasterDataListWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={PackageMasterDataColumns}
              keyField={"randomId"}
              data={packageMasterDataList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PackageMasterData" }) })}
              objSorted={defaultSorted}
              requestAPI={getPackageMasterDataList}
              packageName={encodeURIComponent(validation.values.name)}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(PackageMasterData);
