import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { checkPermission, productRedemptionStatusColor, displayPromotionCode, displayWatermark, getBranchId, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, contructValidationErrorMessage } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useFormik } from "formik";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SignaturePad from "../../app/components/form/SignaturePad";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { Link } from 'react-router-dom';
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import MyButton from "../../app/components/form/MyButton";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import CancelModal from "../../app/components/modal/CancelModal";

const ProductRedemptionNoteDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { productRedemptionNoteStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    successMessageWithoutNavigation,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
    setShowCancelPrompt
  } = commonStore;
  const { productRedemptionNoteDetail, setProductRedemptionNoteDetail, getProductRedemptionNoteWithId, signProductRedemptionNote, requestVoidProductRedemptionNote, reviewVoidProductRedemptionNote, cancelProductRedemptionNote } = productRedemptionNoteStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ProductRedemptionNote" })}`;
  const breadCrumbList = [{ title: `${intl.formatMessage({ id: "VirtualWarehouse" })}`, urlPath: RoutesList.productRedemptionNote }, { title: `${intl.formatMessage({ id: "ProductRedemptionNote" })}`, urlPath: RoutesList.productRedemptionNote }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateProductRedemptionNote = checkPermission([PermissionConstants.UpdateProductRedemptionNote]);
  const blnPermissionRequestVoidProductRedemptionNote = checkPermission([PermissionConstants.RequestVoidProductRedemptionNote]);
  const blnPermissionReviewVoidProductRedemptionNote = checkPermission([PermissionConstants.ReviewVoidProductRedemptionNote]);
  const blnPermissionManageProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCustomerVirtualWarehouse= checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse]);
  const [voidSalesModal, setVoidSalesModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const location = useLocation();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: productRedemptionNoteDetail || {
      id: "",
      productRedemptionNo: "",
      businessEntityName: "",
      branchId: "",
      branchName: "",
      customerId: "",
      customerName: "",
      customerBranchName: "",
      companyNumber: "",
      taxNumber: "",
      performedDateTime: "",
      note: "",
      signatureImage: "",
      status: "",
      voidDate: "",
      reviewedOn: "",
      reviewerUserName: "",
      reviewRemark: "",
      isAllowStockRequest: false,
      productRedemptionNoteDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!valuesTemp.signatureImage) {
        validation.setFieldValue("signatureError", true)
      }
      else {
        let valuesFinal = {
          signatureImage: valuesTemp.signatureImage,
          id: valuesTemp.id
        }

        try {
          let result = await signProductRedemptionNote(valuesFinal);
          if (result?.status == Constants.success) {
            validation.setFieldValue("dataSubmitted", true);
          }
        } finally {
          validation.setSubmitting(false);
        }
      }
    },
  });

  async function fetchProductRedemptionNoteDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getProductRedemptionNoteWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateProductRedemptionNote], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageProductRedemptionNote], true)) {
        return;
      }
    }

    fetchProductRedemptionNoteDetailAPI();

    return (() => {
      if (id && !addAction) {
        setProductRedemptionNoteDetail(undefined);
      }
    })
  }, []);

  useEffect(()=> {
    if (localLoading) {
      validation.setSubmitting(true);
    }
    else {
      validation.setSubmitting(false);
    }
  }, [localLoading])

  useEffect(()=> {
    if (productRedemptionNoteDetail) {
      let indexEditAction = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "EditAction" })}`})
      if(indexEditAction < 0 && viewAction && blnPermissionUpdateProductRedemptionNote && !blnReceivedStatus && !blnVoidedStatus && !blnVoidRequestSubmittedStatus && !blnCancelledStatus){
        burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.productRedemptionNote}/edit/${id}`)} })
      }
    }
  }, [productRedemptionNoteDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const blnReceivedStatus = productRedemptionNoteDetail?.status == Constants.received;
  const blnVoidRequestSubmittedStatus = productRedemptionNoteDetail?.status == Constants.voidRequestSubmitted;
  const blnVoidedStatus = productRedemptionNoteDetail?.status == Constants.voided;
  const blnVoidAllowed = productRedemptionNoteDetail?.isVoidAllowed;
  const blnCancelledStatus = productRedemptionNoteDetail?.status == Constants.cancelled;
  const blnPendingStatus = productRedemptionNoteDetail?.status == Constants.pending;
  const blnIsSameBranch =  productRedemptionNoteDetail?.branchId === getBranchId();

  const blnHqStaff = !getBranchUser()
  
  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted">
            {value}
          </h6>
        </div>
      </Row>
    )
  }

  const onClickVoidSalesOrder = async () => {
    setLocalLoading(true);
    await requestVoidProductRedemptionNote({ id: id })
    setLocalLoading(false);
  }

  const onClickReviewSalesOrder = async (valueAction: string) => {
    setLocalLoading(true);
    await reviewVoidProductRedemptionNote({ id: id, reviewRemark: validation.values.reviewRemark, workflowAction: valueAction })
    setLocalLoading(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnShowPrint={true}>
        {loading && !successMessage ? (
          <Loading />
        ) :
          (
            <Form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}
            >
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody className="">
                      <div className="productRedemptionNote-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "ProductRedemptionNote" })}
                              </span>
                              <br />
                              <h3 className="font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {validation.values.businessEntityName} {validation.values.companyNumber ? `(${validation.values.companyNumber})` : ""}
                              </h3>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ProductRedemptionNo" })}:`, validation.values.productRedemptionNo)}
                              {displayHeaderRowData(`${intl.formatMessage({ id: "Date" })}:`, moment(validation.values.performedDateTime).format(Constants.displayDateFormat))}
                              {displayHeaderRowData(`${intl.formatMessage({ id: "Time" })}:`, moment(validation.values.performedDateTime).format(Constants.displayTimeFormat))}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6">
                          <address>
                            {/* <strong>{intl.formatMessage({id: "Branch"})}</strong> */}
                            <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "Branch" })}:</strong>
                            <br />
                            <span className="font-size-15 text-muted">{validation.values.branchName}</span>
                          </address>
                        </Col>
                        <Col xs="6" className="text-end">
                          <address>
                            <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "ShipTo" })}:</strong>
                            <br />
                            <span className="font-size-15 text-muted"> 
                              {
                                blnPermissionViewCustomer ?
                                <Link to={validation.values.customerId ? `/${RoutesList.customer}/view/${validation.values.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{validation.values.customerName || Constants.emptyData}</Label>
                                </Link>
                              :
                                `${validation.values.customerName}`
                              }
                            </span>
                          </address>
                        </Col>
                      </Row>
                      <Row>
                      <Col xs="6">
                      <address>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus 
                            status={validation.values.status}
                            statusColorFunction={productRedemptionStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      </Col>
                      {
                        blnVoidedStatus
                        &&
                        <Col xs="6" className="text-end text-muted">
                          <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted">{`${intl.formatMessage({id: "VoidRequestApprovedBy"})}: ${validation.values.reviewerUserName}`}</h4>
                          <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>{`${intl.formatMessage({id: "VoidRequestApprovedDate"})}: ${moment(validation.values.reviewedOn).format(Constants.displayDateFormat)}`}</h4>
                          <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>{`${intl.formatMessage({id: "VoidedDate"})}: ${moment(validation.values.voidDate).format(Constants.displayDateFormat)}`}</h4>
                        </Col>
                      }
                    </Row>
                      {/* watermark */}
                      {displayWatermark()}
                      <div className="py-2 margin-top-36">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "ProductRedemptions" }) })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "VirtualWarehouseNo" })}</th>
                              <th>{intl.formatMessage({ id: "Product" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "Quantity" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              validation.values.productRedemptionNoteDetails.map((item, key) => (
                                <tr key={key}>
                                  <td>
                                    {
                                      blnPermissionManageProductRedemptionNote ?
                                      <Link to={item.virtualWarehouseId ? `/${RoutesList.customerVirtualWarehouse}/view/${item.virtualWarehouseId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                        <Label className="margin-bottom-0 pointer-clickable">{item.virtualWarehouseNo || Constants.emptyData}</Label>
                                      </Link>
                                      :
                                      `${item.virtualWarehouseNo}`
                                    }
                                  </td>
                                  <td>{getPurchaseTypeIcon(Constants.product)}{item.productName}</td>
                                  <td className="text-center">{item.quantity}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <SignaturePad
                        successMessage={successMessage}
                        validation={validation}
                        disabled={blnCancelledStatus || blnReceivedStatus || blnVoidedStatus || blnVoidRequestSubmittedStatus || Boolean(successMessage) || viewAction}
                        isSubmitting={validation.isSubmitting || localLoading} />
                      {
                        (blnVoidRequestSubmittedStatus || blnVoidedStatus) &&
                        <>
                          <div className={`py-2 mt-5`}>
                            <h3 className="font-size-15 font-weight-bold">
                              {intl.formatMessage({ id: "ReviewComment" })} :
                            </h3>
                          </div>
                          <GeneralTextArea
                            title=""
                            name="reviewRemark"
                            row={5}
                            disabled={loading || localLoading || blnVoidedStatus || !blnPermissionReviewVoidProductRedemptionNote || Boolean(successMessage)}
                            validation={validation} />
                        </>
                      }
                      {
                        <div className="d-print-none flex-direction-row" style={{ marginTop: "3rem" }}>
                          {
                            blnVoidAllowed 
                            &&
                            blnPermissionRequestVoidProductRedemptionNote 
                            &&
                            blnReceivedStatus 
                            &&
                            !Boolean(successMessage)
                            &&
                            ((!blnHqStaff && blnIsSameBranch ) || blnHqStaff)
                            &&
                            <MyButton
                              type="button"
                              class="btn btn-danger margin-left-8"
                              content={intl.formatMessage({ id: "VoidRedemption" })}
                              style={{ width: '150px' }}
                              disable={loading || localLoading || Boolean(successMessage)}
                              loading={loading || localLoading}
                              onClick={async () => {
                                setVoidSalesModal(true);
                              }}
                            />
                          }
                          {
                            blnPermissionReviewVoidProductRedemptionNote
                            &&
                            blnVoidRequestSubmittedStatus
                            &&
                            !Boolean(successMessage)
                            &&
                            <>
                              <MyButton
                                type="button"
                                class="btn btn-success margin-right-8"
                                content={intl.formatMessage({ id: "Approve" })}
                                onClick={() => {
                                  onClickReviewSalesOrder(Constants.approve);
                                }}
                                disable={loading || localLoading || !blnPermissionReviewVoidProductRedemptionNote || Boolean(successMessage)}
                                loading={loading || localLoading}
                              />
                              <MyButton
                                type="button"
                                class="btn btn-danger"
                                content={intl.formatMessage({ id: "Reject" })}
                                onClick={() => {
                                  onClickReviewSalesOrder(Constants.reject);
                                }}
                                disable={loading || localLoading || !blnPermissionReviewVoidProductRedemptionNote || Boolean(successMessage)}
                                loading={loading || localLoading}
                              />
                            </>
                          }
                          <GeneralSubmitAndCancelBtn
                            successMessage={successMessage}
                            className="width-100-percentage"
                            viewAction={viewAction || blnReceivedStatus || blnVoidedStatus || blnVoidRequestSubmittedStatus || blnCancelledStatus}
                            validation={validation}
                            showPrint={true}
                            showExtraRightButton={blnPermissionUpdateProductRedemptionNote && blnPendingStatus}
                            extraRightButtonTitle={intl.formatMessage({ id: "Cancel" })}
                            extraRightButtonFunction={async () => {
                              setShowCancelPrompt(true);
                            }}
                            extraRightButtonColor={"btn-warning"}
                            extraRightButtonWithoutForm={true}
                            extraRightDirtyDisabled={true}
                            submitButtonTitle={intl.formatMessage({ id: "Receive" })}
                            submitButtonColor={"btn-info"}
                          />
                        </div>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        {
          voidSalesModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "VoidDeliveryOrderAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={voidSalesModal}
            setShowPrompt={setVoidSalesModal}
            onPrimaryClick={() => { onClickVoidSalesOrder() }}
          />
        }
        <CancelModal
          onCancelClick={async () => {
            setLocalLoading(true);
            await cancelProductRedemptionNote(id)
            setLocalLoading(false);
          }} />
      </DetailViewLayout>
    </div>
  );
};

export default observer(ProductRedemptionNoteDetail);
