import { ForgetPasswordFormValues, ForgetPasswordRequestFormValues, User, UserDropdownListObject, UserFormValues } from "../models/user";
import { makeAutoObservable, runInAction } from "mobx";
import { store } from "./store";
import agent from "../api/agent";
import { history } from "../..";
import { Constants } from "../constants/Constants";
import { RoutesList } from "../constants/RoutesList";
import _ from "lodash";

export default class UserStore {
  user: User | null = null;
  branchId: string = "";
  branchName: string = "";
  refreshTokenTimeout: any;
  userDropdownList: UserDropdownListObject[] = [];
  urlPathAfter401: string = "";
  switchBrandModal: boolean = false;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.user = null;
    this.branchId = "";
    this.branchName = "";
    this.stopRefreshTokenTimer();
    this.userDropdownList = [];
  }

  get isLogin(){
    return !!this.user;
  }

  setBranchId = (branchId: string) => {
    this.branchId = branchId;
  }

  setBranchName = (branchName: string) => {
    this.branchName = branchName;
  }

  setUrlPathAfter401 = (urlPathAfter401: string) => {
    this.urlPathAfter401 = urlPathAfter401;
  }

  setSwitchBrandModal = (switchBrandModal: boolean) => {
    this.switchBrandModal = switchBrandModal;
  }

  login = async (creds: UserFormValues) => {
    try{
      const user = await agent.Account.login(creds);
      return Promise.resolve(user);
    }catch(error){
      console.error(error);
      throw error;
    }
  }

  loginWithBrand = async (creds: UserFormValues) => {
    try{
      const userWithBrand = await agent.Account.brandLogin(creds);
      store.commonStore.setToken(userWithBrand.token);
      //Need to check on App.tsx -> fetchInitialData
      let aryAPI: any = [
        store.permissionStore.getPermissionUser(),
        store.profileStore.getProfileDetail(),
        store.brandStore.getSubBrandDropdown()
      ];
      this.startRefreshTokenTimer(userWithBrand);
      runInAction(() => {
          this.user = userWithBrand;
      })

      if (userWithBrand.preferredName) {
        store.commonStore.setDisplayName(userWithBrand.preferredName);
      }
      
      if (!userWithBrand.branchId) {
        aryAPI.push(store.branchStore.getBranchDropDown({}, true, false, true));
      }

      await Promise.all(aryAPI);
      if (this.urlPathAfter401 && this.urlPathAfter401 !== `/${RoutesList.login}` && this.urlPathAfter401 !== `/`) {
        history.push(`${this.urlPathAfter401}`)
        this.setUrlPathAfter401("");
      }
      else {
        history.push(`/${RoutesList.dashboard}`);
      }
    }catch(error){
      console.error(error);
      throw error;
    }
  }

  clearCredential = () => {
    store.commonStore.setToken(null);
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem('brandName');
    window.localStorage.removeItem('brandId');
    window.localStorage.removeItem('branchId');
    window.localStorage.removeItem('branchName');
    window.localStorage.removeItem('displayName');
    window.localStorage.removeItem('displayImage');
    window.localStorage.removeItem('showPendingMenu');
    
    this.user = null;
  }

  logout = async (tokenExpired: boolean = false, callLogoutAPI: boolean = true, refreshPage: boolean = true) => {
    try {
      store.commonStore.setDisplayName("");
      if (tokenExpired) {
        this.clearCredential();
        history.push({pathname: '/login', state: Constants.tokenExpired});
        if (refreshPage) {
          // window.location.reload();
          this.clearData();
        }
        return;
      }
      else if (callLogoutAPI){
        agent.Account.logout();
      }

      //Set this timeout is let logout api call with existing token first.
      setTimeout(()=> {
        this.clearCredential();
        history.push('/login');
        if (refreshPage) {
          // window.location.reload();
          this.clearData();
        }
      }, 100);
    }
    catch (e) {
      this.clearCredential();
      history.push('/login');
      if (refreshPage) {
        // window.location.reload();
        this.clearData();
      }
    }
  }

  getUser = async () => {
    try{
      const user = await agent.Account.current();
      store.commonStore.setToken(user.token);
      runInAction(() => this.user = user);
      this.startRefreshTokenTimer(user);
    }catch(error){
      console.error(error);
    }
  }

  refreshToken = async () => {
    this.stopRefreshTokenTimer();
    try{
      const user = await agent.Account.refreshToken();
      runInAction(() => {
          this.user = user;
      });
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
      return Promise.resolve(Constants.success);
    }catch(error){
      console.error(error);
    }
  }

  forgetPasswordRequest = async (username: ForgetPasswordRequestFormValues) =>{
    try{
      await agent.Account.forgetPasswordRequest(username);
      store.commonStore.setSuccessMessage(`EmailSendSuccess`)
      return Promise.resolve({status: Constants.success});
    }catch(error){
      console.error(error);
      throw error;
    }
  }

  forgetPassword = async (forgetPasswordRequestBody: ForgetPasswordFormValues) =>{
    try{
      await agent.Account.forgetPassword(forgetPasswordRequestBody);
      store.commonStore.setSuccessMessage(`PasswordResetSuccess`)
    }catch(error){
      console.error(error);
      throw error;
    }
  }

  getUserDropdown = async (employeeType? : number) => {
    try{
      const resultUserDropdown = await agent.Account.userDropdownList(employeeType);
      let resultUserDropdownTemp = resultUserDropdown.map((valueResultUserDropdown, index)=> {
        return({
          ...valueResultUserDropdown,
          userNameAndPreferredName: `${valueResultUserDropdown.preferredName} (${valueResultUserDropdown.userName})`, 
        })
      })
      runInAction(() => {
        this.userDropdownList = resultUserDropdownTemp;
      });
      return Promise.resolve(resultUserDropdownTemp)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.userDropdownList = [];
    }
  }

  getSwitchBrand = async (requestBody: { id: string }) => {
    try{
      let resultSwitchBrand = await agent.Account.switchBrand(requestBody.id);
      this.clearCredential();
      store.commonStore.setToken(resultSwitchBrand.token);
      window.localStorage.setItem("refreshTab", "true");
      setTimeout(()=> {
        window.localStorage.removeItem("refreshTab");
        window.location.reload();
      }, 100)
    }catch(error){
      console.error(error);
      throw error;
    }
  }

  private startRefreshTokenTimer(user: User) {
    const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    let timeout = expires.getTime() - Date.now() - (60 * 1000);
    if (timeout > 2147483647) {
      timeout = 2000000000;
    }
    this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  }

  private stopRefreshTokenTimer(){
    clearTimeout(this.refreshTokenTimeout);
  }

  private clearData() {
    store.additionalFormFieldStore.reset();
    store.appointmentStore.reset();
    store.auditLogStore.reset();
    store.branchStore.reset();
    store.brandStore.reset();
    store.businessEntityStore.reset();
    store.categoryStore.reset();
    store.reportSalesStore.reset();
    store.countryStore.reset();
    store.creditAdjustmentStore.reset();
    store.currencyStore.reset();
    store.customerCartStore.reset();
    store.customerOrderStore.reset();
    store.customerRefundStore.reset();
    store.customerSalesOrderStore.reset();
    store.customerStore.reset();
    store.deliveryOrderStore.reset();
    store.eisStore.reset();
    store.employeeStore.reset();
    store.employeeLeaveStore.reset();
    store.epfContributionStore.reset();
    store.goodsReceiveNoteStore.reset();
    store.inboundNoticeStore.reset();
    store.invoiceStore.reset();
    store.leadStore.reset();
    store.leaveEntitlementStore.reset();
    store.measurementStore.reset();
    store.mediaSourceStore.reset();
    store.packageStore.reset();
    store.payrollItemStore.reset();
    store.pcbStore.reset();
    store.permissionStore.reset();
    store.positionStore.reset();
    store.productStore.reset();
    store.profileStore.reset();
    store.promotionStore.reset();
    store.purchaseDeliverOrderStore.reset();
    store.purchaseOrderStore.reset();
    store.raceStore.reset();
    store.reportMarcomStore.reset();
    store.reportSalesStore.reset();
    store.reportAppointmentStore.reset();
    store.reportCustomerStore.reset();
    store.reportPaymentStore.reset();
    store.reportStockStore.reset();
    store.reportServicesStore.reset();
    store.reportManagementStore.reset();
    store.salesCategoryStore.reset();
    store.salesTargetStore.reset();
    store.serviceTreatmentStore.reset();
    store.settingsStore.reset();
    store.socsoStore.reset();
    store.staticReferenceStore.reset();
    store.stockMovementStore.reset();
    store.stockRequestStore.reset();
    store.stockTakeStore.reset();
    store.supplierStore.reset();
    store.taxStore.reset();
    store.transferCreditStore.reset();
    store.userRoleStore.reset();
    store.userStore.reset();
    store.warehouseStore.reset();
    store.stockAdjustmentStore.reset();
    store.stockInStore.reset();
    store.stockOutStore.reset();
    store.paymentMethodStore.reset();
    store.payslipStore.reset();
    store.notificationStore.reset();
    store.notificationTemplateStore.reset();
    store.dashboardStore.reset();
    store.extendPackageStore.reset();
    store.stockReturnToHQStore.reset();    
    store.customerPaymentAdjustmentStore.reset();
    store.customerProductReturnStore.reset();
    store.paymentMethodOptionStore.reset();
    store.salesAdjustmentStore.reset();
    store.commissionSchemeStore.reset();
    store.customerAccessRequestStore.reset();
    store.reportMasterDataStore.reset();
    store.customerTransferRequestStore.reset();
    store.additionBonusSalesTargetStore.reset();
    store.branchCommissionStore.reset();
    store.reportHumanResourceStore.reset();
    store.customerVirtualWarehouseStore.reset();
    store.productRedemptionNoteStore.reset();
    store.virtualWarehouseCreditExchangeStore.reset();
    store.productRedemptionNoteReturnStore.reset();
    store.contractLiabilityStore.reset();
    store.dsrAdjustmentStore.reset();
    store.batchJobStore.reset();
    store.customerSubBranchRequestStore.reset();
    store.appolousCustomerPackageStore.reset();
    store.appolousCustomerProductStore.reset();
    store.appolousCustomerSalesStore.reset();
    store.reportAppointmentStore.reset();
    store.reportAppolousStore.reset();
    store.virtualWarehouseStockMovementStore.reset();
    store.appointmentTargetStore.reset();
    store.marcomDailyRecordStore.reset();
    store.cacheStore.reset();
    store.departmentStore.reset();
    store.customerChangePaymentMethodStore.reset();
    store.appointmentLeadStore.reset();
    store.reportAppolousStore.reset();
    store.machineStore.reset();
    store.contractLiabilityBenchmarkStore.reset();
    store.groupCodeStore.reset();
    store.reportAccountStore.reset();
    store.tncStore.reset();
    store.unknownPaymentStore.reset();
    store.cashOutStore.reset();
    store.paymentTerminalStore.reset();
    store.changePaymentModeStore.reset();
    store.closeCounterStore.reset();
  }
}