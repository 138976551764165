import { PaginationRequestBody } from "../models/pagination";
import { ContractLiabilityDetailObject, ContractLiabilitySiteMapObject, ContractLiabilityTimelineObject } from "../models/contractLiability";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class contractLiabilityStore {
  contractLiabilitySiteMap: ContractLiabilitySiteMapObject | undefined = undefined;
  contractLiabilityDetail: ContractLiabilityDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.contractLiabilitySiteMap = undefined;
    this.contractLiabilityDetail = undefined;
  }

  setContractLiabilitySiteMap = (contractLiabilitysSiteMap: ContractLiabilitySiteMapObject | undefined) => {
    this.contractLiabilitySiteMap = contractLiabilitysSiteMap;
  }

  setContractLiabilityDetail = (contractLiabilityDetail: ContractLiabilityDetailObject | undefined) => {
    this.contractLiabilityDetail = contractLiabilityDetail;
  }
  
  getContractLiabilitySiteMap = async (id: string) => {
    try{
      const resultContractLiabilitySiteMap = await agent.ContractLiability.contractLiabilitySiteMap(id);
      runInAction(() => {
        this.contractLiabilitySiteMap = resultContractLiabilitySiteMap;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilitySiteMap = undefined;
    }
  }

  getContractLiabilityDetail = async (id: string) => {
    try{
      const resultContractLiabilityDetail = await agent.ContractLiability.contractLiabilityDetail(id);
      runInAction(() => {
        this.contractLiabilityDetail = resultContractLiabilityDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilityDetail = undefined;
    }
  }

  getContractLiabilityTimelineDetail = async (id: string) => {
    try{
      const resultContractLiabilityTimelineDetail = await agent.ContractLiability.contractLiabilityTimelineDetail(id);
      return Promise.resolve(resultContractLiabilityTimelineDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}