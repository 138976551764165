import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import { useLocation } from "react-router-dom";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import Borderline from "../../app/components/form/BorderLine";
import { CustomerSalesOrderUpgradeFormValues } from "../../app/models/customerSalesOrder";
import { getBranchId, reformatSubmitValueWithClass, calculateBalanceAmount, contructValidationErrorMessage, checkPermission } from "../../app/common/function/function";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import PaymentSubview from "../CustomerOrder/subView/PaymentSubview";
import PackageUpgradeCart from "./subView/PackageUpgradeCart";
import { AvailableUnknownPaymentListObject } from "../../app/models/customer";
import { PaymentMethodOptionForPaymentListObject } from "../../app/models/paymentMethodOption";
import { PermissionConstants } from "../../app/constants/PermissionConstants";

const PackagePayment = () => {
  //Use INTL
  const intl = useIntl();
  
  //Use Store
  const { branchStore, customerStore, employeeStore, settingsStore, staticReferenceStore, customerSalesOrderStore, paymentMethodOptionStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, setErrorMessage, setSuccessMessage, windowSize } = commonStore;
  const { customerDropdownList, getCustomerAvailablePayment } = customerStore;
  const { employeeDropdownList } = employeeStore;
  const { branchPaymentMethod, getBranchPaymentMethod } = branchStore;
  const { generalSettings, getGeneralSettings} = settingsStore;
  const { cardType, getStaticReferenceWithType } = staticReferenceStore;
  const { submitCustomerSalesOrderUpgrade } = customerSalesOrderStore;
  const { getPaymentMethodOptionDropdown } = paymentMethodOptionStore;
  const location = useLocation();
  const breadCrumbList = [{ title: `${intl.formatMessage({id: "Adjustment"})}`, urlPath: RoutesList.packageUpgrade }, { title: `${intl.formatMessage({id: "PackageUpgrade"})}`, urlPath: RoutesList.packageUpgrade }];
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [availableUnknownPaymentList, setAvailableUnknownPaymentList] = useState<AvailableUnknownPaymentListObject[]>([]);
  const blnPermissionUseUnknownCustomerPayment = checkPermission([PermissionConstants.UseUnknownCustomerPayment]);

  // validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      salesOrderDetailId: "",
      customerPackageName: "",
      branchId: "",
      consultantIds: [],
      // expiredDate: "",
      note: "",
      customerPackageDetails: [],
      customerPaymentDetails: [],
      paidNumber: 0,
      totalPrice: 0,
      availableCredits: 0
		},
		validationSchema: Yup.object({
      customerPaymentDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({id: "PaymentMethod"}).toLowerCase()})),
      // expiredDate: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ExpiredDate" }) })),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = reformatSubmitValueWithClass(values, CustomerSalesOrderUpgradeFormValues);
      
      let totalPaid = 0;
      valuesTemp.customerPaymentDetails = valuesTemp.customerPaymentDetails.map((valueCustomerPaymentDetailsTemp)=> {
        totalPaid += Number(valueCustomerPaymentDetailsTemp.paidAmount)
        return ({
          ...valueCustomerPaymentDetailsTemp,
          transactionNo: valueCustomerPaymentDetailsTemp.transactionNo ? String(valueCustomerPaymentDetailsTemp.transactionNo): "",
        })
      })

      if (totalPaid > values.totalPrice) {
        setErrorMessage(intl.formatMessage({ id: "TotalPaidAmountCouldNotMoreThanTotalPrice"}))
        return;
      }

      valuesTemp.consultantIds = valuesTemp.consultantIds.map((valueTemp)=> valueTemp.value)
      valuesTemp.branchId = getBranchId();

      let result = await submitCustomerSalesOrderUpgrade(valuesTemp);
      if (result?.status == Constants.success) {
        let valuesFinal : any = _.cloneDeep(values);
        if (result?.data) {
          valuesFinal = {
            ...values, 
            salesOrderId: result?.data?.salesOrderId,
            invoiceId: result?.data?.invoiceId
          }
        }
        setTimeout(() => {
          setSuccessMessage("")
          // history.push({
          //   pathname: `/${RoutesList.packageUpgradeAcknowledgement}`,
          //   state: { detail: valuesFinal }
          // });
          history.push({ pathname: `/${RoutesList.customerSalesOrder}/view/${result?.data?.salesOrderId}`});
        }, Constants.dismissSuccessMessage)
      }
		},
	});

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchPackageAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchPaymentMethod(getBranchId()),
        getStaticReferenceWithType(Constants.cardType),
        getGeneralSettings()
      ];
      if (location.state) {
        aryAPI.push(getCustomerAvailablePayment(location.state.detail.customerId))
      }
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI.length > 3) {
        validation.setFieldValue("availableCredits", resultAPI[3].availableCustomerCredits || 0);
        setAvailableUnknownPaymentList(resultAPI[3].availableUnknownPayments || []);
      }
      setLoading(false);
    }

    fetchPackageAPI();
  }, [])

  useEffect(() => {
    if (location.state) {
      validation.setValues({
        ...location.state.detail,
        paidNumber: location.state.detail.totalPackagePrice,
        totalPrice: location.state.detail.totalPackagePrice,
        totalRoundingPrice: location.state.detail.totalRoundingPrice,
        // expiredDate: returnExpiredDate(Constants.textInputDateFormat)
      })
    }
    else {
      history.push(`/${RoutesList.packageUpgrade}`)
    }
 }, [location]);

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const deletePaymentOrder = (index) => {
    let customerPaymentDetailsTemp : any = _.cloneDeep(validation.values.customerPaymentDetails);
    if (customerPaymentDetailsTemp[index].unknownPaymentId) {
      let availableUnknownPaymentListTemp = _.cloneDeep(availableUnknownPaymentList);
      let indexAvailableUnknownPaymentTemp = _.findIndex(availableUnknownPaymentListTemp, {unknownPaymentId : customerPaymentDetailsTemp[index].unknownPaymentId})
      if (indexAvailableUnknownPaymentTemp > -1) {
        availableUnknownPaymentListTemp[indexAvailableUnknownPaymentTemp].availableAmount = Number(availableUnknownPaymentListTemp[indexAvailableUnknownPaymentTemp].availableAmount) + Number(customerPaymentDetailsTemp[index].paidAmount)
        setAvailableUnknownPaymentList(availableUnknownPaymentListTemp);
      }
    }
    customerPaymentDetailsTemp.splice(index, 1);
    validation.setFieldValue("customerPaymentDetails", customerPaymentDetailsTemp);
    let prefillPaidNumber : any = calculateBalanceAmount(validation, "customerPaymentDetails", "totalPrice", customerPaymentDetailsTemp);
    validation.setFieldValue("paidNumber", prefillPaidNumber);
  }

  return (
    <div>
      <div 
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "PackageUpgradeSummary"})})}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
            <div className="h4">{intl.formatMessage({id: "PackageUpgradeSummary"})}</div>
            </Col>
          </Row>
          <BreadCrumb 
            breadCrumbList={breadCrumbList}
            activeBreadCrumbTitle={intl.formatMessage({id: "PackageUpgradeSummary"})}/>
          {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          {loading && !successMessage ? (
            <Loading />
					) : (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={()=> {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="5" md="5" sm="12" xs="12">
                  <div className="standard-layout">
                    <PackageUpgradeCart
                      validation={validation}
                      disabledPackageUpgradePayment={disabledFieldInput}
                      deletePaymentOrder={deletePaymentOrder}/>
                  </div>
                </Col>
                <Col xl="7" md="7" sm="12" xs="12" className={`${Constants.innerWidthCompare770 > windowSize.innerWidth ? "margin-top-16" : ""}`}>
                  <div className={`standard-layout ${Constants.innerWidthCompare770 > windowSize.innerWidth ? "padding-top-0 margin-top--40" : ""}`}>
                    <PaymentSubview
                      validation={validation}
                      disabledFieldInput={disabledFieldInput}
                      branchPaymentMethod={branchPaymentMethod}
                      availableUnknownPaymentList={blnPermissionUseUnknownCustomerPayment ? availableUnknownPaymentList : []}
                      setAvailableUnknownPaymentList={setAvailableUnknownPaymentList}
                      cardType={cardType}
                      paymentFieldName={"customerPaymentDetails"}
                      isTransferCreditEnabled={generalSettings?.isTransferCreditEnabled || false}
                      setErrorMessage={setErrorMessage}
                      getPaymentMethodOptionDropdown={getPaymentMethodOptionDropdown}/>
                    <Borderline/>
                    <Row>
                      <DoubleColumnRowParent
                        blnDoubleTab={true}>
                        <DropDownWithTitle
                          name={"customerId"}
                          className="margin-bottom-4"
                          placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                          title={intl.formatMessage({id: "Customer"})}
                          specifyReturnFieldName={[
                            {
                              field: "customerId",
                              value: "id",
                            },
                            {
                              field: "customerName",
                              value: "preferredName",
                            },
                            {
                              field: "customerNo",
                              value: "customerNo",
                            },
                            {
                              field: "customerMobileNo",
                              value: "mobileNo",
                            },
                            {
                              field: "availableCredits",
                              value: "availableCredits",
                            },
                          ]}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={customerDropdownList}
                          disabled={true}
                          initialLabel={validation.values.customerName}
                          initialValue={validation.values.customerId}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent
                        blnDoubleTab={true}>
                        <DropDownWithTitleMultiSelect
                          name={"consultantIds"}
                          title={"Consultants"}
                          specifyReturnFieldName={[{"field": "consultantIds", "value": "id"}]}
                          returnFieldWithLabel={true}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={employeeDropdownList}
                          disabled={true}
                          initialValue={validation.values.consultantIds}
                          validationRequired={true}
                          validation={validation}
                          />
                      </DoubleColumnRowParent>
                    </Row>
                    <Borderline/>
                    <Row>
                      <Col xl={4}>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="button"
                              class="btn btn-back"
                              style={{ width: '100%' }}
                              content={intl.formatMessage({ id: "Back" }).toUpperCase()}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                history.push({
                                  pathname: `/${RoutesList.packageUpgrade}`
                                });
                              }}
                            />
                          )}
                      </Col>
                      <Col xl={"8"}>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="submit"
                              class="btn btn-primary"
                              style={{width: '100%'}}
                              content={intl.formatMessage({ id: "ConfirmUpgradePackage"}).toUpperCase()}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                            />
                          )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </div>
    </div>
  );
}

export default observer(PackagePayment);
