import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const PaymentMethodDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { staticReferenceStore, paymentMethodStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const {
    addPaymentMethod,
    updatePaymentMethod,
    paymentMethodDetail,
    setPaymentMethodDetail,
    getPaymentMethodWithId,
  } = paymentMethodStore;
  const { paymentMethodOptionType, getStaticReferenceWithType } =
    staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "PaymentMethod" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "PaymentMethod" }), urlPath: RoutesList.paymentMethod }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdatePaymentMethod = checkPermission([PermissionConstants.UpdatePaymentMethod]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: paymentMethodDetail || {
      name: "",
      isCardInput: false,
      isQRPay: false,
      isCustomerCredit: false,
      isActive: false,
      isTransferCredit: false,
      isOptionAvailable: false,
      isAllowEPP: false,
      optionType: "",
      displayOrder: 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PaymentMethodName" }) })),
      displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DisplayOrder" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!blnOptionAvailable) {
        valuesTemp.optionType = null;
      }
      try {
        if (addAction) {
          await addPaymentMethod(valuesTemp);
        } else {
          await updatePaymentMethod(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnOptionAvailable = validation.values.isOptionAvailable

  useEffect(() => {
    async function fetchPaymentMethodDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.paymentMethodOptionType),
      ];
      if (id && !addAction) {
        aryAPI.push(getPaymentMethodWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setPaymentMethodDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePaymentMethod], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePaymentMethod], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePaymentMethod], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdatePaymentMethod){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.paymentMethod}/edit/${id}`)} })
    }

    fetchPaymentMethodDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPaymentMethodDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PaymentMethod" }) })}
                  h4Title
                />
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "PaymentMethodName" })}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "OptionAvailable" })}
                    name="isOptionAvailable"
                    type="checkbox"
                    disabled={
                      disabledFieldInput ||
                      viewAction
                    }
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                {
                  blnOptionAvailable &&
                  <SingleColumnRowParent>
                    <DropDownWithTitle
                      name={"optionType"}
                      title={intl.formatMessage({ id: "PaymentMethodOptionType" })}
                      specifyReturnFieldName={[
                        {
                          field: "optionType",
                          value: "displayValue",
                        },
                      ]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={paymentMethodOptionType}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={!addAction ? validation.values.optionType : undefined}
                      validationRequired={true}
                      validation={validation}
                    />
                  </SingleColumnRowParent>
                }
                <Row>
                  <Col sm="6" md="6" xl="6">
                    <Row>
                      <Col>
                        <GeneralInput
                          title={intl.formatMessage({ id: "CardInput" })}
                          name="isCardInput"
                          type="checkbox"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col>
                        <GeneralInput
                          title={intl.formatMessage({ id: "QRPay" })}
                          name="isQRPay"
                          type="checkbox"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col>
                        <GeneralInput
                          title={intl.formatMessage({ id: "CustomerCredit" })}
                          name="isCustomerCredit"
                          type="checkbox"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col>
                        <GeneralInput
                          title={intl.formatMessage({ id: "TransferCredit" })}
                          name="isTransferCredit"
                          type="checkbox"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AllowEPP" })}
                    name="isAllowEPP"
                    type="checkbox"
                    disabled={
                      disabledFieldInput ||
                      viewAction
                    }
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Active" })}
                    name="isActive"
                    type="checkbox"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        {intl.formatMessage({ id: "Yes" })}
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DisplayOrder" })}
                    name="displayOrder"
                    type="number"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(PaymentMethodDetail);
